/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */

import React from "react";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import PropTypes from "prop-types";
import NPIf from "np-if";
import Button from "react-bootstrap/Button";
import{money} from "../../lib/money/money";
import NbioApi from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import {Alert, OverlayTrigger} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from "react-bootstrap/Tooltip";


//DATES
const dayjs = require('dayjs')
//CONST
const INFINITY = 9999999999;
const _  = require('underscore');

class CouponSummary extends React.Component {
    constructor(p) {
        super(p);
        this.onClickCancel      = this.onClickCancel.bind(this);
        this.onClickPublish     = this.onClickPublish.bind(this);
        this.state = {
            tooltip:'Copiar'
        }
    }
    renderType = (type) => {
        switch (type){
            case 'total':
                return 'Total de la compra';
                break;
            case 'item':
                return 'Item de carrito';
                break;
            case 'free_shipping':
                return 'Envio gratis';
                break;
            default:
                return '---';
                break;
        }
    }

    renderMinMaxPurchase = (hasMinMax,amount) => {
        if(hasMinMax){
            return `Si / ${money(amount)}`;
        }else{
            return 'No'
        }
    }

    renderDate = (date) => {
        if(date){
            return date
        }else{
            return '---'
        }
    }
    renderPublishedBy = (user) =>{
        if(!user){
            return '-';
        }
        const name = [user.name, user.last_name].filter((n) => n).join(' ');
        return `${name} (${user.email})`;
    }

    renderDiscountType = (discount) => {
        switch (discount){
            case 'fixed_amount':
                return 'Cantidad';
                break;
            case 'percentage':
                return 'Porcentaje';
                break;
            default:
                return '---';
                break;
        }
    }

    renderDistribution = (dis) => {
        switch (dis){
            case 'internal':
                return 'Dentro de la aplicación';
                break;
            case 'external':
                return 'Fuera de las aplicación';
                break;
            default:
                return '---';
                break;
        }
    }
    createInfluencerInput = (coupon) =>{
        Swal.fire({
            title:'Escribe el nombre del influencer',
            html:'Lo que ingreses a continuación será visible para el influencer',
            input: 'text',
            confirmButtonText: 'Crear enlace',
            showCancelButton:true,
            cancelButtonText:'Cancelar',
            preConfirm: (influencerName) => {
                if(!influencerName){
                    Swal.showValidationMessage(
                        `El nombre del influencer no puede estar vacio.`
                    )
                }else{
                    return influencerName;
                }
            },
            heightAuto:false
        }).then((res) => {
            const influencerName = res.value
            if (res.isConfirmed) {
                this.doCreateInfluencerLink(coupon,influencerName);
            }
        });
    }
    createInfluencerLink = (coupon) =>{
        Swal.fire({
            title: '¿Estás seguro?',
            html: '¿Estás seguro de crear el enlace para influencers? Los datos del cupón serán visibles para los usuarios que visiten el enlace aún cuando el cupón haya caducado o desactivado.',
            confirmButtonText: 'Crear enlace',
            cancelButtonText: 'Cancelar',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false,
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                // this.doCreateInfluencerLink(coupon);
                this.createInfluencerInput(coupon);
            } else if(result.dismiss === Swal.DismissReason.cancel){
            }
        })
    }
    renderAudience = (audience,segmentacionArray) => {
        const groups = _.groupBy(segmentacionArray,x => x.segmentLabel);
        const keys = Object.keys(groups);
        if(audience === 'segmentation'){
            // return 'segmentation'
            return keys.map((k) => {
                const segments = groups[k].map((s) => s.label).join(', ');
                return(
                    <>
                        <b>{k}:</b> {segments}
                        &nbsp;
                    </>
                );
            })
            // return segmentacionArray.map((s) => `${s.segmentLabel}: ${s.label}`).join(' ');
        }else if(audience === 'all'){
            return 'Todos los usuarios';
        }else{
            return '---'
        }
    }

    renderProducts = (type,products, categories) => {
        if(type === 'item'){
            const p = products.map(item => item.name);

            return(
                <>
                    <Col sm={2}>Productos: </Col><Col sm={10}>{p.length > 0 ? p.join(', ') : '---'}</Col>
                    <Col sm={2}>Categorías: </Col><Col sm={10}>{categories.length > 0 ? categories.join(', ') : '---'}</Col>
                    <hr/>
                </>
            )
        }else{
            return null
        }
    }
    onClickDeactivate = () =>{
        let text = `Al desactivar el cupón este será removido de los usuarios y no podrá ser canejado. Esta acción no puede ser deshecha.`;
        Swal.fire({
            title: 'Desactivar',
            html: text,
            confirmButtonText: 'Desactivar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#4dda85',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false,
            reverseButtons: true
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                this.deactivateCoupon();
            } else if(result.dismiss === Swal.DismissReason.cancel){
            }
        })
    }
    onClickPublish = () => {
        let text = `Una vez publicado y enviado, <u><b>el cupón no podrá ser editado</b></u>.
                    Solo se enviarán los cupones que su distribución sea por medio de la aplicación.`;

        Swal.fire({
            title: 'Publicar y enviar',
            html: text,
            confirmButtonText: 'Publicar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#4dda85',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false,
            reverseButtons: true
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                this.publishCoupon();
            } else if(result.dismiss === Swal.DismissReason.cancel){
            }
        })
    }
    deactivateCoupon = () =>{
            NbioApi.coupons.deactivate(this.props.coupon._id).then((res) => {
                Swal.fire({
                    title: 'Cupón desactivado',
                    html: 'Cupón desactivado',
                    icon: 'success',
                    confirmButtonColor: '#4dda85',
                    heightAuto: false
                }).then((_res) => {
                    this.props.onDeactivated(this.props.coupon);
                });
            }).catch((ex) => {
                let errorMsg = 'Hubo un problema al desactivar el cupón. Intenta más tarde.';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {

                }
                Swal.fire({
                    icon:'error',
                    text:errorMsg,
                });
            })
    }
    doCreateInfluencerLink = (coupon,influencerName) =>{
        NbioApi.coupons.createInfluencerLink(this.props.coupon._id,influencerName).then((res) => {
            this.props.onInfluencerLinkCreated(this.props.coupon);
        }).catch((ex) => {
            let errorMsg = 'Hubo un problema al crear el enlace. Intenta más tarde.';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {

            }
            Swal.fire({
                icon:'error',
                text:errorMsg,
            });
        })
    }
    onClickCancel = () => {
        this.props.onClickCancel();
    }

    publishCoupon = () => {
        NbioApi.coupons.publish(this.props.coupon._id).then((res) => {
            Swal.fire({
                title: 'Cupón publicado',
                html: 'Cupón publicado',
                icon: 'success',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((_res) => {
                this.props.onPublished(this.props.coupon);
            });
        }).catch((ex) => {
            let errorMsg = 'Hubo un problema al publicar el cupón. Intenta más tarde.';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {

            }
            Swal.fire({
                icon:'error',
                text:errorMsg,
            });
        })
    }
    renderValue(){
        const coupon           = this.props.coupon;
        if(coupon.discountType === 'percentage') {
            return `${coupon.value} %`;
        }else if(coupon.discountType === 'fixed_amount') {
            return `${money(coupon.value)}`;
        }else if(coupon.discountType === 'free_shipping') {
            return 'Envío gratis'
        }else{
            return coupon.value;
        }
    }
    renderInfluencerLink = () =>{
        const coupon           = this.props.coupon;
        if(coupon?.isInfluencerLinkActivated){
            const baseUrl =  process.env.REACT_APP_BASE_URL;
            return (
                <div>
                    {/*http://localhost:3002/influencers/coupons/6511c74d3e624f3d158c6680/i-eJf9CtgKOUJBKlg5XmK*/}
                    <a href={`${baseUrl}/influencers/coupons/${coupon._id}/${coupon.influencerAccessCode}/`} target={'_blank'}>
                        {`${baseUrl}/influencers/coupons/${coupon._id}/${coupon.influencerAccessCode}/`}
                    </a>
                    <input type={'hidden'} value={`${baseUrl}/influencers/coupons/${coupon._id}/${coupon.influencerAccessCode}/`} id={'influencer_link'}></input>
                    <OverlayTrigger placement="top" overlay={
                        <Tooltip id="tooltip">
                            {this.state.tooltip}
                        </Tooltip>
                    } >
                        <div className={'card-link'} onClick={() => this.copyLink('influencer_link')}
                             onMouseLeave={() => {
                                 setTimeout(() =>{
                                     this.setState({tooltip:'Copiar'})
                                 },500);
                             }}
                             id={`copy-link-btn-influencer_link`}>
                            <FontAwesomeIcon icon={'link'}/>
                        </div>
                    </OverlayTrigger>
                </div>
            )
        }else{
            return (
                <Button size={'sm'} variant={'link'} className={'p-0'} onClick={() => this.createInfluencerLink(coupon)}>Crear</Button>
            )
        }

    }
    copyLink = (id) => {
        this.setState({tooltip:'Copiado!'})
        const copyText = document.getElementById(id);
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
    }
    render() {
        const coupon           = this.props.coupon;
        return (
            <>
                <Row>
                    <Col className='py-3' md={12}>
                        <NPIf condition={!coupon.isPublished}>
                            <Card bg={'medium'}>
                                <Card.Body>
                                    Revisa la informacion del cupón.
                                    <br/>
                                    Una vez publicado y enviado, <u><b>el cupón no podrá ser editado</b></u>.
                                    Solo se enviarán los cupones que su distribución sea por medio de la aplicación.
                                    <br/>
                                    El cupón puede ser desactivado en cualquier momento.
                                </Card.Body>
                            </Card>
                        </NPIf>
                        <NPIf condition={!coupon.isActive}>
                            <Alert variant={'danger'}>
                                Cupón desactivado
                            </Alert>
                        </NPIf>

                        <Card className={'mt-3'}>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>Resumen del cupón {coupon.code}</Card.Title>

                                <Row>
                                    <Col sm={2}>Código del cupón</Col><Col sm={10}>{this.renderType(coupon.code)}</Col>
                                    <Col sm={2}>Tipo de cupón:</Col><Col sm={10}>{this.renderType(coupon.type)}</Col>
                                    <Col sm={2}>Tipo de descuento:</Col><Col sm={10}>{this.renderDiscountType(coupon.discountType)}</Col>
                                    <Col sm={2}>Valor:</Col><Col sm={10}>{this.renderValue()}</Col>
                                    <hr/>
                                    {this.renderProducts(coupon.type,coupon.products, coupon.categories)}

                                    <Col sm={2}>Aplica con otros cupones?</Col><Col sm={10}>{coupon.canBeUsedWithOtherCoupons ? 'Si' : 'No'}</Col>
                                    <Col sm={2}>Aplica con otros descuentos?</Col><Col sm={10}>{coupon.canBeUsedWithDiscounts ? 'Si' : 'No'}</Col>
                                    <Col sm={2}>Tiene mínimo de compra?</Col>
                                    <Col sm={10}>
                                        {this.renderMinMaxPurchase(coupon.hasMinimumPurchase, coupon.minimumPurchase)}
                                    </Col>
                                    <Col sm={2}>Tiene máximo de compra?</Col>
                                    <Col sm={10}>
                                        {this.renderMinMaxPurchase(coupon.hasMaximumPurchase, coupon.maximumPurchase)}
                                    </Col>
                                    <Col sm={2}>Número de canjes del cupón:</Col><Col sm={10}>{coupon.maxRedeems === INFINITY ? 'No tiene máximo' : coupon.maxRedeems}</Col>
                                    <Col sm={2}>Número de canjes del cupón por usuario:</Col><Col sm={10}>{coupon.maxRedeemsPerUser}</Col>

                                    <hr/>
                                    <Col sm={2}>Fecha de inicio:</Col><Col sm={10}>{this.renderDate(coupon.startDate)}</Col>
                                    <Col sm={2}>Fecha de finalización:</Col><Col sm={10}>{this.renderDate(coupon.endDate)}</Col>
                                    <NPIf condition={coupon.isPublished}>
                                        <Col sm={2}>Fecha de publicación:</Col><Col sm={10}>{this.renderDate(coupon.publishedAt)}</Col>
                                        <Col sm={2}>Publicado por </Col><Col sm={10}>{this.renderPublishedBy(coupon.publishedBy)}</Col>
                                    </NPIf>

                                    <hr/>
                                    <Col sm={2}>Distribución:</Col><Col sm={10}>{this.renderDistribution(coupon.distributionMethod)}</Col>

                                    <hr/>
                                    <Col sm={2}>Audiencia:</Col><Col sm={10}>{this.renderAudience(coupon.audience,coupon.userSegments)}</Col>
                                    <hr/>
                                    <Col sm={2}>Veces usado:</Col><Col sm={10}>{coupon.redeemCount}</Col>
                                    <Col sm={2}>Enlace para influencer:</Col><Col sm={10}>
                                        {this.renderInfluencerLink()}

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col md={12} className={'d-flex justify-content-end'}>
                        <NPIf condition={coupon.isActive && coupon.isPublished}>
                            <Button onClick={() => this.onClickDeactivate()} className={'mrAcerca-1'}
                                    variant={'danger'}
                                    disabled={!this.props.coupon.isActive}>
                                Desactivar
                            </Button>
                        </NPIf>
                        <Button onClick={() => this.onClickCancel()} variant={'outline-danger'}
                            disabled={this.props.coupon.isPublished}>
                            Cancelar
                        </Button>
                        <Button onClick={() => this.onClickPublish()} className={'ml-1'}
                                disabled={this.props.coupon.isPublished}>
                            Publicar y enviar
                        </Button>
                    </Col>
                </Row>

            </>
        )
    }
}

CouponSummary.propTypes    = {
    coupon: PropTypes.object,
    onClickCancel: PropTypes.func,
    onPublished: PropTypes.func,
    onDeactivated: PropTypes.func,
    onInfluencerLinkCreated: PropTypes.func
}
CouponSummary.defaultProps = {
    coupon: {},
    onClickCancel: () =>{},
    onPublished: () => {},
    onDeactivated: () =>{},
    onInfluencerLinkCreated: () =>{}

}
export default CouponSummary;
