/* Copyright (C) Nodeport SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * América Mendoza  <amendoza@nodeport.co>,
 * Darién Miranda <dmiranda@nodeport.co>,
 * Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import React from 'react';
import PropTypes from "prop-types";

/**
 * Displays a Paginator
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */

import '../../scss/components/ui/NpPaginator.scss';
import NPIf from "np-if";

class NPPaginator extends React.Component {
    constructor(s) {
        super(s);
        this.state          = {
            currentPage: 1,
            hasPreloaded:false
        }
        this.onPageSelected = this.onPageSelected.bind(this);
        this.next           = this.next.bind(this);
        this.previous       = this.previous.bind(this);
        this.reset          = this.reset.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        if (!state.hasPreloaded && props.initialPage) {

            return {
                currentPage:props.initialPage,
                hasPreloaded: true
            }
        }
        return null;
    }
    goToTop(){
        window.scrollTo(0, 0);
    }
    onPageSelected(page) {
        this.goToTop();
        this.setState({currentPage: page});
        this.props.onPageChanged(page);
    }

    next() {
        this.goToTop();
        let nextPage = this.state.currentPage + 1;
        nextPage = nextPage < this.props.numberOfPages ? nextPage : this.props.numberOfPages
        this.setState({
            currentPage:nextPage
        })
        this.props.onPageChanged(nextPage);
    }

    previous() {
        this.goToTop();
        let previousPage = this.state.currentPage - 1;
        previousPage =  previousPage > 1 ? previousPage : 1
        this.setState({
            currentPage: previousPage
        })
        this.props.onPageChanged(previousPage);

    }
    reset(){
        this.setState({currentPage:1})
    }
    render() {
        const paginatorArray = Array.from({length: this.props.numberOfPages}, (_, i) => i + 1);
        let start            = this.state.currentPage;
        let end              = 0;
        let isAtStart        = false;
        let isAtEnd          = false;
        if (start < 5) {
            start     = 0;
            end       = 5;
            isAtStart = true;
        } else if (start >= 4) {
            start   = start - 3;
            end     = start + 5;
            isAtEnd = true;
        }
        const subArray = paginatorArray.slice(start, end);
        const isSmall  = this.props.numberOfPages < 5;
        return (
            <div className={'np-paginator'}>
                <NPIf condition={this.state.currentPage > 1}>
                    <div className={'previous no-select'} onClick={this.previous}>&lt; {this.props.previousLabel}</div>
                </NPIf>
                <NPIf condition={isAtEnd && !isSmall}>
                    <div className={`page no-select ${this.state.currentPage === 1 ? 'selected' : ''}`}
                         key={1} onClick={() => this.onPageSelected(1)}>
                        {1}
                    </div>
                    <div className={'page ellipsis'}>…</div>
                </NPIf>
                {
                    subArray.map((page) => {
                        return (
                            <div className={`page no-select ${this.state.currentPage === page ? 'selected' : ''}`}
                                 key={page} onClick={() => this.onPageSelected(page)}>
                                {page}
                            </div>
                        )
                    })

                }
                <NPIf condition={isAtStart && !isSmall}>
                    <div className={'page ellipsis'}>…</div>
                    <div className={`page no-select ${this.state.currentPage === this.props.numberOfPages ? 'selected' : ''}`}
                         key={this.props.numberOfPages} onClick={() => this.onPageSelected(this.props.numberOfPages)}>
                        {this.props.numberOfPages}
                    </div>
                </NPIf>
                <NPIf condition={this.state.currentPage < this.props.numberOfPages}>
                    <div className={'next no-select'} onClick={this.next}>{this.props.nextLabel} &gt;</div>
                </NPIf>

            </div>
        )

    }
}

export default NPPaginator;
NPPaginator.propTypes    = {
    numberOfPages: PropTypes.number,
    onPageChanged: PropTypes.func,
    initialPage:PropTypes.number,
    nextLabel:PropTypes.string,
    previousLabel:PropTypes.string
}
NPPaginator.defaultProps = {
    numberOfPages: 10,
    onPageChanged: (page) => {
    },
    initialPage:1,
    nextLabel:'Siguiente',
    previousLabel:'Anterior'
};
