/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import NbioApi from "../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import NPIf from "np-if";
import Nav from 'react-bootstrap/Nav';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//components
import UploadFile from "./bulk_importer/UploadFile";
import ValidateFile from "./bulk_importer/ValidateFile";
import ImportingFile from "./bulk_importer/ImportingFile";
import UploadImages from "./bulk_importer/UploadImages";
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/cjs/Spinner";


class ProductsBulkExporter extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            isDownloading: false
        }
    }

    componentDidMount() {


    }


    downloadProducts = () => {
        this.setState({
            isDownloading: true
        });
        NbioApi.products.exportBulk().then((res) => {
            this.setState({
                isDownloading: false
            })
            const url = window.URL.createObjectURL(
                new Blob([res.data],
                    {
                        type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }))
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Productos-nbio.xlsx');
            document.body.appendChild(link);
            link.click();
        }).catch((ex) => {
        }).finally(() => {
            this.setState({
                isDownloading: false
            });
        });
    }

    render() {
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className={'ml-auto mr-auto'} sm={12} md={9}>
                        <Row>
                            <Col className='mt-3 d-flex align-items-center justify-content-between'>
                                <h5 className={'text-capitalize '}>Exportador</h5>
                                <a href={'https://nbio.blob.core.windows.net/cdn/Muestra-nbio.xlsx'}>

                                </a>
                            </Col>
                        </Row>

                        <Row className={'my-5'}>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='d-flex align-items-center justify-content-between'>
                                            <span>Exportar</span>
                                            {
                                                this.state.isDownloading ?
                                                    <Spinner animation={'grow'}>

                                                    </Spinner> :
                                                    <Button variant={'info'} onClick={() => this.downloadProducts()}>
                                                        <FontAwesomeIcon icon={'file-excel'}

                                                        />
                                                        &nbsp;Exportar todos los productos
                                                    </Button>
                                            }

                                        </Card.Title>
                                        <p>
                                            Da click en el botón para descargar todos los productos. El proceso puede
                                            tardar algunos minutos.
                                        </p>
                                    </Card.Body>
                                </Card>

                            </Col>
                        </Row>
                    </Col>
                    <Col className='py-3 d-sm-none d-md-block ml-auto' md={2}>

                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(ProductsBulkExporter);

