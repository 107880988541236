/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */

import React from 'react';
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

//style
import '../../scss/components/ui/dragzone.scss'

//components
import NPIf from "np-if";

class DragZone extends React.Component {
    constructor(props) {
        super(props);
        this.state          = {
            isDropping: false,
            imagePicked: false,
            imageBase64: null,
        }
        this.handleDrop     = this.handleDrop.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragExit = this.handleDragExit.bind(this);
        this.onImagePicked  = this.onImagePicked.bind(this);
        this.handleFile     = this.handleFile.bind(this);
        this.onClick        = this.onClick.bind(this);
        this.droppedFile    = null;
    }

    onImagePicked(base64Result) {
        this.setState({
            imagePicked: true,
            imageBase64: base64Result
        });
        this.props.onImageDropped(base64Result,this.droppedFile);
    }

    handleDrop(ev) {
        ev.preventDefault()
        ev.stopPropagation()
        ev.nativeEvent.stopImmediatePropagation();
        this.setState({isDropping: false})

        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file         = ev.dataTransfer.items[i].getAsFile();
                    var reader       = new FileReader();
                    const _this      = this;
                    reader.onloadend = function () {
                        _this.onImagePicked(reader.result)
                    }
                    reader.readAsDataURL(file);
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
            }
        }
    }

    handleDragOver(e) {
        e.preventDefault()
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation();
        this.setState({isDropping: true})
    }

    handleDragExit(e) {
        this.setState({isDropping: false})
    }

    handleFile(e) {
        const file       = e.target.files[0];
        var reader       = new FileReader();
        const _this      = this;
        _this.droppedFile = file;
        reader.onloadend = function () {
            _this.onImagePicked(reader.result)
        }
        reader.readAsDataURL(file);
    }

    onClick(ev) {
        this.input.click();
    }

    render() {
        const img = this.props.preSelectedImage &&
        !this.state.imagePicked ? this.props.preSelectedImage : this.state.imageBase64;
        const style = {
            backgroundImage: `url(${img})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition:'center center'
        }
        const withImage = img ? 'with-image' : '';
        const disabled = this.props.disabled ? 'disabled' : '';

        return (
            <div className={`drag-zone ${withImage} ${disabled}`}
                 onDrop={this.handleDrop}
                 onDragOver={this.handleDragOver}
                 onDragExit={this.handleDragExit}
                 style={style}
                 onClick={this.onClick}>
                <span className={`${withImage}`}>Da click o suelta la imagen aquí</span>
                <input type={'file'}
                       onChange={this.handleFile}
                       disabled={this.props.disabled}
                       ref={(ref) => this.input = ref}/>
                <NPIf condition={!this.state.imagePicked && !this.props.preSelectedImage}>
                    <NPIf condition={!this.state.isDropping}>
                        <FontAwesomeIcon className={'icon'} icon={'arrow-circle-up'} size={"3x"}></FontAwesomeIcon>
                        <Button onClick={this.onClick} variant={'info'}>{this.props.buttonLabel}</Button>
                        <div>{this.props.label}</div>
                    </NPIf>
                    <NPIf condition={this.state.isDropping}>
                        <div>Drop Here</div>
                    </NPIf>
                </NPIf>
            </div>
        )
    }
}

DragZone.propTypes = {
    onImageDropped: PropTypes.func,
    preSelectedImage: PropTypes.string,
    buttonLabel: PropTypes.string,
    label: PropTypes.string,
    onFileDropped:PropTypes.func,
    disabled: PropTypes.bool

}
DragZone.defaultProps    = {
    onImageDropped: (img) => {},
    preSelectedImage:'',
    buttonLabel: 'Agregar imagen',
    label: 'o suelta la imagen aquí',
    onFileDropped: () =>{},
    disabled: false
}
export default DragZone
