/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * June 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import NbioApi from "../../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from 'react-bootstrap/Spinner'
import * as _ from 'underscore';

//components
import RegionsMap from "./RegionsMap";



class Regions extends React.Component{
    constructor(p) {
        super(p);
        this.state       = {
            regions:[],
            isLoading:true,
        }
        this.loadRegions = this.loadRegions.bind(this);
    }

    componentDidMount() {
        this.loadRegions();
    }

    loadRegions(){
        const pageSize = 50;
        const currentPage = 1;

        this.setState({isLoading:true});
        NbioApi.regions.getRegions(currentPage,pageSize,this.state.activeSort).then(res => {
            let regions = res.data.regions.map((r) => {
                return {
                    subRegions:r.subRegions,
                    color:r.color,
                    name:r.name,
                    _id:r._id
                }
            });
            const subRegions = [];
            regions.forEach((region) =>{
                region.subRegions.forEach((sr) =>{
                    console.log(sr.featureGeoJson)
                    const featureGeoJson = sr.featureGeoJson;
                    featureGeoJson.name = region.name;
                    featureGeoJson.color = region.color;
                    featureGeoJson._id = region._id;
                    subRegions.push(featureGeoJson)
                })


            })


            console.log(subRegions)
            // const mergeSubRegions = [].concat.apply([], subRegions);
            this.setState({
                regions:subRegions,
                isLoading:false,
            });
        });
    }



    render(){
        const regions = this.state.regions;
        const filteredRegions =  _.unique(this.state.regions,true,(v) => v._id);
        const featuresRegions = regions;
        const colors = regions.map((r) => r.color);

        const columns = [
            {
                dataField: 'name',
                text: 'Regiones',
                formatter: (value, row) => {
                    return (
                        <a href={`/regions/${row._id}`}>{value}</a>
                    )
                },
            },
            {
                dataField: 'color',
                text: '',
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'25px',
                    }
                },
                formatter:(value,row) =>{
                    return <div style={{width:25,height:25,borderRadius:5,backgroundColor:value}}></div>
                }
            },
        ];
        return(
            <Container fluid style={{overflowY:"scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3 d-flex flex-row align-items-center'>
                        <h5 className={'text-capitalize mr-auto'}>Regiones</h5>
                        <Button variant="primary"
                                onClick={() => this.props.history.push('/regions/create')}>
                            Crear región
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <NPIf condition={this.state.isLoading}>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <Spinner animation="grow" variant={"secondary"}/>
                        </div>
                        <NPElse>
                            <Col className='py-3'>
                                <RegionsMap interactiveMode={false}
                                            features={featuresRegions}
                                            color={colors}
                                            height={'600px'}>
                                </RegionsMap>
                            </Col>
                            <Col className='py-3' lg={2}>
                                <BootstrapTable keyField='_id'
                                                data={filteredRegions}
                                                columns={columns}
                                                noDataIndication={`No se encontraron regiones`}
                                                bordered={false}
                                />
                            </Col>
                        </NPElse>
                    </NPIf>
                </Row>
            </Container>
        )
    }
}

export default withRouter(Regions);
