import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateRangePicker} from "react-date-range";
import es from "date-fns/locale/es";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/cjs/Spinner";
import {defaultInputRanges, defaultStaticRanges} from "react-date-range/dist/defaultRanges";
import NbioApi from '../../lib/api/NbioApi';
import NPIf from "np-if";
import Badge from "react-bootstrap/cjs/Badge";
import BootstrapTable from "react-bootstrap-table-next";
import SortBtns from "../ui/SortBtns";
import _ from 'underscore';
import DropdownButton from "react-bootstrap/cjs/DropdownButton";
import {ResponsivePieCanvas} from '@nivo/pie'

class Reports extends React.Component {
    constructor(c) {
        super(c);
        this.state = {
            selectedRanges: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            reports: [],
            loaded: false,
            data: []
        }
    }

    componentDidMount() {
        this.loadReports();
    }

    loadReports() {
        NbioApi.segmentation.getAvailableReports().then((res) => {
            this.setState({reports: res.data.list, loaded: true})
        }).catch((ex) => {

        })
    }


    render() {
        const staticRangesLabels = {
            "Today": "Hoy",
            "Yesterday": "Ayer",
            "This Week": "Esta semana",
            "Last Week": "La última semana",
            "This Month": "Este mes",
            "Last Month": "El último mes"
        };

        const inputRangesLabels = {
            "days up to today": "Días hasta hoy",
            "days starting today": "Días a partir de hoy"
        };

        function translateRange(dictionary) {
            return (item) =>
                dictionary[item.label] ? {...item, label: dictionary[item.label]} : item;
        }

        const esStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
        const esInputRanges  = defaultInputRanges.map(translateRange(inputRangesLabels));
        return (
            <Container fluid className={'h-100'} style={{overflowY: "auto"}}>
                <Row>
                    <Col className='py-3 d-flex align-items-center justify-content-between'>
                        <h5 className={'text-capitalize'}>Reportes</h5>
                    </Col>
                </Row>
                <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
                    <ButtonGroup aria-label="Second group">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle variant="info" id="dropdown-basic" disabled={true}>
                                <FontAwesomeIcon icon={'calendar'}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div>
                                    <DateRangePicker
                                        locale={es} onChange={(v) => this.handleDateSelect(v)}
                                        ranges={[this.state.selectedRanges]}
                                        staticRanges={esStaticRanges}
                                        inputRanges={esInputRanges}>
                                    </DateRangePicker>
                                    <div className={'ml-3'}>
                                        <Button onClick={() => this.onAllDatesClicked()}>Todas las fechas</Button>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <DropdownButton id="report-drowndown" variant="info" title="Selecciona un reporte">
                            {
                                this.state.reports.map((report) => {
                                    return (
                                        <Dropdown.Item
                                            onClick={() => this.loadReport(report)}>{report.label}</Dropdown.Item>
                                    )
                                })
                            }
                        </DropdownButton>
                        <Button variant="info" disabled={true} onClick={() => {
                        }}>
                            {
                                this.state.isDownloading ?
                                    <Spinner animation={'border'} size={'sm'}/>
                                    :
                                    <FontAwesomeIcon icon={'file-excel'}/>
                            }
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>

                <div style={{height: 500, width: 500}}>
                    <ResponsivePieCanvas
                        data={this.state.data}
                        margin={{top: 40, right: 200, bottom: 40, left: 80}}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        colors={{scheme: 'paired'}}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.6
                                ]
                            ]
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{from: 'color'}}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor="#333333"
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: 'rgba(255, 255, 255, 0.3)',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'ruby'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'c'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'go'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'python'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'scala'
                                },
                                id: 'lines'
                            },
                            {
                                match: {
                                    id: 'lisp'
                                },
                                id: 'lines'
                            },
                            {
                                match: {
                                    id: 'elixir'
                                },
                                id: 'lines'
                            },
                            {
                                match: {
                                    id: 'javascript'
                                },
                                id: 'lines'
                            }
                        ]}
                        legends={[
                            {
                                anchor: 'right',
                                direction: 'column',
                                justify: false,
                                translateX: 140,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 60,
                                itemHeight: 14,
                                itemTextColor: '#999',
                                itemDirection: 'left-to-right',
                                itemOpacity: 1,
                                symbolSize: 14,
                                symbolShape: 'circle'
                            }
                        ]}
                    />
                </div>
            </Container>
        )
    }

    loadReport(report) {
        NbioApi.segmentation.generateReport(report.name, []).then((res) => {
            this.setState({data: res.data.list.data})
        }).catch((ex) => {

        })
    }
}

export default withRouter(Reports);

