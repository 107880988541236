/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import NbioApi from "../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import NPIf from "np-if";
import {Calendar} from 'react-date-range';
import es from "date-fns/locale/es";


//style calendar
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

//components
import ProductSelector from "../products/ProductSelector";
import ProductList from "../products/ProductList";
import CategoriesPicker from "../categories/CategoriesPicker";
import NPElse from "np-if/src/NPElse";
import DiscountSummary from "./DiscountSummary";

//date js
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);
const timezone        = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

class Discount extends React.Component {
    constructor(p) {
        super(p);
        this.state                 = {
            discount: {
                name: '',
                type: 'fixed_amount', //fixed_amount, percentage,
                value: 0,
                products: [],
                categories: [],
                appliesToAllProducts: false,
                startDate: dayjs(new Date()).format('MM-DD-YYYY'),
                endDate:'',
                isActive:false,
                isArchived:false,
            },
            selectedStartDate: '',
            selectedEndDate: '',
            step: 'setup', //setup, select_products, setup_dates, summary
        }

        this.onDiscountTypeChanged   = this.onDiscountTypeChanged.bind(this);
        this.createDiscount          = this.createDiscount.bind(this);
        this.onChange              = this.onChange.bind(this);
        this.onProductSelected     = this.onProductSelected.bind(this);
        this.onDeletedProduct      = this.onDeletedProduct.bind(this);
        this.onCategoryChecked     = this.onCategoryChecked.bind(this);
        this.onCategoryUnchecked   = this.onCategoryUnchecked.bind(this);
        this.handleSelectStartDate = this.handleSelectStartDate.bind(this);
        this.handleSelectEndDate   = this.handleSelectEndDate.bind(this);
    }

    componentDidMount() {
        const discountId = this.props.match.params.id;

        if (discountId !== 'create') {
            this.loadDiscount(discountId,'summary');
        } else {
            this.setState({isLoaded: true, step:'setup'})
        }
    }

    loadDiscount = (discountId,step = '') => {
        NbioApi.discounts.getDiscount(discountId).then(res => {
            this.setState({
                discount: res.data.discount,
                isLoaded: true,
                step: step
            });
        }).catch((ex) => {
        });
    }

    isValidForm() {
        const discount = this.state.discount;
        if (discount.name.trim() === '') {
            return {
                isValid: false,
                message: 'Ingresa un nombre para el descuento'
            }
        }
        if (discount.value <= 0) {
            return {
                isValid: false,
                message: 'Valor del descuento inválido'
            }
        }
        if (discount.value >= 100 && discount.type === 'percentage') {
            return {
                isValid: false,
                message: 'El valor no puede ser mayor a 100.'
            }
        }
        if(isNaN(discount.value)){
            return {
                isValid: false,
                message: 'El valor del descuento es incorrecto.'
            }
        }

        return {
            isValid: true
        }
    }

    isDatesValid() {
        const discount = this.state.discount;
        if(!this.state.selectedStartDate){
            return {
                isValid: false,
                message: 'Selecciona una fecha de inicio'
            }
        }
        // validate dates
        const _startDate = dayjs(this.state.selectedStartDate);
        const _endDate = discount.endDate ? dayjs(this.state.selectedEndDate) : null
        if(_endDate){
            if(_startDate.isAfter(_endDate)){
                return {
                    isValid: false,
                    message: 'La fecha de finalización no puede ser antes de la de inicio'
                }
            }

        }
        return {
            isValid: true
        }
    }


    onChange = (e) => {
        const isCheckBox = ['isActive',
            'isArchive',
            'appliesToAllProducts',
        ].includes(e.target.id);

        this.setState(prevState => {
            let discount          = Object.assign({}, prevState.discount);
            discount[e.target.id] = isCheckBox ? e.target.checked : e.target.value;
            return {discount};
        });
    }

    setAppliesToAllProductsAndCategories = () =>{
        const _discount        = {...this.state.discount};
        _discount.appliesToAllProducts   = true;
        _discount.appliesToAllProducts = true;
        this.setState({discount: _discount})
    }

    onDiscountTypeChanged = (event) => {
        const _discount        = {...this.state.discount};
        _discount.type = event.target.value;
        this.setState({discount: _discount})
    }

    onProductSelected = (product) => {
        if(!(this.state.discount.products.find((p) => p.sku === product.sku))){
            this.setState(prevState => {
                let discount          = Object.assign({}, prevState.discount);
                discount.products = [...discount.products, product];
                return {discount};
            });
        }

    }

    onDeletedProduct = (product) => {
        this.setState(prevState => {
            let discount          = Object.assign({}, prevState.discount);
            discount.products =  discount.products.filter(item => item.sku !== product.sku);
            return {discount};
        });
    }

    onCategoryChecked = (c) => {
        this.setState(prevState => {
            let discount          = Object.assign({}, prevState.discount);
            discount.categories = [...discount.categories, c];
            return {discount};
        });
    }

    onCategoryUnchecked = (c) => {
        this.setState(prevState => {
            let discount          = Object.assign({}, prevState.discount);
            discount.categories =  discount.categories.filter(item => item !== c);
            return {discount};
        });
    }

    handleSelectStartDate = (date) => {
        this.setState({selectedStartDate: date}, () => {
            this.setFormatDates();
        });
    }

    handleSelectEndDate = (date) => {
        this.setState({selectedEndDate: date}, () => {
            this.setFormatDates();
        });
    }

    setFormatDates = async () => {
        return new Promise((resolve, reject) => {
            this.setState(prevState => {
                let discount       = Object.assign({}, prevState.discount);
                discount.startDate = this.state.selectedStartDate ? dayjs(this.state.selectedStartDate).format('MM-DD-YYYY') : '';
                discount.endDate   = this.state.selectedEndDate ? dayjs(this.state.selectedEndDate).format('MM-DD-YYYY') : '';
                return {discount};
            }, () => {
                resolve();
            });
        });
    }

    onClickNextStepSelectProducts() {
        const isValidForm = this.isValidForm();

        if (!isValidForm.isValid) {
            return Swal.fire({
                title: 'Datos inválidos',
                icon: 'warning',
                html: isValidForm.message,
                heightAuto: false
            })
        }

        this.setStep('select_products');
    }

    async onClickCreateDiscount() {
        const isDatesValid = this.isDatesValid();

        if (!isDatesValid.isValid) {
            return Swal.fire({
                title: 'Datos inválidos',
                icon: 'warning',
                html: isDatesValid.message,
                heightAuto: false
            })
        }

        await this.setFormatDates()

        return this.createDiscount();
    }

    setStep      = (stepName) => {
        this.setState({step: stepName});
    }

    mapProduct = (product) => {
        return {
            images: product.images,
            sku: product.sku,
            name: product.name,
            description: product.description
        }
    }
    createDiscount = () => {
        const discount = {...this.state.discount};
        this.props.history.push(`/discounts`);
        discount.products = discount.products.map((p) => this.mapProduct(p));
        NbioApi.discounts.create(discount).then((res) => {
            Swal.fire({
                title: 'Descuento creado',
                html: 'Descuento creado',
                icon: 'success',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((_res) => {
                    // this.props.history.push(`/discounts`);
            });
        }).catch((ex) => {
            let errorMsg = 'Hubo un error al crear el descuento.';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {

            }
            Swal.fire({
                title: 'Error',
                html: errorMsg,
                icon: 'error',
                heightAuto: false
            }).then((res) => {
            })
        })
    }

    renderSetup() {
        const discount = this.state.discount ? this.state.discount : {};
        return (
            <>
                <Row>
                    <Col className='py-3' md={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>Información general</Card.Title>

                                <Form.Group as={Row} className="mb-3" controlId="name">
                                    <Form.Label column sm={2}>Nombre del descuento</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Nombre"
                                                      value={discount.name}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="type">
                                    <Form.Label column sm={2}>Tipo de descuento</Form.Label>
                                    <Col sm={6}>
                                        <Form.Check
                                            inline
                                            label="Cantidad"
                                            name="type"
                                            type={"radio"}
                                            id={"discountType1"}
                                            value={"fixed_amount"}
                                            onChange={event => this.onDiscountTypeChanged(event)}
                                            checked={discount.type === 'fixed_amount'}
                                        />
                                        <Form.Check
                                            inline
                                            label="Porcentaje"
                                            name="type"
                                            type={"radio"}
                                            id={"discountType2"}
                                            value={"percentage"}
                                            onChange={event => this.onDiscountTypeChanged(event)}
                                            checked={discount.type === 'percentage'}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="value">
                                    <Form.Label column sm={2}>Valor</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Valor"
                                                      value={discount.value}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col md={12} className={'d-flex justify-content-end'}>
                        <Button onClick={() => this.onClickNextStepSelectProducts()} className={'ml-1'}>Continuar</Button>
                    </Col>
                </Row>
            </>
        )
    }

    renderSelectProducts() {
        const discount = this.state.discount ? this.state.discount : {};
        return (
            <>
                <Row>
                    <Col className='py-3' md={12}>
                        <Card className={'mt-3'}>
                            <Card.Body>
                                <Card.Title className={'text-primary'}>Seleccionar Categorías y Productos</Card.Title>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="appliesToAllProducts">
                                    <Col sm={12}>
                                        <Form.Check
                                            type="switch"
                                            id="appliesToAllProducts"
                                            label="Aplicar descuento a todas los productos y categorías"
                                            checked={discount.appliesToAllProducts}
                                            onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <NPIf condition={!discount.appliesToAllProducts}>
                                    <hr/>

                                    <Row className="mt-4 mb-4">
                                        <Col sm={12} className={'mb-3'}>
                                           <b>Categorías</b>
                                        </Col>
                                        <Col sm={12}>
                                            <CategoriesPicker onCategoryChecked={this.onCategoryChecked}
                                                              onCategoryUnchecked={this.onCategoryUnchecked}
                                            />
                                        </Col>
                                    </Row>
                                </NPIf>
                                <NPIf condition={!discount.appliesToAllProducts}>
                                    <hr/>

                                    <Row className="mt-4 mb-4">
                                        <Col sm={12} className={'mb-3'}>
                                            <b>Productos</b>
                                        </Col>
                                        <Col>
                                            <ProductSelector onProductSelected={(p) => this.onProductSelected(p)}>
                                            </ProductSelector>
                                        </Col>
                                        <Col>
                                            <ProductList products={this.state.discount.products}
                                                         onDeleteProduct={this.onDeletedProduct}/>
                                        </Col>
                                    </Row>
                                </NPIf>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col md={12} className={'d-flex justify-content-end'}>
                        <Button onClick={() => this.setStep('setup')} variant={'outline-primary'}>Atrás</Button>
                        <Button onClick={() => this.setStep('setup_dates')} className={'ml-1'}>Continuar</Button>
                    </Col>
                </Row>
            </>
        )
    }

    renderSetupDates() {
        return (
            <>
                <Row>
                    <Col className='py-3' md={12}>
                        <Card className={'mt-3'}>
                            <Card.Body>
                                <Card.Title className={'text-primary'}>Caducidad</Card.Title>
                                <Row>
                                    <Col>
                                        <div>Fecha de inicio</div>

                                        <Calendar locale={es}
                                                  date={this.state.selectedStartDate}
                                                  onChange={this.handleSelectStartDate}
                                                  weekStartsOn={0}
                                                  minDate={new Date()}
                                        />
                                    </Col>
                                    <Col>
                                        <div>Fecha de finalización</div>
                                        <Calendar locale={es}
                                                  date={this.state.selectedEndDate}
                                                  onChange={this.handleSelectEndDate}
                                                  weekStartsOn={0}
                                                  minDate={new Date()}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col md={12} className={'d-flex justify-content-end'}>
                        <Button onClick={() => this.setStep('select_products')} variant={'outline-primary'}>Atrás</Button>
                        <Button onClick={() => this.onClickCreateDiscount()} className={'ml-1'}>Crear descuento</Button>
                    </Col>
                </Row>
            </>
        )
    }

    renderContent() {
        switch (this.state.step) {
            case 'setup':
                return this.renderSetup();
                break;
            case 'select_products':
                return this.renderSelectProducts();
                break;
            case 'setup_dates':
                return this.renderSetupDates();
                break;
            case 'summary':
                return <DiscountSummary discount={this.state.discount}
                                        onDeactivated={() => this.loadDiscount(this.state.discount._id,'summary')}
                />
                break;
            default:
                return null;
        }
    }

    render() {
        const discountId = this.props.match.params.id;

        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3'>
                        <NPIf condition={discountId === 'create'}>
                            <h5 className={'text-capitalize '}>Crear descuento</h5>
                            <NPElse>
                                <h5 className={'text-capitalize '}>Descuento: <span
                                    className={'text-black-50'}> {this.state.discount.name}</span></h5>
                            </NPElse>
                        </NPIf>
                    </Col>
                </Row>

                {this.renderContent()}
            </Container>
        )
    }
}

export default withRouter(Discount);
