import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import Card from "react-bootstrap/cjs/Card";
import dayjs from "dayjs";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import NbioApi from "../../lib/api/NbioApi";
import {money} from "../../lib/money/money";
import Col from "react-bootstrap/Col";

/**
 * Display the order stripe's refund
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */


class OrderRefunds extends React.Component {
    constructor(s) {
        super(s);
        this.state = {
            refunds: [],
            isLoading: true,
            isRefunding: false
        }
    }

    componentDidMount() {
        this.loadRefunds();
    }

    loadRefunds() {
        NbioApi.orders.getRefunds(this.props.orderId).then((res) => {
            this.setState({refunds: res.data.refunds, isLoading: false})
        }).catch((ex) => {
            this.setState({isLoading: false})
        })
    }

    async refund() {
        const orderId = this.props.orderId;
        // API.admin.orders.refund(orderId).then((res) =>{
        //     console.log(res.data);
        // })
        const {value: amount} = await Swal.fire({
            title: 'New refund',
            input: 'text',
            inputLabel: 'Enter amount to refund',
            inputValue: '',
            showCancelButton: true,
            confirmButtonColor: '#facd05',
            heightAuto:false,
            inputValidator: (value) => {
                if (!value) {
                    return 'Invalid amount'
                }
            }
        })
        if (amount) {
            // Swal.fire({
            //     title: 'Are you sure?',
            //     text: `Are you sure you want to refund ${money((amount * 100) / 100)}`,
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#facd05',
            //     confirmButtonText: 'Yes, refund',
            //     cancelButtonText: 'No, cancel'
            // }).then((result) => {
            //     if(result.isConfirmed) {
            //         this.setState({isRefunding:true});
            //         // API.admin.orders.refund(orderId,amount * 100).then((res) =>{
            //         //     this.loadRefunds();
            //         //     this.setState({isRefunding:false});
            //         //     Swal.fire({title:'Refund created',text:'Refund successful',icon:'success'});
            //         // }).catch((ex) =>{
            //         //     this.setState({isRefunding:false});
            //         //     Swal.fire({title:'Error creating refund',text:ex.response.data.reason,icon:'error'});
            //         // })
            //     }else{
            //     }
            // });
        }
    }

    render() {
        const columns = [
            {
                dataField: 'date_created',
                text: 'Fecha',
                headerStyle: () => {
                    return {
                        width: '150px'
                    }
                },
                formatter: (value, row) => {
                    return (
                        <div>{dayjs(value).format('DD-MM-YYYY HH:mm')}</div>
                    )
                },
            },
            {
                dataField: 'amount',
                text: 'Cantidad',
                headerStyle: () => {
                    return {
                        width: '250px'
                    }
                },
                formatter: (value, row) => {
                    return (
                        <div>
                            {money(value )}
                        </div>
                    )

                }
            },
            {
                dataField: 'status',
                text: 'Estado',
                formatter: (value, row) => {
                    let v = ''
                    switch (value) {
                        case 'approved':
                            v = 'Aprobado';
                            break;
                        case 'in_process':
                            v = 'En proceso';
                            break;
                        case 'rejected':
                            v = 'Rechazado';
                            break;
                        case 'cancelled':
                            v = 'Cancelado';
                            break;
                        case 'authorized':
                            v = 'Autorizado';
                            break;
                        default:
                            v = value;
                    }
                    return <div>{v}</div>

                }
            }
        ]
        return (
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title className={'text-capitalize'}>
                            Reembolsos
                        </Card.Title>
                        <div className={'mb-2'}>
                            <NPIf condition={this.state.isLoading}>
                                <Spinner animation={'grow'} variant={'primary'}>

                                </Spinner>
                                <NPElse>
                                    <BootstrapTable keyField='created'
                                                    data={this.state.refunds}
                                                    columns={columns}
                                                    noDataIndication={`Aún no hay reembolsos`}
                                                    bordered={false}/>
                                </NPElse>
                            </NPIf>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}

OrderRefunds.defaultProps = {
    orderId: ''
}
OrderRefunds.propTypes    = {
    orderId: PropTypes.string,
}
export default withRouter(OrderRefunds);

