/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */
import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import WSSClient from "../../../lib/wss/wss-client";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from "react-chartjs-2";
import API from "../../../lib/api/NbioApi";
import {Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/cjs/Button";
import NbioApi from "../../../lib/api/NbioApi";
import ReactMapGL, {Layer,Marker, Source} from "react-map-gl";
import icon from "../../../assets/logo-icon-small.png"

// @ts-ignore
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
//token TODO: replace to nbio token
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZWFwZXJlenYiLCJhIjoiY2w0ZzIzdWduMDJuMDNjczF5bTA2Y2M4MSJ9.Iyqpdv6RE0BeqkesEq7IXw';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
// register
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);


const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);
const  LINE_TEMP_COLOR =  '#00b2e3';
const  POINT_TEMP_COLOR = '#009dc8';

const  LINE_TEMP_COLOR2 =  '#1d93b3';
const  POINT_TEMP_COLOR2 = '#0d6077';
const  LINE_HUM_COLOR = '#2dd36f';
const  POINT_HUM_COLOR = '#28ba62';

const  LINE_HUM_COLOR2 = '#179348';
const  POINT_HUM_COLOR2 = '#0d6530';
const CHART_OPTIONS = {
    responsive: true,
    maintainAspectRatio: true,
    scales:{

    }

}

class OrderTracking extends React.Component {
    constructor(props) {
        super(props);
        this.state                         = {
            order:{},
            dataTemperature:{
                labels:[],
                datasets:[
                    {
                        label: 'Temperatura (T0)',
                        data: [],
                        borderColor: LINE_TEMP_COLOR,
                        backgroundColor: POINT_TEMP_COLOR,
                    },
                    {
                        label: 'Temperatura 2 (T1)',
                        data: [],
                        borderColor: LINE_TEMP_COLOR2,
                        backgroundColor: POINT_TEMP_COLOR2,
                    }
                ]
            },
            dataHumidity:{
                labels:[],
                datasets:[
                    {
                        label: 'Humedad relativa (H0)',
                        data: [],
                        borderColor: LINE_HUM_COLOR,
                        backgroundColor: POINT_HUM_COLOR,
                    },
                    {
                        label: 'Humedad relativa 2 (H1)',
                        data: [],
                        borderColor: LINE_HUM_COLOR2,
                        backgroundColor: POINT_HUM_COLOR2,
                    }
                ]
            },
            isLoaded:false,
            viewport:{
                width: '800px',
                height: '600px',
                latitude: 19.432608,
                longitude: -99.133209,
                zoom: 13
            },
            operatorPath:[],
            currentTemperature:'-',
            currentHumidity:'-',
            currentTemperature2:'-',
            currentHumidity2:'-',
            lastReportTimeStamp:null,
        }
        this.wssOnMessage                  = this.wssOnMessage.bind(this);
        this.connectWSS                    = this.connectWSS.bind(this);
    }

    componentDidMount() {
        const orderType = this.props.match.params.id;
        this.connectWSS();
        this.loadInterval = setInterval(() =>{
            this.getTrackingData()
        },1000 * 60)
        this.getTrackingData();
        this.loadOrder();
        this.loadRoute();
        this.lastKnownGPSPosition();

    }
    componentWillUnmount() {
        clearInterval(this.loadInterval)
    }
    loadRoute = () =>{
        NbioApi.orders.gpsTrack(this.props.match.params.id).then((res) =>{
            const locationData  = res.data.data.map((d) => d.loc.coordinates);
            this.setState({operatorPath:locationData})
        }).catch((ex) =>{

        })
    }
    lastKnownGPSPosition = () =>{
        NbioApi.orders.lastKnownGPSPosition(this.props.match.params.id).then((res) =>{
            const locationData  = res.data.data.map((d) => d.loc.coordinates);
            if(locationData){
                if(locationData.length > 0){
                    this.setState({
                        currentLat:locationData[0][1],
                        currentLng:locationData[0][0]
                    })
                }
            }



        }).catch((ex) =>{

        })
    }
    loadOrder(){
        const orderId = this.props.match.params.id;
        NbioApi.orders.getOrder(orderId).then((res) => {
            this.setState({
                order: res.data.order,
                lastUpdateDate: new Date()
            })
        }).catch((ex) => {

        })
    }
    getTrackingData(){
        const orderId = this.props.match.params.id;

        API.orders.track(orderId).then((res) =>{
            // TEMP 0
            const tempData = res.data.data.map((d) => d.temperature);
            const tempLabels = res.data.data.map((d) => d.shortLabel);
            const dataTemperature = {...this.state.dataTemperature};
            dataTemperature.datasets[0].data = tempData;
            dataTemperature.labels = tempLabels;
            // T1
            const tempData1 = res.data.data.map((d) => d.temperature2);
            const tempLabels1 = res.data.data.map((d) => d.shortLabel);

            dataTemperature.datasets[1].data = tempData1;
            dataTemperature.labels = tempLabels1;

            // HUM
            const humData = res.data.data.map((d) => d.humidity);
            const humLabels = res.data.data.map((d) => d.shortLabel);
            const dataHumidity = {...this.state.dataHumidity};
            dataHumidity.datasets[0].data = humData;
            dataHumidity.labels = humLabels;
            // HUM2
            const humData2 = res.data.data.map((d) => d.humidity2);
            const humLabels2 = res.data.data.map((d) => d.shortLabel);
            dataHumidity.datasets[1].data = humData2;
            dataHumidity.labels = humLabels2;

            this.setState({
                dataTemperature:dataTemperature,
                dataHumidity:dataHumidity,
                isLoaded:true
            })
        }).catch((ex) =>{
            console.log(ex)
        })
    }
    connectWSS() {
        const _this = this;
        WSSClient.startListening().then((socket) => {
            socket.addEventListener('open', () => {
                _this.requestLiveData()
            })
            socket.addEventListener('message', this.wssOnMessage);
            socket.addEventListener('close', () => {
                socket.removeEventListener('message', this.wssOnMessage);
                setTimeout(() => {
                    _this.connectWSS();
                    _this.loadOrder();
                }, 3000)
            })
        }).catch((ex) => {
            setTimeout(() => {
                _this.connectWSS();
                _this.loadOrder();
            }, 3000)
        })
    }
    requestLiveData() {
        const orderId = this.props.match.params.id;

        NbioApi.orders.requestLiveData(orderId).then((res) => {

        }).catch((ex) => {

        })
    }
    wssOnMessage(event) {
        let jsonEvent = {}
        try {
            jsonEvent = JSON.parse(event.data);
        } catch (e) {

        }
        switch (jsonEvent.nbioEvent) {
            case WSSClient.NBIO_EVENTS.ORDER_CREATED:
                return -1
                break;
            case WSSClient.NBIO_EVENTS.ORDER_UPDATED:
                // console.log(jsonEvent)
                if (jsonEvent.data.orderId === this.state.orderId) {
                    this.loadOrder();
                }
                break;
            case WSSClient.NBIO_EVENTS.DATA_DEVICE_UPDATED:
                console.log(jsonEvent.data)
                this.setState({isReportingLive: true});
                const currentTemperature = jsonEvent.data.deviceData.T0 || this.state.currentTemperature;
                const currentHumidity = jsonEvent.data.deviceData.H0 || this.state.currentHumidity;
                const currentTemperature2 = jsonEvent.data.deviceData.T1 || this.state.currentTemperature2;
                const currentHumidity2 = jsonEvent.data.deviceData.H1 || this.state.currentHumidity2;
                this.setState(
                    {
                        currentTemperature:currentTemperature,
                        currentHumidity:currentHumidity,
                        currentTemperature2:currentTemperature2,
                        currentHumidity2:currentHumidity2,
                        lastReportTimeStamp:jsonEvent.data.deviceData.created_at
                    })
                // gps

                const LAT = jsonEvent.data.deviceData.LAT;
                const LONG= jsonEvent.data.deviceData.LONG
                this.setState({
                    currentLat:LAT,
                    currentLng:LONG
                })
                this.loadRoute();
                break;
            default:
                return -1;
        }
    }


    formatDate(date) {
        if (!date) {
            return '-';
        }
        return dayjs(date).format('DD MMM YYYY - HH:mm:ss');
    }
    renderDataDevice() {
        const status = this.state.order.status;
        if(['on_route','delivering'].includes(status) && this.state.isReportingLive) {
            return (
                <div>
                    <div style={{backgroundColor: 'red', padding: 10, borderRadius: 5, display: 'inline-block'}}>
                        <Spinner animation={'grow'} variant={"white"} size={'sm'}>
                        </Spinner>
                        <span style={{color: 'white', marginLeft: 5}}>En vivo</span>
                    </div>
                    <div className={'d-flex align-items-center mt-2'}>
                        <Card border={'secondary'}  >
                            <Card.Body className={'d-flex flex-column align-items-center'} style={{minWidth: '175px'}}>
                                <FontAwesomeIcon icon="temperature-high" size={'lg'} color={'#00b2e3'}/>
                                <div className={'d-flex mt-2'}>
                                    <div><b>T0: {this.state.currentTemperature} °C</b></div>
                                    <div className={'ml-2'}><b>T1: {this.state.currentTemperature2} °C</b></div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card border={'success'} className={'ml-2'} style={{minWidth: '175px'}}>
                            <Card.Body className={'d-flex flex-column align-items-center'}>
                                <FontAwesomeIcon icon="water" size={'lg'} color={'#2dd36f'}/>
                                <div className={'d-flex mt-2'}>
                                    <div><b>H0: {this.state.currentHumidity} %</b></div>
                                    <div className={'ml-2'}><b>H1: {this.state.currentHumidity2} %</b></div>
                                </div>
                            </Card.Body>
                        </Card>

                        <div>
                            <div style={{padding: 10, borderRadius: 5, display: 'inline-block'}}>
                                <FontAwesomeIcon icon="clock"/>
                                <span className={'ml-2'}><b>{this.formatDate(this.state.lastReportTimeStamp)}</b></span>
                            </div>
                            <a href={`/orders/${this.state.order._id}/tracking`}>
                                <Button variant={'link'}>
                                    Más información
                                </Button>
                            </a>

                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className={'d-flex'}>
                    <div style={{backgroundColor: 'gray', padding: 10, borderRadius: 5,display:'inline-block'}}>
                        <span style={{color: 'white', marginLeft: 5}}>No disponible</span>
                    </div>
                </div>
            )
        }
    }
    getPathGeoJSON = () => {
        let path = {
            "type": "FeatureCollection",
            "features": [
                {
                    "type": "Feature",
                    "properties": {},
                    "geometry": {
                        "type": "LineString",
                        "coordinates": this.state.operatorPath
                    }
                }
            ]
        }
        return path;
    }
    renderMap(){
        return(
            <ReactMapGL
                {...this.state.viewport}
                mapStyle="mapbox://styles/eaperezv/cl4hbzpj9001s15nywg1tdutg"
                mapboxApiAccessToken={MAPBOX_TOKEN}
                onViewportChange={(viewport) => {
                    this.setState({viewport:viewport})
                }}
            >
                <Source id="courier-points" type="geojson" data={this.getPathGeoJSON()}>
                    <Layer type={'line'} paint={{
                        "line-color": "#00ff59",
                        'line-opacity': 1,
                        'line-width':3
                    }}/>
                </Source>
                {
                    this.state.currentLat ?
                        <Marker longitude={this.state.currentLng} latitude={this.state.currentLat} >
                            <img src={icon} width={25}/>
                        </Marker>
                        : null
                }
            </ReactMapGL>
        )
    }
    render() {
        let deviceName = ''
        try{
            deviceName = this.state.order.dataDeviceId.name;

        }catch (e) {
            deviceName = 'Sin asignar'
        }
        return (
            <Container fluid className={'h-100 overflow-auto'}>
                <Row>
                    <Col className='py-3 d-flex flex-column'>
                        <h5 className={'text-capitalize '}>
                            <FontAwesomeIcon icon={'arrow-left'} style={{cursor:'pointer'}} onClick={() =>{
                                this.props.history.goBack();
                            }}/> Tracking
                        </h5>
                    </Col>
                </Row>

                <Row className={'mb-3'}>
                    <Col sm={4}>
                        Última actualización:<br/> <b>{this.formatDate(this.state.lastUpdateDate)}</b>
                    </Col>
                    <Col sm={8} className={'d-flex justify-content-end'}>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col sm={12} xxl={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>En vivo</Card.Title>
                                {
                                    this.renderDataDevice()
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col sm={12} xxl={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>Dispositivo asignado</Card.Title>
                                {deviceName}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col sm={12} xxl={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Temperatura
                                </Card.Title>
                                    <Line redraw={true} data={this.state.dataTemperature} options={CHART_OPTIONS}  />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col sm={12} xxl={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Humedad relativa
                                </Card.Title>
                                <Line redraw={true} data={this.state.dataHumidity} options={CHART_OPTIONS}  />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-4'}>
                    <Col sm={12} xxl={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Ruta
                                </Card.Title>
                                {this.renderMap()}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )

    }


}

export default withRouter(OrderTracking);
