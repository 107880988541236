/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NbioApi from "../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import {money} from "../lib/money/money";
import {withRouter} from "react-router";
import Swal from "sweetalert2";
import Alert from "react-bootstrap/Alert";

//components
import ImageSelector from "../components/ui/ImageSelector";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NPIf from "np-if";


class ViewTicketsView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            order: {
                shipping: {},
                shippingMethod:{},
                items:[],
                user:{},
                logs:[],
                coupons:[]
            },
            items: [],
            rowIndex: -1,
            isEdited: false,
            selectedImage: '',
            isValidating:false,
            isEditing:false,
            hasDiscount:false
        }

        this.loadOrder                      = this.loadOrder.bind(this);
    }
    componentDidMount() {
        this.loadOrder();
    }
    loadOrder(){
        const orderId = this.props.match.params.id;
        NbioApi.orders.getOrder(orderId).then((res) => {
            const selectedImage = res.data.order.tickets.length > 0 ? res.data.order.tickets[0].imageUrl : '';
            const hasDiscount = res.data.order.items.some((i) => !!i.discount);
            this.setState({
                order: res.data.order,
                selectedImage: selectedImage,
                hasDiscount: hasDiscount
            });
        })
    }

    render(){
        const items = this.state.items ? this.state.items : [];
        const order = this.state.order ? this.state.order : {};
        const selectedImage = this.state.selectedImage;


        return(
            <Container fluid className={'h-100 d-flex flex-column'}>
                <Row>
                    <Col>
                        <Card.Body  className={'d-flex justify-content-between align-items-center'}>
                            <h5 className={'text-capitalize'}>Ver recibos
                                <span className={'pl-1 text-info'}><small>#{order._id}</small></span>
                            </h5>
                            <Button variant={"light"}
                                    onClick={() => {this.props.history.goBack()}}
                            >
                                <FontAwesomeIcon icon={"times"}/>
                            </Button>
                        </Card.Body>
                    </Col>
                </Row>

                <Row className={'flex-grow-1 mt-2'}>
                    <Col sm={12} md={12} >
                        {
                            order.tickets?.length === 0 ?
                                'Aún no hay recibos agregados':
                                <ImageSelector images={order.tickets}
                                               selectedImage={selectedImage}
                                               onImageSelected={(img) => this.setState({selectedImage:img})} >
                                </ImageSelector>

                        }

                    </Col>
                </Row>
                <Row className={'mt-auto mb-3'}>
                    <Col>
                        <Card.Body  className={'d-flex justify-content-end'}>
                            <Button className={'mr-1'} variant={"danger"} size="lg"  onClick={() => this.props.history.goBack()()}>
                                Volver
                            </Button>

                        </Card.Body>
                    </Col>
                </Row>
            </Container>
        )

    }
}

export default withRouter(ViewTicketsView);
