/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NbioApi from "../../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from 'react-bootstrap/Spinner'
import NPPaginator from "../ui/NPPaginator";
import SortBtns from "../ui/SortBtns";


class UsersTable extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
            query:'',
            users:[],
            isLoading:true,
            showSearchResults: false,
            pages:1,
            currentPage:1,
            searchMode:false,
            searchedQuery:'',
            searchCount:0
        }
        this.loadProducts   = this.loadProducts.bind(this);
        this.onSearch       = this.onSearch.bind(this);
        this.onClearSearch  = this.onClearSearch.bind(this);
        this.onChange       = this.onChange.bind(this);
    }

    componentDidMount() {
        this.loadProducts();
    }

    loadProducts(){
        const pageSize=25;
        this.setState({isLoading:true});
        NbioApi.users.getUsers('admin|operator|super_admin|region_admin|region_operator',this.state.currentPage,pageSize).then(res => {
            console.log(res.data);
            this.setState({
                users:res.data.users,
                isLoading:false,
                pages:res.data.pages
            });
        }).catch((ex) =>{
            this.setState({
                users:[],
                isLoading:false,
                pages:1,
            });
        })
    }
    loadSearch(){
        this.setState({isLoading:true});
        NbioApi.users.getUsers(this.state.searchedQuery,this.state.currentPage).then(res => {
            this.setState({
                users:res.data.users,
                isLoading:false,
                showSearchResults:true,
                pages:res.data.pages,
                searchCount:res.data.count
            });
        }).catch((ex) =>{
            this.setState({
                users:[],
                isLoading:false,
                pages:1,
            });
        })

    }

    onChange(e){
        this.setState({
            [e.target.id]:e.target.value
        })
    }

    onSearch(){
        const query = this.state.query.trim();
        this.setState({searchMode:true,searchedQuery:query}, () =>{
            if(query){
                this.setState({isLoading:true,currentPage:1});
                this.loadSearch();
            }
        });

    }

    onClearSearch(){
        this.setState({query:'',showSearchResults:false,isLoading:true,searchMode:false}, () => {
            this.loadProducts();
        })
    }
    onPageChanged(page){
        this.setState({currentPage:page},() =>{
            if(this.state.searchMode) {
                this.loadSearch();
            }else{
                this.loadProducts();
            }
        })
    }
    parseRole(role){
        if(role === 'operator'){
            return 'Operador';
        }else if(role === 'super_admin') {
            return 'Super Administrador';
        }else if(role === 'admin') {
            return 'Administrador';
        }else if(role === 'user') {
            return 'Usuario';
        }else if(role === 'region_operator'){
            return 'Operador de región';
        }else if(role === 'region_admin'){
            return 'Administrador de región';
        }else{
            return role;
        }
    }
    render(){
        const columns = [
            {
                dataField: 'name',
                text: 'Nombre',
                formatter:(v,r)=>{
                    return(
                        <a href={`/users/${r._id}`}>{[r.name, r.last_name].filter((n) => n).join(' ')}</a>
                    )
                },
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'200px',
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'roles',
                text: 'Roles',
                formatter:(v,r)=>{
                    return(
                        v.map((r) => this.parseRole(r)).join(', ')
                    )
                },
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'200px',
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'email',
                text: 'e-mail',
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'is_disabled',
                text: '¿Deshabilitado?',
                formatter:(v)=>{
                    const txt = v ? 'Si' : 'No';
                    return txt;
                },
            },
        ];

        return(
            <Container fluid style={{overflowY:"scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3 d-flex justify-content-between'>
                        <h5 className={'text-capitalize '}>Usuarios</h5>
                        <a href={'/users/create'}>
                            <Button className={'ml-auto'}>Crear usuario</Button>
                        </a>
                    </Col>
                </Row>

                <Row>
                    <Col className='pb-3'>
                        <NPIf condition={this.state.isLoading}>
                            <div className={'d-flex align-items-center justify-content-center'}>
                                <Spinner animation="grow" variant={"secondary"}/>
                            </div>
                            <NPElse>
                                <BootstrapTable keyField='_id'
                                                data={this.state.users}
                                                columns={columns}
                                                noDataIndication={`No se encontraron usuarios`}
                                                bordered={false}
                                />
                                <NPPaginator
                                    numberOfPages={this.state.pages}
                                    onPageChanged={(p) => this.onPageChanged(p)} initialPage={this.state.currentPage}>
                                </NPPaginator>

                            </NPElse>
                        </NPIf>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(UsersTable);
