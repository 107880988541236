/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from "react";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



class SortBtns extends React.Component{
    render(){
        const order = this.props.order;

        if (!order) return (<FontAwesomeIcon className={'ml-1 text-muted'} icon={"sort"} />);
        else if (order === 'asc') return (<FontAwesomeIcon className={'ml-1 text-secondary'} icon={"sort-up"} />);
        else if (order === 'desc') return (<FontAwesomeIcon className={'ml-1 text-secondary'} icon={"sort-down"} />);
        return null;
    }

}

SortBtns.defaultProps = {
    order: null
}
SortBtns.propTypes = {
    order: PropTypes.string
}

export default SortBtns;
