/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NPIf from "np-if";
import Form from "react-bootstrap/Form";
import API from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Alert from "react-bootstrap/Alert";

//components
import DragZone from "../ui/Dropzone";
import UsersPicker from "../ui/pickers/UsersPicker";
import DevicesPicker from "../ui/pickers/DevicesPicker";
import Spinner from "react-bootstrap/cjs/Spinner";


class DataDevice extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            dataDevice: {},
            isLoading: true,
            isSaving: false
        }
        this.getDataDevice = this.getDataDevice.bind(this);
        this.onClickBtn = this.onClickBtn.bind(this);
        this.createDataDevice = this.createDataDevice.bind(this);
        this.updateDataDevice = this.updateDataDevice.bind(this);
        this.deleteDataDevice = this.deleteDataDevice.bind(this);
        this.confirmDataDevice = this.confirmDataDevice.bind(this);


    }

    componentDidMount() {
        const dataDeviceId = this.props.match.params.id;
        if (dataDeviceId !== 'create') {
            this.setTitle('Editar dispositivo');
            this.setLabel('Guardar cambios');
            this.getDataDevice(dataDeviceId);
        } else {
            this.setTitle('Crear dispositivo');
            this.setLabel('Crear dispositivo');
            this.setState({isLoading: false})
        }
    }

    setTitle = (title) => {
        this.setState({title: title});
    }

    setLabel = (label) => {
        this.setState({btnLabel: label});
    }

    getDataDevice = (deviceId) => {
        this.setState({isLoading: true})
        API.devices.getDevice(deviceId).then(res => {
            const dataDevice = res.data.dataDevice;
            this.setState({dataDevice: dataDevice, isLoading: false});
        }).catch(ex => {
            console.log(ex);
        })
    }

    onChange = (e) => {
        this.setState(prevState => {
            let dataDevice = Object.assign({}, prevState.dataDevice);
            dataDevice[e.target.id] = e.target.value;
            return {dataDevice};
        });
    }

    onClickBtn = () => {
        const {isValid, message} = this.isValidForm();
        const dataDeviceId = this.props.match.params.id;
        this.setState({
            isSaving: true
        });
        if (isValid) {
            if (dataDeviceId === 'create') {
                //create vehicle
                this.createDataDevice();
            } else {
                //update vehicle
                this.updateDataDevice();
            }
        } else {
            Swal.fire({
                title: 'Datos inválidos',
                html: message,
                icon: 'warning',
                heightAuto: false
            })
            this.setState({
                isSaving: false
            });
        }
    }
    updateDataDevice = () => {
        const dataDevice = {...this.state.dataDevice};
        this.setState({
            isSaving: true
        });
        API.devices.updateDevice(dataDevice).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'Dispositivo de datos actualizado',
                confirmButtonColor: '#4dda85',
                heightAuto: false,
            }).then((res) => {
                this.setState({
                    isSaving: false
                });
                this.props.history.push('/devices');
            });
        }).catch((ex) => {
                let errorMsg = 'Hubo un error al actualizar el dispositivo';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {

                }
                this.setState({
                    isSaving: false
                });
                this.fireSwalError(errorMsg);
        });
    }
    createDataDevice = () => {
        const dataDevice = {...this.state.dataDevice};
        this.setState({
            isSaving: true
        });
        API.devices.create(dataDevice).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'Dispositivo de datos creado',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
                this.setState({
                    isSaving: false
                });
                this.props.history.push('/devices');
            })
        })
            .catch((ex) => {
                let errorMsg = 'Hubo un error al crear el vehículo';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {

                }
                this.setState({
                    isSaving: false
                });
                this.fireSwalError(errorMsg);
            })
    }


    deleteDataDevice = () => {
        const dataDeviceId = this.props.match.params.id;
        API.devices.deleteDevice(dataDeviceId).then(res => {
            Swal.fire({
                icon: 'success',
                title: 'Dispositivo de datos borrado',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
                this.props.history.push('/devices');
            });
        }).catch(ex => {
            let errorMsg = 'Hubo un error al borrar el usuario'
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {
                errorMsg = 'Servicio no disponible';
            }
            this.fireSwalError(errorMsg);
        })
    }
    fireSwalError = (txt) => {
        Swal.fire({
            icon: 'error',
            title: txt,
            confirmButtonColor: '#2a7de1',
            heightAuto: false
        })
    }


    confirmDataDevice = () => {
        Swal.fire({
            icon: 'warning',
            title: "¿Estás seguro de borrar el dispositivo de datos?",
            html: "El dispositivo de datos será borrado permanentemente del sistema. Esta acción no puede ser revertida",
            confirmButtonColor: '#f32735',
            cancelButtonText: 'No',
            confirmButtonText: 'Si, borrar',
            showCancelButton: true,
            reverseButtons: true,
            heightAuto: false
        }).then((res) => {
            if (res.isConfirmed) {
                //Delete user
                this.deleteDataDevice();
            }
        });
    }

    isValidForm() {
        const {dataDevice} = this.state;
        // validate name
        if (isEmpty(dataDevice.name)) {
            return {
                message: 'El nombre no puede estar vacío',
                isValid: false
            }
        } else if (isEmpty(dataDevice.id)) {
            return {
                message: 'El identificador no puede estar vacío',
                isValid: false
            }
        }
        return {
            message: '',
            isValid: true
        }
    }

    render() {
        const dataDevice = this.state.dataDevice;
        const dataDeviceId = this.props.match.params.id;
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3'>
                        <div className={'d-flex align-items-center'}>
                            <h5 className={'text-capitalize mr-auto'}>{this.state.title}</h5>
                            <NPIf condition={dataDeviceId !== "create"}>
                                <DropdownButton id="dropdown-basic-button" title="Configuración" variant={'danger'}>
                                    <Dropdown.Item
                                        onClick={() => this.confirmDataDevice()}>Borrar dispositivo
                                    </Dropdown.Item>
                                </DropdownButton>
                            </NPIf>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col className='py-3' sm={dataDeviceId === "create" ? 12 : 8}>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Datos del dispositivo
                                </Card.Title>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="name">
                                    <Form.Label column sm={2}>Nombre</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Nombre"
                                                      value={dataDevice.name}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="id">
                                    <Form.Label column sm={2}>Identificador</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Identificador: Debe de coincidir con la configuración del dispositivo"
                                                      value={dataDevice.id}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <div className={'d-flex justify-content-end'}>
                                    {
                                        this.isSaving ?
                                            <Spinner>

                                            </Spinner>
                                            :
                                            <Button onClick={() => this.onClickBtn()}
                                            >{this.state.btnLabel}</Button>
                                    }

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        )
    }

}

export default withRouter(DataDevice);
const isEmpty = (v) => {
    if (v) {
        return v.trim() === '';
    } else {
        return true;
    }
}
