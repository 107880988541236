/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

import React, {forwardRef} from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import API from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import {arrayMove, SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import Form from "react-bootstrap/Form";
import ReactMapGL, {Layer, Marker, Source} from "react-map-gl";
import NPIf from "np-if";
import {money} from "../../lib/money/money";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import {Badge, DropdownButton} from "react-bootstrap";
import ButtonGroup from "react-bootstrap/ButtonGroup";

//style
import "../../scss/components/region.scss";

// geo
import {getDistance} from 'geolib';
import {Link} from "react-router-dom";
import NPElse from "np-if/src/NPElse";
import NbioApi from "../../lib/api/NbioApi";
import Card from "react-bootstrap/Card";
import dayjs from "dayjs";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

//token TODO: replace to nbio token
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZWFwZXJlenYiLCJhIjoiY2w0ZzIzdWduMDJuMDNjczF5bTA2Y2M4MSJ9.Iyqpdv6RE0BeqkesEq7IXw';
const _            = require('underscore');


//---------functions----------------------------------------------------------------------------------------------------
const renderAddress = (order) => {
    const shipping = order.shipping || {};
    return [
        shipping.address, shipping.address2, shipping.borough, shipping.state, shipping.zipCode
    ].filter((p) => p).join(', ');
}
const renderOperator = (order) => {
    const assignedUser = order.assignedUser;
    if(!assignedUser){
        return 'Sin operador';
    }else{
        return `${assignedUser.name} ${assignedUser.last_name}`;
    }
}
const DragHandle = SortableHandle(() =>
    <div style={{cursor: 'move', width: 36, height: 38}}
         className={'d-flex align-items-center justify-content-center border border-medium ml-1 rounded'}>
        <FontAwesomeIcon icon={"bars"}></FontAwesomeIcon>
    </div>);

const SortableItem = SortableElement(({value, onClickMove, i, length, onDelete}) => {
        const region     = value.region || {};
        const styleBadge = {
            backgroundColor: region ? region.color : 'transparent',
            color: 'white'
        }

        const status = (value) => {
            let variant = '';
            let txt     = '';

            switch (value) {
                case 'processing':
                    variant = 'warning';
                    txt     = 'Procesando';
                    break;
                case 'received':
                    variant = 'warning';
                    txt     = 'Recibida';
                    break;
                case 'amount_validated':
                    variant = 'warning';
                    txt     = 'Monto validado';
                    break;
                case 'on_route':
                    variant = 'warning';
                    txt     = 'En camino';
                    break;
                case 'delivering':
                    variant = 'warning';
                    txt     = 'Entregando';
                    break;
                case 'delivered':
                    variant = 'success';
                    txt     = 'Entregado';
                    break;
                case 'finished':
                    variant = 'success';
                    txt     = 'Terminado';
                    break;
                case 'cancelled':
                    variant = 'danger';
                    txt     = 'Cancelada';
                    break;
                default:
                    variant = 'info';
                    txt     = '---';
                    break;
            }
            return (
                <span className={`text-${variant}`}>{txt}</span>
            )
        }

        return (
            <ListGroup.Item as={'li'} className={'d-flex justify-content-between align-items-start'}>
                <div className={'d-flex flex-column ms-2 me-auto'}>
                    <div className={'d-flex'}>
                        <div>
                            <Link to={`/orders/${value._id}`}>
                                <div className={'fw-bold'}>{value._id} </div>
                            </Link>
                        </div>
                        <div>
                            &nbsp;/ {status(value.status)}
                        </div>
                    </div>
                    <div>{renderOperator(value)}</div>
                    <div>{renderAddress(value)}</div>
                    <div>{money(value.total)}</div>
                    <div>
                        <span className={'badge'} style={styleBadge}>{region.name}</span>
                    </div>

                    <div className={'h5 mt-3'}>
                        <FontAwesomeIcon icon={"route"} style={{color: "#26bee7"}}/> &nbsp;
                        <b>
                        {
                            value.distanceToNextPoint ?
                                `${parseFloat(value.distanceToNextPoint).toFixed(2)} Km.`
                                : '-'
                        }
                        </b>
                    </div>
                </div>
                <div>
                    <DropdownButton title={'Mover'} as={ButtonGroup}>
                        <Dropdown.Item onClick={() => onClickMove(value, i, i - 1)}>Arriba</Dropdown.Item>
                        <Dropdown.Item onClick={() => onClickMove(value, i, i + 1)}>Abajo</Dropdown.Item>
                        <Dropdown.Item onClick={() => onClickMove(value, i, 0)}>Mover al inicio</Dropdown.Item>
                        <Dropdown.Item onClick={() => onClickMove(value, i, length)}>Mover al final</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={() => onDelete(value, i)}>Eliminar</Dropdown.Item>
                    </DropdownButton>
                </div>
                <DragHandle></DragHandle>
            </ListGroup.Item>
        )
    }
);


const SortableList = SortableContainer(({items, onClickMove, onDelete}) => {
    return (
        <ListGroup as={'ol'} numbered={true}>
            {items.map((value, index) => (
                <SortableItem key={`item-${value}`} index={index} value={value} i={index}
                              onClickMove={onClickMove} length={items.length} onDelete={onDelete}

                />
            ))}
        </ListGroup>
    );
});

//---------classes------------------------------------------------------------------------------------------------------

class SortableComponent extends React.Component {
    state = {
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
    };

    render() {
        return <SortableList useDragHandle items={this.props.items}
                             onSortEnd={this.props.onSortEnd} onClickMove={this.props.onClickMove}
                             onDelete={this.props.onDelete}
        />;
    }
}

class Route extends React.Component {
    constructor(p) {
        super(p);
        this.state         = {
            route: {
                name: '',
                orders: [],
            },
            distances: [],
            isLoading: true,
            viewport: {
                width: '100%',
                height: '600px',
                latitude: 19.432608,
                longitude: -99.133209,
                zoom: 13
            },
            operators: []
        }
        this.getRoute      = this.getRoute.bind(this);
        this.onClickBtn    = this.onClickBtn.bind(this);
        this.validateRoute = this.validateRoute.bind(this);
        this.create        = this.create.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.onSortEnd     = this.onSortEnd.bind(this);
        this.onClickMove   = this.onClickMove.bind(this);
        this.onDelete      = this.onDelete.bind(this);
    }

    componentDidMount() {
        const routeId = this.props.match.params.id;
        if (routeId !== 'create') {
            this.getRoute(routeId);
        } else {
            this.setState({isLoading: false})
        }
        this.getOperators();
    }

    getOperators() {
        NbioApi.users.getUsers('operator').then((res) => {
            this.setState({
                operators: res.data.users
            })
        }).catch((ex) => {

        })
    }

    getRoute = (routeId) => {
        this.setState({isLoading: true})
        API.routes.getRoute(routeId).then((res) => {
            let route = {...res.data.route};
                route.logs = route.logs ? route.logs.reverse() : [];
            this.setState({route: route, isLoading: false}, () => {
                this.calcDistances();
            });
        }).catch((ex) => {
            this.setState({isLoading: false})
        })
    }


    onChange = (e) => {
        this.setState(prevState => {
            let route          = Object.assign({}, prevState.route);
            route[e.target.id] = e.target.value;
            return {route: route};
        });
    }

    onClickBtn    = () => {
        const {error, isValid} = this.validateRoute();
        if (!isValid) {
            return Swal.fire({
                icon: 'error',
                title: 'Error',
                html: error,
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            })
        }
        const regionId = this.props.match.params.id;
        if (regionId === 'create') {
            //create Route
            this.create();
        } else {
            //update Route
            this.update();
        }
    }
    validateRoute = () => {
        // check name
        let error     = '';
        const isValid = false;
        if (!this.state.route.name.trim()) {
            error = 'El nombre no puede estar vacío';
            return {
                isValid: false,
                error: error
            }
        }
        return {
            isValid: true,
            error: null
        }

    }
    create        = () => {
        const txt = `¿Estás seguro de crear la ruta?`;
        Swal.fire({
            icon: 'warning',
            title: 'Crear ruta',
            html: txt,
            confirmButtonColor: '#4dda85',
            heightAuto: false,
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed) {
                API.routes.create(this.state.route).then((res) => {
                    this.props.history.push('/routes');
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al crear la ruta';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                })
            }
        })
    }

    update = () => {
        const txt   = `¿Estás seguro de actualizar la ruta?`;
        const route = this.state.route;
        Swal.fire({
            icon: 'warning',
            title: 'Guardar ruta',
            html: txt,
            confirmButtonColor: '#4dda85',
            heightAuto: false,
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed) {
                const _route  = {...route};
                const orders  = _route.orders || [];
                _route.orders = orders.map((r) => r._id);
                API.routes.update(route._id, {route: _route}).then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Ruta actualizada',
                        confirmButtonColor: '#4dda85',
                        heightAuto: false,
                        showCancelButton: true
                    });
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al actualizar la ruta';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                })
            }
        })

    }

    fireSwalError = (txt) => {
        Swal.fire({
            icon: 'error',
            title: txt,
            confirmButtonColor: '#2a7de1',
            heightAuto: false
        })
    }

    confirmDelete = () => {
        Swal.fire({
            icon: 'error',
            title: '¿Estás seguro de borrar la ruta?',
            html: 'Una vez borrada la región no se podrá recuperar',
            confirmButtonColor: '#f32735',
            confirmButtonText: 'Sí, borrar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            reverseButtons: true,
        }).then((res) => {
            if (res.isConfirmed) {
                const regionId = this.props.match.params.id;
                API.routes.delete(regionId).then((res) => {
                    this.props.history.push('/routes');
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al borrar la ruta';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                })
            }
        })
    }

    onDelete(value, index) {
        const route  = {...this.state.route};
        route.orders = route.orders.filter((o, i) => i !== index);
        this.setState({
            route: route
        }, () => {
            this.calcDistances();
        });

    }

    onClickMove(value, oldIndex, newIndex) {

        this.onSortEnd({oldIndex, newIndex});
    }

    onSortEnd({oldIndex, newIndex}) {
        const route  = {...this.state.route};
        route.orders = arrayMove(route.orders, oldIndex, newIndex);
        this.setState({
            route: route
        }, () => {
            this.calcDistances();
        })
    }

    generateGoogleMapsLink() {
        const route  = this.state.route;
        const orders = route.orders || [];
        if (orders.length > 1) {
            const origin      = orders[0];
            const destination = orders[orders.length - 1];
            let waypoints     = '';
            if (orders.length > 2) {
                waypoints = orders.slice(1, orders.length - 1).map((o) => {
                    return [o.shipping.latitude, o.shipping.longitude].join(',')
                }).join('|');

            }
            try {
                const link = `
            https://www.google.com/maps/dir/?api=1&origin=${origin.shipping.latitude},${origin.shipping.longitude}
            &destination=${destination.shipping.latitude},${destination.shipping.longitude}&waypoints=${waypoints}`;
                return link;
            } catch (ex) {
                return '';
            }

        }

    }

    calcDistances() {
        const orders    = this.state.route.orders || {};
        const distances = orders.map((o, i) => {
            const shipping    = o.shipping || {};
            // add color
            const randomColor = Math.floor(Math.random() * 16777215).toString(16);
            o.color           = `#${randomColor}`;
            if (i < orders.length - 1) {
                const shipping2 = orders[i + 1].shipping || {};
                const d         = getDistance(
                    {latitude: shipping.latitude, longitude: shipping.longitude},
                    {latitude: shipping2.latitude, longitude: shipping2.longitude},
                );
                return d / 1000;
            }
        });
        orders.map((o, i) => {
            o.distanceToNextPoint = distances[i];
        })
        this.setState({
            orders: orders,
            distances: distances
        })
    }

    renderMap() {
        return (
            <ReactMapGL
                {...this.state.viewport}
                mapStyle="mapbox://styles/eaperezv/cl4hbzpj9001s15nywg1tdutg"
                mapboxApiAccessToken={MAPBOX_TOKEN}
                onViewportChange={(viewport) => {
                    this.setState({viewport: viewport})
                }}
            >
                {
                    this.state.route.orders.map((order, index) => {
                        if (order) {
                            const shipping = order.shipping || {};
                            return (
                                <Marker longitude={shipping.longitude} latitude={shipping.latitude} key={order._id}>
                                    <Badge bg={'primary'}>{index + 1}</Badge>
                                </Marker>
                            )
                        } else {
                            return null;
                        }

                    })
                }
            </ReactMapGL>
        )
    }

    optimize() {
        // DUMMY WAY TO GET DISTANCES
        // DO NOT SCALE! YOU HEARD RIGHT!
        // DO NOT SCALE!
        // AGAIN: DO NOT SCALE!
        const orders = this.state.orders;
        orders.forEach((o, i) => {
            const shipping = o.shipping || {};
            orders.forEach((p) => {
                const shipping2 = p.shipping || {};
                const d         = getDistance(
                    {latitude: shipping.latitude, longitude: shipping.longitude},
                    {latitude: shipping2.latitude, longitude: shipping2.longitude},
                );
                // console.log(i,o._id,p._id,d);
            })


        })
        // _.sortBy(this.state.distances, function(num, index){
        //     console.log(num,index);
        // });
    }

    assignOperator(uid) {
        const routeId = this.props.match.params.id;
        NbioApi.routes.assignToOperator(routeId, uid).then(res => {
            this.getRoute(routeId);
        }).catch(ex => {
            let errorMsg = 'Hubo un error al asignar al operador';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {
                errorMsg = 'Servicio no disponible';
            }
            this.fireSwalError(errorMsg);
        });
    }

    render() {
        const route        = this.state.route;
        const routeId      = this.props.match.params.id;
        const operators    = this.state.operators || [];
        const assignedUser = route.assignedUser ? route.assignedUser : {};
        const logs         = route.logs || [];
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>

                <Row>
                    <Col className='py-3'>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <h5 className={'text-capitalize mr-auto'}>{routeId === 'create' ? 'Crear ruta' : 'Ruta'}</h5>
                            <NPIf condition={routeId !== 'create'}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="danger" id="dropdown-basic">
                                        Configuración
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.confirmDelete()}>Borrar
                                            ruta</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </NPIf>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className='py-3' sm={12}>
                        <Form.Group as={Row} className="mt-4 mb-3" controlId="name">
                            <Form.Label column sm={2}>Nombre</Form.Label>
                            <Col sm={10}>
                                <Form.Control type="text" placeholder="Nombre" value={route.name}
                                              onChange={event => this.onChange(event)}/>
                            </Col>
                        </Form.Group>
                    </Col>
                    <NPIf condition={routeId !== 'create'}>
                        <Col className='py-3' sm={12}>
                            <Form.Group as={Row} className="mt-4 mb-3" controlId="name">
                                <Form.Label column sm={2}>Operador</Form.Label>
                                <Col sm={10}>
                                    <NPIf condition={assignedUser.uid}>
                                        <div className={'amount text-capitalize'}>
                                            {assignedUser.name} {assignedUser.last_name} ({assignedUser.email})
                                        </div>
                                        <NPElse>
                                            <div className={'amount text-capitalize'}>
                                                El operador no ha sido asignado
                                            </div>
                                        </NPElse>
                                    </NPIf>
                                    <DropdownButton as={ButtonGroup}
                                                    variant="primary"
                                                    className={'text-uppercase'}
                                                    title={'ASIGNAR OPERADOR'}>
                                        {
                                            operators.map(operator => {
                                                return (
                                                    <Dropdown.Item key={operator.uid}
                                                                   className={'text-capitalize'}
                                                                   onClick={() => this.assignOperator(operator.uid)}>
                                                        {operator.name} {operator.last_name}
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </DropdownButton>
                                </Col>
                            </Form.Group>
                        </Col>
                    </NPIf>

                </Row>
                {/*<Row>*/}
                {/*    <Col sm={12} className={'d-flex justify-content-end'}>*/}
                {/*        <Button className={'ml-1'} onClick={() => this.optimize('short-distances')}>Optimizar ruta</Button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row className={'mb-5'}>
                    <Col sm={12} md={6} className={''}>
                        {this.renderMap()}
                    </Col>
                    <Col sm={12} md={6} className={''}>
                        <SortableComponent
                            items={this.state.route.orders}
                            onSortEnd={this.onSortEnd}
                            onClickMove={this.onClickMove}
                            distances={this.state.distances}
                            onDelete={this.onDelete}
                        ></SortableComponent>
                        <br/>
                        <b>
                            Distancia total (lineal): {
                            parseFloat(this.state.distances.filter((d) => d).reduce((a, b) => a + b, 0)).toFixed(2)
                        } Km.
                        </b>
                        <NPIf condition={this.props.match.params.id !== 'create'}>
                            <Row>
                                <b>
                                    Estado de la ruta {this.renderStatus()}
                                </b>
                            </Row>
                        </NPIf>

                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className={'d-flex justify-content-end'}>
                        <Button variant="link"
                                href={this.generateGoogleMapsLink()} target={'blank'}>Ver ruta en Google Maps</Button>

                        <Button className={'ml-1'} onClick={() => this.onClickBtn()}>Guardar</Button>
                    </Col>
                </Row>
                <Row className={'mt-4 mb-4'}>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize'}>Historial de la ruta</Card.Title>
                                {
                                    logs.map((log) => {
                                        return (
                                            <Row className={'mb-4'}>
                                                <Col sm={2} style={{display: 'flex', alignItems: 'center'}}>
                                                    <b>{dayjs(log.created_at).format('DD MMM HH:mm, YYYY')}</b>
                                                </Col>
                                                <Col sm={8} style={{display: 'flex', alignItems: 'center'}}>
                                                    <span style={{fontSize: 25, marginRight: 10}}>
                                                        {
                                                            log.logType === 'viewed' ? '👀' : ''
                                                        }
                                                    </span>
                                                    {log.log}
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
        )
    }

    renderStatus() {
        const route = this.state.route;
        if (route.status === 'open') {
            return <Badge bg={'primary'}>Abierta</Badge>
        } else if (route.status === 'on_route') {
            return <Badge bg={'warning'}>En ruta</Badge>
        } else if (route.status === 'finished') {
            return <Badge bg={'success'}>Terminada</Badge>
        } else {
            return <Badge>{route.status}</Badge>
        }
    }
}

export default withRouter(Route);
