/* Copyright (C) Nodeport SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <amendoza@nodeport.co> &
 * Darien Miranda <dmiranda@nodeport.co>
 * December 2022
 */

import React from 'react';
import PropTypes from "prop-types";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import NbioApi from "../../lib/api/NbioApi";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/cjs/Row";
//style
// import "../../scss/components/product-selector.scss"

//components

class CategoriesPicker extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            categories: []
        }

        this.onChange           = this.onChange.bind(this);
        this.getCategories      = this.getCategories.bind(this);
    }

    componentDidMount() {
        this.getCategories();
    }

    //todo: remove
    // onChange = (e) => {
    //     this.setState({
    //         [e.target.id]:e.target.value
    //     })
    // }

    getCategories = () => {
        NbioApi.categories.getCategories().then((res) => {
            this.setState({categories:res.data.categories});
        }).catch(ex => {
        })
    }


    onChange = (e) => {
        const target = e.target;
        console.log(target.value);
        if(target.checked){
            this.props.onCategoryChecked(target.value);
        }else{
            this.props.onCategoryUnchecked(target.value);
        }
    }

    render() {
        const categories =  this.state.categories;

        return (
            <Form as={Row}>
                {categories.map((category) => {
                    console.log(category);
                    return(
                        <Form.Check key={`key-${category.name}`}
                                    label={category.name}
                                    className={'text-capitalize col-3'}
                                    name="category"
                                    type={"checkbox"}
                                    id={`${category.name}`}
                                    value={`${category.lowerCaseName}`}
                                    onChange={(e) => {this.onChange(e)}}
                        />
                    )
                })}
            </Form>
        )
    }
}

export default CategoriesPicker;

CategoriesPicker.propTypes = {
    onCategoryChecked: PropTypes.func,
    onCategoryUnchecked: PropTypes.func
}
CategoriesPicker.defaultProps = {
    onCategoryChecked: () => {},
    onCategoryUnchecked: () => {}
}
