/* Copyright (C) Nodeport SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <amendoza@nodeport.co> &
 * Darien Miranda <dmiranda@nodeport.co>
 * December 2022
 */

import React from 'react';
import PropTypes from "prop-types";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import NbioApi from "../../lib/api/NbioApi";

//style
import "../../scss/components/product-list.scss"

//components

class ProductList extends React.Component {
    render() {
        const products =  this.props.products;

        return (
            <div className={'product-list'}>
                <NPIf condition={products.length === 0}>
                    <div className={'pl-item'}>
                        No hay productos seleccionados
                    </div>
                    <NPElse>
                        {
                            products.map((product,index) => {
                                return(
                                    <div key={product.sku} className={'pl-item'}>
                                        <img src={product.images[0]} height={'30px'}/>
                                        <div>
                                            <div className={'pl-item-text'}>
                                                {product.name}
                                            </div>
                                            <div className={'pl-item-text pl-item-gray'}>
                                                {product.sku}
                                            </div>
                                        </div>

                                        <Button  size={'sm'}
                                                 className={'ms-auto'}
                                                 variant={'outline-danger'}
                                                 onClick={() => this.props.onDeleteProduct(product)}>
                                            <FontAwesomeIcon icon={'trash'}/>
                                        </Button>
                                    </div>
                                )
                            })
                        }
                    </NPElse>
                </NPIf>

            </div>
        )
    }

}

export default ProductList;

ProductList.propTypes = {
    products: PropTypes.array,
    onDeleteProduct: PropTypes.func
}
ProductList.defaultProps = {
    products: [],
    onDeleteProduct: () => {}
}
