/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import API from "../../lib/api/NbioApi";
import OverlayTrigger from "react-bootstrap/cjs/OverlayTrigger";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Tooltip from "react-bootstrap/Tooltip";
import NPIf from "np-if";

//components
import DragZone from "../ui/Dropzone";


class Ad extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            ad: {
                link: '',
                image: '',
                image2: '',
                isPopup: false,
                name: ''
            },
            title: '',
        }

        this.getAd           = this.getAd.bind(this);
        this.setTitle        = this.setTitle.bind(this);
        this.onClickBtn      = this.onClickBtn.bind(this);
        this.validateAd      = this.validateAd.bind(this);
        this.onImageDropped  = this.onImageDropped.bind(this);
        this.onImage2Dropped = this.onImage2Dropped.bind(this);
        this.onChange        = this.onChange.bind(this);
        this.onChangeSwitch  = this.onChangeSwitch.bind(this);
        this.confirmDelete   = this.confirmDelete.bind(this);
        this.deleteAd        = this.deleteAd.bind(this);

    }

    componentDidMount() {
        const adId = this.props.match.params.id;
        if (adId !== 'create') {
            this.getAd(adId);
        } else {
            this.setTitle('Crear anuncio');
        }
    }

    setTitle = (title) => {
        this.setState({title: title});
    }


    getAd = (id) => {
        this.setTitle(`Anuncio ${id}`);

        API.ads.getAd(id).then((res) => {
            this.setState({ad: res.data.ad});
        }).catch((ex) => {
            console.log(ex);
        })
    }

    onChange = (e) => {
        const isCreating  = this.props.match.params.id === 'create';
        if(isCreating){
            // ok
        }else if(!isCreating && this.state.image2){
            // ok
        }
        else if(isCreating || (!isCreating && this.state.ad.image2)){
            this.setState(prevState => {
                let ad = Object.assign({}, prevState.ad);
                if (e.target.id === 'isPopup') {
                    ad[e.target.id] = e.target.checked;
                } else {
                    ad[e.target.id] = e.target.value;
                }
                return {ad};
            });
        }
        if(e.target.id !== 'isPopup'){
            this.setState(prevState => {
                let ad = Object.assign({}, prevState.ad);
                    ad[e.target.id] = e.target.value;
                return {ad};
            });
        }
    }

    onChangeSwitch = (e) => {
        const adId = this.props.match.params.id;
        const isCreating  = this.props.match.params.id === 'create';

        if (!this.state.ad.image2  && !isCreating) {
            return Swal.fire({
                title: 'Datos faltantes',
                icon: 'warning',
                html: 'Agrega una imagen para anuncio principal para activar esta función.',
                heightAuto:false

            })
        }
        if (adId !== 'create') {
            //make ad Popup
            API.ads.makePopup(adId).then((res) => {
                this.props.history.push('/ads');
            })
        }
    }

    confirmDelete = () => {
        Swal.fire({
            icon: 'warning',
            title: '¿Estás seguro de borrar el anuncio?',
            cancelButtonText: 'No',
            confirmButtonColor: '#f32735',
            confirmButtonText: 'Si, borrar',
            showCancelButton: true,
            reverseButtons: true,
            heightAuto:false
        }).then((res) => {
            if (res.isConfirmed) {
                this.deleteAd();
            }
        })
    }

    deleteAd = () => {
        const adId = this.props.match.params.id;

        API.ads.deleteAd(adId).then(res => {
            this.props.history.push('/ads');
        }).catch((ex) => {
            console.log(ex);
        })
    }


    onClickBtn = () => {
        const isAdValid = this.validateAd();
        const adId      = this.props.match.params.id;

        if (isAdValid) {

            if (adId === 'create') {
                //create ad
                const ad = {...this.state.ad};
                API.ads.create(ad).then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Anuncio creado',
                        confirmButtonColor: '#4dda85',
                        heightAuto:false
                    }).then((res) => {
                        this.props.history.push('/ads');
                    })
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al crear el anuncio';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {

                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al crear el anuncio',
                        html: errorMsg,
                        confirmButtonColor: '#2a7de1',
                        heightAuto:false
                    })
                });
            } else {
                //update image
                const _ad = {...this.state.ad};
                if (!_ad.image.includes('data:image')) {
                    delete _ad.image
                }
                if (!_ad.image2.includes('data:image')) {
                    delete _ad.image2
                }
                API.ads.updateAd(_ad._id, _ad).then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Anuncio actualizado',
                        confirmButtonColor: '#4dda85',
                        heightAuto:false
                    }).then((res) => {
                        this.props.history.push('/ads');
                    })
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al actualizar el anuncio';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {

                    }
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al actualizar el anuncio',
                        html: errorMsg,
                        confirmButtonColor: '#2a7de1',
                        heightAuto:false
                    })
                });
            }
        }
    }

    validateAd = () => {
        const ad    = this.state.ad;
        const name  = ad.name;
        const link  = ad.link;
        const image = ad.image;

        if (name.trim() === '') {
            Swal.fire({
                title: 'Datos faltantes',
                icon: 'warning',
                html: 'Agrega el nombre del anuncio.',
                heightAuto:false
            })
            return false
        }

        if (link.trim() === '') {
            Swal.fire({
                title: 'Datos faltantes',
                icon: 'warning',
                html: 'Agrega un enlace para el anuncio.',
                heightAuto:false
            })
            return false
        }
        if (image.trim() === '' || image === null) {
            Swal.fire({
                title: 'Datos faltantes',
                icon: 'warning',
                html: 'Agrega una imagen para el anuncio.',
                heightAuto:false
            })
            return false
        }
        if(ad.isPopup && !ad.image2){
            Swal.fire({
                title: 'Datos faltantes',
                icon: 'warning',
                html: 'Agrega una imagen para anuncio principal para activar esta función.',
                heightAuto:false
            })
            return false;
        }
        return true
    }

    onImageDropped(image) {
        const ad = {...this.state.ad};
        ad.image = image;
        this.setState({ad: ad})
    }

    onImage2Dropped(image2) {
        const ad  = {...this.state.ad};
        ad.image2 = image2;
        this.setState({ad: ad})
    }

    render() {
        const ad       = this.state.ad;
        const adImage  = ad.image ? ad.image : null;
        const adImage2 = ad.image2 ? ad.image2 : null;
        const adId     = this.props.match.params.id;


        return (
            <Container fluid className={'h-100'} style={{overflowY:'auto'}}>
                <Row>
                    <Col className='py-3 d-flex'>
                        <h5 className={'text-capitalize'}>{this.state.title}</h5>
                        <NPIf condition={adId !== 'create'}>
                            <Button className={'ml-auto'}
                                    variant={'danger'}
                                    onClick={() => this.confirmDelete()}
                            >Borrar anuncio</Button>
                        </NPIf>
                    </Col>
                </Row>

                <Row>
                    <Col className='pt-3 d-flex' sm={12} md={8}>
                        <Form.Check
                            type="switch"
                            id="isPopup"
                            label="Hacer anuncio principal"
                            onChange={event => {
                                this.onChange(event);
                                this.onChangeSwitch(event);
                            }}
                            checked={ad.isPopup}
                        />
                        <OverlayTrigger
                            key='help'
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-help`}>
                                    El anuncio principal se mostrará como Popup en la aplicación de Nbio.
                                </Tooltip>
                            }
                        >
                            <Button className={'ml-3'} variant="light" size="sm"><FontAwesomeIcon
                                icon={'question'}/></Button>
                        </OverlayTrigger>
                    </Col>
                    <Col className='py-3' sm={12} md={8}>
                        <Form.Label>
                            <b>Imagen para anuncios intercalados</b>
                        </Form.Label>
                        <DragZone onImageDropped={this.onImageDropped}
                                  preSelectedImage={adImage}></DragZone>
                        <small className={'text-muted'}>La imagen debe ser cuadrada y de 512x512 pixeles. Al subir otra medida, la imagen será deformada.</small>
                    </Col>
                    <Col className='py-3' sm={12} md={8}>
                        <Form.Label>
                            <b>Imagen para anuncio principal</b>
                        </Form.Label>
                        <DragZone onImageDropped={this.onImage2Dropped}
                                  preSelectedImage={adImage2}></DragZone>
                        <small className={'text-muted'}>La imagen debe ser vertical y de 320x640 pixeles.</small>
                    </Col>
                    <Col className='py-3' sm={12} md={8}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>
                                <b>
                                    Nombre
                                </b>
                            </Form.Label>
                            <Form.Control type="text"
                                          placeholder="Nombre"
                                          value={ad.name}
                                          onChange={event => this.onChange(event)}/>
                        </Form.Group>
                    </Col>
                    <Col className='py-3' sm={12} md={8}>
                        <Form.Group className="mb-3" controlId="link">
                            <Form.Label>
                                <b>
                                    Enlace
                                </b>
                            </Form.Label>
                            <Form.Control type="text"
                                          placeholder="Ejemplo: /products/sku"
                                          value={ad.link}
                                          onChange={event => this.onChange(event)}/>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} md={8} className={'d-flex justify-content-end mb-3'}>
                        <Button onClick={() => this.onClickBtn()}>Guardar</Button>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(Ad);
