/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * February 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import BootstrapTable from "react-bootstrap-table-next";
import NbioApi from "../../lib/api/NbioApi";
import Card from "react-bootstrap/Card";
import StarRatings from 'react-star-ratings';

class Reviews extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
            review:[],
            isLoading:true,
        }

        this.loadReview   = this.loadReview.bind(this);
    }

    componentDidMount() {
        const reviewId = this.props.match.params.id;
        this.loadReview(reviewId);
    }

    loadReview(reviewId){
        this.setState({isLoading:true});
        NbioApi.reviews.getReview(reviewId).then(res => {
            this.setState({
                review:res.data.review,
                isLoading:false,
            });
        }).catch((ex) =>{
            this.setState({
                review: {},
                isLoading:false,
            });
        })
    }


    render(){
        const review = this.state.review;
        const orderId = review.orderId;
        const products = review.products ? review.products : [];
        const productReviews = review.productReviews ? review.productReviews : [];
        const reviewComment = review.review !== "" ? review.review : '---' ;

        return(
            <Container fluid style={{overflowY:"scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3'>
                        <h5 className={'text-capitalize '}>Reseña de la orden <a href={`/orders/${orderId}`}>{orderId}</a></h5>
                    </Col>
                </Row>

                <Row>
                    <Col className='pb-3'>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize pb-3'}>
                                    <span>Reseña del operador</span>
                                </Card.Title>
                                <Row>
                                    <Col>
                                        <StarRatings
                                            rating={review.rating}
                                            starRatedColor="#ffc409"
                                            numberOfStars={5}
                                            starDimension="20px"
                                            starSpacing="2px"
                                            name={'operator-review'}
                                        />
                                        <div className={'text-muted'}>{review.rating} de 5</div>

                                        <div><strong>Comentarios: </strong>{reviewComment}</div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col className='pb-3'>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-capitalize pb-3'}>
                                    <span>Reseñas de productos</span>
                                </Card.Title>
                                {
                                    products.map((p,index) => {
                                        return(
                                            <Row key={p._id}>
                                                <Col xs={2}>
                                                    <img src={p.imageUrl} width={'100%'} height={'auto'}/>
                                                </Col>
                                                <Col>
                                                    <div><strong>Nombre: </strong>{p.description}</div>
                                                    <div><strong>SKU: </strong>{p.SKU}</div>

                                                    <StarRatings
                                                        rating={productReviews[index].rating}
                                                        starRatedColor="#ffc409"
                                                        numberOfStars={5}
                                                        starDimension="20px"
                                                        starSpacing="2px"
                                                        name={p._id}
                                                    />
                                                    <div className={'text-muted'}>{productReviews[index].rating} de 5</div>
                                                </Col>
                                            </Row>
                                        )}
                                    )
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(Reviews);
