/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * June 2022
 */

import React from "react";
import PropTypes from "prop-types";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import ReactMapGl, {Layer, NavigationControl, Source} from "react-map-gl"
import {EditingMode, Editor, DrawPolygonMode, DrawCircleFromCenterMode, DrawPointMode} from "react-map-gl-draw";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/cjs/Button";
import * as _ from 'underscore';
import * as turf from '@turf/turf'
//STYLE
import 'mapbox-gl/dist/mapbox-gl.css';
import '../../scss/components/regions-map.scss'

//MAPBOX TOKEN
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZWFwZXJlenYiLCJhIjoiY2w0ZzIzdWduMDJuMDNjczF5bTA2Y2M4MSJ9.Iyqpdv6RE0BeqkesEq7IXw';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


/**
 * Regions map
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */


const MODES = [
    {
        id: 'drawPolygon',
        text: 'Crear',
        handler: DrawPolygonMode
    },
    {
        id: 'drawCircleFromCenter',
        text: 'Circulo',
        handler: DrawCircleFromCenterMode
    },
    {
        id: 'editing',
        text: 'Editar',
        handler: EditingMode
    }
]

class RegionsMap extends React.Component {
    constructor(p) {
        super(p);
        this.state             = {
            zoom: 12,
            features: [],
            mode: null,
            selectedMode: '',
            viewport: {},
            modeOpts: {
                booleanOperation: null
            },
            selectedFeatureIndex: null,
        }
        this.onUpdateDrawing   = this.onUpdateDrawing.bind(this);
        this.createPolygonMode = this.createPolygonMode.bind(this);
        this.createCircleMode  = this.createCircleMode.bind(this);
    }

    componentDidMount() {
        this.setState({
            features: this.props.features,
            viewport: {
                latitude: this.props.latitude,
                longitude: this.props.longitude,
                zoom: this.state.zoom
            }
        })
    }

    editMode        = () => {
        this.setState({
            mode: new EditingMode(),
            selectedMode: 'edit'
        });
    }
    selectMode      = () => {
        this.setState({
            mode: null,
            selectedMoe: 'select'
        });
    }
    createPointMode = () => {
        this.setState({
            mode: new DrawPointMode(),
            selectedMode: 'create-point'
        });
    }

    createPolygonMode = () => {
        this.setState({
            mode: new DrawPolygonMode(),
            selectedMode: 'create-polygon'
        });
    }

    createCircleMode = () => {
        this.setState({
            mode: new DrawCircleFromCenterMode(),
            selectedMode: 'create-circle'
        });
    }

    deleteMode      = () => {
        if (this.state.selectedFeatureIndex > -1) {
            let f = [...this.state.features];
            f     = f.filter((_f, i) => i !== this.state.selectedFeatureIndex);
            this.setState({features: f}, () => {
                this.props.onPolygonCompleted(f);
            });
        }
    }
    sortPoint       = (d) => {
        const geometry = d.geometry || {};
        return geometry.type === 'Point'
    }
    onUpdateDrawing = (e) => {
        if (e.editType === 'addFeature') {
            const sortedData = _.sortBy(e.data, (d) => this.sortPoint(d));
            this.setState({features: sortedData});
            this.props.onPolygonCompleted(sortedData);
        } else if (e.editType === 'movePosition') {
            const sortedData = _.sortBy(e.data, (d) => this.sortPoint(d));
            this.setState({features: sortedData});
            this.props.onPolygonCompleted(sortedData);

        }
    }

    styleMap = (feature, index, style) => {

        let color;
        if (index > -1) {
            color = this.props.color[index];
        } else {
            color = this.props.defaultColor;
        }
        if (feature.geometry.type === 'Point') {
            return {
                stroke: "red",

                strokeWidth: "2px",
                fill: "red",
                fillOpacity: "1",
                r: "8px",
            };
        }
        if (style === 'UNCOMMITTED') {
            return {
                fill: color,
                stroke: color,
                fillOpacity: 0.1,
            }
        }
        return {
            fill: color,
            stroke: color,
            fillOpacity: 0.2,
        }
    }

    deleteFeature(index) {
        let f = [...this.state.features];
        f     = f.filter((_f, i) => i !== index);
        this.setState({features: f}, () => {
            this.props.onPolygonCompleted(f);
        });
    }

    renderBtns = () => {
        if (this.props.interactiveMode) {
            return (
                <>
                    <div className={'regions-map-buttons'}>
                        <Button onClick={() => this.createPointMode()}
                                variant={this.state.selectedMode === 'create-point' ? 'secondary' : 'primary'}>
                            <FontAwesomeIcon icon={'map-pin'}/>
                        </Button>
                        <Button onClick={() => this.createPolygonMode()}
                                variant={this.state.selectedMode === 'create-polygon' ? 'secondary' : 'primary'}>
                            <FontAwesomeIcon icon={'draw-polygon'}/>
                        </Button>
                        <Button onClick={() => this.createCircleMode()}
                                variant={this.state.selectedMode === 'create-circle' ? 'secondary' : 'primary'}>
                            <FontAwesomeIcon icon={["far", "circle"]}/>
                        </Button>
                        <Button onClick={() => this.editMode()}
                                variant={this.state.selectedMode === 'edit' ? 'secondary' : 'primary'}>

                            <FontAwesomeIcon icon={'pen'}/>
                        </Button>
                        <Button onClick={() => this.deleteMode()} variant={'danger'}>
                            <FontAwesomeIcon icon={'trash'}/>
                        </Button>
                    </div>
                </>

            )
        } else {
            return null
        }
    }

    onSelect(e) {
        this.setState({
            selectedFeatureIndex: e.selectedFeatureIndex
        })
    }
    render() {
        return (
            <ReactMapGl
                mapStyle="mapbox://styles/eaperezv/cl4hbzpj9001s15nywg1tdutg"
                mapboxApiAccessToken={MAPBOX_TOKEN}
                width={this.props.width}
                height={this.props.height}
                onViewportChange={(e) => this.setState({viewport: e})}
                {...this.state.viewport}

            >
                <NavigationControl showCompass showZoom position='bottom-right'/>
                {this.renderBtns()}
                <Editor mode={this.state.mode}
                        features={this.state.features}
                        onSelect={(e) => this.onSelect(e)}
                        featureStyle={({feature, index, state}) => this.styleMap(feature, index, state)}
                        selectable={this.state.selectedMode === 'edit'}
                        onUpdate={(e) => this.onUpdateDrawing(e)}>
                </Editor>
            </ReactMapGl>
        )
    }
}


RegionsMap.propTypes    = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    onPolygonCompleted: PropTypes.func,
    features: PropTypes.array,
    color: PropTypes.array,
    defaultColor: PropTypes.string,
    interactiveMode: PropTypes.bool,
}
RegionsMap.defaultProps = {
    latitude: 19.4326018,
    longitude: -99.1332049,
    width: '100%',
    height: '500px',
    onPolygonCompleted: () => {
    },
    features: [],
    color: ['#000000'],
    defaultColor: '#000000',
    interactiveMode: true
}

export default RegionsMap;

