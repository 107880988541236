/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * May 2023
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from  "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import { register } from 'swiper/element/bundle';
import Swal from "sweetalert2";
import API from "../../lib/api/NbioApi";


 //style
import "../../scss/components/carousel.scss";

//components
import DragZone from "../ui/Dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";

let Reorder = require('react-reorder');
register();

class Carousel extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            page: {
                goTo: '',
                image: '',
                imageMobile: '',
            },
            carousel:{pages:[]},
            showModal:false,
            pages:[],
            showAlert:false,
            alertVariant:'primary',
            alertMessage:''
        }


        this.getCarousel                = this.getCarousel.bind(this);
        this.onClickSaveCarouselItem    = this.onClickSaveCarouselItem.bind(this);
        this.validateCarouselItem       = this.validateCarouselItem.bind(this);
        this.onImageDropped             = this.onImageDropped.bind(this);
        this.onImage2Dropped            = this.onImage2Dropped.bind(this);
        this.onChange                   = this.onChange.bind(this);
        this.confirmDelete              = this.confirmDelete.bind(this);
        this.swiperElRef = React.createRef();
        this.swiper  = null;
        this.progress = null;
    }

    componentDidMount() {
        this.getCarousel();

        this.swiperElRef.current.addEventListener('progress', (e) => {
            this.swiper = e.detail;
            this.progress = e.detail;
        });

        this.swiperElRef.current.addEventListener('slidechange', (e) => {
        });
    }

    getCarousel = () => {
        API.store.getCarousel().then((res) => {
            // debugger;
            const carousel = res.data.carousel
            this.setState({carousel: carousel, pages: carousel.pages});
        }).catch((ex) => {
            console.log(ex);
        })
    }

    onChange = (e) => {
        this.setState(prevState => {
            let page = Object.assign({}, prevState.page);
            page[e.target.id] = e.target.value;
            return {page};
        });
    }

    confirmDelete = (item,index) => {
        Swal.fire({
            icon: 'warning',
            title: '¿Estás seguro de borrar la página?',
            cancelButtonText: 'No',
            confirmButtonColor: '#f32735',
            confirmButtonText: 'Si, borrar',
            showCancelButton: true,
            reverseButtons: true,
            heightAuto:false
        }).then((res) => {
            if (res.isConfirmed) {
                this.onDelete(item,index);
            }
        })
    }

    onClickSaveCarouselItem = () => {
        this.setState({isUploading:true});
        const isCarouselValid = this.validateCarouselItem();
        if (isCarouselValid) {
            const page = {page:this.state.page};
            page.goTo = ((page.goTo) || '').trim();
            API.admin.createCarouselItem(page).then((res) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Página creada',
                    confirmButtonColor: '#4dda85',
                    heightAuto: false
                }).then((res) => {
                    //on add new carousel item close modal and reset values
                    this.setState({showModal: false,isUploading:false});
                    this.showRenderAlert('Carrusel actualizado','success');
                    this.getCarousel();
                    this.resetNewCarouselItem();
                })
            }).catch((ex) => {
                this.setState({isUploading:false});
                let errorMsg = 'Hubo un error al crear la página';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {

                }
                Swal.fire({
                    icon: 'error',
                    title: 'Error al crear la página',
                    html: errorMsg,
                    confirmButtonColor: '#2a7de1',
                    heightAuto: false
                })
                this.getCarousel();
            });
        }else{
            this.setState({isUploading:false});
        }
    }

    validateCarouselItem = () => {
        const page    = this.state.page;
        const goTo  = page.goTo;
        const image = page.image;
        const imageMobile = page.imageMobile;
        if (image.trim() === '' || image === null) {
            Swal.fire({
                title: 'Datos faltantes',
                icon: 'warning',
                html: 'Agrega una imagen para móviles.',
                confirmButtonColor: '#2a7de1',
                heightAuto:false
            })
            return false
        }
        if (imageMobile.trim() === '' || imageMobile === null) {
            Swal.fire({
                title: 'Datos faltantes',
                icon: 'warning',
                html: 'Agrega una imagen para web.',
                confirmButtonColor: '#2a7de1',
                heightAuto:false
            })
            return false
        }
        return true
    }

    onImageDropped(image) {
        const page = {...this.state.page};
        page.image = image;
        this.setState({page: page})
    }

    onImage2Dropped(imageMobile) {
        const page = {...this.state.page};
        page.imageMobile = imageMobile;
        this.setState({page: page})
    }

    resetNewCarouselItem = () => {
        this.setState({page:{}})
    }

    onReorder (event, previousIndex, nextIndex, fromId, toId) {
        this.setState({
            pages: this.reorder(this.state.pages, previousIndex, nextIndex)
        }, () => {
            const pages = {pages:this.state.pages};
            API.admin.updateCarouselPages(pages).then(res => {
                this.getCarousel();
                this.showRenderAlert('Carrusel actualizado','success');
            }).catch(ex => {
                console.log(ex);
                this.showRenderAlert('Hubo un error actualizando el carrito','danger');

            })
        });
    }

    reorder(pages,p,n) {
        let newPages = [...pages];
        newPages[p] = pages[n];
        newPages[n] = pages[p];
        return newPages;
    }
    onDelete = (item,index) =>{
        let newPages = [...this.state.pages];
        newPages = newPages.filter((p,i) => i !== index);
        API.admin.updateCarouselPages({pages:newPages}).then(res => {
            this.getCarousel();
            this.showRenderAlert('Carrusel actualizado','success');
        }).catch(ex => {
            this.showRenderAlert('Hubo un error actualizando el carrito','danger');
        })
    }
    showRenderAlert(alertMessage = '',variant = 'primary'){
        this.setState({showAlert:true,alertMessage:alertMessage});
        setTimeout(() =>{
            this.setState({showAlert:false,alertMessage:''});
        },2000)
    }
    renderAlert(){
        return (
            <div className={'alert-wrapper'}>
                <Alert variant={this.state.alertVariant} dismissible={true}
                       show={this.state.showAlert} onClose={() => this.setState({showAlert:false})}
                >
                    {this.state.alertMessage}
                </Alert>
            </div>

        )
    }
    render() {
        const page        = this.state.page;
        const image       = page.image ? page.image : null;
        const imageMobile = page.imageMobile ? page.imageMobile : null;
        const pages       = this.state.pages ? this.state.pages : [];

        return (
            <Container fluid className={'h-100'} style={{overflowY:'auto'}}>
                {this.renderAlert()}
                <Row>
                    <Col className='py-3 d-flex'>
                        <h5 className={'text-capitalize'}>Carrusel</h5>
                    </Col>
                </Row>
                {/*Left Column*/}
                <Row>
                    <Col sm={6} lg={6}>
                        <Row>
                            <Col sm={12} >
                                <h6 className={'text-muted'}>Páginas del carrusel</h6>
                            </Col>
                            <Col sm={12}>
                                <Card bg='primary'
                                      className={'card-btn'}
                                      onClick={() => this.setState({showModal:true})}>
                                    <Card.Body className={'text-light text-center'}>
                                        <FontAwesomeIcon icon={"plus"}  className={'me-1'}/>
                                        <b>Agregar Página</b>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <hr/>
                                <p className={'text-muted'}>
                                    Arrastra las imágenes para cambiar el orden de aparición en el carrusel
                                </p>
                            </Col>
                            <Col className='pt-3 overflow-hidden' sm={12}>
                                <Reorder
                                    reorderId={'carousel-page-list'}
                                    lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                                    holdTime={80} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                                    touchHoldTime={80} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                                    mouseHoldTime={80} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                                    onReorder={this.onReorder.bind(this)} // Callback when an item is dropped (you will need this to update your state)
                                    disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                                >
                                    {
                                        this.state.pages.map((page,index) => (
                                            <Card key={page._id}  className={'reorder-card prevent-select'}>
                                                <Card.Header className={'d-flex justify-content-between align-items-center prevent-select'}
                                                             onClick={(ev) => {
                                                                 ev.stopPropagation();

                                                             }}
                                                >
                                                    <b> Página {index+1}</b>
                                                    <div>
                                                        <Button variant={'outline-danger'}  onClick={() => this.confirmDelete(page,index)}>
                                                            <FontAwesomeIcon icon={"trash"}  >

                                                            </FontAwesomeIcon>
                                                        </Button>
                                                    </div>

                                                </Card.Header>
                                                <div className={'card-content'}>
                                                    <img src={page.imageUrl} draggable={false}/>
                                                    <div>
                                                        {
                                                            page.goTo ?
                                                            <>
                                                                <FontAwesomeIcon
                                                                    icon={'link'} color={'#2a7de1'}
                                                                    size={'1x'} className={'ml-1'}>
                                                                </FontAwesomeIcon>
                                                                <span className={'ml-1'}>
                                                                    {page.goTo}
                                                                </span>
                                                            </>
                                                                :
                                                                <>
                                                                    <FontAwesomeIcon
                                                                        icon={'unlink'} color={'#2a7de1'}
                                                                        size={'1x'} className={'ml-1'}>
                                                                    </FontAwesomeIcon>
                                                                    <span className={'ml-1'}>
                                                                    Sin enlace
                                                                </span>
                                                                </>
                                                        }

                                                    </div>

                                                </div>
                                                {/*<Card.Footer className={'d-flex justify-content-end mr-'}>*/}
                                                {/**/}
                                                {/*</Card.Footer>*/}
                                            </Card>
                                        ))
                                        /*
                                        Note this example is an ImmutableJS List so we must convert it to an array.
                                        I've left this up to you to covert to an array, as react-reorder updates a lot,
                                        and if I called this internally it could get rather slow,
                                        whereas you have greater control over your component updates.
                                        */
                                    }
                                </Reorder>
                            </Col>
                        </Row>
                    </Col>

                    {/*Right Column*/}
                    <Col sm={6} lg={6}>
                        <Row>
                            <Col sm={12} className={'d-flex justify-content-end'}>
                                <h6 className={'text-muted'}>
                                    <b>Vista previa en dispositivo móvil:</b>
                                </h6>
                            </Col>
                            <Col sm={12} className={'d-flex justify-content-end'}>
                                <div className={"swiper-wrapper"}>
                                    <swiper-container
                                        ref={this.swiperElRef}
                                        slides-per-view="1"
                                        navigation="true"
                                        pagination="true"
                                        loop="true"
                                        effect={"fade"}
                                        autoplay={"true"}
                                    >
                                        {pages.map(page => {
                                            return(
                                                <swiper-slide key={page._id} >
                                                    <img src={page.imageUrl} className={"carousel-item-preview"}/>
                                                </swiper-slide>
                                            )
                                        })}
                                    </swiper-container>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                {/*Start Modal*/}
                <Modal
                    show={this.state.showModal}
                    onHide={() => {
                        this.setState({showModal: false});
                        this.resetNewCarouselItem();
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Agregar Página al Carrusel
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={12}>
                                <Form.Group className="mb-3" controlId="goTo">
                                    <Form.Label>
                                        <b>
                                            Enlace
                                        </b>
                                    </Form.Label>
                                    <Form.Control type="text"
                                                  placeholder="Ejemplo: /products/sku"
                                                  value={page.goTo}
                                                  onChange={event => this.onChange(event)}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className={'mt-2'}>
                            <Col sm={12}>
                                <Form.Label>
                                    <b>Imagen móvil</b>
                                </Form.Label>
                                <DragZone onImageDropped={this.onImageDropped}
                                          preSelectedImage={image}></DragZone>
                                <small className={'text-muted'}>La imagen debe ser rectangular y de 1920x1080 pixeles. Al subir otra medida, la imagen será deformada.</small>
                            </Col>

                        </Row>
                        <Row className={'mt-2'}>
                            <Col sm={12}>
                                <Form.Label>
                                    <b>Imagen para web</b>
                                </Form.Label>
                                <DragZone onImageDropped={this.onImage2Dropped}
                                          preSelectedImage={imageMobile}></DragZone>
                                <small className={'text-muted'}>La imagen debe ser rectangular y de 1920x700 pixeles. Al subir otra medida, la imagen será deformada.</small>
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.isUploading ?
                                <Spinner>

                                </Spinner>
                                :
                                <Button onClick={() => this.onClickSaveCarouselItem()}>Agregar página</Button>
                        }
                    </Modal.Footer>
                </Modal>
            </Container>
        )
    }
}

export default withRouter(Carousel);
