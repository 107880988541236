import React from "react";
import PropTypes from 'prop-types';
import { ProgressBar, Step } from "react-step-progress-bar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//style
import "react-step-progress-bar/styles.css";
import "../../scss/components/ui/progressBarStatus.scss";

class ProgressBarStatus extends React.Component{
    constructor(props) {
        super(props);
        this.getPercent         = this.getPercent.bind(this);
    }

    getPercent(){
        let percent = 0;
        switch (this.props.status){
            case 'processing':
                percent  = 0;
                break;
            case 'received':
                percent  = 0;
                break;
            case 'amount_validated':
                percent  = 20;
                break;
            case 'on_route':
                percent  = 40;
                break;
            case 'delivering':
                percent  = 60;
                break;
            case 'delivered':
                percent  = 80;
                break;
            case 'finished':
                percent  = 100;
                break;
            default:
                percent = 0;
                break;
        }

        return percent;
    }

    renderCancelLabel = () => {
        if(this.props.status === 'cancelled'){
            return (
                <div className={'cancel-label '}>
                    CANCELADA
                </div>
            )
        }else{
            return null
        }

    }

    render(){
        const percent = this.getPercent();
        return(
            <div className={'wrapper-progress-bar'}>
                {this.renderCancelLabel()}
                <ProgressBar
                    percent={percent}
                    filledBackground="linear-gradient(to right, #a1c3e8, #00b2e3, #2a7de1, #1f1646">

                    <Step transition="scale">
                        {({ accomplished }) => (

                            <div className={`pbs-wrapper-step ${accomplished ? "accomplished" : ""}`}>
                                <div className={'wrapper-icon '}>
                                    <FontAwesomeIcon icon={'receipt'}></FontAwesomeIcon>
                                </div>
                                <span>Procesando {accomplished}</span>
                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`pbs-wrapper-step ${accomplished ? "accomplished" : ""}`}>
                                <div className={'wrapper-icon'}>
                                    <FontAwesomeIcon icon={'dollar-sign'}></FontAwesomeIcon>
                                </div>
                                <span>Monto validado</span>
                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`pbs-wrapper-step ${accomplished ? "accomplished" : ""}`}>
                                <div className={'wrapper-icon'}>
                                    <FontAwesomeIcon icon={'motorcycle'}></FontAwesomeIcon>
                                </div>
                                <span>En camino</span>
                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`pbs-wrapper-step ${accomplished ? "accomplished" : ""}`}>
                                <div className={'wrapper-icon'}>
                                    <FontAwesomeIcon icon={'dolly'}></FontAwesomeIcon>
                                </div>
                                <span>Entregando</span>
                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`pbs-wrapper-step ${accomplished ? "accomplished" : ""}`}>
                                <div className={'wrapper-icon'}>
                                    <FontAwesomeIcon icon={'people-carry'}></FontAwesomeIcon>
                                </div>
                                <span>Entregado</span>
                            </div>
                        )}
                    </Step>
                    <Step transition="scale">
                        {({ accomplished }) => (
                            <div className={`pbs-wrapper-step ${accomplished ? "accomplished" : ""}`}>
                                <div className={'wrapper-icon'}>
                                    <FontAwesomeIcon icon={'box'}></FontAwesomeIcon>
                                </div>
                                <span>Terminado</span>
                            </div>
                        )}
                    </Step>
                </ProgressBar>
            </div>
        )
    }

}
ProgressBarStatus.defaultProps = {
    status: ''
}
ProgressBarStatus.propTypes = {
    status: PropTypes.string
}

export default ProgressBarStatus;
