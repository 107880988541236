/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import NbioApi from "../../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from 'react-bootstrap/Spinner'
import {money} from "../../lib/money/money";

//components
import NPPaginator from "../ui/NPPaginator";


class CouponsTable extends React.Component {
    constructor(p) {
        super(p);
        this.state       = {
            coupons: [],
            isLoading: true,
            pages: 1,
            currentPage: 1,
        }
        this.loadCoupons = this.loadCoupons.bind(this);
    }

    componentDidMount() {
        this.loadCoupons();
    }

    loadCoupons() {
        const pageSize = 25;
        this.setState({isLoading: false});
        NbioApi.coupons.getCoupons(this.state.currentPage, pageSize).then(res => {
            this.setState({
                coupons: res.data.coupons,
                isLoading: false,
                pages: res.data.pages
            });
        }).catch((ex) => {
            this.setState({
                coupons: [],
                isLoading: false,
                pages: 1,
            });
        })
    }

    onPageChanged(page) {
        this.setState({currentPage: page}, () => {
            this.loadCoupons();
        })
    }

    render() {
        const columns = [

            {
                dataField: 'code',
                text: 'Código',
                formatter:(v,row) =>{
                    return(
                        <div>
                            <a href={`/coupons/${row._id}`}>{v}</a>
                        </div>
                    )
                }
            },
            {
                dataField: 'isPublished',
                text: '¿Publicado?',
                formatter:(v,row) =>{
                    return v ? 'Si' : 'No'
                }
            },
            {
                dataField: 'isActive',
                text: '¿Activo?',
                formatter:(v,row) =>{
                    return v ? 'Si' : 'No'
                }
            },
            {
                dataField: 'value',
                text: 'Valor',
                formatter:(v,row) =>{
                    let txt = ''
                    if(row.discountType === 'fixed_amount'){
                        txt = `${money(v)}`
                    }
                    if(row.discountType === 'percentage'){
                        txt = `${v} %`
                    }
                    return txt
                }
            },
            {
                dataField: 'type',
                text: 'Tipo',
                formatter:(v,row) =>{
                    let txt = ''
                    if(v === 'free_shipping'){
                        txt = 'Envio gratis'
                    }
                    if(v === 'total'){
                        txt = 'Total de la compra'
                    }
                    if(v === 'item'){
                        txt = 'Por item'
                    }
                    return txt
                }
            },
            // {
            //     dataField: 'discountType',
            //     text: 'Tipo de descuento',
            //     formatter:(v,row) =>{
            //         let txt = ''
            //         if(v === 'fixed_amount'){
            //             txt = 'Cantidad'
            //         }
            //         if(v === 'percentage'){
            //             txt = 'Porcentaje'
            //         }
            //         return txt
            //     }
            // },
            {
                dataField: 'distributionMethod',
                text: 'Distribución',
                formatter:(v,row) =>{
                    let txt = ''
                    if(v === 'internal'){
                        txt = 'Aplicación'
                    }
                    if(v === 'external'){
                        txt = 'Fuera de la aplicación'
                    }

                    return txt
                }
            },
            {
                dataField: 'audience',
                text: 'Audiencia',
                formatter:(v,row) =>{
                    let txt = ''
                    if(v === 'segmentation'){
                        txt = 'Segmentación'
                    }
                    if(v === 'all'){
                        txt = 'Todos los usuarios'
                    }

                    return txt
                }
            }
        ];

        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3 d-flex justify-content-between'>
                        <h5 className={'text-capitalize '}>Cupones</h5>
                        <a href={'/coupons/create'}>
                            <Button className={'ml-auto'}>Crear cupón</Button>
                        </a>
                    </Col>
                </Row>

                <Row>
                    <Col className='pb-3'>
                        <NPIf condition={this.state.isLoading}>
                            <div className={'d-flex align-items-center justify-content-center'}>
                                <Spinner animation="grow" variant={"secondary"}/>
                            </div>
                            <NPElse>
                                <BootstrapTable keyField='_id'
                                                data={this.state.coupons}
                                                columns={columns}
                                                noDataIndication={`No se encontraron cupones`}
                                                bordered={false}
                                />
                                <NPPaginator
                                    numberOfPages={this.state.pages}
                                    onPageChanged={(p) => this.onPageChanged(p)} initialPage={this.state.currentPage}>
                                </NPPaginator>
                            </NPElse>
                        </NPIf>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(CouponsTable);
