/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import axios from 'axios';
const LOCAL_STORAGE_TOKEN = 'nbio-dashboard-token';
const config =  () => {
    return {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}`
        }
    }
}
const excelDownload = () => {
    const headers = config();
    headers['headers']['Content-Disposition'] = "attachment; filename=template.xlsx";
    headers['headers']['Content-Type'] ='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    headers['responseType'] = 'arraybuffer';
    return headers;
}
const pdfDownload = (orderId) => {
    const headers = config();
    headers['headers']['Content-Disposition'] = `attachment; filename=${orderId}.pdf`;
    headers['headers']['Content-Type'] ='application/pdf';
    headers['responseType'] = 'arraybuffer';
    return headers;
}
const API = {
    API_URL: process.env.REACT_APP_API_URL,
    users: {
        getMe() {
            return axios.get(`${API.API_URL}/users/me`, config());
        },
        async login(email, password) {
            const data = {
                email: email,
                password: password,
                context:'admin'
            }
            return axios.post(`${API.API_URL}/users/login`, data, config()).then((res) =>{
                let token = res.data.result.token;
                localStorage.setItem(LOCAL_STORAGE_TOKEN,token);
                return res;
            }).catch((ex) =>{
                return Promise.reject(ex);
            })
        },
        logout() {
            return axios.post(`${API.API_URL}/users/me/logout`, {}, config()).then((res) =>{
                localStorage.setItem(LOCAL_STORAGE_TOKEN,'');
                return res;
            }).catch((ex) =>{
                return Promise.reject(ex);

            })
        },
        getAuthToken(){
            return localStorage.getItem(LOCAL_STORAGE_TOKEN);
        },
        getUsers(roles,page = 1,pageSize = 50,){
            return axios.get(`${API.API_URL}/users?roles=${roles}&page=${page}&pageSize=${pageSize}`,config());
        },
        create(user){
            return axios.post(`${API.API_URL}/users/createAdmin`,user,config());
        },
        getUser(userId){
            return axios.get(`${API.API_URL}/users/${userId}`,config());
        },
        updateUser(user){
            return axios.post(`${API.API_URL}/users/${user._id}/update`,user,config());
        },
        updateImage(userId,image){
            const data = {
                img: image
            }
            return axios.post(`${API.API_URL}/users/${userId}/updateProfilePicture`,data,config());
        },
        changePassword(userId,password,password2){
            const data = {
                password: password,
                password2: password2,
            }
            return axios.post(`${API.API_URL}/users/${userId}/updatePassword`,data,config());
        },
        disableUser(userId){
            return axios.post(`${API.API_URL}/users/${userId}/disableAccount`, {},config());
        },
        enableUser(userId){
            return axios.post(`${API.API_URL}/users/${userId}/enableAccount`, {},config());
        },
        deleteUser(userId){
            return axios.post(`${API.API_URL}/users/${userId}/deleteAccount`, {},config());
        },
    },
    orders:{
        getOrders: (page = 1,pageSize = 50,orderType='',
                    shippingMethod = 'all',sort = 'newest',startDate = '',
                    endDate ='',format = 'json',q='',requiresInvoice=false,
                    regions = [], dateField = 'created_at') =>{
            const _regions = regions.join('|');
            return axios.get(
                `${API.API_URL}/orders?page=${page}&pageSize=${pageSize}`+
                `&orderType=${orderType}&shippingMethod=${shippingMethod}&sort=${sort}`+
                    `&startDate=${startDate}&endDate=${endDate}&expFormat=${format}&q=${q}`+
                `&requiresInvoice=${requiresInvoice}&regions=${_regions}&dateField=${dateField}`,
                format === 'json' || format === 'pdf' || format === 'count' ? config() : excelDownload());
        },
        getOrder(orderId) {
            return axios.get(`${API.API_URL}/orders/${orderId}`,config());
        },
        downloadOrder(orderId){
            return axios.get(`${API.API_URL}/orders/${orderId}/export`,pdfDownload(orderId));
        },
        getStripeStatus(orderId){
            return axios.get(`${API.API_URL}/orders/${orderId}/stripeStatus`,config());
        },
        getOrderStatusMP(orderId){
            return axios.get(`${API.API_URL}/orders/${orderId}/mercadoPagoStatus`,config());
        },
        validatePrescription(orderId, itemId, isPrescriptionValid, comment){
            const data = {
                isPrescriptionValid: isPrescriptionValid,
                comment: comment,
            }
            return axios.post(`${API.API_URL}/orders/${orderId}/items/${itemId}/validatePrescription`, data, config());
        },
        assignOrder(orderId,uid){
            const data = {
                uid: uid,
            }
            return axios.post(`${API.API_URL}/orders/${orderId}/assign`, data, config());
        },
        validateAmount(orderId,items){
            const data = {
                items:items
            }
            return axios.post(`${API.API_URL}/orders/${orderId}/validateAmount`, data, config());
        },
        getRefunds(orderId){
            return axios.get(`${API.API_URL}/orders/${orderId}/refunds`, config());
        },
        markOrderAsFinished(orderId){
            return axios.post(`${API.API_URL}/orders/${orderId}/markOrderAsFinished`, {}, config());
        },
        requestLiveData(orderId){
            return axios.post(`${API.API_URL}/orders/${orderId}/requestLiveData`, {}, config());
        },
        gpsTrack(orderId){
            return axios.get(`${API.API_URL}/orders/${orderId}/gpsTracking`,  config());
        },
        lastKnownGPSPosition(orderId){
            return axios.get(`${API.API_URL}/orders/${orderId}/lastKnownGPSPosition`,  config());
        },
        track(orderId){
            return axios.get(`${API.API_URL}/orders/${orderId}/tracking`,  config());
        },
        cancelOrder(orderId,reason){
            return axios.post(`${API.API_URL}/orders/${orderId}/cancel`,  {reason:reason},config());
        },
        getDeliveryBlocks(){
            return axios.get(`${API.API_URL}/programmed-orders`,config());
        },
        editItems(orderId,items){
            return axios.post(`${API.API_URL}/orders/${orderId}/items/edit`,  {items:items},config());
        },
        addParcel(orderId,parcelName,parcelNumber,parcelTrackingLink){
            const data = {parcelName,parcelNumber,parcelTrackingLink};
            return axios.post(`${API.API_URL}/orders/${orderId}/addParcel`,  data,config());
        },
    },
    products:{
        create:(product) =>{
            return axios.post(`${API.API_URL}/products/create`,product,config())
        },
        getProductLogs:(_id,page = 1, pageSize = 50) =>{
            return axios.get(`${API.API_URL}/products/${_id}/logs?page=${page}&pageSize=${pageSize}`,config());
        },
        getProducts: (page = 1,pageSize = 50,sort = 'newest') =>{
            return axios.get(`${API.API_URL}/products?page=${page}&pageSize=${pageSize}&sort=${sort}`,config());
        },
        searchProducts: (q,page =1,pageSize=25) =>{
            return axios.get(`${API.API_URL}/products/search?q=${q}&page=${page}&pageSize=${pageSize}`,config());
        },
        getProduct: (productId) => {
            return axios.get(`${API.API_URL}/products/admin/${productId}`,config());
        },
        update:(productId,product = {}) => {
            return axios.post(`${API.API_URL}/products/${productId}/edit`,product,config())
        },
        uploadBulk:(file,onUploadProgress = () =>{}) =>{
            const _config = {
                onUploadProgress: (ev) => onUploadProgress(ev),
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}`,
                    'content-type': 'multipart/form-data',

                }
            }
            const formData = new FormData();
            formData.append('file',file)
            return axios.post(`${API.API_URL}/products/bulk/create`,formData,_config);
        },
        uploadImagesBulk:(files,onUploadProgress = () =>{}) =>{
            const _config = {
                onUploadProgress: (ev) => onUploadProgress(ev),
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}`,
                    'content-type': 'multipart/form-data',

                }
            }
            const formData = new FormData();
            files.forEach(file=>{
                formData.append("file", file);
            });
            return axios.post(`${API.API_URL}/products/bulk/uploadImages`,formData,_config);
        },
        exportBulk:() =>{
            return axios.get(
                `${API.API_URL}/products/bulk/export`,
                excelDownload()
            );
        },
        deleteProduct:(productId) =>{
            return axios.post(`${API.API_URL}/products/${productId}/delete`,{},config())
        }
    },
    bulkImports:{
        getBulkImport: (bulkImportId) => {
            return axios.get(`${API.API_URL}/bulkImports/${bulkImportId}`,config());
        },
        startImporting: (bulkImportId) => {
            return axios.post(`${API.API_URL}/bulkImports/${bulkImportId}/startImporting`,{},config());
        },
    },
    reviews:{
        getReviews: (page = 1,pageSize = 50) =>{
            return axios.get(`${API.API_URL}/reviews/admin?page=${page}&pageSize=${pageSize}`,config());
        },
        getReview: (reviewId) =>{
            return axios.get(`${API.API_URL}/reviews/admin/${reviewId}`,config());
        },
    },
    vehicles:{
        getVehicles:(page=1,pageSize=50) =>{
            return axios.get(`${API.API_URL}/vehicles?page=${page}&pageSize=${pageSize}`,config());
        },
        getVehicle:(vehicleId) =>{
            return axios.get(`${API.API_URL}/vehicles/${vehicleId}`,config());
        },
        create:(vehicle) =>{
            return axios.post(`${API.API_URL}/vehicles/create`,vehicle,config())
        },
        updateVehicle:(vehicle) =>{
            return axios.post(`${API.API_URL}/vehicles/${vehicle._id}/edit`,vehicle,config())
        },
        deleteVehicle:(vehicleId) =>{
            return axios.post(`${API.API_URL}/vehicles/${vehicleId}/delete`,{},config())
        }
    },
    devices:{
        getDevices:(page=1,pageSize=50) =>{
            return axios.get(`${API.API_URL}/dataDevices?page=${page}&pageSize=${pageSize}`,config());
        },
        getDevice:(deviceId) =>{
            return axios.get(`${API.API_URL}/dataDevices/${deviceId}`,config());
        },
        create:(dataDevice) =>{
            return axios.post(`${API.API_URL}/dataDevices/create`,dataDevice,config())
        },
        updateDevice:(dataDevice) =>{
            return axios.post(`${API.API_URL}/dataDevices/${dataDevice._id}/edit`,dataDevice,config())
        },
        deleteDevice:(dataDeviceId) =>{
            return axios.post(`${API.API_URL}/dataDevices/${dataDeviceId}/delete`,{},config())
        }
    },
    ads:{
        create(ad){
            return axios.post(`${API.API_URL}/ads/create`,ad,config());
        },
        getAds: (page = 1,pageSize = 50) =>{
            return axios.get(`${API.API_URL}/ads?page=${page}&pageSize=${pageSize}`,config());
        },
        getAd: (adId) =>{
            return axios.get(`${API.API_URL}/ads/${adId}`,config());
        },
        updateAd(adId,ad){
            return axios.post(`${API.API_URL}/ads/${adId}/update`,ad,config());
        },
        makePopup: (adId) => {
            return axios.post(`${API.API_URL}/ads/${adId}/makePopup`, {},config());
        },
        deleteAd(adId){
            return axios.post(`${API.API_URL}/ads/${adId}/remove`,{},config());
        },
    },
    analytics:{
        getMainStats: () =>{
            return axios.get(`${API.API_URL}/analytics/mainStats`,config());
        },
        getMostSoldProducts: (startDate,endDate) =>{
            return axios.get(`${API.API_URL}/analytics/mostSoldProducts?startDate=${startDate}&endDate=${endDate}`,config());
        },
        getMostViewedProducts: (startDate,endDate) =>{
            return axios.get(`${API.API_URL}/analytics/mostViewedProducts?startDate=${startDate}&endDate=${endDate}`,config());
        },
        getNewAccounts: (startDate,endDate) =>{
            return axios.get(`${API.API_URL}/analytics/newAccounts?startDate=${startDate}&endDate=${endDate}`,config());
        },
        getOperatorReviews: (startDate,endDate) =>{
            return axios.get(`${API.API_URL}/analytics/operatorReviews?startDate=${startDate}&endDate=${endDate}`,config());
        },
        getAdClicks: (startDate,endDate) =>{
            return axios.get(`${API.API_URL}/analytics/adClicks?startDate=${startDate}&endDate=${endDate}`,config());
        },
        getAdConversions: (startDate,endDate) =>{
            return axios.get(`${API.API_URL}/analytics/adConversions?startDate=${startDate}&endDate=${endDate}`,config());
        },
    },
    categories:{
        getCategories: () => {
            return axios.get(`${API.API_URL}/categories/`,config());
        },
    },
    subCategories:{
        getSubCategories: () => {
            return axios.get(`${API.API_URL}/subcategories/`,config());
        },
    },
    segmentation: {
        getAvailableSegments(){
            return axios.get(`${API.API_URL}/segmentation`, config());
        },
        process(){
            return axios.get(`${API.API_URL}/segmentation/process`, config());
        },
        count(segments,subSegments){
            const data = {
                segments:segments,
                subSegments:subSegments
            }
            return axios.post(`${API.API_URL}/segmentation/count`,data, config());
        },
        getUserIds(segments,subSegments){
            const data = {
                segments:segments,
                subSegments:subSegments
            }
            return axios.post(`${API.API_URL}/segmentation/getUserIds`,data, config());
        },
        getAvailableReports(){
            return axios.get(`${API.API_URL}/segmentation/reports`, config());
        },
        generateReport(segments,subSegments){
            const data = {
                segments:segments,
                subSegments:subSegments
            }
            return axios.post(`${API.API_URL}/segmentation/reports/generate`,data, config());

        }


    },
    regions:{
        create(data){
            return axios.post(`${API.API_URL}/regions/create`,data, config());
        },
        update(regionId,data){
            return axios.post(`${API.API_URL}/regions/${regionId}/update`,data, config());
        },
        getRegions(){
            return axios.get(`${API.API_URL}/regions/`, config());
        },
        getRegion(regionId){
            return axios.get(`${API.API_URL}/regions/${regionId}`, config());
        },
        delete(regionId){
            return axios.post(`${API.API_URL}/regions/${regionId}/remove`, {}, config());
        },
    },
    routes:{
        create(data){
            return axios.post(`${API.API_URL}/routes/create`,data, config());
        },
        update(routeId,data){
            return axios.post(`${API.API_URL}/routes/${routeId}/update`,data, config());
        },
        getRoutes(page = 1, pageSize = 50,sort=''){
            return axios.get(`${API.API_URL}/routes?page=${page}&pageSize=${pageSize}`, config());
        },
        addOrders(routeId,ordersIds){
            return axios.post(`${API.API_URL}/routes/${routeId}/addOrders`, {orders:ordersIds}, config());
        },
        getRoute(routeId){
            return axios.get(`${API.API_URL}/routes/${routeId}`, config());
        },
        delete(routeId){
            return axios.post(`${API.API_URL}/routes/${routeId}/remove`, {}, config());
        },
        assignToOperator(routeId,uid){
            const data = {
                uid: uid,
            }
            return axios.post(`${API.API_URL}/routes/${routeId}/assign`, data, config());
        },
    },
    admin:{
        processSegments(data){
            return axios.post(`${API.API_URL}/admin/segmentation/process`,data, config());
        },
        regenerateCategories(){
            return axios.post(`${API.API_URL}/admin/categories/recreate`,{}, config());
        },
        regenerateKeywords(){
            return axios.post(`${API.API_URL}/admin/keywords/recreate`,{}, config());
        },
        getPaymentMethods(){
            return axios.get(`${API.API_URL}/admin/paymentMethods`, config());
        },
        setPaymentMethodActive(id,body){
            return axios.post(`${API.API_URL}/admin/paymentMethods/${id}/setActive`,body, config());
        },
        createCarouselItem(body){
            return axios.post(`${API.API_URL}/admin/carousel/create`,body, config());
        },
        updateCarouselPages(body){
            return axios.post(`${API.API_URL}/admin/carousel/update`,body, config());
        },
        exportFBCatalog(){
            return axios.post(`${API.API_URL}/admin/fb-catalogs/create`,{}, config());
        },
        getFBCatalogs(){
            return axios.get(`${API.API_URL}/admin/fb-catalogs`, config());
        },
        exportGMCatalog(){
            return axios.post(`${API.API_URL}/admin/gm-catalogs/create`,{}, config());
        },
        getGMCatalogs(){
            return axios.get(`${API.API_URL}/admin/gm-catalogs`, config());
        },
        addToBlacklist(skus){
            return axios.post(`${API.API_URL}/admin/gm-catalogs/blacklist/add`,{skus:skus}, config());
        },
        generateSitemap(){
            return axios.post(`${API.API_URL}/admin/generate-sitemap`,{}, config());
        },
    },
    coupons:{
        create(data){
            return axios.post(`${API.API_URL}/coupons/create`,data, config());
        },
        update(couponId,data){
            return axios.post(`${API.API_URL}/coupons/${couponId}/update`,data, config());
        },
        delete(couponId){
            return axios.post(`${API.API_URL}/routes/${couponId}/remove`, {}, config());
        },
        getCoupons(page,pageSize){
            return axios.get(`${API.API_URL}/coupons?page=${page}&pageSize=${pageSize}`,config());
        },
        getCoupon(couponId){
            return axios.get(`${API.API_URL}/coupons/${couponId}`,config());
        },
        getAccessCode(couponId,accessCode){
            return axios.get(`${API.API_URL}/influencers/coupon/${couponId}/${accessCode}`,config());
        },
        publish(couponId){
            return axios.post(`${API.API_URL}/coupons/${couponId}/publish`, {}, config());
        },
        createInfluencerLink(couponId,influencerName){
            return axios.post(`${API.API_URL}/coupons/${couponId}/influencers/activate`, {influencerName}, config());
        }
    },
    discounts:{
        create(data){
            return axios.post(`${API.API_URL}/discounts/create`,data, config());
        },
        update(discountId,data){
            return axios.post(`${API.API_URL}/discounts/${discountId}/update`,data, config());
        },
        delete(discountId){
            return axios.post(`${API.API_URL}/discounts/${discountId}/remove`, {}, config());
        },
        getDiscounts(data){
            return axios.get(`${API.API_URL}/discounts`,config());
        },
        getDiscount(discountId){
            return axios.get(`${API.API_URL}/discounts/${discountId}`,config());
        },
        deactivate(discountId){
            return axios.post(`${API.API_URL}/discounts/${discountId}/deactivate`, {}, config());
        }
    },
    zipCodes:{
        uploadBulk:(file,onUploadProgress = () =>{}) =>{
            const _config = {
                onUploadProgress: (ev) => onUploadProgress(ev),
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem(LOCAL_STORAGE_TOKEN)}`,
                    'content-type': 'multipart/form-data',

                }
            }
            const formData = new FormData();
            formData.append('file',file)
            return axios.post(`${API.API_URL}/zipcodes/upload`,formData,_config);
        },
        download:() =>{
            return axios.get(
                `${API.API_URL}/zipcodes/download`,
                excelDownload()
            );
        },
        deleteAllZipCodes:() =>{
            return axios.post(`${API.API_URL}/zipcodes/deleteAll`,{},config());
        }
    },
    store:{
        getCarousel: () => {
            return axios.get(`${API.API_URL}/store/carousel`,config());
        },
    },
}
export default API;
