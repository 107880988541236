/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import NbioApi from "../../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from 'react-bootstrap/Spinner'
import {Badge} from "react-bootstrap";
import NPPaginator from "../ui/NPPaginator";

const dayjs = require("dayjs");


class Routes extends React.Component {
    constructor(p) {
        super(p);
        this.state      = {
            routes: [],
            pages: 1,
            currentPage: 1,
            isLoading: true,
        }
        this.loadRoutes = this.loadRoutes.bind(this);
    }

    componentDidMount() {
        this.loadRoutes();
    }
    onPageChanged(page) {
        this.setState({currentPage: page}, () => {
            this.loadRoutes();
        })
    }
    loadRoutes() {
        const pageSize    = 50;
        this.setState({isLoading: true});
        NbioApi.routes.getRoutes(this.state.currentPage, pageSize, this.state.activeSort).then(res => {
            // console.log(subRegions)
            // const mergeSubRegions = [].concat.apply([], subRegions);
            this.setState({
                routes: res.data.routes,
                page: res.data.page,
                pages: res.data.pages,
                isLoading: false,
            });
        }).catch((ex) => {
        })
    }

    renderStatus(status) {
        if (status === 'open') {
            return <Badge bg={'primary'}>Abierta</Badge>
        } else if (status === 'on_route') {
            return <Badge bg={'warning'}>En ruta</Badge>
        } else if (status === 'finished') {
            return <Badge bg={'success'}>Terminada</Badge>
        } else {
            return <Badge>{status}</Badge>
        }
    }


    render() {
        const routes  = this.state.routes;
        const columns = [
            {
                dataField: 'name',
                text: 'Nombre',
                formatter: (value, row) => {
                    return (
                        <a href={`/routes/${row._id}`}>{value}</a>
                    )
                },
            },
            {
                dataField: 'orders',
                text: 'Núm. de Órdenes',
                formatter: (value, row) => {
                    return value ? value.length : value
                }
            },
            {
                dataField: 'status',
                text: 'Estado',
                formatter: (value, row) => {
                    return this.renderStatus(value);
                }
            },
            {
                dataField: 'startedAt',
                text: 'Hora de inicio',
                formatter: (value, row) => {
                    if (value) {
                        return dayjs(value).format('DD-MM-YYYY HH:MM')
                    }
                }
            },
            {
                dataField: 'finishedAt',
                text: 'Hora de finalización',
                formatter: (value, row) => {
                    if (value) {
                        return dayjs(value).format('DD-MM-YYYY HH:MM')
                    }
                }
            },
            {
                dataField: 'assignedUser',
                text: 'Operador',
                formatter: (value, row) => {
                    const assignedUser = value;
                    if(!assignedUser){
                        return 'Sin operador';
                    }else{
                        return `${assignedUser.name} ${assignedUser.last_name}`;
                    }
                }
            },
        ];
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3 d-flex flex-row align-items-center'>
                        <h5 className={'text-capitalize mr-auto'}>Rutas</h5>
                        <Button variant="primary"
                                onClick={() => this.props.history.push('/routes/create')}>
                            Crear ruta
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <NPIf condition={this.state.isLoading}>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <Spinner animation="grow" variant={"secondary"}/>
                        </div>
                        <NPElse>
                            <Col className='py-3' lg={16}>
                                <BootstrapTable keyField='_id'
                                                data={routes}
                                                columns={columns}
                                                noDataIndication={`No se encontraron rutas`}
                                                bordered={false}
                                />
                            </Col>
                            <NPPaginator numberOfPages={this.state.pages}
                                         onPageChanged={(p) => this.onPageChanged(p)}
                                         initialPage={this.state.currentPage}>
                            </NPPaginator>
                        </NPElse>
                    </NPIf>
                </Row>
            </Container>
        )
    }
}

export default withRouter(Routes);
