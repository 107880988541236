/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Badge from "react-bootstrap/Badge";
import PropTypes from "prop-types";
import {Marker} from "react-map-gl"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

//style
import "../../scss/components/orders-marker.scss";
import Button from "react-bootstrap/Button";

//const
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);

//------Popover Start---------------------------------------------------------------------------------------------------
const popover = (order) => {
    return(
        <Popover id={`popover-${order._id}`}>
            <Popover.Header as="h3">Orden <a href={`/orders/${order._id}`}>{order._id}</a> </Popover.Header>
            <Popover.Body>
                <div className={'d-flex justify-content-between mb-3'}>
                    <div>{formatDate(order.created_at)}</div>
                    <div>{formatterStatus(order.status)}</div>
                </div>

                <div>Región: {formatterRegion(order.region)}</div>
                <div>Método de pago: {formatterPaymentMethod(order.paymentMethod)} / {formatterPaymentStatus(order.paymentStatus)}</div>
                <div>Total: ${order.displayTotals.parsedTotal}</div>
            </Popover.Body>
        </Popover>
    )
}

const formatDate = (date) => {
    if (!date) {
        return '-';
    }
    return (
        <div>
            <span>{dayjs(date).format('DD MMM YYYY')}</span>
            <span className={'ml-1'}>{dayjs(date).format('HH:mm')}</span>
        </div>
    )
}

const formatterStatus= (value) => {
    let variant = '';
    let txt     = value;
    switch (value) {
        case 'processing':
            variant = 'info';
            txt     = 'Procesando';
            break;
        case 'received':
            variant = 'info';
            txt     = 'Recibida';
            break;
        case 'amount_validated':
            variant = 'info';
            txt     = 'Monto validado';
            break;
        case 'on_route':
            variant = 'info';
            txt     = 'En camino';
            break;
        case 'delivering':
            variant = 'info';
            txt     = 'Entregando';
            break;
        case 'delivered':
            variant = 'info';
            txt     = 'Entregado';
            break;
        case 'finished':
            variant = 'info';
            txt     = 'Terminado';
            break;
        case 'cancelled':
            variant = 'danger';
            txt     = 'Cancelada';
            break;
        default:
            variant = 'info';
            txt     = '---';
            break;
    }
    return (
        <Badge bg={variant}>{txt}</Badge>
    )
}

const formatterPaymentMethod = (value) => {
    switch (value) {
        case 'card':
            return 'Tarjeta de Crédito/Débito'
        case 'cash':
            return 'Efectivo'
        case 'pos':
            return 'Terminal Punto de Venta'
        case 'mercado-pago':
            return 'Mercado Pago'
        default :
            return '---'
    }
}

const formatterPaymentStatus = (value) => {
    let variant = '';
    let txt     = value;
    switch (value) {
        case 'paid':
            variant = 'success';
            txt     = 'Pagado';
            break;
        case 'pending':
            variant = 'warning';
            txt     = 'Pendiente de pago';
            break;
        default:
            variant = 'danger';
            break;
    }
    return (
        <span className={`text-${variant}`}>{txt}</span>
    )
}

const formatterRegion = (value) => {
    if (!value) {
        return '---';
    }
    const style = {
        color: value.color
    }
    return (
        <span style={style}><b>{value.name}</b></span>
    )
}
//------Popover Ends----------------------------------------------------------------------------------------------------

/**
 * Order map
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */

class OrdersMarker extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const order = this.props.order;
        const shipping = order.shipping;
        return (
            <Marker longitude={shipping.longitude}
                    latitude={shipping.latitude}
                    className={'o-marker'}>
                <OverlayTrigger trigger="click" placement="auto" overlay={popover(order)} >
                    <Button className={'btn-trigger-overlay'}>
                        <FontAwesomeIcon icon={'map-marker-alt'}
                                         className={'o-icon'}
                                         size={'lg'}
                        />
                    </Button>
                </OverlayTrigger>
            </Marker>
        )
    }
}

export default withRouter(OrdersMarker);

OrdersMarker.propTypes    = {
    order: PropTypes.object,
}
OrdersMarker.defaultProps = {
    order: {},
}
