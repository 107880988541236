/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from 'react';
import {withRouter} from "react-router";
import Container from "react-bootstrap/cjs/Container";
import Navbar from "react-bootstrap/Navbar";
import {Switch,Route} from "react-router";
import NBRoute from "../components/routes/Route";
import Button from "react-bootstrap/cjs/Button";
import API from "../lib/api/NbioApi";
import Swal from "sweetalert2";
import {AuthContext} from "../lib/context/AuthContext";

//image
import Logo from "../assets/logo-icon-small-dark-mode.png";

//style
import "../scss/views/dashboardView.scss";

//components
import Sidebar from "../components/Sidebar";
import Dashboard from "../components/Dashboard";
import Orders from "../components/orders/Orders";
import Order from "../components/orders/Order";
import NbioToast from "../components/ui/toast/NbioToast";
import ProductsTable from "../components/products/ProductsTable";
import Product from "../components/products/Product";
import User from "../components/users/User";
import UsersTable from "../components/users/UsersTable";
import SecurityGuard from "../components/security/SecurityGuard";
import ReviewsTable from "../components/reviews/ReviewsTable";
import Reviews from "../components/reviews/Reviews";
import Bell from "../components/bell/Bell";
import Settings from "../components/settings/Settings";
import AdsTable from "../components/ads/AdsTable";
import Ad from "../components/ads/Ad";
import ProductsAnalytics from "../components/analytics/ProductsAnalytics";
import UserAnalytics from "../components/analytics/UserAnalytics";
import VehiclesTable from "../components/vehicles/VehiclesTable";
import Vehicle from "../components/vehicles/Vehicle";
import DataDevicesTable from "../components/dataDevices/DataDevicesTable";
import DataDevice from "../components/dataDevices/DataDevice";
import OrderTracking from "../components/orders/tracking/OrderTracking";
import AdsAnalytics from "../components/analytics/AdsAnalytics";
import ProductsBulkImporter from "../components/products/ProductsBulkImporter";
import Segmentation from "../components/analytics/Segmentation";
import Reports from "../components/analytics/Reports";
import Region from "../components/regions/Region";
import Regions from "../components/regions/Regions";
import OrderScheduledPlanner from "../components/orders/OrdersScheduledPlanner";
import WSSClientComponent from "../components/wss/WSSClientComponent";
import Routes from "../components/routes/Routes";
import CouponsTable from "../components/coupons/CouponsTable";
import Coupon from "../components/coupons/Coupon";
import DiscountTable from "../components/discounts/DiscountTable";
import Discount from "../components/discounts/Discount";
import ZipCodesBulkImporter from "../components/zip-codes/ZipCodesBulkImporter";
import Carousel from "../components/carousel/Carousel";
import ProductsBulkExporter from "../components/products/ProductsBulkExporter";

class DashboardView extends React.Component{
    static contextType = AuthContext;

    constructor(s) {
        super(s);
        this.logout = this.logout.bind(this);
    }

    logout(){
        const _this = this;
        Swal.fire({
            title:'Cerrar sesión',
            html:'¿Estás seguro?',
            icon:'warning',
            confirmButtonText:'Si, Cerrar sesión',
            cancelButtonText:'Cancelar',
            showCancelButton:true,
            heightAuto:false
        }).then((res) =>{
            if(res.isConfirmed){
                API.users.logout().then((res) =>{
                    _this.props.history.push('/')
                }).catch((ex) =>{

                })
            }
        })
    }

    render(){
        return(
            <Container fluid className={"p-0 h-100"}>
                <WSSClientComponent></WSSClientComponent>
                <Bell></Bell>
                <NbioToast></NbioToast>
                <Navbar bg={'dark'} variant="dark">
                    <Container fluid>
                        <Navbar.Brand href="/dashboard" className="d-inline-block align-top">
                            <img src={Logo} height={30}/> Nbio
                        </Navbar.Brand>
                        <div >
                            <span style={{color:"white"}} className={'mr-1'}>
                                {
                                    this.context.user.email
                                }
                            </span>
                            <Button onClick={this.logout} variant={'outline-light'} className={'mr-1'}>Salir</Button>
                        </div>

                    </Container>
                </Navbar>
                 <div className='dashboard-divider'></div>
                <div  className={"wrapper-dashboard"}>
                    <Sidebar {...this.props}></Sidebar>
                    <Switch>
                        <Route path={"/dashboard"}>
                            <SecurityGuard allowedRoles={['admin','region_admin','super_admin']}>
                            </SecurityGuard>
                            <Dashboard></Dashboard>
                        </Route>
                        {/*products routes*/}
                        <Route exact path={"/regions/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Regions></Regions>
                        </Route>
                        <Route path={"/regions/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Region></Region>
                        </Route>

                        {/*orders routes*/}
                        <Route exact path={"/orders"}>
                            <SecurityGuard allowedRoles={['admin','region_admin','super_admin']}>
                            </SecurityGuard>
                            <Orders></Orders>
                        </Route>
                        <Route path={"/orders/:id/tracking"} render={(props) => {
                            return <>
                                <SecurityGuard allowedRoles={['admin','region_admin','super_admin']}>
                                </SecurityGuard>
                                <OrderTracking/>
                            </>
                        }}>
                        </Route>
                        <Route path={"/orders/:id"} render={(props) => {
                            if(["asap","scheduled","pending","finished","paymentIntent"].includes(props.match.params.id)){
                                return <>
                                            <SecurityGuard allowedRoles={['admin','region_admin','super_admin']}>
                                            </SecurityGuard>
                                            <Orders></Orders>
                                       </>
                            } else{
                                return <>
                                    <SecurityGuard allowedRoles={['admin','region_admin','super_admin']}>
                                    </SecurityGuard>
                                    <Order></Order>
                                </>
                            }
                        }}
                        >
                        </Route>

                        {/*products routes*/}
                        <Route exact path={"/products"}>
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <ProductsTable></ProductsTable>
                        </Route>
                        <Route exact path={"/products/bulk/export"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <ProductsBulkExporter></ProductsBulkExporter>
                        </Route>
                        <Route exact path={"/products/bulk/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <ProductsBulkImporter></ProductsBulkImporter>
                        </Route>
                        <Route path={"/products/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Product></Product>
                        </Route>
                        {/*users routes*/}
                        <Route exact path={"/users/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <UsersTable></UsersTable>
                        </Route>
                        <Route path={"/users/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <User></User>
                        </Route>

                        {/*ads routes*/}
                        <Route exact path={"/ads/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <AdsTable></AdsTable>
                        </Route>
                        <Route path={"/ads/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Ad></Ad>
                        </Route>

                        {/*review routes*/}
                        <Route exact path={"/reviews/"} >
                            <SecurityGuard allowedRoles={['admin','super_admin']}>
                            </SecurityGuard>
                            <ReviewsTable></ReviewsTable>
                        </Route>
                        <Route path={"/reviews/:id"} >
                            <SecurityGuard allowedRoles={['admin','super_admin']}>
                            </SecurityGuard>
                            <Reviews></Reviews>
                        </Route>

                        {/*settings routes*/}
                        <Route exact path={"/settings/zipcodes/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <ZipCodesBulkImporter></ZipCodesBulkImporter>
                        </Route>
                        <Route path={"/settings"} >
                            <SecurityGuard allowedRoles={['admin','region_admin','super_admin']}>
                            </SecurityGuard>
                            <Settings></Settings>
                        </Route>

                        {/*Analytics routes*/}
                        <Route exact path={"/analytics"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Dashboard></Dashboard>
                        </Route>
                        <Route path={"/analytics/products"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <ProductsAnalytics></ProductsAnalytics>
                        </Route>

                        <Route path={"/analytics/users"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <UserAnalytics></UserAnalytics>
                        </Route>
                        <Route path={"/analytics/ads"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <AdsAnalytics></AdsAnalytics>
                        </Route>
                        <Route path={"/analytics/segmentation"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Segmentation></Segmentation>
                        </Route>
                        <Route path={"/analytics/reports"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Reports></Reports>
                        </Route>
                        {/*Vehicles*/}
                        <Route path={"/vehicles/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Vehicle></Vehicle>
                        </Route>
                        <Route path={"/vehicles/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <VehiclesTable></VehiclesTable>
                        </Route>
                        {/*Devices*/}
                        <Route path={"/devices/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <DataDevice></DataDevice>
                        </Route>
                        <Route path={"/devices/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <DataDevicesTable></DataDevicesTable>
                        </Route>
                        {/* Routes*/}
                        <Route exact path={"/routes/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Routes></Routes>
                        </Route>
                        <Route path={"/routes/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <NBRoute></NBRoute>
                        </Route>
                        {/* Coupons*/}
                        <Route exact path={"/coupons/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <CouponsTable></CouponsTable>
                        </Route>
                        <Route path={"/coupons/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Coupon></Coupon>
                        </Route>
                        {/* Discounts*/}
                        <Route exact path={"/discounts/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <DiscountTable/>
                        </Route>
                        <Route path={"/discounts/:id"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Discount/>
                        </Route>
                        {/* Carousel */}
                        <Route exact path={"/carousel/"} >
                            <SecurityGuard allowedRoles={['super_admin']}>
                            </SecurityGuard>
                            <Carousel/>
                        </Route>
                    </Switch>

                </div>
            </Container>
        )
    }
}

export default withRouter(DashboardView);
