/* Copyright (C) Nodeport SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <amendoza@nodeport.co> &
 * Darien Miranda <dmiranda@nodeport.co>
 * December 2022
 */

import React from 'react';
import PropTypes from "prop-types";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import InputGroup from "react-bootstrap/InputGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import NbioApi from "../../lib/api/NbioApi";

//style
import "../../scss/components/product-selector.scss"

//components

class ProductSelector extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            query:'',
            products: []
        }

        this.onChange           = this.onChange.bind(this);
        this.onSearch           = this.onSearch.bind(this);
        this.onClearSearch      = this.onClearSearch.bind(this);
    }

    onChange = (e) => {
        this.setState({
            [e.target.id]:e.target.value
        })
    }

    onSearch = () => {
        const query = this.state.query.trim();

        NbioApi.products.searchProducts(query,1).then(res => {
            this.setState({
                products:res.data.products,
            });
        })
    }

    onClearSearch = () => {
        this.setState({
            query:'',
            products:[]
        });
    }
    onProductSelected(product){
        this.props.onProductSelected(product);
        const clearSearch = this.props.clearSeach || false;
        if(clearSearch){
            this.onClearSearch();
        }
    }
    render() {
        const products =  this.state.products;

        return (
            <div className={'product-selector'}>
                <InputGroup>
                    <InputGroup.Text>
                        <FontAwesomeIcon icon={"search"} />
                    </InputGroup.Text>
                    <FormControl
                        id={'query'}
                        type={'text'}
                        placeholder="Buscar productos"
                        value={this.state.query}
                        onKeyUp={(e) => {
                            this.onSearch();
                        }}
                        onChange={event => this.onChange(event)}
                    />
                    <Button variant="outline-info"
                            disabled={this.state.query.trim() === ''}
                            onClick={this.onClearSearch}>
                        <FontAwesomeIcon icon={"times"}/>
                    </Button>
                </InputGroup>
                <NPIf condition={products.length > 0}>
                    <div className={'ps-list'}>
                        {
                            products.map((product) => {
                                return(
                                    <div key={product.sku} className={'ps-item'} onClick={() =>  this.onProductSelected(product)}>
                                        <img className={'ps-item-img'} src={product.images[0]} height={'60px'}/>
                                        <div className={'ps-item-text'}>
                                            <span>{product.name}</span>
                                            <span className={'ps-item-text-sku'}>{product.sku}</span>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </NPIf>
            </div>
        )
    }

}

export default ProductSelector;

ProductSelector.propTypes = {
    onProductSelected: PropTypes.func

}
ProductSelector.defaultProps = {
    onProductSelected: () => {}

}
