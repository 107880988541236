/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NbioApi from "../../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from 'react-bootstrap/Spinner'


//components
import NPPaginator from "../ui/NPPaginator";
import SortBtns from "../ui/SortBtns";
import {money} from "../../lib/money/money";



class ProductsTable extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
            query:'',
            products:[],
            isLoading:true,
            showSearchResults: false,
            pages:10,
            currentPage:1,
            searchMode:false,
            searchedQuery:'',
            searchCount:0,
            activeSort:'newest'
        }
        this.loadProducts   = this.loadProducts.bind(this);
        this.onSearch       = this.onSearch.bind(this);
        this.onClearSearch  = this.onClearSearch.bind(this);
        this.onChange       = this.onChange.bind(this);
        this.onFilterSelected = this.onFilterSelected.bind(this);
        this.onSortChanged    = this.onSortChanged.bind(this);
    }

    componentDidMount() {
        this.loadProducts();
    }

    loadProducts(){
        const pageSize=25;
        this.setState({isLoading:true});
        NbioApi.products.getProducts(this.state.currentPage,pageSize,this.state.activeSort).then(res => {
            this.setState({
                products:res.data.products,
                isLoading:false,
                pages:res.data.pages
            });
        });
    }

    loadSearch(){
        this.setState({isLoading:true});
        NbioApi.products.searchProducts(this.state.searchedQuery,this.state.currentPage).then(res => {
            this.setState({
                products:res.data.products,
                isLoading:false,
                showSearchResults:true,
                pages:res.data.pages,
                searchCount:res.data.count
            });
        })
    }

    onChange(e){
        this.setState({
            [e.target.id]:e.target.value
        })
    }
    onSortChanged = (value) => {
        this.setState({activeSort:value}, () =>{
            if(this.state.searchMode){
                this.loadSearch();
            }else{
                this.loadProducts();

            }
        });
    }
    onSearch(){
        const query = this.state.query.trim();
        this.setState({searchMode:true,searchedQuery:query}, () =>{
            if(query){
                this.setState({isLoading:true,currentPage:1});
                this.loadSearch();
            }
        });

    }

    onClearSearch(){
        this.setState({query:'',showSearchResults:false,isLoading:true,searchMode:false}, () => {
            this.loadProducts();
        })
    }

    onPageChanged(page){
        this.setState({currentPage:page},() =>{
            if(this.state.searchMode) {
                this.loadSearch();
            }else{
                this.loadProducts();
            }
        })
    }

    onFilterSelected = (filterValue) => {
        return null;
    }

    render(){
        const columns = [
            {
                dataField: 'images',
                text: '',
                formatter: (value, row) => {
                    let img = value.length > 0 ? value[0] : '';
                    return (
                        <img className={'img-thumbnail'} src={img} alt={'Product img'} width={75}/>
                    )
                },
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'75px',
                    }
                }
            },
            {
                dataField: 'sku',
                text: 'SKU',
                formatter: (value, row) => {
                    return (
                        <a href={`/products/${row._id}`}>{value}</a>
                    )
                },
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'100px',
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'name',
                text: 'Nombre',
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'250px',
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
            {
                dataField: 'price',
                text: 'Precio',
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'100px',
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                },
                formatter:(v) =>{
                    return money(v);
                }
            },
            {
                dataField: 'description',
                text: 'Descripción',
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'250px',
                    }
                }
            },
        ];
        return(
            <Container fluid style={{overflowY:"scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3 d-flex flex-row align-items-center'>
                        <h5 className={'text-capitalize mr-auto'}>Productos</h5>
                        <Button variant="primary"
                                onClick={() => this.props.history.push('/products/create')}>
                            Crear producto
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className='py-3'>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={"search"} />
                            </InputGroup.Text>
                            <FormControl
                                id={'query'}
                                type={'text'}
                                placeholder="Buscar producto por sku"
                                value={this.state.query}
                                onKeyUp={event => {
                                    if (event.keyCode === 13) {
                                        this.onSearch();
                                    }
                                }}
                                onChange={event => this.onChange(event)}

                            />
                            <Button variant="outline-info"
                                    disabled={this.state.query.trim() === ''}
                                    onClick={this.onClearSearch}>
                                <FontAwesomeIcon icon={"times"}/>
                            </Button>
                            <Button variant="outline-secondary"
                                    disabled={this.state.query.trim() === ''}
                                    onClick={this.onSearch}>
                                Buscar
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col sm={1} className='py-3'>
                        <Dropdown className={'float-right'}>
                            <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                                <FontAwesomeIcon  icon={'filter'}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => this.onSortChanged('newest')}
                                    active={this.state.activeSort === 'newest'}>
                                    Más recientes
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.onSortChanged('oldest')}
                                    active={this.state.activeSort === 'oldest'}>
                                    Menos recientes
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.onSortChanged('name_asc')}
                                    active={this.state.activeSort === 'name_asc'}>
                                    Nombre de A - Z
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.onSortChanged('name_desc')}
                                    active={this.state.activeSort === 'name_desc'}>
                                    Nombre de Z - A
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.onSortChanged('price_desc')}
                                    active={this.state.activeSort === 'price_desc'}>
                                    Precio de mayor a menor
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.onSortChanged('price_asc')}
                                    active={this.state.activeSort === 'price_asc'}>
                                    Precio de menor a mayor  </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col className='pb-3'>
                        <NPIf condition={this.state.isLoading}>
                            <div className={'d-flex align-items-center justify-content-center'}>
                                <Spinner animation="grow" variant={"secondary"}/>
                            </div>
                            <NPElse>
                                <NPIf condition={this.state.showSearchResults}>
                                    <div className={'mb-2'}><b>{this.state.searchCount} resultados de la búsqueda</b></div>
                                </NPIf>
                                <BootstrapTable keyField='_id'
                                                data={this.state.products}
                                                columns={columns}
                                                noDataIndication={`No se encontraron productos`}
                                                bordered={false}
                                />
                                <NPPaginator numberOfPages={this.state.pages} onPageChanged={(p) => this.onPageChanged(p)} initialPage={this.state.currentPage}></NPPaginator>
                            </NPElse>
                        </NPIf>

                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(ProductsTable);
