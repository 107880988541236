/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import NbioApi from "../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Calendar} from 'react-date-range';
import es from "date-fns/locale/es";
import InputGroup from 'react-bootstrap/InputGroup';

//style calendar
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

//components
import ProductSelector from "../products/ProductSelector";
import ProductList from "../products/ProductList";
import CategoriesPicker from "../categories/CategoriesPicker";
import CouponAudience from "./CouponAudience";
import CouponSummary from "./CouponSummary";

//date js
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);

// Simulated Infinity
const INFINITY = 9999999999;
class Coupon extends React.Component {
    constructor(p) {
        super(p);
        this.state                 = {
            coupon: {
                code: '',
                type: 'item', //total, item, free_shipping
                discountType: 'fixed_amount', //fixed_amount, percentage, free_shipping
                value: 0,
                userSegments: [],
                sentTo: [],
                products: [],
                categories: [],
                appliesToAllCategories: false,
                appliesToAllProducts: false,
                redeemsCount: 0,
                redeemBy:[],
                hasMaximumPurchase: false,
                hasMinimumPurchase: false,
                maximumPurchase: 0,
                minimumPurchase: 0,
                startDate: dayjs(new Date()).format('MM-DD-YYYY'),
                endDate:'',
                isActive:false,
                isArchived:false,
                isPublished: false,
                maxRedeemsPerUser:1,
                maxRedeems: INFINITY,
                canBeUsedWithOtherCoupons: true,
                canBeUsedWithDiscounts: false,
            },
            isEditingCoupon: false,
            isLoaded: false,
            selectedStartDate: new Date(),
            selectedEndDate: '',
            step: '',
            hasMaxRedeems: false,
        }
        this.loadCoupon            = this.loadCoupon.bind(this);
        this.onCouponTypeChanged   = this.onCouponTypeChanged.bind(this);
        this.saveCoupon            = this.saveCoupon.bind(this);
        this.onClickSave           = this.onClickSave.bind(this);
        this.createCoupon          = this.createCoupon.bind(this);
        this.onChange              = this.onChange.bind(this);
        this.onProductSelected     = this.onProductSelected.bind(this);
        this.onDeletedProduct      = this.onDeletedProduct.bind(this);
        this.onCategoryChecked     = this.onCategoryChecked.bind(this);
        this.onCategoryUnchecked   = this.onCategoryUnchecked.bind(this);
        this.handleSelectStartDate = this.handleSelectStartDate.bind(this);
        this.handleSelectEndDate   = this.handleSelectEndDate.bind(this);
        this.setFreeShipping       = this.setFreeShipping.bind(this);

    }

    componentDidMount() {
        const couponId = this.props.match.params.id;

        if (couponId !== 'create') {
            this.loadCoupon(couponId);
        } else {
            this.setState({isLoaded: true, step:'setup'})
        }
    }

    isValidForm() {
        const coupon = this.state.coupon;
        if (coupon.code.trim() === '') {
            return {
                isValid: false,
                message: 'Ingresa un código para el cupón'
            }
        }
        if (coupon.code.trim() === '') {
            return {
                isValid: false,
                message: 'Ingresa un código para el cupón'
            }
        }
        if (coupon.value <= 0 && coupon.discountType !== 'free_shipping') {
            return {
                isValid: false,
                message: 'Valor del cupón inválido'
            }
        }
        if (coupon.value >= 100 && coupon.discountType === 'percentage') {
            return {
                isValid: false,
                message: 'El valor no puede ser mayor a 100.'
            }
        }
        if(isNaN(coupon.value)){
            return {
                isValid: false,
                message: 'El valor del cupón es incorrecto.'
            }
        }

        if(coupon.hasMaximumPurchase){
            if(isNaN(coupon.maximumPurchase)){
                return {
                    isValid: false,
                    message: 'El valor de máximo de compra es incorrecto.'
                }
            }
            if(coupon.maximumPurchase <= 0){
                return {
                    isValid: false,
                    message: 'El valor de máximo de compra no puede ser menor a 1'
                }
            }
        }
        if(coupon.hasMinimumPurchase){
            if(isNaN(coupon.minimumPurchase)){
                return {
                    isValid: false,
                    message: 'El valor de mínimo de compra es inválido'
                }
            }
            if(coupon.minimumPurchase <= 0){
                return {
                    isValid: false,
                    message: 'El valor de mínimo de compra no puede ser menor a 1'
                }
            }
        }
        if(isNaN(coupon.maxRedeems)){
            return {
                isValid: false,
                message: 'El valor de número de canjes es inválido'
            }
        }
        if(coupon.maxRedeems <= 0){
            return {
                isValid: false,
                message: 'El valor de número de canjes no puede ser menor a 1'
            }
        }
        if(isNaN(coupon.maxRedeemsPerUser)){
            return {
                isValid: false,
                message: 'El valor de número de canjes por usuario es inválido'
            }
        }
        if(coupon.maxRedeemsPerUser <= 0){
            return {
                isValid: false,
                message: 'El valor de número de canjes por usuario no puede ser menor a 1'
            }
        }
        // validate dates
        const _startDate = dayjs(this.state.selectedStartDate);
        const _endDate = coupon.endDate ? dayjs(this.state.selectedEndDate) : null
        if(_endDate){
            if(_startDate.isAfter(_endDate)){
                return {
                    isValid: false,
                    message: 'La fecha de finalización no puede ser antes de la de inicio'
                }
            }

        }
        return {
            isValid: true
        }
    }

    loadCoupon = (couponId,step = '') => {
        NbioApi.coupons.getCoupon(couponId).then(res => {
            this.setState({
                coupon: res.data.coupon,
                isEditingCoupon: true,
                isLoaded: true,
                hasMaxRedeems: res.data.coupon.maxRedeems > 0 ? true : false,
                step: step ? step : res.data.coupon.isPublished ? 'summary' : 'setup'
            });
        }).catch((ex) => {
        });
    }

    onChange = (e) => {
        const isCheckBox = ['isActive',
            'isArchive',
            'hasMaximumPurchase',
            'hasMinimumPurchase',
            'appliesToAllCategories',
            'appliesToAllProducts',
            'canBeUsedWithOtherCoupons',
            'canBeUsedWithDiscounts'
        ].includes(e.target.id);

        this.setState(prevState => {
            let coupon          = Object.assign({}, prevState.coupon);
            coupon[e.target.id] = isCheckBox ? e.target.checked : e.target.value;
            return {coupon};
        });
    }

    onCouponTypeChanged = (event) => {
        const _coupon = {...this.state.coupon};
        _coupon.type  = event.target.value;
        this.setState({coupon: _coupon}, () =>{
            if(_coupon.type === 'free_shipping'){
                this.setFreeShipping();
            }
            if(_coupon.type === 'total'){
                this.setAppliesToAllProductsAndCategories();
            }
        })
    }
    setFreeShipping = () =>{
        const _coupon        = {...this.state.coupon};
        _coupon.discountType = 'free_shipping';
        this.setState({coupon: _coupon})
    }
    setAppliesToAllProductsAndCategories = () =>{
        const _coupon        = {...this.state.coupon};
        _coupon.appliesToAllProducts   = true;
        _coupon.appliesToAllCategories = true;
        this.setState({coupon: _coupon})
    }
    onCouponDiscountTypeChanged = (event) => {
        const _coupon        = {...this.state.coupon};
        _coupon.discountType = event.target.value;
        this.setState({coupon: _coupon})
    }

    onProductSelected = (product) => {
        if(!(this.state.coupon.products.find((p) => p.sku === product.sku))){
            this.setState(prevState => {
                let coupon          = Object.assign({}, prevState.coupon);
                coupon.products = [...coupon.products, product];
                return {coupon};
            });
        }

    }

    onDeletedProduct = (product) => {
        this.setState(prevState => {
            let coupon          = Object.assign({}, prevState.coupon);
            coupon.products =  coupon.products.filter(item => item.sku !== product.sku);
            return {coupon};
        });
    }

    onCategoryChecked = (c) => {
        this.setState(prevState => {
            let coupon          = Object.assign({}, prevState.coupon);
            coupon.categories = [...coupon.categories, c];
            return {coupon};
        });
    }

    onCategoryUnchecked = (c) => {
        this.setState(prevState => {
            let coupon          = Object.assign({}, prevState.coupon);
            coupon.categories =  coupon.categories.filter(item => item !== c);
            return {coupon};
        });
    }

    handleSelectStartDate = (date) => {
        this.setState({selectedStartDate: date}, () => {
            this.setFormatDates();
        });
    }

    handleSelectEndDate = (date) => {
        this.setState({selectedEndDate: date}, () => {
            this.setFormatDates();
        });
    }

    setFormatDates = async () => {
        return new Promise((resolve, reject) => {
            this.setState(prevState => {
                let coupon       = Object.assign({}, prevState.coupon);
                coupon.startDate = this.state.selectedStartDate ? dayjs(this.state.selectedStartDate).format('MM-DD-YYYY') : '';
                coupon.endDate   = this.state.selectedEndDate ? dayjs(this.state.selectedEndDate).format('MM-DD-YYYY') : '';
                return {coupon};
            }, () => {
                resolve();
            });
        });
    }

    setCategories
    async onClickSave(step = null) {
        const isValidForm = this.isValidForm();
        const couponId    = this.props.match.params.id;

        if (!isValidForm.isValid) {
            return Swal.fire({
                title: 'Datos inválidos',
                icon: 'warning',
                html: isValidForm.message,
                heightAuto: false
            })
        }

        //Todo: hacer async???
        await this.setFormatDates()

        if (couponId === 'create') {
            return this.createCoupon(step);
        } else {
            return this.saveCoupon(couponId, step);
        }
    }

    setStep      = (stepName) => {
        this.setState({step: stepName});
    }
    mapProduct = (product) => {
        return {
            images: product.images,
            sku: product.sku,
            name: product.name,
            description: product.description
        }
    }
    createCoupon = (step = null) => {
        const coupon = {...this.state.coupon};
        coupon.maxRedeems = this.state.hasMaxRedeems ?  coupon.maxRedeems : INFINITY;
        coupon.products = coupon.products.map((p) => this.mapProduct(p));
        NbioApi.coupons.create(coupon).then((res) => {
            Swal.fire({
                title: 'Cupón creado',
                html: 'Cupón creado',
                icon: 'success',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((_res) => {
                if (!step) {
                    this.props.history.push(`/coupons/${res.data.coupon._id}`);
                } else {
                    this.loadCoupon(res.data.coupon._id,'audience');
                    this.setStep(step);
                }
            });
        }).catch((ex) => {
            let errorMsg = 'Hubo un error al crear el cupón.';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {

            }
            Swal.fire({
                title: 'Error',
                html: errorMsg,
                icon: 'error',
                heightAuto: false
            }).then((res) => {
            })
        })
    }

    saveCoupon = (couponId, step = null) => {
        const coupon = {...this.state.coupon};
        coupon.maxRedeems = this.state.hasMaxRedeems ?  coupon.maxRedeems : INFINITY;
        coupon.products = coupon.products.map((p) => this.mapProduct(p));
        NbioApi.coupons.update(couponId, coupon).then((res) => {
            if (step) {
                this.setStep(step);
            } else {
                Swal.fire({
                    title: 'Cupón actualizado',
                    html: 'Cupón actualizado',
                    icon: 'success',
                    confirmButtonColor: '#4dda85',
                    heightAuto: false
                });
            }

        }).catch((ex) => {
            let errorMsg = 'Hubo un error al actualizar el cupón.';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {

            }
            Swal.fire({
                title: 'Error',
                html: errorMsg,
                icon: 'error',
                heightAuto: false
            }).then((res) => {
            })
        });
    }

    renderSetup() {
        const coupon = this.state.coupon ? this.state.coupon : {};
        return (
            <>
                <Row>
                    <Col className='py-3' md={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>Información general</Card.Title>

                                <Form.Group as={Row} className="mb-3" controlId="code">
                                    <Form.Label column sm={2}>Código del cupón</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Código"
                                                      value={coupon.code}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="type">
                                    <Form.Label column sm={2}>Tipo de cupón</Form.Label>
                                    <Col sm={6}>
                                        <Form.Check
                                            inline
                                            label="Total"
                                            name="type"
                                            type={"radio"}
                                            id={"couponType1"}
                                            value={"total"}
                                            onChange={event => this.onCouponTypeChanged(event)}
                                            checked={coupon.type === 'total'}
                                        />
                                        <Form.Check
                                            inline
                                            label="Item"
                                            name="type"
                                            type={"radio"}
                                            id={"couponType2"}
                                            value={"item"}
                                            onChange={event => this.onCouponTypeChanged(event)}
                                            checked={coupon.type === 'item'}
                                        />
                                        <Form.Check
                                            inline
                                            label="Envio gratis"
                                            name="type"
                                            type={"radio"}
                                            id={"couponType3"}
                                            value={"free_shipping"}
                                            onChange={event => this.onCouponTypeChanged(event)}
                                            checked={coupon.type === 'free_shipping'}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="discountType">
                                    <Form.Label column sm={2}>Tipo de descuento</Form.Label>
                                    <Col sm={6}>
                                        <Form.Check
                                            inline
                                            label="Cantidad"
                                            name="discountType"
                                            type={"radio"}
                                            id={"couponDiscount1"}
                                            value={"fixed_amount"}
                                            onChange={event => this.onCouponDiscountTypeChanged(event)}
                                            checked={coupon.discountType === 'fixed_amount'}
                                            disabled={coupon.type === 'free_shipping'}
                                        />
                                        <Form.Check
                                            inline
                                            label="Porcentaje"
                                            name="discountType"
                                            type={"radio"}
                                            id={"couponDiscount2"}
                                            value={"percentage"}
                                            onChange={event => this.onCouponDiscountTypeChanged(event)}
                                            checked={coupon.discountType === 'percentage'}
                                            disabled={coupon.type === 'free_shipping'}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="value">
                                    <Form.Label column sm={2}>Valor</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Valor"
                                                      value={coupon.value}
                                                      disabled={coupon.type === 'free_shipping'}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>

                        <NPIf condition={coupon.type === 'item'}>
                            <Card className={'mt-3'}>
                                <Card.Body>
                                    <Card.Title className={'text-primary'}>Aplicar cupón por Item</Card.Title>


                                    <Form.Group as={Row} className="mt-4 mb-3" controlId="appliesToAllProducts">
                                        <Col sm={12}>
                                            <Form.Check
                                                type="switch"
                                                id="appliesToAllProducts"
                                                label="Aplicar cupón a todos los productos"
                                                checked={coupon.appliesToAllProducts}
                                                onChange={event => this.onChange(event)}/>
                                        </Col>
                                    </Form.Group>
                                    <Row className="mb-4">
                                        <Col>
                                            <ProductSelector onProductSelected={(p) => this.onProductSelected(p)}>
                                            </ProductSelector>
                                        </Col>
                                        <Col>
                                            <ProductList products={this.state.coupon.products}
                                                         onDeleteProduct={this.onDeletedProduct}/>
                                        </Col>
                                    </Row>

                                    <hr/>

                                    <Form.Group as={Row} className="mt-4 mb-3" controlId="appliesToAllCategories">
                                        <Col sm={12}>
                                            <Form.Check
                                                type="switch"
                                                id="appliesToAllCategories"
                                                label="Aplicar cupón a todas las categorías"
                                                checked={coupon.appliesToAllCategories}
                                                onChange={event => this.onChange(event)}/>
                                        </Col>
                                    </Form.Group>
                                    <NPIf condition={!coupon.appliesToAllCategories}>
                                        <Row className="mb-4">
                                            <Col>
                                                <CategoriesPicker onCategoryChecked={this.onCategoryChecked}
                                                                  onCategoryUnchecked={this.onCategoryUnchecked}
                                                />
                                            </Col>
                                        </Row>
                                    </NPIf>

                                </Card.Body>
                            </Card>
                        </NPIf>
                        <Card className={'mt-3'}>
                            <Card.Body>
                                <Card.Title className={'text-primary'}>Restricciones</Card.Title>
                                <Form.Group as={Row} className="mb-3" controlId="canBeUsedWithOtherCoupons">
                                    <Col sm={12}>
                                        <Form.Check
                                            type="switch"
                                            id="canBeUsedWithOtherCoupons"
                                            label="Aplica con otros cupones"
                                            checked={coupon.canBeUsedWithOtherCoupons}
                                            onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="canBeUsedWithDiscounts">
                                    <Col sm={12}>
                                        <Form.Check
                                            type="switch"
                                            id="canBeUsedWithDiscounts"
                                            label="Aplica con descuentos"
                                            checked={coupon.canBeUsedWithDiscounts}
                                            onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <hr/>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="hasMinimumPurchase">
                                    <Col sm={12}>
                                        <Form.Check
                                            type="switch"
                                            id="hasMinimumPurchase"
                                            label="Mínimo de compra"
                                            checked={coupon.hasMinimumPurchase}
                                            onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <NPIf condition={coupon.hasMinimumPurchase}>
                                    <Form.Group as={Row} className="mb-3" controlId="minimumPurchase">
                                        <Form.Label column sm={2} className={'text-end'}>Cantidad mínima</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="text"
                                                          placeholder="Cantidad"
                                                          value={coupon.minimumPurchase}
                                                          onChange={event => this.onChange(event)}/>
                                        </Col>
                                    </Form.Group>
                                </NPIf>

                                <Form.Group as={Row} className="mb-3" controlId="hasMaximumPurchase">
                                    <Col sm={12}>
                                        <Form.Check
                                            type="switch"
                                            id="hasMaximumPurchase"
                                            label="Máximo de compra"
                                            checked={coupon.hasMaximumPurchase}
                                            onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <NPIf condition={coupon.hasMaximumPurchase}>
                                    <Form.Group as={Row} className="mb-3" controlId="maximumPurchase">
                                        <Form.Label column sm={2} className={'text-end'}>Cantidad máxima</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="text" placeholder="Cantidad"
                                                          value={coupon.maximumPurchase}
                                                          onChange={event => this.onChange(event)}/>
                                        </Col>
                                    </Form.Group>
                                </NPIf>

                                <hr/>

                                <Form.Group as={Row} className="mb-3" controlId="hasMaxRedeems">
                                    <Col sm={12}>
                                        <Form.Check
                                            type="switch"
                                            id="hasMaximumPurchase"
                                            label="Limitar el número de canjes del cupón"
                                            checked={this.state.hasMaxRedeems}
                                            onChange={event => this.setState({hasMaxRedeems: event.target.checked})}/>
                                    </Col>
                                </Form.Group>

                                <NPIf condition={this.state.hasMaxRedeems}>
                                    <Form.Group as={Row} className="mt-4 mb-3" controlId="maxRedeems">
                                        <Form.Label column sm={2} >Número de canjes del cupón</Form.Label>

                                        <Col sm={10}>
                                            <Form.Control type="text"
                                                          placeholder="Número de canjes del cupón"
                                                          value={coupon.maxRedeems}
                                                          disabled={!this.state.hasMaxRedeems}
                                                          onChange={event => this.onChange(event)}/>
                                        </Col>
                                    </Form.Group>
                                </NPIf>

                                <Form.Group as={Row} className="mb-3" controlId="maxRedeemsPerUser">
                                    <Form.Label column sm={2} >Número de canjes por usuario</Form.Label>

                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Número de canjes por usuario"
                                                      value={coupon.maxRedeemsPerUser}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>

                        <Card className={'mt-3'}>
                            <Card.Body>
                                <Card.Title className={'text-primary'}>Caducidad</Card.Title>
                                <Row>
                                    <Col>
                                        <div>Fecha de inicio</div>
                                        {/*return dayjs(date).format('DD MMM YYYY - HH:mm:ss');*/}

                                        <Calendar locale={es}
                                                  date={this.state.selectedStartDate}
                                                  onChange={this.handleSelectStartDate}
                                                  weekStartsOn={0}
                                                  minDate={new Date()}
                                        />
                                    </Col>
                                    <Col>
                                        <div>Fecha de finalización</div>
                                        <Calendar locale={es}
                                                  date={this.state.selectedEndDate}
                                                  onChange={this.handleSelectEndDate}
                                                  weekStartsOn={0}
                                                  minDate={new Date()}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>


                    </Col>

                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col md={12} className={'d-flex justify-content-end'}>
                        <Button onClick={() => this.onClickSave()} variant={'outline-primary'}>Guardar</Button>
                        <Button onClick={() => this.onClickSave('audience')} className={'ml-1'}>Guardar y
                            continuar</Button>
                    </Col>
                </Row>
            </>

        )
    }
    onCouponAudienceCancel(){
        this.setStep('setup');
    }
    onCouponAudienceSave(data){
        const coupon = {...this.state.coupon};
        coupon.audience = data.audience;
        coupon.distributionMethod = data.distributionMethod;
        coupon.userSegments = data.userSegments;
        this.setState({coupon:coupon}, ()=>{
            this.saveCoupon(coupon._id,'summary');
        })
    }
    renderContent() {
        switch (this.state.step) {
            case 'setup':
                return this.renderSetup();
                break;
            case 'audience':
                return <CouponAudience coupon={this.state.coupon}
                                       onCancel={() => this.onCouponAudienceCancel()}
                                       onSave={(data) => this.onCouponAudienceSave(data)}
                ></CouponAudience>
                break;
            case 'summary':
                return <CouponSummary
                        coupon={this.state.coupon} onClickCancel={() => this.onCouponAudienceCancel()}
                        onDeactivated={(coupon) => this.onCouponPublished(coupon)}
                        onInfluencerLinkCreated={(coupon) => this.onInfluencerLinkCreated(coupon)}
                        onPublished={(coupon) => this.onCouponPublished(coupon)}>
                        </CouponSummary>
                break;
            default:
                return null;
        }
    }

    render() {
        const couponId  = this.props.match.params.id;
        const coupon    = this.state.coupon ? this.state.coupon : {};
        const isEditing = this.state.isEditingCoupon;

        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3'>
                        <NPIf condition={couponId === 'create'}>
                            <h5 className={'text-capitalize '}>Crear cupón</h5>
                            <NPElse>
                                <h5 className={'text-capitalize '}>Cupón: <span
                                    className={'text-black-50'}> {coupon.code}</span></h5>
                            </NPElse>
                        </NPIf>
                    </Col>
                </Row>

                {this.renderContent()}

            </Container>
        )
    }

    onCouponPublished(coupon) {
        this.loadCoupon(coupon._id);
        this.props.history.push(`/coupons/${coupon._id}`);
    }
    onInfluencerLinkCreated(coupon){
        this.loadCoupon(coupon._id);
        this.props.history.push(`/coupons/${coupon._id}`);
    }
}

export default withRouter(Coupon);

const isEmpty = (v) => {
    if (v) {
        return v.trim() === '';
    } else {
        return true;
    }
}

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
