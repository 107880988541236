/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import NbioApi from "../../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/cjs/Badge";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Nav from 'react-bootstrap/Nav';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import WSSClient from "../../../lib/wss/wss-client";
import BootstrapTable from "react-bootstrap-table-next";
import dayjs from "dayjs";


//components


class ImportingFile extends React.Component {
    constructor(p) {
        super(p);
        this.state            = {
            file: null,
            step: 'IMPORTING', //IMPORTING, IMPORTED
            validateProgress:0,
            currentRow:0,
            rowCount:0,
            bulkImport:{}
        }
        this.wssOnMessage                  = this.wssOnMessage.bind(this);
        this.connectWSS                    = this.connectWSS.bind(this);
    }
    componentDidMount() {
        this.connectWSS();
    }
    getBulkImport(){
        NbioApi.bulkImports.getBulkImport(this.props.bulkImport._id).then((res) =>{
            this.setState({
                bulkImport:res.data.bulkImport,
                step:'IMPORTED'
            })
        }).catch((ex) =>{

        })
    }
    wssOnMessage(event) {
        let jsonEvent = {}
        try {
            jsonEvent = JSON.parse(event.data);
        } catch (e) {

        }
        switch (jsonEvent.nbioEvent) {
            case WSSClient.NBIO_EVENTS.BULK_IMPORT_PROGRESS:
                // console.log(jsonEvent.data)
                this.setState({
                    currentRow:jsonEvent.data.row,
                    rowCount:jsonEvent.data.rowsCount,
                    validateProgress: jsonEvent.data.row * 100 / jsonEvent.data.rowsCount
                })
                break;
            case WSSClient.NBIO_EVENTS.BULK_IMPORT_FINISHED:
                this.getBulkImport();
                break;
            default:
                return -1;
        }
    }
    connectWSS() {
        const _this = this;
        console.log('connect')
        WSSClient.startListening().then((socket) => {
            socket.addEventListener('message', this.wssOnMessage);
            socket.addEventListener('close', () => {
                socket.removeEventListener('message', this.wssOnMessage);
                setTimeout(() => {
                    _this.connectWSS();
                }, 3000)
            })
        }).catch((ex) => {
            setTimeout(() => {
                _this.connectWSS();
            }, 3000)
        })
    }
    renderStep = () => {
        switch (this.state.step) {
            case 'IMPORTING':
                return this.renderValidatingProgress();
            case 'IMPORTED':
                return this.renderValidatedResult();
            default:
                return null
        }
    }

    renderValidatingProgress = () => {
        const label  = this.state.currentRow === 0 ? 'Inicializando...' : `${this.state.validateProgress.toFixed(2)}%`;
        return(
            <div>
                {this.state.currentRow === 0 ? 'Inicializando...' : ''}
                <ProgressBar now={this.state.validateProgress} label={label}/>
            </div>
        )
    }

    renderValidatedResult = () => {
        if(this.state.bulkImport.status === 'finished'){
            return(
                <div>
                    <b className={'text-success'}>
                        Importación completada
                    </b>
                    {
                        this.renderResultsTable()
                    }
                </div>
            )
        }
    }
    renderResultsTable    = () =>{

        const columns = [
            {
                dataField:'row',
                text:'Fila',
                formatter:(value) =>{
                    return value
                }
            },
            {
                dataField:'sku',
                text:'SKU',
                formatter:(value) =>{
                    return value
                }
            },
            {
                dataField:'success',
                text:'Resultado',
                formatter:(success,row) =>{
                    if(success){
                        if(!row.isNew){
                            return <div className={'text-success'}>Actualizado</div>
                        }else{
                            return <div className={'text-success'}>Creado</div>

                        }
                    }else{
                        return <div className={'text-danger'}>Error</div>
                    }
                }
            }
        ]
        const importResults =this.state.bulkImport.importResults;
        return(
            <BootstrapTable keyField='_id'
                            classes={'mt-3'}
                            data={importResults}
                            columns={columns}
                            striped={true}
                            noDataIndication={`No se encontraron productos`}
                            bordered={false}
            />
        )
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title className='d-flex align-items-center justify-content-between'>
                        <span>Importando productos</span>
                    </Card.Title>
                    <div className={'py-3'}>
                        {this.renderStep()}
                    </div>
                </Card.Body>
                <Card.Footer className={'d-flex '}>
                    <NPIf condition={this.state.bulkImport.status === 'finished'}>
                        <Button size="sm"
                                className={'text-uppercase ml-auto'}
                                onClick={() =>{
                                    this.props.nextStep()
                                }}>
                            SUBIR IMAGENES
                        </Button>

                    </NPIf>
                </Card.Footer>
            </Card>
        )
    }
}

export default withRouter(ImportingFile);


ImportingFile.propTypes = {
    nextStep: PropTypes.func,
    bulkImport: PropTypes.object,
    onError:PropTypes.func
}

ImportingFile.defaultProps = {
    nextStep: () => {
    },
    bulkImport:{},
    onError: () =>{}
}
