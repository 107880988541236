import React from "react";
import PropTypes from 'prop-types';
import API from "../../../lib/api/NbioApi";
import Tooltip from "react-bootstrap/Tooltip";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup"
import {Badge} from "react-bootstrap";
import Button from "react-bootstrap/cjs/Button";


class DevicesPicker extends React.Component {
    MAX_DEVICES_SELECT = 1;
    constructor() {
        super();
        this.state      = {
            devices: [],
            selectedDevices: []
        }
        this.loadUsers    = this.loadUsers.bind(this);
        this.removeDevice = this.removeDevice.bind(this);

    }

    componentDidMount() {
        this.setState({
            selectedDevices:this.props.selectedDevices
        })
        this.loadUsers();
    }
    loadUsers() {
        API.devices.getDevices(1,200).then((res) => {
            this.setState({devices: res.data.dataDevices})
        }).then((res) => {

        })
    }
    onDeviceSelected(device) {
        if (this.state.selectedDevices.some((u) => u._id === device._id)) {
            // do nothing
        } else {
            // push
            const newSelected = [...this.state.selectedDevices];
            newSelected.push(device);
            this.setState({selectedDevices: newSelected}, () =>{
                this.props.onChange(this.state.selectedDevices);
            });
        }
    }

    removeDevice(user) {
        let newSelected = [...this.state.selectedDevices];
        newSelected     = newSelected.filter((u) => u._id !== user._id)
        this.setState({selectedDevices: newSelected}, () =>{
            this.props.onChange(this.state.selectedDevices);
        });
    }

    render() {
        return (
            <div>
                {/*  SELECTED USERS AREA  */}
                <DropdownButton as={ButtonGroup}
                                variant="outline-primary"
                                className={'text-uppercase'}
                                title={'AGREGAR DISPOSITIVO'}>
                    {
                        this.state.devices.map(device => {
                            return (
                                <Dropdown.Item key={device._id}
                                               disabled={
                                                   this.state.selectedDevices.length === this.MAX_DEVICES_SELECT
                                               }
                                               className={'text-capitalize'}
                                               onClick={() => this.onDeviceSelected(device)}>
                                    {device.name} ( {device.id} )
                                </Dropdown.Item>
                            )
                        })
                    }
                </DropdownButton>
                <ListGroup className={'mt-2'}>
                    {
                        this.state.selectedDevices.map((device) => {
                            return (
                                <ListGroup.Item className={'d-flex justify-content-between'}>
                                    {device.name} ( {device.id} )
                                    <Button size={"sm"} variant={'link'} onClick={() => this.removeDevice(device)}>Remover</Button>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </div>

        )
    }

}


DevicesPicker.defaultProps = {
    /** User object**/
    selectedDevices: [],
    /** Called when the user changes the selected users on the component**/
    onChange: (devices) => {
    },
}
DevicesPicker.propTypes    = {
    selectedDevices: PropTypes.array,
    onChange: PropTypes.func
}

export default DevicesPicker;
