/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import NbioApi from "../../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import NPIf from "np-if";
import ProgressBar from "react-bootstrap/ProgressBar";
import WSSClient from "../../../lib/wss/wss-client";

//components


class UploadImages extends React.Component {
    constructor(p) {
        super(p);
        this.state            = {
            file: null,
            step: 'UPLOAD', //IMPORTING, IMPORTED
            validateProgress:0,
            currentRow:0,
            rowCount:0,
            bulkImport:{},
            imagesSelected:false
        }
        this.wssOnMessage                  = this.wssOnMessage.bind(this);
        this.connectWSS                    = this.connectWSS.bind(this);
        this.onUploadProgress              = this.onUploadProgress.bind(this);

    }

    componentDidMount() {
        this.connectWSS();
    }

    onUploadProgress = (ev) => {
        const progress = ev.loaded / ev.total * 100;
        this.setState({
            uploadProgress: progress
        })
    }

    wssOnMessage(event) {
        let jsonEvent = {}
        try {
            jsonEvent = JSON.parse(event.data);
        } catch (e) {

        }
        switch (jsonEvent.nbioEvent) {
            case WSSClient.NBIO_EVENTS.BULK_IMAGE_UPLOAD_PROGRESS:
                // console.log(jsonEvent.data)
                this.setState({
                    currentRow:jsonEvent.data.row,
                    rowCount:jsonEvent.data.rowsCount,
                    savingProgress: jsonEvent.data.row * 100 / jsonEvent.data.rowsCount
                })
                break;
            case WSSClient.NBIO_EVENTS.BULK_IMAGE_UPLOAD_FINISHED:
                this.setState({step:'FINISHED'})
                break;
            default:
                return -1;
        }
    }

    connectWSS() {
        const _this = this;
        WSSClient.startListening().then((socket) => {
            socket.addEventListener('message', this.wssOnMessage);
            socket.addEventListener('close', () => {
                socket.removeEventListener('message', this.wssOnMessage);
                setTimeout(() => {
                    _this.connectWSS();
                }, 3000)
            })
        }).catch((ex) => {
            setTimeout(() => {
                _this.connectWSS();
            }, 3000)
        })
    }

    renderStep = () => {
        switch (this.state.step) {
            case 'UPLOAD':
                return this.renderUploadArea();
            case 'UPLOADING':
                return this.renderUploadProgress();
            case 'SAVING':
                return this.renderSavingProgress();
            case 'FINISHED':
                return this.renderFinished();
            default:
                return null
        }
    }

    onImagesSelected(e){
        const files = e.target.files;
        const validImages = [];
        for(let f=0;f<files.length;f++){
            if(files[f].type === 'image/jpg' || files[f].type === 'image/jpeg'){
                validImages.push(files[f]);
            }
        }
        this.setState({images:validImages,imagesSelected:validImages.length > 0})
    }

    onUploadImages(){
        this.setState({step: 'UPLOADING'}, () => {
            NbioApi.products.uploadImagesBulk(this.state.images,this.onUploadProgress).then((res) => {
                // this.props.nextStep(res.data);
            }).catch((ex) => {

            })
        });

    }

    renderUploadArea = () => {
        return (
            <div>
                <Form.Group controlId="file">
                    <Form.Label></Form.Label>
                    <Form.Control type="file"
                                  id="file"
                                  multiple={'multiple'}
                                  className={'border-0'}
                                  accept=".jpg,.jpeg"
                                  style={{height: 'auto'}}
                                  onChange={(e) => this.onImagesSelected(e)}
                    />
                </Form.Group>
            </div>
        )
    }

    renderUploadProgress = () => {
            return(
                <div>
                    <ProgressBar now={this.state.uploadProgress} label={`${this.state.uploadProgress}%`}/>
                </div>
            )
    }

    renderSavingProgress = () => {
        return(
            <div>
                Guardando
                <ProgressBar now={this.state.savingProgress} label={`${this.state.savingProgress}%`}/>
            </div>
        )
    }

    renderFinished =() =>{
        return(
            <div>
                <div>
                    <b>
                        Imagenes guardadas
                    </b>
                </div>
                <a href={'/products'}>Ver productos</a>
            </div>
        )
    }
    render() {
        return (
            <Card>
                <Card.Body>
                    <Card.Title className='d-flex align-items-center justify-content-between'>
                        <span>Subir Imágenes</span>
                    </Card.Title>
                    <span><small>La imágenes deben de ser de 512x512 pixeles y en formato .jpeg o .jpg</small></span>

                    <div className={'py-3'}>
                        {this.renderStep()}
                    </div>
                </Card.Body>
                <Card.Footer className={'d-flex '}>
                    <NPIf condition={this.state.imagesSelected && this.state.step === 'UPLOAD'}>
                        <Button size="sm"
                                className={'text-uppercase ml-auto'}
                                onClick={() =>{
                                    this.onUploadImages()
                                }}>
                            SUBIR IMÁGENES
                        </Button>
                    </NPIf>
                </Card.Footer>
            </Card>
        )
    }
}

export default withRouter(UploadImages);


UploadImages.propTypes = {
    nextStep: PropTypes.func,
    bulkImport: PropTypes.object,
    onError:PropTypes.func
}

UploadImages.defaultProps = {
    nextStep: () => {
    },
    bulkImport:{},
    onError: () =>{}
}
