import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import Card from "react-bootstrap/cjs/Card";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from "react-bootstrap/Spinner";
import {money} from "../../lib/money/money";
import Col from "react-bootstrap/Col";

/**
 * Display the discounts applied to order
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */


class OrderParcels extends React.Component {
    render() {
        const columns = [
            {
                dataField: 'parcelName',
                text: 'Paquetería',
                formatter:(v,row) =>{
                    return(
                        <div>
                            {v}
                        </div>
                    )
                }
            },
            {
                dataField: 'parcelNumber',
                text: 'Numero de guía',
                formatter:(v,row) =>{
                    return(
                        <div>
                            {v}
                        </div>
                    )
                }
            },
            {
                dataField: 'parcelTrackingLink',
                text: 'Enlace para tracking',
                formatter:(v,row) =>{
                    return(
                        <a href={v} target={'_blank'}>
                            {v}
                        </a>
                    )
                }
            },

        ];
        return (
            <Col>
                <NPIf condition={this.props.isLoading}>
                    <Spinner animation={'grow'} variant={'primary'}>
                    </Spinner>
                    <NPElse>
                        <BootstrapTable keyField='_id'
                                        data={this.props.parcels}
                                        columns={columns}
                                        noDataIndication={`No se han agregado números de guía`}
                                        bordered={false}/>
                    </NPElse>
                </NPIf>
            </Col>
        )
    }
}
export default withRouter(OrderParcels);
OrderParcels.propTypes    = {
    parcels: PropTypes.array,
    isLoading: PropTypes.bool
}
OrderParcels.defaultProps = {
    parcels: [],
    isLoading: false
}

