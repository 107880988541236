import React from 'react';
import {AuthContext} from "../../lib/context/AuthContext";
import {arrayOf, string} from "prop-types";

const _ = require('underscore');
class SecurityGuard extends React.Component{
    static contextType = AuthContext;
    checkInterval = null;
    componentDidMount() {
        this.checkInterval = setInterval(() =>{
            if(this.context._id !== ''){
                const intersection = _.intersection(this.context.user.roles,this.props.allowedRoles);
                if(intersection.length === 0){
                    clearInterval(this.checkInterval);
                    window.location = '/';
                }else{
                }
            }
        },5000)
    }
    componentWillUnmount(){
        clearInterval(this.checkInterval);
    }

    render() {
        return(<></>)
    }
}
export default SecurityGuard;
SecurityGuard.defaultProps = {
    allowedRoles: ['admin']
}
SecurityGuard.propTypes = {
    allowedRoles: arrayOf(string)
}
