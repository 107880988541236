/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from 'react';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

//style
import "./scss/app.scss";

//components
import LoginView from "./views/LoginView";
import DashboardView from "./views/DashboardView";
//context
import {AuthContext} from "./lib/context/AuthContext";
import NbioApi from "./lib/api/NbioApi";
import ValidateAmountView from "./views/ValidateAmountView";
import ViewTicketsView from "./views/ViewTicketsView";
import InfluencerCouponView from "./components/coupons/InfluencerCouponView";
// init icons
library.add(fab, far, fas);

class App extends React.Component {
    constructor(props) {
        super(props);

        const login  = (user) => {
            this.setState({isLoggedIn: true, user: user});
        }
        const logout = () => {
            const user = {
                name: '',
                last_name: '',
                email: '',
                roles: [],
                uid: '',
                _id: '',
                is_disabled: false
            }
            this.setState({isLoggedIn: false, user: user});
        }

        this.state = {
            isLoggedIn: false,
            loginChecked: false,
            isCheckingLogin:false,
            user: {
                name: '',
                last_name: '',
                email: '',
                roles: [],
                uid: '',
                _id: '',
                is_disabled: false
            },
            login: login,
            logout: logout
        }
    }
    componentDidMount() {
        this.setState({isCheckingLogin:true});
        NbioApi.users.getMe().then(res => {
            this.setState({
                isLoggedIn: true,
                loginChecked:true,
                user: res.data.user,
                isCheckingLogin:false
            });
        }).catch(error => {
            const response = error.response || {};
            const data = response.data || {};
            if(data.error_code === 'INVALID_AUTH'){
                // do not redirect influencers
                if(window.location.href.includes('/influencers')){

                }
                // redirect
                else if(!window.location.href.includes('/login')){
                    window.location = '/';
                }
            }
            this.setState({loginChecked:true,isLoggedIn:false,isCheckingLogin:false})
        })
    }
    render() {
        return (
            <AuthContext.Provider value={this.state}>
                <Router>
                    <Switch>
                        <Route exact path='/'>
                            <Redirect to={"/login"}></Redirect>
                        </Route>
                        <Route path='/login'>
                            <LoginView user={this.state.user}></LoginView>
                        </Route>
                        <Route path='/orders/:id/validate-amount'>
                            <ValidateAmountView></ValidateAmountView>
                        </Route>
                        <Route path='/orders/:id/view-tickets'>
                            <ViewTicketsView></ViewTicketsView>
                        </Route>
                        <Route path={'/influencers/coupons/:id/:code/'}>
                            <InfluencerCouponView></InfluencerCouponView>
                        </Route>
                        <Route path='/:id'>
                            <DashboardView user={this.state.user}></DashboardView>
                        </Route>
                    </Switch>
                </Router>
            </AuthContext.Provider>
        )

    }
}

export default App;
