/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import {StaticMap,Marker} from 'react-map-gl';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//STYLE
import 'mapbox-gl/dist/mapbox-gl.css';

//MAPBOX TOKEN
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZWFwZXJlenYiLCJhIjoiY2w0ZzIzdWduMDJuMDNjczF5bTA2Y2M4MSJ9.Iyqpdv6RE0BeqkesEq7IXw';

// const MAPBOX_TOKEN = 'pk.eyJ1IjoicGFuemVyIiwiYSI6ImNrN2RsZzcwMDIxMHAzZW9naWh1amhkejIifQ.2MkaNWU65XhWigJvQUm1_A';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


/**
 * Static map
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */


class StaticMapDashboard extends React.Component {

    render() {
        return (
            <StaticMap
                mapStyle="mapbox://styles/eaperezv/cl4hbzpj9001s15nywg1tdutg"
                mapboxApiAccessToken={MAPBOX_TOKEN}
                width={this.props.width}
                height={this.props.height}
                latitude={this.props.latitude}
                longitude={this.props.longitude}
                zoom={this.props.zoom} >

                <Marker longitude={this.props.longitude} latitude={this.props.latitude} offsetTop={-18}>
                    <FontAwesomeIcon icon={'map-marker-alt'} color={'#F32735'}></FontAwesomeIcon>
                </Marker>
            </StaticMap>
        )
    }
}


StaticMapDashboard.propTypes    = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
    zoom: PropTypes.number
}
StaticMapDashboard.defaultProps = {
    latitude: 0,
    longitude: 0,
    width: '100%',
    height: '100%',
    zoom: 8,
}
export default withRouter(StaticMapDashboard);

