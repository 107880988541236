/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/cjs/Card";
import Button from "react-bootstrap/Button";
import Bell from "../bell/Bell";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import API from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
const dayjs = require('dayjs');


class Settings extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            activeKey: 'notifications',
            userRoles: [],
            paymentMethods: [],
            fbCatalogs: [],
            gmCatalogs: [],
            isLoadingFBCatalog:false,
            isRequestingFBCatalog:false,
            isLoadingGMCatalog:false,
            isRequestingGMCatalog:false,
            skusToBlacklist:''
        }
        this.bell = null;
        this.catalogsInterval = null;
    }

    componentDidMount() {
        API.users.getMe().then((res) => {
            this.setState({userRoles: res.data.user.roles});
        }).catch((ex) => {
        })
        this.getPaymentMethods();
        this.getFBCatalogs();
        this.getGMCatalogs();
        this.catalogsInterval = setInterval(() =>{
            this.getFBCatalogs();
            this.getGMCatalogs();
        },3000)
    }
    componentWillUnmount() {
        clearInterval(this.catalogsInterval);
    }

    getPaymentMethods() {
        API.admin.getPaymentMethods().then((res) => {
            console.log('res', res);
            this.setState({paymentMethods: res.data.paymentMethods || []})
        }).catch((ex) => {

        });
    }

    renderTabs = () => {
        const roles = this.state.userRoles;
        if (roles.includes('super_admin')) {
            return this.renderAdminTabs();
        } else if (roles.includes('admin') || roles.includes('region_admin')) {
            return this.renderRegionAdminTabs();
        } else {
            return null;
        }
    }

    renderRegionAdminTabs = () => {
        return (
            <Tabs defaultActiveKey="notifications"
                  activeKey={this.state.activeKey}
                  onSelect={(ev) => {
                      this.setState({activeKey: ev})
                  }}>
                <Tab eventKey="notifications" title="Notificaciones"
                     onClick={() => this.setState({activeKey: 'notifications'})}>
                    <Card.Body className={'border border-top-0 region-tab-content'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Sonido</Form.Label>
                            <Col sm={10}>
                                <Button onClick={() => this.bell.test()}>Probar sonido</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Tab>
            </Tabs>
        )
    }
    getFBCatalogs = () => {
        this.setState({
            isLoadingFBCatalog: true
        })
        API.admin.getFBCatalogs().then((res) => {
            this.setState({
                fbCatalogs: res.data.fbCatalogs,
                isLoadingFBCatalog: false
            })
        }).catch((ex) => {
            this.setState({
                isLoadingFBCatalog:false
            })
        });
    }
    getGMCatalogs = () => {
        this.setState({
            isLoadingGMCatalog: true
        })
        API.admin.getGMCatalogs().then((res) => {
            this.setState({
                gmCatalogs: res.data.gmCatalogs,
                isLoadingGMCatalog: false
            })
        }).catch((ex) => {
            this.setState({
                isLoadingGMCatalog:false
            })
        });
    }
    exportFBCatalog = () => {
        this.setState({
            isRequestingFBCatalog:true
        });
        API.admin.exportFBCatalog().then((res) => {
            this.setState({
                isRequestingFBCatalog:false
            });
            Swal.fire({
                icon: 'success',
                title: 'Se solicitó exportar los datos a Facebook.',
                text: 'Dependiendo de la configuración de Facebook, los cambios pueden tardar horas en verse reflejados.',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            });
            this.getFBCatalogs();
        }).catch((ex) => {
            this.setState({
                isRequestingFBCatalog:false
            });
            Swal.fire({
                icon: 'error',
                title: 'No se pudo solicitar la exportación de datos Facebook.',
                heightAuto: false
            }).then((res) => {
            });
        });
    }
    exportGMCatalog = () => {
        this.setState({
            isRequestingGMCatalog:true
        });
        API.admin.exportGMCatalog().then((res) => {
            this.setState({
                isRequestingGMCatalog:false
            });
            Swal.fire({
                icon: 'success',
                title: 'Se solicitó exportar los datos a Google Merchant.',
                text: 'Dependiendo de la configuración de Google Merchant, los cambios pueden tardar horas en verse reflejados.',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            });
            this.getGMCatalogs();
        }).catch((ex) => {
            this.setState({
                isRequestingGMCatalog:false
            });
            Swal.fire({
                icon: 'error',
                title: 'No se pudo solicitar la exportación de datos a Google Merchant.',
                heightAuto: false
            }).then((res) => {
            });
        });
    }
    generateSiteMap = () => {
        this.setState({
            isRequestingSitemap:true
        });
        API.admin.generateSitemap().then((res) => {
            this.setState({
                isRequestingSitemap:false
            });
            Swal.fire({
                icon: 'success',
                title: 'Se solicitó generar el sitemap',
                text: 'Puede tardar unos minutos en verse reflejado',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            });
        }).catch((ex) => {
            this.setState({
                isRequestingSitemap:false
            });
            Swal.fire({
                icon: 'error',
                title: 'No se pudo solicitar generar el sitemap',
                heightAuto: false
            }).then((res) => {
            });
        });
    }
    renderFBCatalogsTable = () => {
        const columns = [
            {
                dataField: 'created_at',
                text:'Fecha',
                formatter: (value) =>{
                    return dayjs(value).format('YYYY/MM/DD - HH:mm:ss');
                }
            },
            {
                dataField: 'createdBy',
                text: 'Iniciado por:',
                formatter: (value) => {
                    if (value) {
                        return `${value?.name} ${value?.last_name} (${value?.email})`;
                    } else {
                        return 'Sistema'
                    }
                }
            },
            {
                dataField: 'status',
                text: 'Estado',
                formatter: (value) => {
                    if (value === 'completed') {
                        return 'Completado ✅'

                    } else if (value === 'processing') {
                        return 'Procesando ⌛';
                    } else if (value === 'failed') {
                        return 'Fallido ❌';
                    }else{
                        return value;
                    }
                }
            }
        ];
        return (<BootstrapTable keyField='_id'
                                classes={'mt-3'}
                                data={this.state.fbCatalogs}
                                columns={columns}
                                striped={true}
                                noDataIndication={`No se encontraron registros`}
                                bordered={false}
        />)
    }
    renderGMCatalogsTable = () => {
        const columns = [
            {
                dataField: 'created_at',
                text:'Fecha',
                formatter: (value) =>{
                    return dayjs(value).format('YYYY/MM/DD - HH:mm:ss');
                }
            },
            {
                dataField: 'createdBy',
                text: 'Iniciado por:',
                formatter: (value) => {
                    if (value) {
                        return `${value?.name} ${value?.last_name} (${value?.email})`;
                    } else {
                        return 'Sistema'
                    }
                }
            },
            {
                dataField: 'status',
                text: 'Estado',
                formatter: (value) => {
                    if (value === 'completed') {
                        return 'Completado ✅'

                    } else if (value === 'processing') {
                        return 'Procesando ⌛';
                    } else if (value === 'failed') {
                        return 'Fallido ❌';
                    }else{
                        return value;
                    }
                }
            }
        ];
        return (<BootstrapTable keyField='_id'
                                classes={'mt-3'}
                                data={this.state.gmCatalogs}
                                columns={columns}
                                striped={true}
                                noDataIndication={`No se encontraron registros`}
                                bordered={false}
        />)
    }
    renderAdminTabs = () => {
        return (
            <Tabs defaultActiveKey="notifications" activeKey={this.state.activeKey}
                  onSelect={(ev) => {
                      this.setState({activeKey: ev})
                  }}>
                <Tab eventKey="notifications" title="Notificaciones"
                     onClick={() => this.setState({activeKey: 'notifications'})}>
                    <Card.Body className={'border border-top-0 region-tab-content p-2'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Sonido</Form.Label>
                            <Col sm={10}>
                                <Button onClick={() => this.bell.test()}>Probar sonido</Button>

                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Tab>
                <Tab eventKey="analytics" title="Analytics" onClick={() => this.setState({activeKey: 'analytics'})}>
                    <Card.Body className={'border border-top-0 region-tab-content p-2'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Segmentación</Form.Label>
                            <Col sm={10}>
                                <Button onClick={() => this.processSegments()}>Segmentar datos</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Tab>
                <Tab eventKey="categories" title="Categorías" onClick={() => this.setState({activeKey: 'categories'})}>
                    <Card.Body className={'border border-top-0 region-tab-content p-2'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Categorías</Form.Label>
                            <Col sm={10}>
                                <Button onClick={() => this.regenerateCategories()}>Recrear categorías</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Tab>
                <Tab eventKey="keywords" title="Sugerencias" onClick={() => this.setState({activeKey: 'keywords'})}>
                    <Card.Body className={'border border-top-0 region-tab-content p-2'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Sugerencias de búsqueda</Form.Label>
                            <Col sm={10}>
                                <Button onClick={() => this.regenerateKeywords()}>Recrear sugerencias de
                                    búsqueda</Button>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Tab>
                <Tab eventKey="paymentMethods" title="Métodos de pago"
                     onClick={() => this.setState({activeKey: 'paymentMethods'})}>
                    <Card.Body className={'border border-top-0 region-tab-content p-2'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Métodos de pago</Form.Label>
                            <Col sm={10}>
                                {
                                    this.state.paymentMethods.map((pm) => {
                                        return (
                                            <Form.Group as={Row} className="mt-4 mb-3" controlId={pm.code} key={pm._id}>
                                                <Col sm={12}>
                                                    <Form.Check
                                                        type="switch"
                                                        id={pm.code}
                                                        label={pm.name}
                                                        checked={pm.isActive}
                                                        onChange={event => this.onChangePaymentMethod(pm, event)}/>
                                                </Col>
                                            </Form.Group>
                                        )
                                    })
                                }
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Tab>
                <Tab eventKey="facebookPixel" title="Facebook Pixel"
                     onClick={() => this.setState({activeKey: 'facebookPixel'})}>
                    <Card.Body className={'border border-top-0 region-tab-content p-2'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Catálogo de Facebook Pixel</Form.Label>
                            <Col sm={10}>
                                {
                                    !this.state.isRequestingFBCatalog ?
                                        <Button onClick={() => this.exportFBCatalog()}>Exportar catálogo</Button>
                                        : <Spinner animation={'grow'} variant={'secondary'}></Spinner>
                                }

                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Enlace para Sincronizar con Facebook:</Form.Label>
                            <Col sm={10}>
                                <FontAwesomeIcon icon={"link"}/>
                                &nbsp;
                                <a href={'https://nbio.blob.core.windows.net/fb-catalog/nbio-fb-catalog.csv'} target={'_blank'}>
                                    https://nbio.blob.core.windows.net/fb-catalog/nbio-fb-catalog.csv
                                </a>
                            </Col>
                        </Form.Group>
                        <Card.Title className={'mt-5'}>
                            Registro de exportaciones
                            {
                                this.state.isLoadingFBCatalog ?
                                    <Spinner animation={'grow'} variant={'secondary'} size={'sm'}></Spinner>
                                : null
                            }
                        </Card.Title>
                        {this.renderFBCatalogsTable()}
                    </Card.Body>
                </Tab>
                <Tab eventKey="googleMerchant" title="Google Merchant"
                     onClick={() => this.setState({activeKey: 'googleMerchant'})}>
                    <Card.Body className={'border border-top-0 region-tab-content p-2'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Catálogo de Google Merchant</Form.Label>
                            <Col sm={10}>
                                {
                                    !this.state.isRequestingGMCatalog ?
                                        <Button onClick={() => this.exportGMCatalog()}>Exportar catálogo</Button>
                                        : <Spinner animation={'grow'} variant={'secondary'}></Spinner>
                                }

                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Enlace para Sincronizar con Google Merchant:</Form.Label>
                            <Col sm={10}>
                                <FontAwesomeIcon icon={"link"}/>
                                &nbsp;
                                <a href={'https://nbio.blob.core.windows.net/gm-catalog/nbio-gm-catalog.txt'} target={'_blank'}>
                                    https://nbio.blob.core.windows.net/gm-catalog/nbio-gm-catalog.txt
                                </a>
                            </Col>
                        </Form.Group>
                        <Card.Title className={'mt-5'}>
                            Registro de exportaciones
                            {
                                this.state.isLoadingGMCatalog ?
                                    <Spinner animation={'grow'} variant={'secondary'} size={'sm'}></Spinner>
                                    : null
                            }
                        </Card.Title>
                        {this.renderGMCatalogsTable()}
                        <Card.Title className={'mt-5'}>
                            Blacklist
                        </Card.Title>
                        <Card.Subtitle  className={'mt-1 font-weight-normal'}>
                            Google restringe la promoción de contenido relacionado con la atención sanitaria, tal como el siguiente:
                                <ul >
                                    <li>
                                        Medicamentos sin receta
                                    </li>
                                    <li>
                                        Medicamentos con receta
                                    </li>
                                    <li>
                                        Fármacos y suplementos no aprobados
                                    </li>
                                    <li>
                                        Productos relacionados con la fertilidad y el embarazo
                                    </li>
                                </ul>
                            Para más información sobre este tema consulta las&nbsp;
                            <a href={'https://support.google.com/merchants/answer/6149970?hl=es'}
                               target={'_blank'}>Políticas de anuncios de shopping</a>.
                            <br/>
                            Utiliza la blacklist para marcar que productos no deben de ser exportados a Google Merchant.
                        </Card.Subtitle>
                        <Form.Control type={'input'} value={this.state.skusToBlacklist}
                                      onChange={
                                          (ev) => {
                                              this.setState({skusToBlacklist: ev.target.value})
                                          }}
                                      placeholder={'Ingresa tus skus separados por coma'}
                        >
                        </Form.Control>
                        {
                            this.state.isAddingToBlacklist ?
                                <Spinner animation={'grow'} variant={'secondary'}></Spinner>
                                :
                                <Button onClick={() => this.addSKUToBlacklist()} size={'sm'} className={'mt-2'}>Agregar a la blacklist</Button>
                        }
                    </Card.Body>
                </Tab>
                <Tab eventKey="seo" title="SEO"
                     onClick={() => this.setState({activeKey: 'seo'})}>
                    <Card.Body className={'border border-top-0 region-tab-content p-2'}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>Generar Sitemap</Form.Label>
                            <Col sm={10}>
                                {
                                    !this.state.isRequestingSitemap ?
                                        <Button onClick={() => this.generateSiteMap()}>Generar sitemap</Button>
                                        : <Spinner animation={'grow'} variant={'secondary'}></Spinner>
                                }

                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm={2}>
                                Enlace de sitemap.xml</Form.Label>
                            <Col sm={10}>
                                <FontAwesomeIcon icon={"link"}/>
                                &nbsp;
                                <a href={'https://www.nbio.mx/sitemap.xml'} target={'_blank'}>
                                    https://www.nbio.mx/sitemap.xml
                                </a>
                            </Col>
                        </Form.Group>
                    </Card.Body>
                </Tab>
            </Tabs>
        )
    }

    render() {
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Bell muted={false} ref={(ref) => this.bell = ref}></Bell>
                <Row>
                    <Col className='py-3'>
                        <h5 className={'text-capitalize '}>Opciones</h5>
                    </Col>
                </Row>
                <Row className={'mb-5'}>
                    <Col className='py-3' sm={12}>
                        {this.renderTabs()}
                    </Col>
                </Row>
            </Container>
        )
    }

    processSegments() {
        API.admin.processSegments().then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'Se solicitó segmentar los datos',
                text: 'Puede tardar unos minutos en verse reflejado en la aplicación',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            });
        }).catch((ex) => {
            Swal.fire({
                icon: 'error',
                title: 'No se pudo solicitar la segmentación de datos',
                heightAuto: false
            }).then((res) => {
            });
        });
    }

    regenerateCategories() {
        API.admin.regenerateCategories().then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'Se solicitó recrear las categorías',
                text: 'Puede tardar unos minutos en verse reflejado en la aplicación',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            });
        }).catch((ex) => {
            Swal.fire({
                icon: 'error',
                title: 'No se pudo solicitar recrear las categorías',
                heightAuto: false
            }).then((res) => {
            });
        });
    }

    regenerateKeywords() {
        API.admin.regenerateKeywords().then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'Se solicitó recrear las sugerencias de búsqueda',
                text: 'Puede tardar unos minutos en verse reflejado en la aplicación',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            });
        }).catch((ex) => {
            Swal.fire({
                icon: 'error',
                title: 'No se pudo solicitar recrear las sugerencias de búsqueda',
                heightAuto: false
            }).then((res) => {
            });
        });
    }

    onChangePaymentMethod(pm, event) {
        const checked = event.target.checked;
        API.admin.setPaymentMethodActive(pm._id, {isActive: checked}).then((res) => {
            this.getPaymentMethods();
        }).catch((ex) => {

        });
    }

    addSKUToBlacklist() {
        this.setState({isAddingToBlacklist:true});
        const skus = this.state.skusToBlacklist.split(',').map((sku) => sku.trim());
        API.admin.addToBlacklist(skus).then((res) => {
            this.getPaymentMethods();
            Swal.fire({
                icon: 'success',
                title: 'Blacklist actualizada.',
                text: 'Los cambios pueden tardar en ser propagados a Google Merchant.',
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            });
        }).catch((ex) => {
            Swal.fire({
                icon: 'error',
                title: 'No se pudo agregar a la blacklist.',
                heightAuto: false
            }).then((res) => {
            });
        }).finally(() =>{
            this.setState({isAddingToBlacklist:false});
        })
    }
}

export default withRouter(Settings);

