/* Copyright (C) Nodeport SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by América Mendoza <amendoza@nodeport.co> &
 * Darien Miranda <dmiranda@nodeport.co>
 * February 2022
 */

import React from 'react';

//style
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import WSSClient from "../../lib/wss/wss-client";
import {bool} from "prop-types";

//components

class Bell extends React.Component {
    constructor(p) {
        super(p);
        this.bell  = new Audio("/mp3/bell.mp3");
        this.state = {
            newOrders: []
        }
        this.connectWSS   = this.connectWSS.bind(this);
        this.wssOnMessage = this.wssOnMessage.bind(this);
        this.test         = this.test.bind(this);


    }
    componentDidMount() {
        this.connectWSS();
    }

    connectWSS(){
        const _this = this;
        WSSClient.startListening().then((socket) =>{
            socket.addEventListener('message', this.wssOnMessage);
            socket.addEventListener('close',() =>{
                socket.removeEventListener('message',this.wssOnMessage);
                setTimeout(() =>{
                    _this.connectWSS();
                },3000)
            })
        }).catch((ex) =>{
            setTimeout(() =>{
                _this.connectWSS();
            },3000)
        })
    }
    wssOnMessage(event){
        const _this = this;
        let jsonEvent = {}
        try{
            jsonEvent = JSON.parse(event.data);
        }catch (e) {

        }
        switch (jsonEvent.nbioEvent) {
            case WSSClient.NBIO_EVENTS.ORDER_CREATED:
                if(!this.props.muted){
                    _this.bell.play();
                }
                return -1
                break;
            case WSSClient.NBIO_EVENTS.ORDER_UPDATED:
                // _this.bell.play();
                return -1;
                break;
            default:
                return -1;
        }
    }
    render() {
        return null
    }
    test(){
        this.bell.play();
    }

}
Bell.propTypes = {
    muted: bool
}
Bell.defaultProps = {
    muted:false
}
export default Bell;
