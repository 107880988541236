/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateRangePicker} from "react-date-range";
import es from "date-fns/locale/es";
import Button from "react-bootstrap/Button";
import NPIf from "np-if";
import Spinner from "react-bootstrap/cjs/Spinner";
import NPElse from "np-if/src/NPElse";
import BootstrapTable from "react-bootstrap-table-next";
import {defaultInputRanges, defaultStaticRanges} from "react-date-range/dist/defaultRanges";
import API from "../../lib/api/NbioApi";


//const
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);

const staticRangesLabels = {
    "Today": "Hoy",
    "Yesterday": "Ayer",
    "This Week": "Esta semana",
    "Last Week": "La última semana",
    "This Month": "Este mes",
    "Last Month": "El último mes"
};
const inputRangesLabels = {
    "days up to today": "Días hasta hoy",
    "days starting today": "Días a partir de hoy"
};

function translateRange(dictionary) {
    return (item) =>
        dictionary[item.label] ? {...item, label: dictionary[item.label]} : item;
}

const esStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
const esInputRanges  = defaultInputRanges.map(translateRange(inputRangesLabels));

class UserAnalytics extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            operatorsRates: [],
            newUsers: '0',
            isLoading:true,
            selectedRanges: {
                startDate: dayjs().startOf('week').toDate(),
                endDate: dayjs().endOf('week').toDate(),
                key: 'selection',
            },
            dateMode:'week',
        }
        this.loadNewUsersStats      = this.loadNewUsersStats.bind(this);
        this.loadOperatorsStats     = this.loadOperatorsStats.bind(this);
        this.handleDateSelect       = this.handleDateSelect.bind(this);
        this.getStartDate           = this.getStartDate.bind(this);
        this.getEndDate             = this.getEndDate.bind(this);
        this.onAllDatesClicked      = this.onAllDatesClicked.bind(this);
    }

    componentDidMount(){
        this.loadNewUsersStats();
        this.loadOperatorsStats();
    }

    loadNewUsersStats = () => {
        const startDate = this.getStartDate();
        const endDate   = this.getEndDate();

        API.analytics.getNewAccounts(startDate,endDate).then((res) => {
            this.setState({newUsers:res.data.data,isLoading:false});
        }).catch((ex) => {
            this.setState({isLoading:false});
        });
    }

    loadOperatorsStats = () => {
        const startDate = this.getStartDate();
        const endDate   = this.getEndDate();

        API.analytics.getOperatorReviews(startDate,endDate).then((res) => {
            this.setState({operatorsRates:res.data.data,isLoading:false});
        }).catch((ex) => {
            this.setState({isLoading:false});
        });
    }

    getStartDate = () => {
        if(this.state.dateMode === 'range'){
            return dayjs(this.state.selectedRanges.startDate).format('MM-DD-YYYY');
        }else if(this.state.dateMode === 'all'){
            const d = new Date(2021, 10, 1);
            return dayjs(d).format('MM-DD-YYYY');
        }else{
            return '';
        }
    }

    getEndDate = () => {
        if(this.state.dateMode === 'range'){
            return dayjs(this.state.selectedRanges.endDate).format('MM-DD-YYYY');
        }else if(this.state.dateMode === 'all'){
            return dayjs().endOf('day').format('MM-DD-YYYY');
        }else{
            return '';
        }
    }

    handleDateSelect(ranges) {
        this.setState({
            selectedRanges: ranges.selection,
            dateMode:'range',
            isLoading: true
        }, () =>{
            this.loadNewUsersStats();
            this.loadOperatorsStats();
        });
    }

    onAllDatesClicked = () => {
        this.setState({
            dateMode:'all',
            isLoading: true
        }, () =>{
            this.loadNewUsersStats();
            this.loadOperatorsStats();
        });
    }

    render() {
        const columns = [
            {
                dataField: '_id',
                text: 'Operador',
                formatter:(value,row)=>{
                    let operatorName = 'Usuario no existente';
                    let uid = '';
                    try{
                        const name = row.operator[0].name;
                        const lastName = row.operator[0].last_name;
                        uid = row.operator[0]._id;
                        operatorName = `${name} ${lastName}`;
                    }catch(ex){

                    }
                    return(
                        <div>
                            <a href={`/users/${uid}`}>{operatorName}</a>
                        </div>
                    )
                },
            },
            {
                dataField: 'avgRating',
                text: 'Calificación promedio',
                formatter:(value,row)=>{
                    return(<div>{JSON.stringify(value)}</div>)
                },
            }
        ];

        return (
            <Container fluid className={'h-100'} style={{overflowY:"auto"}}>
                <Row>
                    <Col className='py-3 d-flex align-items-center justify-content-between'>
                        <h5 className={'text-capitalize'}>Estadísticas de usuarios</h5>
                        <Dropdown>
                            <Dropdown.Toggle variant="info" id="dates-filter">
                                <FontAwesomeIcon icon={'calendar'}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div>
                                    <DateRangePicker
                                        locale={es} onChange={(v) => this.handleDateSelect(v)}
                                        ranges={[this.state.selectedRanges]}
                                        staticRanges={esStaticRanges}
                                        inputRanges={esInputRanges}
                                    >
                                    </DateRangePicker>
                                    <Button className={'ml-4'} onClick={() => this.onAllDatesClicked()}>Todas las fechas</Button>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={8} className='py-3'>
                        <Card >
                            <Card.Body>
                                <Card.Title className={'d-flex align-items-center justify-content-between text-capitalize text-primary mb-4'}>
                                    Calificación de operadores <span> </span>
                                </Card.Title>

                                <NPIf condition={this.state.isLoading}>
                                    <div className={'w-100 d-flex align-items-center justify-content-center'} style={{height:'100px'}}>
                                        <Spinner animation={"grow"} variant={'secondary'}></Spinner>
                                    </div>
                                    <NPElse>
                                        <BootstrapTable keyField='_id'
                                                        data={this.state.operatorsRates}
                                                        columns={columns}
                                                        noDataIndication={`No se encontraron datos`}
                                                        bordered={false}
                                                        striped={true}
                                        />
                                    </NPElse>
                                </NPIf>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12} md={4} className='py-3'>
                        <Card >
                            <Card.Body>
                                <Card.Title className={'d-flex align-items-center justify-content-between text-capitalize text-primary mb-4'}>
                                    Nuevos usuarios<span> </span>
                                </Card.Title>

                                <NPIf condition={this.state.isLoading}>
                                    <div className={'w-100 d-flex align-items-center justify-content-center'} style={{height:'100px'}}>
                                        <Spinner animation={"grow"} variant={'secondary'}></Spinner>
                                    </div>
                                    <NPElse>
                                        <div className={'w-100 d-flex align-items-center justify-content-center'} style={{height:'100px'}}>
                                            <h2>{this.state.newUsers}</h2>
                                        </div>
                                    </NPElse>
                                </NPIf>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(UserAnalytics);
