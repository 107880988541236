/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NbioApi from "../../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from 'react-bootstrap/Spinner'
import NPPaginator from "../ui/NPPaginator";
import PropTypes from "prop-types";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import {money} from "../../lib/money/money";
import dayjs from "dayjs";


class ProductsLogTable extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
            query:'',
            products:[],
            isLoading:true,
            showSearchResults: false,
            pages:10,
            currentPage:1,
            searchedQuery:'',
            searchCount:0,
            showModal:false,
            detailsProduct:{}
        }

        this.loadProducts   = this.loadProducts.bind(this);
        this.handleClose    = this.handleClose.bind(this);
        this.reload         = this.reload.bind(this);

    }

    componentDidMount() {
        this.loadProducts();
    }
    reload(){
        this.setState({
            isLoading:true,
            currentPage:1
        }, () =>{
            this.loadProducts()
        })
    }
    loadProducts(){
        const pageSize=25;
        this.setState({isLoading:true});
        NbioApi.products.getProductLogs(this.props.productId,this.state.currentPage,pageSize).then(res => {
            this.setState({
                products:res.data.products,
                isLoading:false,
                pages:res.data.pages
            });
        });
    }

    onPageChanged(page){
        this.setState({currentPage:page},() =>{
                this.loadProducts();
        })
    }
    handleClose(){
        this.setState({showModal:false})
    }
    renderModal(){
        if(!this.state.detailsProduct){
            return null;
        }else{
        }
        const product = this.state.detailsProduct.product || {};
        const productKeys = Object.keys(product);
        const tableData = productKeys.map((k) =>{
            return {
                'field':fieldMapper(k),
                'value':valueMapper(product[k])
            }
        })

        const columns = [
            {
                dataField: 'field',
                text: 'Campo',
                formatter: (value, row) => {
                    return value
                },
            },
            {
                dataField: 'value',
                text: 'Valor',
                formatter: (value, row) => {
                    return value
                },
            },
        ]
        return(
            <Modal
                show={this.state.showModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                fullscreen={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Historial de producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>

                        <b>Fecha {formatDate(this.state.detailsProduct.created_at)}</b>
                        <BootstrapTable keyField='_id'
                                        data={tableData}
                                        columns={columns}
                                        noDataIndication={``}
                                        bordered={false}
                        />
                    </Container>
                </Modal.Body>
            </Modal>
            )

    }
    render(){
        const columns = [
            {
              dataField: 'created_at',
              text:'Fecha',
                formatter: (value, row) => {
                    return formatDate(value)
                },
            },
            {
                dataField: 'userText',
                text: 'Usuario',
                formatter: (value, row) => {
                    return value
                },
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'200px',
                    }
                }
            },
            {
                dataField: 'sku',
                text: 'SKU',
                formatter: (value, row) => {
                    return (
                        <a href={`/products/${row._id}`}>{value}</a>
                    )
                },
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'100px',
                    }
                }
            },
            {
                dataField: 'product',
                text: 'Precio',
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'250px',
                    }
                },
                formatter:(v) =>{
                    return v.price ? money(v.price) : '-'
                }
            },
            {
                dataField: 'product',
                text: 'Detalles',
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'250px',
                    }
                },
                formatter:(p,row) => {
                    return (
                        <a href={''} onClick={(ev) => {
                            ev.preventDefault();
                            this.openDetails(row)
                        }}>Ver detalles</a>
                    )

                }
            }
        ];
        return(
            <Card >
                {this.renderModal()}
                <Card.Body>
                    <Row>
                        <Col className='py-3'>
                            <Card.Title className={'text-primary'}>Historial</Card.Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='pb-3'>
                            <NPIf condition={this.state.isLoading}>
                                <div className={'d-flex align-items-center justify-content-center'}>
                                    <Spinner animation="grow" variant={"secondary"}/>
                                </div>
                                <NPElse>
                                    <NPIf condition={this.state.showSearchResults}>
                                        <div className={'mb-2'}><b>{this.state.searchCount} resultados de la búsqueda</b></div>
                                    </NPIf>
                                    <BootstrapTable keyField='_id'
                                                    data={this.state.products}
                                                    columns={columns}
                                                    noDataIndication={`No se encontraron productos`}
                                                    bordered={false}
                                    />
                                    <NPPaginator numberOfPages={this.state.pages} onPageChanged={(p) => this.onPageChanged(p)} initialPage={this.state.currentPage}></NPPaginator>
                                </NPElse>
                            </NPIf>

                        </Col>
                    </Row>
                </Card.Body>

            </Card>
        )
    }

    openDetails(p) {
        this.setState({detailsProduct:p,showModal:true})
    }
}
const valueMapper = (v) =>{
    if(Array.isArray(v)){
        return v.join(', ')
    }else if(typeof v == "boolean") {
        return v ? 'si' : 'no'
    }else if(v ==='child'){
        return 'Hijo';
    }else if(v ==='child'){
        return 'Padre';
    }else{
        return v
    }
}
const formatDate = (date) =>{
    if(!date){
        return '-';
    }
    return dayjs(date).format('DD MMM HH:mm:ss, YYYY');
}
const fieldMapper  = (f) =>{
    const es = {
        description2:'Indicación terapéutica',
        price:'Precio',
        categories: 'Categorías',
        isControlled : 'Es controlado',
        isVisible : 'Es visible en tienda',
        isPromoted : 'Es promocionado',
        weight : 'Peso',
        depth : 'Profunidad',
        width : 'Ancho',
        images : 'Imagen',
        isTaxable : 'Libre de IVA',
        keywords : 'Palabras clave',
        sku : 'Sku',
        description : 'Descripción',
        handleUrl : 'HandleUrl',
        height: 'Altura',
        name : 'Nombre',
        type : 'Tipo',
        parentSku : 'SKU padre',
    }
    if(Object.keys(es).includes(f)){
        return es[f];
    }else{
        return f;
    }
}

ProductsLogTable.propTypes = {
    productId: PropTypes.string,

}
ProductsLogTable.defaultProps = {
    productId: '',
};

export default ProductsLogTable
