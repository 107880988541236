import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateRangePicker} from "react-date-range";
import es from "date-fns/locale/es";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Spinner from "react-bootstrap/cjs/Spinner";
import {defaultInputRanges, defaultStaticRanges} from "react-date-range/dist/defaultRanges";
import NbioApi from '../../lib/api/NbioApi';
import NPIf from "np-if";
import Badge from "react-bootstrap/cjs/Badge";
import BootstrapTable from "react-bootstrap-table-next";
import SortBtns from "../ui/SortBtns";
import _ from 'underscore';
class Segmentation extends React.Component {
    constructor(c) {
        super(c);
        this.state = {
            selectedRanges: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            segments: [],
            loaded:false,
            selectedFilters:[],
            data:[],
            usersInSegmentCount:0
        }
    }

    componentDidMount() {
        this.loadSegments();
        this.loadData();
    }
    onFilterSelected(segment,filter){
        filter.segmentLabel = segment.label;
        let newFilters = [...this.state.selectedFilters];
        const selectedFilters = this.state.selectedFilters.map((f) => f.name);
        if(selectedFilters.includes(filter.name)){
            newFilters = newFilters.filter(f => !f.name);
        }else{
            newFilters.push(filter)
        };
        this.setState({selectedFilters:newFilters}, () =>{
            this.countSegments();
            this.loadData();
        });
    }
    countSegments(){
        const subSegments = this.state.selectedFilters.map((f) => f.name);
        NbioApi.segmentation.count([],subSegments).then((res) =>{
            this.setState({
                usersInSegmentCount:res.data.count
            });
        }).catch((ex) =>{

        })
    }
    loadData(){
        const subSegments = this.state.selectedFilters.map((f) => f.name);

        NbioApi.segmentation.getUserIds([],subSegments).then((res) =>{
            let data = res.data.data;
            let groups = _.groupBy(data,(v) => {
                return v.subSegment.label
            });

            const keys = Object.keys(groups);

            const tableData = keys.map((k) =>{
                return {
                    amount:groups[k].length,
                    label:k,
                    uids:groups[k].map((u) => u.uid)
                }
            })
            this.setState({data:tableData,loaded:true})
        }).catch((ex) =>{

        })
    }

    loadSegments(){
        NbioApi.segmentation.getAvailableSegments().then((res) =>{
            this.setState({segments:res.data.list,loaded:true})
        }).catch((ex) =>{

        })
    }
    renderFilters(){
        return (
            this.state.selectedFilters.map((filter) =>{
                return (
                    <Badge className={'mr-1'}>{filter.segmentLabel}: {filter.label}</Badge>
                )
            })
        )
    }
    renderSegments() {
        const segments = this.state.segments;
        return (
            <>
                {
                    segments.map((segment) =>{
                        const subsegments = segment.subSegments || [];
                        return (
                            <Dropdown as={ButtonGroup}>
                                <Dropdown.Toggle variant="info" id="dropdown-filter">
                                    {segment.label}&nbsp;<FontAwesomeIcon icon={segment.icon}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        subsegments.map((subSegment) => {
                                            return (
                                                <Dropdown.Item
                                                    key={subSegment} onClick={() => this.onFilterSelected(segment,subSegment)}
                                                >
                                                    {subSegment.label}
                                                </Dropdown.Item>
                                            )
                                        })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        )
                    })
                }
            </>


        )

    }

    render() {
        const staticRangesLabels = {
            "Today": "Hoy",
            "Yesterday": "Ayer",
            "This Week": "Esta semana",
            "Last Week": "La última semana",
            "This Month": "Este mes",
            "Last Month": "El último mes"
        };

        const inputRangesLabels = {
            "days up to today": "Días hasta hoy",
            "days starting today": "Días a partir de hoy"
        };

        function translateRange(dictionary) {
            return (item) =>
                dictionary[item.label] ? {...item, label: dictionary[item.label]} : item;
        }

        const esStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
        const esInputRanges  = defaultInputRanges.map(translateRange(inputRangesLabels));
        const columns                = [
            {
                dataField: 'label',
                text: 'subsegmento',
            },
            {
                dataField: 'amount',
                text: 'cantidad',
            },
            {
                dataField: 'uids',
                text:'uids',
                formatter: (value) => {
                    return value.map((v) =>{
                        return (
                            <span>
                                <br></br>
                                {v}
                            </span>
                        )
                    })

                },
            }
        ]
        return (
            <Container fluid className={'h-100'} style={{overflowY: "auto"}}>
                <Row>
                    <Col className='py-3 d-flex align-items-center justify-content-between'>
                        <h5 className={'text-capitalize'}>Segmentación</h5>
                    </Col>
                </Row>
                <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
                    <ButtonGroup aria-label="Second group">
                        <Dropdown as={ButtonGroup}>
                            <Dropdown.Toggle variant="info" id="dropdown-basic" disabled={true}>
                                <FontAwesomeIcon icon={'calendar'}/>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div>
                                    <DateRangePicker
                                        locale={es} onChange={(v) => this.handleDateSelect(v)}
                                        ranges={[this.state.selectedRanges]}
                                        staticRanges={esStaticRanges}
                                        inputRanges={esInputRanges}>
                                    </DateRangePicker>
                                    <div className={'ml-3'}>
                                        <Button onClick={() => this.onAllDatesClicked()}>Todas las fechas</Button>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        <NPIf condition={this.state.loaded}>

                            {this.renderSegments()}


                        </NPIf>

                        <Button variant="info"  disabled={true} onClick={() => {
                        }}>
                            {
                                this.state.isDownloading ?
                                    <Spinner animation={'border'} size={'sm'}/>
                                    :
                                    <FontAwesomeIcon icon={'file-excel'}/>
                            }
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
                {this.renderFilters()}
                <div>
                    Usuarios en estos segmentos: {this.state.usersInSegmentCount}
                </div>
                <BootstrapTable keyField='_id'
                                data={this.state.data}
                                columns={columns}
                                rowStyle={(row, rowIndex) => {
                                    return {
                                        backgroundColor: row.isViewedByUser ? '' : '#1f16462b'
                                    }
                                }}
                                noDataIndication={`No hay órdenes que mostrar`}
                                bordered={false}

                />
            </Container>
        )
    }
}

export default withRouter(Segmentation);

