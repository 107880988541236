/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from 'react';
import {withRouter} from "react-router";
import Nav from "react-bootstrap/Nav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NbioApi from "../lib/api/NbioApi";

//style
import "../scss/components/sidebar.scss";

//component
import NPIf from "np-if";
import WSSClient from "../lib/wss/wss-client";
import Badge from "react-bootstrap/cjs/Badge";
import {Link} from "react-router-dom";

class Sidebar extends React.Component{
    componentDidMount() {
        this.loadCounters();
    }
    constructor(e) {
        super(e);
        this.state = {
            asapCount:0,
            programmedCount:0,
            pendingCount:0,

        }
        this.loadEvents();
    }
    loadEvents(){
        window.addEventListener('nbioWssEvent', (event) =>{
            const detail = event.detail || {};
            const nbioEvent = detail.nbioEvent || {};
            switch (nbioEvent){
                case WSSClient.NBIO_EVENTS.ORDER_CREATED:
                    this.loadCounters();
                    break;
                case WSSClient.NBIO_EVENTS.ORDER_UPDATED:
                    this.loadCounters();
                    break;
                case WSSClient.NBIO_EVENTS.ORDER_CANCELLED:
                    this.loadCounters();
                    break;
            }
        })
    }
    loadCounters(){
        // ASAP count
        NbioApi.orders.getOrders(
            1,
            50,
            'pending',
            'asap',
            'newest',
            '',
            '',
            'count'
        ).then((res) => {
            this.setState({
                asapCount:res.data.count
            })
        });
        // pending programmed
        NbioApi.orders.getOrders(
            1,
            50,
            'pending',
            'programmed',
            'newest',
            '',
            '',
            'count'
        ).then((res) => {
            this.setState({
                programmedCount:res.data.count
            })
        });
        // pending
        NbioApi.orders.getOrders(
            1,
            50,
            'pending',
            '',
            'newest',
            '',
            '',
            'count'
        ).then((res) => {
            this.setState({
                pendingCount:res.data.count
            })
        });
    }


    render(){
        const isSuperAdmin  = this.props.user.roles.includes('super_admin');
        const isRegionAdmin = this.props.user.roles.includes('region_admin');
        const isAdmin         = this.props.user.roles.includes('admin');
        const routeUrl = this.props.match.url;
        const pathName = this.props.location.pathname;
        return(
            <aside className={"sidebar"}>
                <Nav className="flex-column flex-grow-1">
                    <Nav.Link href="/dashboard" eventKey={"dashboard"} active={pathName === "/dashboard"}>
                        <FontAwesomeIcon icon={"home"} /><span> Dashboard</span>
                    </Nav.Link>

                    {/*Regions links*/}
                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/regions" eventKey="regions" active={pathName === "/regions"}>
                                    <FontAwesomeIcon icon={"map"} /><span> Regiones</span>
                                </Nav.Link>
                                <NPIf condition={pathName.includes('regions')}>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/regions/create" eventKey="regionsCreate" active={pathName === "/regions/create"}>
                                            Crear región
                                        </Nav.Link>
                                    </Nav>
                                </NPIf>
                            </Nav.Item>
                        : null
                    }

                    {/*Order links*/}
                    <Nav.Item>
                        <Nav.Link href="/orders/asap" eventKey="orders" active={pathName === "/orders"}>
                            <FontAwesomeIcon icon={"receipt"} /><span> Órdenes</span>
                        </Nav.Link>
                        <Nav className="flex-column" className={'ml-3'}>

                            <div className={'d-flex justify-content-between align-items-center w-100'}>
                                <Nav.Link href="/orders/asap" eventKey="ordersAsap" active={pathName === "/orders/asap"}>
                                    <div>Inmediatas</div>
                                </Nav.Link>
                                <Badge className={'mr-2'}  bg="danger">{this.state.asapCount}</Badge>
                            </div>
                        </Nav>
                        <Nav className="flex-column" className={'ml-3'}>
                            <div className={'d-flex justify-content-between align-items-center w-100'}>
                                <Nav.Link href="/orders/scheduled" eventKey="ordersScheduled" active={pathName === "/orders/scheduled"}>
                                    <div>Programadas</div>
                                </Nav.Link>
                                <Badge className={'mr-2'}  bg="danger">{this.state.programmedCount}</Badge>
                            </div>
                        </Nav>
                        <Nav className="flex-column" className={'ml-3'}>
                            <div className={'d-flex justify-content-between align-items-center w-100'}>
                                <Nav.Link href="/orders/pending" eventKey="ordersPending" active={pathName === "/orders/pending"}>
                                        <div>Pendientes</div>
                                </Nav.Link>
                                <Badge className={'mr-2'}  bg="medium">{this.state.pendingCount}</Badge>
                            </div>


                        </Nav>
                        <Nav className="flex-column" className={'ml-3'}>
                            <Nav.Link href="/orders/finished" eventKey="ordersFinished" active={pathName === "/orders/finished"}>
                                Terminadas
                            </Nav.Link>
                        </Nav>
                    </Nav.Item>
                    {/*Regions links*/}
                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/routes" eventKey="regions" active={pathName === "/routes"}>
                                    <FontAwesomeIcon icon={"route"} /><span> Rutas</span>
                                </Nav.Link>
                                <NPIf condition={pathName.includes('routes')}>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/routes/create" eventKey="routes_create" active={pathName === "/routes/create"}>
                                            <span> Crear ruta</span>
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/routes" eventKey="routes" active={pathName === "/routes"}>
                                            <span> Ver rutas</span>
                                        </Nav.Link>
                                    </Nav>
                                </NPIf>
                            </Nav.Item>
                            : null
                    }
                    {/*Products links*/}
                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/products" eventKey="products"  active={pathName === "/products"}>
                                    <FontAwesomeIcon icon={"tablets"} /><span> Productos</span>
                                </Nav.Link>
                                <NPIf condition={pathName.includes('products')}>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/products/create" eventKey="products_create" active={pathName === "/products/create"}>
                                            <span> Crear producto</span>
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/products/bulk/create" eventKey="products_bulk" active={pathName === "/products/bulk/create"}>
                                            <span> Importar productos</span>
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/products/bulk/upload_images"
                                                  eventKey="products_bulk_upload_images"
                                                  active={pathName === "/products/bulk/upload_images"}>
                                            <span> Subir imágenes</span>
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/products/bulk/export" eventKey="products_bulk" active={pathName === "/products/bulk/export"}>
                                            <span> Exportar productos</span>
                                        </Nav.Link>
                                    </Nav>
                                </NPIf>
                            </Nav.Item>
                        : null
                    }

                    {/*Users links*/}
                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/users" eventKey="users" active={pathName === "/users"}>
                                    <FontAwesomeIcon icon={"users"} /><span> Usuarios</span>
                                </Nav.Link>
                                <NPIf condition={pathName.includes('users')}>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/users/create" eventKey="user" active={pathName === "/users/create"}>
                                            <span> Crear usuario</span>
                                        </Nav.Link>
                                    </Nav>
                                </NPIf>
                            </Nav.Item> : null
                    }
                    {/*Discounts links*/}
                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/discounts" eventKey="discounts" active={pathName === "/discounts"}>
                                    <FontAwesomeIcon icon={"percentage"} /><span> Descuentos</span>
                                </Nav.Link>
                            </Nav.Item> : null

                    }
                    {/*Coupons links*/}
                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/coupons" eventKey="coupons" active={pathName === "/coupons"}>
                                    <FontAwesomeIcon icon={"tag"} /><span> Cupones</span>
                                </Nav.Link>
                            </Nav.Item> : null

                    }
                    {/*Ad links*/}
                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/ads" eventKey="ads" active={pathName === "/ads"}>
                                    <FontAwesomeIcon icon={"bullhorn"} /><span> Anuncios</span>
                                </Nav.Link>
                            </Nav.Item> : null
                    }
                    {/*Ad links*/}

                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/carousel" eventKey="carousel" active={pathName === "/carousel"}>
                                    <FontAwesomeIcon icon={"image"} /><span> Carrusel</span>
                                </Nav.Link>
                            </Nav.Item> : null
                    }

                    {/*Reviews links*/}
                    {
                        isSuperAdmin || isAdmin?
                            <Nav.Item>
                                <Nav.Link href="/reviews" eventKey="reviews" active={pathName === "/reviews"}>
                                    <FontAwesomeIcon icon={"star"} /><span> Reseñas</span>
                                </Nav.Link>
                            </Nav.Item> : null
                    }
                    {/*Analytics links*/}
                    {
                        isSuperAdmin ?
                            <Nav.Item>
                                <Nav.Link href="/analytics" eventKey="analytics" active={pathName === "/analytics"}>
                                    <FontAwesomeIcon icon={"chart-area"} /><span> Estadísticas</span>
                                </Nav.Link>
                                <NPIf condition={pathName.includes('analytics')}>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/analytics/ads" eventKey="analytics_ads" active={pathName === "/analytics/ads"}>
                                            <span> Anuncios</span>
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/analytics/products" eventKey="analytics_products" active={pathName === "/analytics/products"}>
                                            <span> Productos</span>
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/analytics/users" eventKey="analytics_users" active={pathName === "/analytics/users"}>
                                            <span> Usuarios</span>
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/analytics/segmentation" eventKey="analytics_segmentation" active={pathName === "/analytics/segmentation"}>
                                            <span> Segmentación</span>
                                        </Nav.Link>
                                    </Nav>
                                    <Nav className="flex-column" className={'ml-3'}>
                                        <Nav.Link href="/analytics/reports" eventKey="analytics_reports" active={pathName === "/analytics/reports"}>
                                            <span> Reportes</span>
                                        </Nav.Link>
                                    </Nav>
                                </NPIf>
                            </Nav.Item> : null
                    }
                    <Nav.Item>
                        <Nav.Link href="/settings" eventKey="settings" active={pathName === "/settings"}>
                            <FontAwesomeIcon icon={"wrench"} /><span> Opciones</span>
                        </Nav.Link>
                        <NPIf condition={pathName.includes('settings')}>
                            <Nav className="flex-column" className={'ml-3'}>
                                <div className={'d-flex justify-content-between align-items-center w-100'}>
                                    <Nav.Link href="/settings/zipcodes/bulk" eventKey="zipCodesBulk" active={pathName === "/settings/zipcodes/bulk"}>
                                        <div>Códigos Postales</div>
                                    </Nav.Link>
                                </div>
                            </Nav>
                        </NPIf>

                    </Nav.Item>
                    {
                        isSuperAdmin ?
                                <>
                                <Nav.Item>
                                        <Nav.Link href="/vehicles" eventKey="vehicles" active={pathName === "/vehicles"}>
                                            <FontAwesomeIcon icon={"car"} /><span> Vehículos</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="/devices" eventKey="devices" active={pathName === "/devices"}>
                                            <FontAwesomeIcon icon={"signal"} /><span> Dispositivos de datos</span>
                                        </Nav.Link>
                                </Nav.Item>
                                </>
                        : null
                    }

                </Nav>
                <small className={"mt-2 pl-3"}>Powered by <a target="_blank" href={'https://www.nodeport.co/'}>Nodeport</a>⚡</small>
            </aside>
        )

    }
}

export default withRouter(Sidebar);
