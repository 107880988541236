import React from "react";
import PropTypes from 'prop-types';
import {Toast, ToastContainer} from "react-bootstrap";
import WSSClient from "../../../lib/wss/wss-client";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/cjs/Button";
import {withRouter} from "react-router";
const _ = require('underscore');
class NbioToast extends React.Component {
    constructor() {
        super();
        this.state = {
            notifications:[

            ]
        }
        this.removeNotification = this.removeNotification.bind(this);
        this.wssOnMessage       = this.wssOnMessage.bind(this);
        this.connectWSS         = this.connectWSS.bind(this);
    }
    componentDidMount() {
        this.connectWSS();
    }
    connectWSS() {
        const _this = this;
        WSSClient.startListening().then((socket) => {
            socket.addEventListener('message', this.wssOnMessage);
            socket.addEventListener('close',() =>{
                socket.removeEventListener('message',this.wssOnMessage);
                setTimeout(() =>{
                    _this.connectWSS();
                },3000)
            })
        })
    }
    wssOnMessage(event){
        let jsonEvent = {}
        let notification = {
            title:'',
            body:'',
            time:'Ahora'
        }
        try{
            jsonEvent = JSON.parse(event.data);
        }catch (e) {

        }

        switch (jsonEvent.nbioEvent) {
            case WSSClient.NBIO_EVENTS.ORDER_CREATED:
                notification.title = 'Nueva orden';
                notification.body = `Hay una orden nueva (${jsonEvent.data.orderId || ''})`;
                notification.orderId = jsonEvent.data.orderId;
                break;
            case WSSClient.NBIO_EVENTS.ORDER_UPDATED:
                notification.title = 'Orden actualizada';
                notification.body = `La orden ${jsonEvent.data.orderId || ''} ha sido actualizada`;
                notification.orderId = jsonEvent.data.orderId;
                break;
            case WSSClient.NBIO_EVENTS.ORDER_CANCELLED:
                notification.title = 'Orden cancelada';
                notification.body = `🚫🚫🚫  La orden ${jsonEvent.data.orderId || ''} ha sido cancelada 🚫🚫🚫`;
                notification.orderId = jsonEvent.data.orderId;
                break;
            default:
                return -1;
        }
        let notifications = [...this.state.notifications]
        notifications.push(notification);
        this.setState({
            notifications:notifications
        })
    }
    removeNotification(notification){
        let newNotifications = this.state.notifications.filter((n) => n.notificationId !== notification.notificationId);
        this.setState({
            notifications:newNotifications
        })
    }

    render() {
        return (
                <ToastContainer position="top-end" className="p-3" style={{zIndex:9999}}>
                    {
                        this.state.notifications.map((notification,) =>{
                            return(
                                <Toast bg={'light'} key={notification.notificationId} animation={true}
                                       onClose={() => this.removeNotification(notification)}>
                                    <Toast.Header>
                                        <img src="" className="rounded me-2" alt="" />
                                        <FontAwesomeIcon icon={'receipt'} size={'1x'}>
                                        </FontAwesomeIcon>
                                        <strong className="me-auto">&nbsp;{notification.title}</strong>
                                        <small className="text-muted">{notification.time}</small>
                                    </Toast.Header>
                                    <Toast.Body>
                                        {notification.body}
                                        <br/>
                                        <a href={`/orders/${notification.orderId}`}>
                                            <Button variant={'link'} onClick={() =>{
                                            }}>Ver orden</Button>
                                        </a>

                                    </Toast.Body>
                                </Toast>
                            )
                        })
                    }
                </ToastContainer>
        )
    }
}

NbioToast.defaultProps =
    {}
NbioToast.propTypes    =
    {}

export default withRouter(NbioToast);
