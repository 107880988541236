/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NbioApi from "../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import {money} from "../lib/money/money";
import {withRouter} from "react-router";
import Swal from "sweetalert2";
import Alert from "react-bootstrap/Alert";

//components
import ImageSelector from "../components/ui/ImageSelector";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import NPIf from "np-if";


class ValidateAmountView extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            order: {
                shipping: {},
                shippingMethod:{},
                items:[],
                user:{},
                logs:[],
                coupons:[]
            },
            items: [],
            rowIndex: -1,
            isEdited: false,
            selectedImage: '',
            isValidating:false,
            isEditing:false,
            hasDiscount:false
        }

        this.loadOrder                      = this.loadOrder.bind(this);
        this.updateValidatePriceField       = this.updateValidatePriceField.bind(this);
        this.cancelValidate                 = this.cancelValidate.bind(this);
        this.validateOrder                  = this.validateOrder.bind(this);
        this.confirmationValidateOrder      = this.confirmationValidateOrder.bind(this);
        this.areAmountsValid                = this.areAmountsValid.bind(this);
    }

    componentDidMount() {
        this.loadOrder();
    }

    areAmountsValid(){
        const items = this.state.items;
        const hasInvalidItem = items.some((i) =>{
            return i.validatedPrice <= 0 || isNaN(parseFloat(i.validatedPrice));
        })
        const areAmountsValid = !hasInvalidItem;
        return areAmountsValid;
    }

    validateOrder(){
        const orderId = this.props.match.params.id;
        this.setState({isValidating:true});
        NbioApi.orders.validateAmount(orderId,this.state.items).then((res) =>{
            Swal.fire({
                icon:'success',
                title:'Cantidad validada',
                confirmButtonColor: '#4dda85',
                didClose: (e) => {
                    this.setState({isValidating:false});
                    this.props.history.goBack();
                },
                heightAuto:false
            })

        }).catch((ex) =>{
            const data = ex.response.data;
            Swal.fire({
                icon:'error',
                title:'Hubo un problema al validar la cantidad',
                text:data.error_es,
                heightAuto:false
            })
            this.setState({isValidating:false});
        })
    }

    loadOrder(){
        const orderId = this.props.match.params.id;
        NbioApi.orders.getOrder(orderId).then((res) => {
            const selectedImage = res.data.order.tickets.length > 0 ? res.data.order.tickets[0].imageUrl : '';
            const hasDiscount = res.data.order.items.some((i) => !!i.discount);
            this.setState({
                order: res.data.order,
                items: this.generateInitItems(res.data.order),
                selectedImage: selectedImage,
                hasDiscount: hasDiscount
            });
        })
    }
    generateInitItems(order){
        const coupons = order.coupons || [];
        let items = order.items;
        if(coupons.length === 0){
            return items;
        }else{
            // d1 = devolucion
            // d2 = subtotalValidado
            items = items.map((i) => {
                i.d1 = 0;
                i.d2 = i.price * i.quantity;
                i.validatedPrice = i.price;
                return i;
            });
            return items;
        }



    }
    updateValidatePriceField(oldValue, newValue,index){
        const {rowIndex}  = this.state;
        const items = [...this.state.items];

        items[rowIndex].validatedPrice = newValue;
        items[rowIndex].d1 = items[rowIndex].price - newValue < 0 ? 0 : items[rowIndex].price - newValue;
        items[rowIndex].d2 = newValue * items[rowIndex].quantity;

        // update refund

        //Update the item array and the flag value
        this.setState({
            isEdited:true,
            items:items
        });
    }


    cancelValidate(){
        this.setState({
            isEdited:false,
            items:this.state.order.items
        }, () => {
            this.props.history.goBack();
        });
    }

    confirmationValidateOrder(){
        const validatedAmount = this.state.items.map((i) => parseFloat(i.validatedPrice) * i.quantity).reduce((a, b) => a + b, 0);
        const totalAmount = this.state.order.subTotal  -  validatedAmount;
        let text =  ''
        if(totalAmount >= 0){
            text = `El cliente tendrá una devolución de  ${money(totalAmount)}`;
        }else{
            text = 'El cliente no tendrá devolución';
        }

        Swal.fire({
            title: 'Validar monto',
            html: text,
            confirmButtonText: 'Validar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#4dda85',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                if(this.areAmountsValid()){
                    this.validateOrder();
                }
            } else if(result.dismiss === Swal.DismissReason.cancel){
                console.log('validated amount cancel');
            }
        })
    }
    //Header formatter of column validatedPrice
    validatedPriceFormatter = (column, colIndex) => {
        return(
            <OverlayTrigger
                key={'validatedPrice'}
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-validatedPrice`}>
                        Escribe el precio real del producto.
                    </Tooltip>
                }
            >
                <span className='text-primary'>{column.text} <FontAwesomeIcon icon={'question-circle'}/></span>
            </OverlayTrigger>
        )
    }

    //Refund formatter of column
    refundFormatter = (column, colIndex) => {
        return(
            <OverlayTrigger
                key={'refund'}
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-refund`}>
                        La Devolución se calcula automaticamente al escribir el precio validado.
                    </Tooltip>
                }
            >
                <span>{column.text} <FontAwesomeIcon icon={'question-circle'}/></span>
            </OverlayTrigger>
        )
    }

    //Refund formatter of column
    subtotalFormatter = (column, colIndex) => {
        return(
            <OverlayTrigger
                key={'subtotal'}
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-subtotal`}>
                        El Subtotal Validado se calcula automáticamente al escribir el precio validado.
                    </Tooltip>
                }
            >
                <span>{column.text} <FontAwesomeIcon icon={'question-circle'}/></span>
            </OverlayTrigger>
        )
    }

    render(){
        const items = this.state.items ? this.state.items : [];
        const order = this.state.order ? this.state.order : {};
        const selectedImage = this.state.selectedImage;

        const columns  = [
            {
                dataField: 'description',
                text:'Producto',
                editable: false,
                headerStyle:() =>{
                    return {
                        width:'250px'
                    }
                },
                formatter:(value,row) => {
                    return(
                        <div>
                            <div>{value}</div>
                            <div>
                                <b>
                                    SKU: {row.SKU}
                                </b>
                            </div>
                            <div>
                                <b>
                                    Ingrediente Activo: {row.activeIngredient}
                                </b>
                            </div>
                        </div>
                    )
                }
            },
            {
                dataField: 'price',
                text:'Precio',
                editable: false,
                formatter:(value,row) =>{
                    const discount = row.discount ? row.discount : null;
                    const originalPrice =  discount ? row.originalPrice : 0;
                    if(discount){
                        return (
                            <div>
                                <div className={'strikethrough'}>
                                    {money(originalPrice)}
                                </div>
                                <div className={'discounted-price'}>
                                    <b>{money(value)}</b>
                                </div>
                                <div>
                                    <small>
                                        <FontAwesomeIcon icon={"percentage"} />&nbsp;{discount.name}
                                    </small>
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div>
                                {money(value)}
                            </div>
                        )
                    }

                }
            },
            {
                dataField: 'quantity',
                text:'Cantidad',
                editable: false
            },
            {
                dataField: 'price',
                text:'Subtotal',
                editable: false,
                formatter:(value,row) =>{
                    const discount = row.discount ? row.discount : null;
                    const originalPrice =  discount ? row.originalPrice : 0;
                    if(discount){
                        return (
                            <div>
                                <div className={'strikethrough'}>
                                    {money(originalPrice * row.quantity)}
                                </div>
                                <div className={'discounted-price'}>
                                    {money(value)}
                                </div>
                            </div>
                        )
                    }else{
                        return (
                            <div>
                                {money(value * row.quantity)}
                            </div>
                        )
                    }
                }
            },
            {
                dataField: 'd1',
                isDummyField: false,
                text:'Devolución',
                editable: false,
                formatter(value,row,i){
                    if(!value){
                        return '-';
                    }else{
                        return `${money(value)} X ${row.quantity}`;
                    }
                },
                headerFormatter: this.refundFormatter
            },
            {
                dataField: 'd2',
                isDummyField: false,
                text:'Subtotal Validado',
                editable: false,
                formatter(value,row,i){
                    if(!value){
                        return '-';
                    }else{
                        return money(value);
                    }
                },
                headerFormatter: this.refundFormatter
            },
            {
                dataField: 'validatedPrice',
                text:'Precio Validado',
                headerStyle:() =>{
                    return {
                        width:'250px'
                    }
                },
                headerFormatter: this.validatedPriceFormatter,
                validator: (newValue, row, column) => {
                    const value = newValue.trim();

                    if (isNaN(value)) {
                        return {
                            valid: false,
                            message: 'El precio validado debe ser numérico.'
                        };
                    }
                    if (value === '') {
                        return {
                            valid: false,
                            message: 'El precio no puede ser vacio.'
                        };
                    }
                    if (newValue <= 0) {
                        return {
                            valid: false,
                            message: 'El precio debe ser mayor a 0.'
                        };
                    }
                    return true;
                }
            },
        ];
        const isTableEditable = this.state.order.coupons.length > 0;
        const hasDiscount = this.state.hasDiscount;

        return(
            <Container fluid className={'h-100 d-flex flex-column'}>
                <Row>
                    <Col>
                        <Card.Body  className={'d-flex justify-content-between align-items-center'}>
                            <h5 className={'text-capitalize'}>Validar Monto
                                <span className={'pl-1 text-info'}><small>#{order._id}</small></span>
                            </h5>
                            <Button variant={"light"}
                                    onClick={() => {this.props.history.goBack()}}
                                   >
                                <FontAwesomeIcon icon={"times"}/>
                            </Button>
                        </Card.Body>
                    </Col>
                </Row>

                <Row className={'flex-grow-1 mt-2'}>
                    <Col sm={12} md={5} >
                        <ImageSelector images={order.tickets}
                                       selectedImage={selectedImage}
                                       onImageSelected={(img) => this.setState({selectedImage:img})} >
                        </ImageSelector>
                    </Col>
                    <Col sm={12} md={7}>
                        <NPIf condition={isTableEditable}>
                            <Alert variant={'warning'}>
                                Nota: En las órdenes que incluyen cupones el precio validado no puede ser modificado. Presione Validar montos para continuar.
                            </Alert>
                        </NPIf>
                        <NPIf condition={hasDiscount}>
                            <Alert variant={'warning'}>
                                Nota: Esta orden incluye precios con descuentos. El precio original es el que se encuentra tachado. El precio después del descuento es el que no se encuentra tachado.
                            </Alert>
                        </NPIf>
                        <Card>
                            <BootstrapTable keyField='id'
                                            data={items}
                                            columns={columns}
                                            noDataIndication={``}
                                            bordered={false}
                                            striped
                                            tabIndexCell={true}
                                            cellEdit={ cellEditFactory({
                                                mode: isTableEditable ? 'none' : 'click',
                                                blurToSave: true,

                                                onStartEdit: (row, column, rowIndex) => {
                                                    this.setState({rowIndex:rowIndex,isEditing:true})
                                                },
                                                afterSaveCell: (oldValue, newValue) => {
                                                    this.updateValidatePriceField(oldValue,newValue)
                                                    this.setState({isEditing:false})
                                                }
                                            }) }
                            />
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-auto mb-3'}>
                    <Col>
                        <Card.Body  className={'d-flex justify-content-end'}>
                            <Button className={'mr-1'} variant={"danger"} size="lg"  onClick={() => this.cancelValidate()}>
                                Cancelar
                            </Button>
                            <Button variant={"secondary"} size="lg"  onClick={() => this.confirmationValidateOrder()} disabled={this.state.isEditing}>
                                Validar montos
                            </Button>
                        </Card.Body>
                    </Col>
                </Row>
            </Container>
        )

    }
}

export default withRouter(ValidateAmountView);
