/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * June 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import API from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Spinner from "react-bootstrap/cjs/Spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

//style
import "../../scss/components/region.scss";

//components
import RegionsMap from "./RegionsMap";
import UsersPicker from "../ui/pickers/UsersPicker";
import NPIf from "np-if";


class Region extends React.Component {
    PRIORITIES_LEVELS = [
        {
            value:1,
            label:'1.- Mínima'
        },
        {
            value:2,
            label:'2.- Baja'
        },
        {
            value:3,
            label:'3.- Intermedia'
        },
        {
            value:4,
            label:'4.- Alta'
        },
        {
            value:5,
            label:'5.- Máxima'
        },
    ];
    constructor(p) {
        super(p);
        this.state           = {
            region: {
                color: '#000000',
                administrators: [],
                operators: [],
                subRegions: [],
                name: '',
                priority:1
            },
            isLoading: true,
            priceAsap: '',
            priceSchedule: '',
            forms:[],
            activeKey:'data_region'
        }
        this.getRegion          = this.getRegion.bind(this);
        this.onClickBtn         = this.onClickBtn.bind(this);
        this.validateRegion     = this.validateRegion.bind(this);
        this.createRegion       = this.createRegion.bind(this);
        this.setRegionObject    = this.setRegionObject.bind(this);
        this.confirmDelete      = this.confirmDelete.bind(this);


    }

    componentDidMount() {
        const regionId = this.props.match.params.id;
        if (regionId !== 'create') {
            this.getRegion(regionId);
        } else {
            this.setState({isLoading: false})
        }
    }

    getRegion = (regionId) => {
        this.setState({isLoading: true})
        API.regions.getRegion(regionId).then((res) => {

            const region          = {...res.data.region};
            region.subRegions  = region.subRegions.map((sr) =>{
                return {
                    featureGeoJson: sr.featureGeoJson,
                    color: sr.color,
                    name: sr.name || '',
                }
            })
            const forms = res.data.region.subRegions.map((sr) =>{
                const asapPrice = sr.shippingMethods.length > 0 ? sr.shippingMethods[0].price : 0;
                const scheduledPrice = sr.shippingMethods.length > 1 ? sr.shippingMethods[1].price : 0;
                const isAsapActive = sr.shippingMethods.length > 1 ? sr.shippingMethods[0].isActive : false;
                const isScheduledActive = sr.shippingMethods.length > 1 ? sr.shippingMethods[1].isActive : false;
                return {
                    asapPrice:asapPrice,
                    scheduledPrice:scheduledPrice,
                    isAsapActive:isAsapActive,
                    isScheduledActive:isScheduledActive

                }
            })
            this.setState({region: region, forms:forms,isLoading: false});
        }).catch((ex) => {
            this.setState({isLoading: false})
        })
    }

    onChangeRoles = (e) => {
        const region = {...this.state.region}
        region.roles = ['region', e.target.value];
        this.setState({
            region,
        })
    }

    onChange = (e) => {
        this.setState(prevState => {
            let region          = Object.assign({}, prevState.region);
            region[e.target.id] = e.target.value;
            if (e.target.id === 'name') {
                region.subRegions = region.subRegions.map((r, i) => {
                    r.name = `${e.target.value}-${i + 1}`;
                    return r;
                })
            }
            return {region};
        });
    }

    onChangeColor = (e, index) => {
        this.setState(prevState => {
            let region                     = Object.assign({}, prevState.region);
            region.subRegions[index].color = e.target.value;
            return {region};
        });
    }
    onChangeName  = (e, index) => {
        this.setState(prevState => {
            let region                    = Object.assign({}, prevState.region);
            region.subRegions[index].name = e.target.value;
            return {region};
        });
    }
    onChangeAsapPrice  = (e, index) => {
        this.setState(prevState => {
            let forms                    = Object.assign({}, prevState.forms);
            forms[index].asapPrice = e.target.value;
            return forms;
        });
    }
    onChangeScheduledPrice  = (e, index) => {
        this.setState(prevState => {
            let forms                    = Object.assign({}, prevState.forms);
            forms[index].scheduledPrice = e.target.value;
            return forms;
        });
    }

    onChangeDisabledScheduled = (e,index) => {
        this.setState(prevState => {
            let forms                       = Object.assign({}, prevState.forms);
            forms[index].isScheduledActive  =  e.target.checked;
            return forms;
        });
    }

    onChangeDisabledAsap = (e,index) => {
        this.setState(prevState => {
            let forms                       = Object.assign({}, prevState.forms);
            forms[index].isAsapActive  =  e.target.checked;
            return forms;
        });
    }

    onClickBtn    = () => {
        const {error,isValid} = this.validateRegion();
        if(!isValid){
            return Swal.fire({
                icon: 'error',
                title: 'Error',
                html: error,
                confirmButtonColor: '#4dda85',
                heightAuto: false
            }).then((res) => {
            })
        }
        const regionId      = this.props.match.params.id;
        if (regionId === 'create') {
            //create Region
            this.createRegion();
        } else {
            //update Region
            this.updateRegion();
        }
    }

    setRegionObject = () => {
        const region           = {...this.state.region};
        region.administrators  = region.administrators.map((user) => user._id);
        region.operators       = region.operators.map((user) => user._id);
        region.shippingMethods = [
            {
                name: 'Inmediata',
                description: 'El pedido será entregado lo más pronto posible.',
                price: this.state.priceAsap,
                shippingType: 'asap'
            },
            {
                name: 'Programada',
                description: 'Seleccione una hora y fecha para recibir su pedido',
                price: this.state.priceSchedule,
                shippingType: 'schedule'
            }];
        region.subRegions = this.state.region.subRegions.map((sr,index) =>{
            return {
                featureGeoJson: sr.featureGeoJson,
                color: sr.color,
                shippingMethods: [
                        {
                            name:'Inmediata',
                            description:'El pedido será entregado lo más pronto posible.',
                            price:this.state.forms[index].asapPrice,
                            shippingType:'asap',
                            isActive: this.state.forms[index].isAsapActive

                        },
                        {
                            name:'Programada',
                            description:'Seleccione una hora y fecha para recibir su pedido',
                            price:this.state.forms[index].scheduledPrice,
                            shippingType:'schedule',
                            isActive: this.state.forms[index].isScheduledActive
                        }
                    ],
                priority: index
            }
        });

        return region;
    }
    isNumberGreaterOrEqualThanZero = (val) =>{
        const parsedNumber = parseFloat(val);
        // If this is NaN, it will still be comparable
        return parsedNumber >= 0;
    }
    validateRegion  = () => {
        // check name
        // check warehouse
        // check prices
        let error          = '';
        const isValid = false;
        const subRegions = this.state.region.subRegions;
        const forms = this.state.forms.slice(0,subRegions.length);
        const warehouse = this.state.region.subRegions.find((r) => r.featureGeoJson.Type === 'Point');
        const hasValidPrices = forms.every((sr) =>{
            return this.isNumberGreaterOrEqualThanZero(sr.asapPrice) && this.isNumberGreaterOrEqualThanZero(sr.scheduledPrice);
        });

        if (!this.state.region.name.trim()) {
            error = 'El nombre no puede estar vacío';
            return {
                isValid:false,
                error:error
            }
        } else if (warehouse) {
            error = 'Debes de agregar un centro de distribución';
            return {
                isValid:false,
                error
            }
        } else if(this.state.region.subRegions.length === 0){
            error = 'Debes de tener al menos una subregión';
            return {
                isValid:false,
                error
            }
        } else if(!hasValidPrices){
            error = 'Debes de proporcionar un valor mayor a 0 para todos los precios de entrega en las subregiones.';
            return {
                isValid:false,
                error
            }
        } else {
            return {
                isValid:true
            }
        }

    }
    createRegion    = () => {
        const region = this.setRegionObject();
        const txt    = `¿Estás seguro de crear la zona?`;

        Swal.fire({
            icon: 'warning',
            title: 'Crear zona',
            html: txt,
            confirmButtonColor: '#4dda85',
            heightAuto: false,
            showCancelButton:true
        }).then((res) => {
            if (res.isConfirmed) {
                API.regions.create({region: region}).then((res) => {
                    this.props.history.push('/regions');
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al crear la región';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                })
            }
        })
    }

    updateRegion  = () => {
        const region = this.setRegionObject();
        const txt    = `¿Estás seguro de actualizar la región?`;

        Swal.fire({
            icon: 'warning',
            title: 'Guardar región',
            html: txt,
            confirmButtonColor: '#4dda85',
            heightAuto: false,
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed) {
                API.regions.update(region._id, region).then((res) => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Región actualizada',
                        confirmButtonColor: '#4dda85',
                        heightAuto: false,
                        showCancelButton: true
                    });
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al crear la región';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                })
            }
        })

    }
    fireSwalError = (txt) => {
        Swal.fire({
            icon: 'error',
            title: txt,
            confirmButtonColor: '#2a7de1',
            heightAuto: false
        })
    }

    onPolygonCompleted = (polygons) => {
        const region          = {...this.state.region};
        region.subRegions     = polygons.map((polygon, index) => {
            return {
                featureGeoJson: polygon,
                color: index <= this.state.region.subRegions.length - 1 ? this.state.region.subRegions[index].color :this.state.region.color,
                name: `${this.state.region.name}-${index + 1}`
            }
        })

        const forms = [...this.state.forms];
        polygons.forEach((polygon,index) =>{
            if(index >= forms.length){
                forms.push({
                    asapPrice:0,
                    scheduledPrice:0
                })
            }
        })
        this.setState({
            region,
            forms
        })

    }
    getMapFeatures     = () => {
        const regionId = this.props.match.params.id;
        const subRegions =  this.state.region.subRegions;
        let data = [];
        if (regionId === 'create') {
            data = subRegions.map((sr) => {
                const data      = sr.featureGeoJson;
                data.properties = {};
                return data;
            });
        } else {
             data = subRegions.map((sr) => {
                 sr.featureGeoJson.properties = {};
                 return sr.featureGeoJson
            });
        }
        return data;

    }
    setPriority(priority){
        const region = this.state.region;
        region.priority = priority.value;
        this.setState({
            region:region
        })
    }

    confirmDelete = () => {
        Swal.fire({
            icon: 'error',
            title: '¿Estás seguro de borrar la región?',
            html: 'Una vez borrada la región no se podrá recuperar',
            confirmButtonColor: '#f32735',
            confirmButtonText:'Sí, borrar',
            cancelButtonText: 'Cancelar',
            showCancelButton:true,
            reverseButtons:true,
        }).then((res) => {
            if (res.isConfirmed) {
                const regionId = this.props.match.params.id;
                API.regions.delete(regionId).then((res) => {
                    this.props.history.push('/regions');
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al borrar la región';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                })
            }
        })
    }

    render() {
        const region        = this.state.region;
        const regionId      = this.props.match.params.id;
        const priceAsap     = this.state.priceAsap;
        const priceSchedule = this.state.priceSchedule;
        const color         = this.state.region.subRegions.map((sr) => sr.color);
        const wareHouse     = this.state.region.subRegions.find((r) => r.featureGeoJson.geometry.type === 'Point') || null;
        const propertyBtnLabel =
                  this.PRIORITIES_LEVELS.find((p) => p.value === this.state.region.priority).label
                  || null;
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3'>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <h5 className={'text-capitalize mr-auto'}>{regionId === 'create' ? 'Crear región' : 'Región'}</h5>
                            <NPIf condition={regionId !== 'create'}>
                                <Dropdown >
                                    <Dropdown.Toggle variant="danger" id="dropdown-basic">
                                        Configuración
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.confirmDelete()}>Borrar región</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </NPIf>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className='py-3' sm={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Dibuja la región en el mapa
                                </Card.Title>
                                {
                                    this.state.isLoading ?
                                        <Spinner animation={'border'} variant={'primary'}/> :
                                        <RegionsMap
                                            ref={(ref) => this.regionsMap = ref}
                                            onPolygonCompleted={(polygon) => this.onPolygonCompleted(polygon)}
                                            defaultColor={this.state.color}
                                            features={this.getMapFeatures()} color={color}>
                                        </RegionsMap>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mb-5'}>
                    <Col className='py-3' sm={12}>
                        <Tabs defaultActiveKey="data_region"  activeKey={this.state.activeKey}
                              onSelect={(ev) => {
                                  this.setState({activeKey:ev})
                        }}>
                            <Tab eventKey="data_region"
                                 title="Datos de la región"
                                 onClick={() => this.setState({activeKey:'data_region'})}>
                                <Card.Body className={'border border-top-0 region-tab-content p-3'}>
                                    <Form.Group as={Row} className="mb-3" controlId="color">
                                        <Form.Label column sm={2}>Color</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="color" placeholder="Color"
                                                          value={region.color}
                                                          onChange={event => this.onChange(event)}/>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3" controlId="name">
                                        <Form.Label column sm={2}>Nombre</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="text" placeholder="Nombre de la región"
                                                          value={region.name}
                                                          onChange={event => this.onChange(event)}/>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="administrators">
                                        <Form.Label column sm={2}>Administradores</Form.Label>
                                        <Col sm={10}>
                                            {
                                                this.state.isLoading ?
                                                    <Spinner animation={'border'} variant={'primary'}/> :
                                                    <UsersPicker
                                                        roles={'region_admin'}
                                                        label={'Agregar administradores'}
                                                        onChange={(administrators) => {
                                                            const region          = {...this.state.region};
                                                            region.administrators = administrators;
                                                            this.setState({region});
                                                        }}
                                                        selectedUsers={this.state.region.administrators}

                                                    >

                                                    </UsersPicker>
                                            }


                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="operators">
                                        <Form.Label column sm={2}>Operadores</Form.Label>
                                        <Col sm={10}>
                                            {
                                                this.state.isLoading ?
                                                    <Spinner animation={'border'} variant={'primary'}/> :
                                                    <UsersPicker
                                                        roles={'operator'}
                                                        label={'Agregar operadores'}
                                                        onChange={(operators) => {
                                                            const region     = {...this.state.region};
                                                            region.operators = operators;
                                                            this.setState({region});
                                                        }}
                                                        selectedUsers={this.state.region.operators}
                                                    >
                                                    </UsersPicker>
                                            }
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="operators">
                                        <Form.Label column sm={2}>Prioridad</Form.Label>
                                        <Col sm={10}>
                                            <DropdownButton as={ButtonGroup}
                                                            variant="outline-primary"
                                                            className={'text-uppercase'}
                                                            title={propertyBtnLabel}>
                                                {
                                                    this.PRIORITIES_LEVELS.map((priority) => {
                                                        return (
                                                            <Dropdown.Item key={priority.value}
                                                                           className={'text-capitalize'}
                                                                           onClick={() => this.setPriority(priority)}>
                                                                {priority.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </DropdownButton>
                                        </Col>
                                    </Form.Group>
                                    <hr/>
                                    <Form className={'mt-4'}>
                                        <Card.Subtitle className={'mb-2'}>
                                            <b>Centro de distribución</b>
                                        </Card.Subtitle>
                                        {
                                            wareHouse ?
                                                (<Form.Group as={Row} className="mb-3" controlId="name">
                                                    <Form.Label column sm={2}>Coordenadas</Form.Label>
                                                    <Col sm={10}>
                                                        {wareHouse.featureGeoJson.geometry.coordinates[0]},&nbsp;
                                                        {wareHouse.featureGeoJson.geometry.coordinates[1]}
                                                    </Col>
                                                </Form.Group>)
                                                :
                                                <>
                                                    Agrega un centro de distribución desde el mapa con la herramienta
                                                    <Button onClick={() => {}} disabled={true} size={'sm'} className={'ml-2'}
                                                            variant={this.state.selectedMode === 'create-point' ? 'secondary' : 'primary'}>
                                                        <FontAwesomeIcon icon={'map-pin'}/>
                                                    </Button>
                                                    .
                                                </>
                                        }
                                    </Form>
                                </Card.Body>
                            </Tab>
                            {
                                this.state.region.subRegions.map((sr, i) => {
                                    if (sr.featureGeoJson.geometry.type === "Point") {
                                        return null;
                                    }

                                    return(
                                        <Tab key={`region-${i}`}
                                             eventKey={`region-${i}`}
                                             title={`Sub-región #${i+1}`}>
                                            <Card.Body className={'border border-top-0 region-tab-content p-3'}>
                                                <Form.Group as={Row} className="mb-3" controlId="color">
                                                    <Form.Label column sm={2}>Color</Form.Label>
                                                    <Col sm={10}>
                                                        <Form.Control type="color"  placeholder="Color"
                                                                      value={sr.color}
                                                                      onChange={event => this.onChangeColor(event,i)}/>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group as={Row} className="mb-3" controlId="priceSchedule">
                                                    <Form.Label column sm={2}>Prioridad</Form.Label>
                                                    <Col sm={10}>
                                                        <DropdownButton as={ButtonGroup}
                                                                        variant="outline-primary"
                                                                        className={'text-uppercase'}
                                                                        title={i+1}>
                                                            {
                                                                this.state.region.subRegions.map((region,newIndex) => {
                                                                    if(newIndex === this.state.region.subRegions.length -1){
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <Dropdown.Item key={newIndex}
                                                                                       className={'text-capitalize'}
                                                                                       onClick={() => this.changeSubregionsOrder(i,newIndex)}>
                                                                            {newIndex+1}
                                                                        </Dropdown.Item>
                                                                    )
                                                                })
                                                            }
                                                        </DropdownButton>
                                                    </Col>
                                                </Form.Group>
                                                <hr/>
                                                <Form.Label><b>Tipo de entrega</b></Form.Label>

                                                <Form.Group as={Row} className="mb-3" controlId="priceAsap">
                                                    <Form.Label column sm={2}>Precio entrega inmediata</Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control type="text"
                                                                      placeholder="Precio entrega inmediata"
                                                                      value={this.state.forms[i].asapPrice}
                                                                      disabled={!this.state.forms[i].isAsapActive}
                                                                      onChange={event => this.onChangeAsapPrice(event,i)}/>
                                                    </Col>
                                                    <Col sm={2} className={'d-flex flex-column align-items-end'}>
                                                        <Form.Check
                                                            type="switch"
                                                            id="switch-asap"
                                                            label={this.state.forms[i].isAsapActive ? 'Habilitada' : 'Deshabilitada'}
                                                            checked={this.state.forms[i].isAsapActive}
                                                            onChange={event => this.onChangeDisabledAsap(event,i)}/>
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} className="mb-3" controlId="priceSchedule">
                                                    <Form.Label column sm={2}>Precio entrega programada</Form.Label>
                                                    <Col sm={8}>
                                                        <Form.Control type="text"
                                                                      placeholder="Precio entrega programada"
                                                                      value={this.state.forms[i].scheduledPrice}
                                                                      disabled={!this.state.forms[i].isScheduledActive}
                                                                      onChange={event => this.onChangeScheduledPrice(event,i)}/>
                                                    </Col>
                                                    <Col sm={2} className={'d-flex flex-column align-items-end'}>
                                                        <Form.Check
                                                            type="switch"
                                                            id="switch-scheduled"
                                                            label={this.state.forms[i].isScheduledActive ? 'Habilitada' : 'Deshabilitada'}
                                                            checked={this.state.forms[i].isScheduledActive}
                                                            onChange={event => this.onChangeDisabledScheduled(event,i)}/>
                                                    </Col>
                                                </Form.Group>

                                                <div className={'mb-2 d-flex justify-content-end'}>
                                                    <Button onClick={() => {
                                                        this.regionsMap.deleteFeature(i)
                                                    }} variant={'danger'} className={'ml-1'}>
                                                        <FontAwesomeIcon icon={'trash'}/>
                                                    </Button>
                                                </div>
                                            </Card.Body>
                                        </Tab>
                                    )
                                })
                            }
                        </Tabs>
                    </Col>
                    <Col sm={12} className={'d-flex justify-content-end'}>
                        <Button onClick={() => this.onClickBtn()}>Guardar cambios</Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    changeSubregionsOrder(prevIndex,newIndex) {
        this.setState({
            isLoading:true
        })
        const region = {...this.state.region}
        const regions = [...region.subRegions]

        const buff = regions[newIndex];
        regions[newIndex] = regions[prevIndex];
        regions[prevIndex] = buff;
        region.subRegions = regions;
        delete region.name;
        const forms    = [...this.state.forms];
        const buffForm = forms[newIndex];
        forms[newIndex] = forms[prevIndex];
        forms[prevIndex] = buffForm;
        setTimeout(() =>{
            this.setState({
                region:region,
                forms:forms,
                isLoading:false,
                activeKey:`region-${newIndex}`
            })
        },1000)

    }
}

export default withRouter(Region);
