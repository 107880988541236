/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import API from "../lib/api/NbioApi";
import Spinner from "react-bootstrap/cjs/Spinner";
import {money} from "../lib/money/money";
import ScrollContainer from 'react-indiana-drag-scroll'
import NPIf from "np-if";
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            mainStats: {
                totalPendingOrders: [],
                grossSales: [],
                newClients: 0,
                mostViewedProducts: []
            },
            isSuperAdmin:false
        }
    }

    componentDidMount() {
        this.loadIndicators();
        API.users.getMe().then((res) =>{
            const roles = res.data.user.roles;
            this.setState({isSuperAdmin:roles.includes('super_admin')});
        }).catch((ex) =>{

        })

    }

    loadIndicators() {
        API.analytics.getMainStats().then((res) => {
            this.setState({
                mainStats: res.data,
                isLoading: false
            });
        }).catch((ex) => {

        })
    }

    render() {
        const asap       = this.state.mainStats.totalPendingOrders.find((f) => f._id === 'Inmediata');
        const programmed = this.state.mainStats.totalPendingOrders.find((f) => f._id === 'Programada');

        const asapCount       = asap ? asap.count : '-';
        const programmedCount = programmed ? programmed.count : '-';
        const grossSales      = this.state.mainStats.grossSales.length > 0 ? money(this.state.mainStats.grossSales[0].grossIncome) : '';
        return (
            <Container className={'h-100 overflow-auto'}>
                <Row>
                    <Col className='py-3'>
                        <h1 className={'text-capitalize text-primary'}>Dashboard</h1>
                    </Col>
                </Row>

                <Row>
                    <Col sm={12}>
                        <Row>
                            <Col className='py-3'>
                                <h5 className={'text-capitalize'}>
                                    Órdenes pendientes:
                                    <span>{this.state.isLoading? <Spinner animation={'border'}/> : asapCount + programmedCount}</span>
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Card bg={'info'}>
                                    <Card.Body>
                                        <h5 className={'text-center'}>
                                            Entregas inmediatas
                                        </h5>
                                        <h1 className={'text-center'}>
                                            {
                                                this.state.isLoading ?
                                                    <Spinner animation={'border'}></Spinner> : asapCount
                                            }
                                        </h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6}>
                                <Card bg={'warning'}>
                                    <Card.Body>
                                        <h5 className={'text-center'}>
                                            Entregas programadas
                                        </h5>
                                        <h1 className={'text-center'}>
                                            {
                                                this.state.isLoading ?
                                                    <Spinner animation={'border'}></Spinner> : programmedCount
                                            }
                                        </h1>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <NPIf condition={this.state.isSuperAdmin}>
                    <Row className={'mt-5'}>
                        <Col sm={12}>
                            <Row>
                                <Col className='py-3'>
                                    <h5 className={'text-capitalize'}>Totales semanales</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={6}>
                                    <Card bg={'light'}>
                                        <Card.Body>
                                            <h5 className={''}>
                                                Ventas
                                            </h5>
                                            <div className={'d-flex w-100 mt-4'}>
                                                <h1 className={'text-muted'}><FontAwesomeIcon icon={'money-bill'}/></h1>
                                                <h1 className={'text-center ml-3'}>
                                                    {
                                                        this.state.isLoading ?
                                                            <Spinner animation={'border'}></Spinner> : grossSales
                                                    }
                                                </h1>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={6}>
                                    <Card bg={'light'}>
                                        <Card.Body>
                                            <h5 className={''}>
                                                Nuevos usuarios
                                            </h5>
                                            <div className={'d-flex w-100 mt-4'}>
                                                <h1 className={'text-muted'}><FontAwesomeIcon icon={'user'}/></h1>
                                                <h1 className={'text-center ml-3'}>
                                                    {
                                                        this.state.isLoading ?
                                                            <Spinner
                                                                animation={'border'}></Spinner> : this.state.mainStats.newClients
                                                    }
                                                </h1>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className={'mt-5 mb-5'}>
                        <Col sm={12}>
                            <Row>
                                <Col className='py-3'>
                                    <h5 className={'text-capitalize'}>Productos más vistos</h5>
                                </Col>
                            </Row>
                            <Row>
                                <ScrollContainer horizontal={true} className={'d-flex'}>

                                    {
                                        this.state.mainStats.mostViewedProducts.map((product) => {
                                            return (
                                                <Col sm={6} md={4}  lg={3} key={product.sku}>
                                                    <Card>
                                                        <Card.Img variant={'top'}
                                                                  src={product.product.image}>

                                                        </Card.Img>
                                                        <Card.Body>
                                                            <Card.Subtitle className={'text-wrap'} style={{height:80}}>{product.product.name}</Card.Subtitle>
                                                            <div className={'mt-2'}><FontAwesomeIcon icon={'eye'}/> Vistas {product.count}
                                                            </div>
                                                            <div className={'d-flex justify-content-end'}>
                                                                <Card.Link href={`/products/${product.sku}`}>Ver producto</Card.Link>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )

                                        })
                                    }
                                </ScrollContainer>



                            </Row>
                        </Col>
                    </Row>
                </NPIf>


            </Container>
        )

    }
}

export default withRouter(Dashboard);
