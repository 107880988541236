/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * April 2023
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import NbioApi from "../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import NPIf from "np-if";
import Nav from 'react-bootstrap/Nav';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//components
import UploadFile from "./bulk_importer/UploadFile";
import ValidateFile from "./bulk_importer/ValidateFile";
import ImportingFile from "./bulk_importer/ImportingFile";
import Spinner from "react-bootstrap/cjs/Spinner";
import Swal from "sweetalert2";


class ZipCodesBulkImporter extends React.Component {
    constructor(p) {
        super(p);
        this.state               = {
            step: 'UPLOAD_FILE', //UPLOAD_FILE, VALIDATE_FILE, IMPORTING, RESPONSE_IMPORTER, UPLOAD_IMAGES,
            showInstructions: true,
            bulkImport: null,
            isDownloading: false
        }
        this.onFileUploaded      = this.onFileUploaded.bind(this);
        this.onValidationError   = this.onValidationError.bind(this);
        this.onValidatedFile     = this.onValidatedFile.bind(this);
        this.onImportingError    = this.onImportingError.bind(this);
        this.onImportingFinished = this.onImportingFinished.bind(this);
        this.onImagesUploadError = this.onImagesUploadError.bind(this);
        this.onImagesNextStep    = this.onImagesNextStep.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
    }

    onFileUploaded(bl) {
        this.setState({
            step: 'VALIDATE_FILE',
            bulkImport: bl.bulkImport
        })
    }

    onValidationError() {
        this.setState({
            step: 'UPLOAD_FILE',
        })
    }

    onValidatedFile() {
        NbioApi.bulkImports.startImporting(this.state.bulkImport._id).then((res) => {
            this.setState({
                step: 'IMPORTING',

            })
        }).catch((ex) => {

        })


    }

    onImportingError() {

    }

    onImportingFinished() {
        this.setState({
            step: 'UPLOAD_IMAGES',
        })
    }

    onImagesUploadError() {

    }

    onImagesNextStep() {

    }

    renderComponent = () => {
        switch (this.state.step) {
            case 'UPLOAD_FILE':
                return <UploadFile nextStep={this.onFileUploaded}></UploadFile>;
            case 'VALIDATE_FILE':
                return <ValidateFile
                    bulkImport={this.state.bulkImport}
                    onError={this.onValidationError}
                    nextStep={this.onValidatedFile}
                ></ValidateFile>;
            case 'IMPORTING':
                return <ImportingFile
                    bulkImport={this.state.bulkImport}
                    onError={this.onImportingError}
                    nextStep={this.onImportingFinished}
                >
                </ImportingFile>;
            default:
                return ''
        }
    }


    render() {
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className={'ml-auto mr-auto'} sm={12} md={9}>
                        <Row>
                            <Col className='mt-3 d-flex align-items-center justify-content-between'>
                                <h5 className={'text-capitalize '}>Importador de códigos postales</h5>
                                <a href={'https://nbio.blob.core.windows.net/cdn/Muestra-cp.xlsx'} target={'_blank'}>
                                    <Button variant={'info'}>
                                        <FontAwesomeIcon icon={'download'}/> Descargar archivo muestra</Button>
                                </a>
                            </Col>
                        </Row>

                        <NPIf condition={this.state.showInstructions && this.state.step === 'UPLOAD_FILE'}>
                            <Row className={'mt-5'}>
                                <Col>
                                    <Card bg={'medium'}>
                                        <Card.Body>
                                            <Card.Title className='d-flex align-items-center justify-content-between'>
                                                <span>Instrucciones</span>
                                                <Button size="sm"
                                                        variant={'light'}
                                                        onClick={() => this.setState({showInstructions: false})}>
                                                    <FontAwesomeIcon icon={'times-circle'}/>
                                                </Button>
                                            </Card.Title>
                                            <p>
                                                El importador de códigos postales permite configurar la validación del popup de la tienda de Nbio.
                                                El popup toma los datos ingresados por medio del importador y regresa si existe cobertura en esa zona.
                                                El popup no toma en cuenta los datos de las regiones por lo que es necesario ingresar todos los códigos
                                                postales pertenecientes a todas las regiones. De la misma manera el popup puede arrojar falsos negativos si las
                                                regiones y códigos postales no están configurados correctamente.
                                            </p>
                                            <ol>
                                                <li>Descargar el archivo de muestra</li>
                                                <li>Agrega tus códigos postales y da click en subir archivo. Debe de estar en formato .xlsx</li>
                                                <li>Espera el resultado de la validación</li>
                                            </ol>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </NPIf>

                        <Row className={'my-5'}>
                            <Col>
                                {this.renderComponent()}
                            </Col>
                        </Row>
                    </Col>
                    <Col className='py-3 d-sm-none d-md-block ml-auto' md={2}>
                        {/*//UPLOAD_FILE, VALIDATE_FILE, CONFIRM_SAVE, RESPONSE_IMPORTER, UPLOAD_IMAGES,*/}
                        <Nav activeKey={this.state.step} className={'flex-column border-left'}>
                            <Nav.Item>
                                <Nav.Link eventKey="UPLOAD_FILE">Subir archivo</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="VALIDATE_FILE">Validar archivo</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="IMPORTING">Importar códigos postales</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                {/* MORE OPTIONS*/}
                {
                    this.state.step === 'UPLOAD_FILE' ?
                    <Row>
                    <Col className={'ml-auto mr-auto'} sm={12} md={9}>
                        <Row>
                            <Col className='mt-3 d-flex align-items-center justify-content-between'>
                                <h5 className={'text-capitalize '}>Más opciones</h5>

                            </Col>
                        </Row>
                        <Button onClick={() => this.downloadZipCodes()}>
                            {
                                this.state.isDownloading ?
                                    <Spinner animation={'border'} size={'sm'}/>
                                    :
                                    <>
                                        Exportar códigos postales &nbsp;
                                        <span><FontAwesomeIcon icon={'file-excel'}/>
                                        </span>
                                    </>

                            }

                        </Button>
                        <Button variant={'danger'} className={'ml-1'} onClick={() => this.deleteAll()}>
                            Borrar todo
                        </Button>

                    </Col>
                    <Col className='py-3 d-sm-none d-md-block ml-auto' md={2}>

                    </Col>


                </Row>
                    : null
                }
            </Container>
        )
    }
    doDownloadZipCodes(){
        // TODO: CALL
        NbioApi.zipCodes.download().then((res) =>{
            const url  = window.URL.createObjectURL(
                new Blob([res.data],
                    {
                        type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    }))
            const link = document.createElement('a');
            link.href  = url;
            link.setAttribute('download', 'Reporte-Cp.xlsx');
            document.body.appendChild(link);
            link.click();
            this.setState({
                isDownloading:false
            });
        }).catch((ex) =>{
            this.setState({
                isDownloading:false
            });
        })

    }
    downloadZipCodes() {
        if (!this.state.isDownloading) {
            this.setState({
                isDownloading: true
            }, () => {
                this.doDownloadZipCodes();
            })
        }
    }
    deleteAll(){
        Swal.fire({
            icon: 'warning',
            title: '¿Estás seguro de borrar todos los códigos postales?',
            cancelButtonText: 'No',
            confirmButtonColor: '#f32735',
            confirmButtonText: 'Si, borrar',
            showCancelButton: true,
            reverseButtons: true,
            heightAuto:false
        }).then((res) => {
            if (res.isConfirmed) {
                NbioApi.zipCodes.deleteAllZipCodes().then((res) =>{
                    Swal.fire({
                        icon: 'success',
                        title: 'Códigos postales borrados',
                    }).then((res) => {
                    })

                }).catch((ex) =>{
                    Swal.fire({
                        icon: 'error',
                        title: 'Hubo un error borrando los códigos postales',
                    }).then((res) => {
                    })

                })
            }
        })

    }
}

export default withRouter(ZipCodesBulkImporter);

