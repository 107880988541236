/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NPIf from "np-if";
import Form from "react-bootstrap/Form";
import API from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Alert from "react-bootstrap/Alert";

//components
import DragZone from "../ui/Dropzone";
import UsersPicker from "../ui/pickers/UsersPicker";
import DevicesPicker from "../ui/pickers/DevicesPicker";
import Spinner from "react-bootstrap/cjs/Spinner";


class Vehicle extends React.Component {
    constructor(p) {
        super(p);
        this.state                = {
            vehicle: {
                operators: [],
                devices: []
            },
            isLoading: true,
            isSaving: false
        }
        this.getVehicle           = this.getVehicle.bind(this);
        this.onClickBtn           = this.onClickBtn.bind(this);
        this.createVehicle        = this.createVehicle.bind(this);
        this.updateVehicle        = this.updateVehicle.bind(this);
        this.deleteVehicle        = this.deleteVehicle.bind(this);
        this.confirmDeleteVehicle = this.confirmDeleteVehicle.bind(this);
        this.onOperatorsChanged   = this.onOperatorsChanged.bind(this);
        this.onDevicesChanged     = this.onDevicesChanged.bind(this);


    }

    componentDidMount() {
        const vehicleId = this.props.match.params.id;
        if (vehicleId !== 'create') {
            this.setTitle('Editar vehículo');
            this.setLabel('Guardar cambios');
            this.getVehicle(vehicleId);
        } else {
            this.setTitle('Crear vehículo');
            this.setLabel('Crear vehículo');
            this.setState({isLoading: false})
        }
    }

    setTitle = (title) => {
        this.setState({title: title});
    }

    setLabel = (label) => {
        this.setState({btnLabel: label});
    }

    getVehicle = (vehicleId) => {
        this.setState({isLoading: true})
        API.vehicles.getVehicle(vehicleId).then(res => {
            const vehicle = res.data.vehicle;
            this.setState({vehicle: vehicle, isLoading: false});
        }).catch(ex => {
            console.log(ex);
        })
    }

    onChange = (e) => {
        this.setState(prevState => {
            let vehicle          = Object.assign({}, prevState.vehicle);
            vehicle[e.target.id] = e.target.value;
            return {vehicle};
        });
    }

    onClickBtn = () => {
        const {isValid, message} = this.isValidForm();
        const vehicleId          = this.props.match.params.id;
        this.setState({
            isSaving: true
        });
        if (isValid) {
            if (vehicleId === 'create') {
                //create vehicle
                this.createVehicle();
            } else {
                //update vehicle
                this.updateVehicle();
            }
        } else {
            Swal.fire({
                title: 'Datos inválidos',
                html: message,
                icon: 'warning',
                heightAuto:false
            });
            this.setState({
                isSaving: false
            });
        }
    }
    updateVehicle = () => {
        const vehicle     = {...this.state.vehicle};
        vehicle.operators = vehicle.operators.map((o) => o._id);
        vehicle.devices   = vehicle.devices.map((d) => d._id);
        this.setState({
            isSaving: true
        });
        API.vehicles.updateVehicle(vehicle).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'Vehículo actualizado',
                confirmButtonColor: '#4dda85',
                heightAuto:false
            }).then((res) => {
                this.setState({
                    isSaving: false
                });
                this.props.history.push('/vehicles');
            })
        })
            .catch((ex) => {
                let errorMsg = 'Hubo un error al actualizar el vehículo';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {

                }
                this.setState({
                    isSaving: false
                });
                this.fireSwalError(errorMsg);
            })
    }
    createVehicle = () => {
        this.setState({
            isSaving: true
        });
        const vehicle     = {...this.state.vehicle};
        vehicle.operators = vehicle.operators.map((o) => o._id);
        vehicle.devices   = vehicle.devices.map((d) => d._id);
        API.vehicles.create(vehicle).then((res) => {
            Swal.fire({
                icon: 'success',
                title: 'Vehículo creado',
                confirmButtonColor: '#4dda85',
                heightAuto:false
            }).then((res) => {
                this.setState({
                    isSaving: false
                });
                this.props.history.push('/vehicles');
            })
        })
            .catch((ex) => {
                let errorMsg = 'Hubo un error al crear el vehículo';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {

                }
                this.setState({
                    isSaving: false
                });
                this.fireSwalError(errorMsg);
            })
    }


    deleteVehicle = () => {
        const vehicleId = this.props.match.params.id;
        this.setState({
            isSaving: true
        });
        API.vehicles.deleteVehicle(vehicleId).then(res => {
            Swal.fire({
                icon: 'success',
                title: 'Vehículo borrado',
                confirmButtonColor: '#4dda85',
                heightAuto:false
            }).then((res) => {
                this.setState({
                    isSaving: false
                });
                this.props.history.push('/vehicles');
            });
        }).catch(ex => {
            let errorMsg = 'Hubo un error al borrar el usuario'
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {
                errorMsg = 'Servicio no disponible';
            }
            this.setState({
                isSaving: false
            });
            this.fireSwalError(errorMsg);
        })
    }
    fireSwalError = (txt) => {
        Swal.fire({
            icon: 'error',
            title: txt,
            confirmButtonColor: '#2a7de1',
            heightAuto:false
        })
    }


    confirmDeleteVehicle = () => {
        Swal.fire({
            icon: 'warning',
            title: "¿Estás seguro de borrar el vehículo?",
            html: "El vehículo será borrado permanentemente del sistema. Esta acción no puede ser revertida",
            confirmButtonColor: '#f32735',
            cancelButtonText: 'No',
            confirmButtonText: 'Si, borrar',
            showCancelButton: true,
            reverseButtons: true,
            heightAuto:false
        }).then((res) => {
            if (res.isConfirmed) {
                //Delete user
                this.deleteVehicle();
            }
        });
    }

    isValidForm() {
        const {vehicle} = this.state;
        // validate SKU
        if (isEmpty(vehicle.name)) {
            return {
                message: 'El nombre no puede estar vacío',
                isValid: false
            }
        } else if (isEmpty(vehicle.plate)) {
            return {
                message: 'Las placas no pueden estar vacías',
                isValid: false
            }
        }
        return {
            message: '',
            isValid: true
        }
    }

    render() {
        const vehicle   = this.state.vehicle;
        const vehicleId = this.props.match.params.id;
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3'>
                        <div className={'d-flex align-items-center'}>
                            <h5 className={'text-capitalize mr-auto'}>{this.state.title}</h5>
                            <NPIf condition={vehicleId !== "create"}>
                                <DropdownButton id="dropdown-basic-button" title="Configuración" variant={'danger'}>
                                    <Dropdown.Item
                                        onClick={() => this.confirmDeleteVehicle()}>Borrar vehículo
                                    </Dropdown.Item>
                                </DropdownButton>
                            </NPIf>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col className='py-3' sm={vehicleId === "create" ? 12 : 8}>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Datos del vehículo
                                </Card.Title>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="name">
                                    <Form.Label column sm={2}>Nombre</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Nombre"
                                                      value={vehicle.name}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="plate">
                                    <Form.Label column sm={2}>Placas</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Placas"
                                                      value={vehicle.plate}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="plate">
                                    <Form.Label column sm={2}>Operadores</Form.Label>
                                    <Col sm={10}>
                                        {
                                            this.state.isLoading ? <Spinner animation={'border'} variant={'primary'}/> :
                                                <UsersPicker onChange={this.onOperatorsChanged}
                                                             selectedUsers={this.state.vehicle.operators}></UsersPicker>
                                        }
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="devices">
                                    <Form.Label column sm={2}>Dispositivos</Form.Label>
                                    <Col sm={10}>
                                        {
                                            this.state.isLoading ? <Spinner animation={'border'} variant={'primary'}/> :
                                                <DevicesPicker onChange={this.onDevicesChanged}
                                                               selectedDevices={this.state.vehicle.devices}>
                                                </DevicesPicker>
                                        }

                                    </Col>
                                </Form.Group>
                                <div className={'d-flex justify-content-end'}>
                                    {
                                        this.isSaving ?
                                            <Spinner>

                                            </Spinner>
                                            :
                                            <Button onClick={() => this.onClickBtn()}
                                            >{this.state.btnLabel}</Button>
                                    }

                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
            </Container>
        )
    }

    onOperatorsChanged(operators) {
        const vehicle     = {...this.state.vehicle}
        vehicle.operators = operators;
        this.setState({
            vehicle: vehicle
        })
    }

    onDevicesChanged(devices) {
        const vehicle   = {...this.state.vehicle}
        vehicle.devices = devices;
        this.setState({
            vehicle: vehicle
        })
    }

}

export default withRouter(Vehicle);
const isEmpty = (v) => {
    if (v) {
        return v.trim() === '';
    } else {
        return true;
    }
}
