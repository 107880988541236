import React from "react";
import PropTypes from 'prop-types';
import "../../scss/components/ui/avatar.scss"
class Avatar extends React.Component{
    render(){
        let style = {
            backgroundImage: `url(${this.props.user.profileUrl})`,
        }
        return(
            <div className={'avatar-wrapper'}>
                <div className={'avatar'} style={style}>

                </div>
                <div className={'name'}>
                    {this.props.user.name}
                </div>
            </div>

        )
    }

}
Avatar.defaultProps = {
    /** User object**/
    user:{
        '_id':'',
        'name':'',
        'email':'',
        'last_name':'',
        'created_at':'',
        'source':'',
        'uid':'',
        'profileUrl':'',
        'profileUrlThumbnail':'',
        'roles':[]
    },
    /** Called when the user clicks on the component**/
    onClick: (tags) => {
    },
}
Avatar.propTypes = {
    user:PropTypes.object,
    onClick:PropTypes.func
}

export default Avatar;
