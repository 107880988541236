const PARCELS = [
    {
        name:'Baja Pack',
        trackingUrl:'https://bajapack.com/rastrear/',
        generateTrackingUrl:(parcelNumber) =>{
            return `https://bajapack.com/rastrear/`
        }
    },
    {
        name:'Estafeta',
        trackingUrl:'https://www.estafeta.com/herramientas/rastreo',
        generateTrackingUrl:(parcelNumber) =>{
            return `https://cs.estafeta.com/es/Tracking/searchByGet?wayBill=${parcelNumber}&wayBillType=0&isShipmentDetail=False`
        }
    },
    {
        name:'Fedex',
        trackingUrl:'https://www.fedex.com/es-mx/tracking.html',
        generateTrackingUrl:(parcelNumber) =>{
            return `https://www.fedex.com/fedextrack/?trknbr=${parcelNumber}`
        }
    },
    {
        name:'MexPost',
        trackingUrl:'https://www.portal.correosdemexico.com.mx/portal/',
        generateTrackingUrl:(parcelNumber) =>{
            return `https://www.portal.correosdemexico.com.mx/portal/`
        }
    },
    {
        name:'Paquetexpress',
        trackingUrl:'https://www.paquetexpress.com.mx/rastreo',
        generateTrackingUrl:(parcelNumber) =>{
            return `https://www.paquetexpress.com.mx/rastreo/${parcelNumber}`
        }
    },
    {
        name:'RedPack',
        trackingUrl:'https://www.redpack.com.mx/es/inicio/',
        generateTrackingUrl:(parcelNumber) =>{
            return `https://www.redpack.com.mx/es/rastreo/?guias=${parcelNumber}`
        }
    },
    {
        name:'QualityPost',
        trackingUrl:'https://tracking.qualitypost.com.mx/search.php',
        generateTrackingUrl:(parcelNumber) =>{
            return `https://tracking.qualitypost.com.mx/search.php`
        }
    },
    {
        name:'TresGuerras',
        trackingUrl:'https://www.tresguerras.com.mx/3G/tracking.php',
        generateTrackingUrl:(parcelNumber) =>{
            return `https://www.tresguerras.com.mx/3G/tracking.php`
        }
    }
]
module.exports = {
    parcels:PARCELS
}