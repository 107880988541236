/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */
import React from "react";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import PropTypes from "prop-types";
import NPIf from "np-if";
import SegmentsPicker from "../segments/SegmentsPicker";
import Button from "react-bootstrap/Button";

class CouponAudience extends React.Component {
    constructor(p) {
        super(p);
        this.state                       = {
            distributionMethod: '',
            audience: '',
            userSegments: []
        }
        this.onDistributionMethodChanged = this.onDistributionMethodChanged.bind(this);
        this.onSubSegmentsChanged        = this.onSubSegmentsChanged.bind(this);

        this.isSaveDisabled = this.isSaveDisabled.bind(this);
    }

    onSubSegmentsChanged(subSegments) {
        this.setState({userSegments: subSegments});
    }

    onDistributionMethodChanged(event) {
        const distributionMethod = event.target.value;
        this.setState({distributionMethod: distributionMethod})
    }

    onAudienceChanged(event) {
        const audience = event.target.value;
        this.setState({audience: audience})
    }
    isSaveDisabled(){
        if(this.state.distributionMethod === 'external'){
            return false;
        }else{
            if(this.state.audience === 'segmentation'){
                return this.state.userSegments.length === 0
            }else{
                return !(!!this.state.audience);
            }
        }
    }
    onClickCancel(){
        this.props.onCancel();

    }
    onClickSave(){
        this.props.onSave({
            distributionMethod: this.state.distributionMethod,
            audience: this.state.audience || 'all',
            userSegments: this.state.userSegments
        })
    }
    render() {
        const distributionMethod = this.state.distributionMethod;
        const audience           = this.state.audience;

        return (
            <>
                <Row>
                    <Col className='py-3' md={12}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>¿Cómo quieres distribuir el
                                    cupón?</Card.Title>
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="distributionMethod">
                                    <Form.Label column sm={2}>Tipo de cupón</Form.Label>
                                    <Col sm={6}>
                                        <Form.Check
                                            label="Dentro de la aplicación"
                                            name="distributionMethod"
                                            type={"radio"}
                                            id={"distributionMethod"}
                                            value={"internal"}
                                            onChange={event => this.onDistributionMethodChanged(event)}
                                            checked={distributionMethod === 'internal'}
                                        />
                                        <Form.Check
                                            label="Fuera de la aplicación (Medios impresos, redes sociales, etc.) "
                                            name="distributionMethod"
                                            type={"radio"}
                                            id={"distributionMethod2"}
                                            value={"external"}
                                            onChange={event => this.onDistributionMethodChanged(event)}
                                            checked={distributionMethod === 'external'}
                                        />

                                    </Col>

                                </Form.Group>
                            </Card.Body>

                        </Card>
                        <NPIf condition={this.state.distributionMethod === 'internal'}>
                            <Card className={'mt-3'}>
                                <Card.Body>
                                    <Card.Title className={'text-primary mb-3'}>Elige tu audiencia</Card.Title>
                                    <Form.Group as={Row} className="mt-4 mb-3" controlId="audience">
                                        <Form.Label column sm={2}>Tipo de cupón</Form.Label>
                                        <Col sm={6}>
                                            <Form.Check
                                                label="Todos los usuarios dentro de la aplicación"
                                                name="audience"
                                                type={"radio"}
                                                id={"audience"}
                                                value={"all"}
                                                onChange={event => this.onAudienceChanged(event)}
                                                checked={audience === 'all'}
                                            />
                                            <Form.Check
                                                label="Usuarios que eliges por medio de la segmentación"
                                                name="audience"
                                                type={"radio"}
                                                id={"audience2"}
                                                value={"segmentation"}
                                                onChange={event => this.onAudienceChanged(event)}
                                                checked={audience === 'segmentation'}
                                            />

                                        </Col>

                                    </Form.Group>
                                </Card.Body>

                            </Card>
                            <NPIf condition={this.state.audience === 'segmentation'}>
                                <Card className={'mt-3'}>
                                    <Card.Body>
                                        <Card.Title className={'text-primary'}>Configura la segmentación</Card.Title>
                                        <Form.Group as={Row} className="mb-3" controlId="segments">
                                            <Form.Label column sm={12}>Selecciona los segmentos a los que se enviará el
                                                cupón</Form.Label>
                                            <SegmentsPicker
                                                onSubSegmentsChanged={this.onSubSegmentsChanged}
                                                // preselectedSegments={this.state.coupon.userSegments}
                                            ></SegmentsPicker>

                                        </Form.Group>

                                    </Card.Body>
                                </Card>
                            </NPIf>
                        </NPIf>


                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col md={12} className={'d-flex justify-content-end'}>
                        <Button onClick={() => this.onClickCancel()} variant={'outline-danger'}>Cancelar</Button>
                        <Button onClick={() => this.onClickSave()} className={'ml-1'} disabled={this.isSaveDisabled()}>
                            Guardar y continuar
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
}

CouponAudience.propTypes    = {
    coupon: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func

}
CouponAudience.defaultProps = {
    coupon: {},
    onCancel: () => {},
    onSave: () => {}
}
export default CouponAudience;
