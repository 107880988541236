/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2023
 */

import React from "react";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PropTypes from "prop-types";
import NPIf from "np-if";
import Button from "react-bootstrap/Button";
import{money} from "../../lib/money/money";
import NbioApi from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import {Alert} from "react-bootstrap";


//DATES
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);


class DiscountSummary extends React.Component {
    constructor(p) {
        super(p);
    }

    renderDate = (date) => {
        if(date){
            return dayjs(date).format('DD-MMM-YYYY')
        }else{
            return '---'
        }
    }

    renderType = (type) => {
        switch (type){
            case 'fixed_amount':
                return 'Cantidad';
                break;
            case 'percentage':
                return 'Porcentaje';
                break;
            default:
                return '---';
                break;
        }
    }
    
    renderProducts = (products, categories) => {
        const p = products.map(item => item.name);
        if(this.props.discount.appliesToAllProducts || this.props.discount.appliesToAllCategories){
            return(
                <>
                    <Col sm={2}>Productos: </Col><Col sm={10}>Todos</Col>
                    <Col sm={2} className={'mb-3'}>Categorías: </Col>
                    <Col sm={10} className={'mb-3'}>Todas</Col>
                </>
            )
        }else{
            return(
                <>
                    <Col sm={2}>Productos: </Col><Col sm={10}>{p.length > 0 ? p.join(', ') : '---'}</Col>
                    <Col sm={2} className={'mb-3'}>Categorías: </Col>
                    <Col sm={10} className={'mb-3 text-capitalize'}>{categories.length > 0 ? categories.join(', ') : '---'}</Col>
                </>
            )
        }
    }
    
    onClickDeactivate = () =>{
        let text = `Al desactivar el descuento este será removido de los productos y no podrá ser canejado. Esta acción no puede ser deshecha.`;
        Swal.fire({
            title: 'Desactivar',
            html: text,
            confirmButtonText: 'Desactivar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#f32735',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false,
            reverseButtons: true
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                this.deactivateDiscount();
            } else if(result.dismiss === Swal.DismissReason.cancel){
            }
        })
    }
    
    deactivateDiscount = () =>{
            NbioApi.discounts.deactivate(this.props.discount._id).then((res) => {
                Swal.fire({
                    title: 'Descuento desactivado',
                    html: 'Descuento desactivado',
                    icon: 'success',
                    confirmButtonColor: '#f32735',
                    heightAuto: false
                }).then((_res) => {
                    this.props.onDeactivated(this.props.discount);
                });
            }).catch((ex) => {
                let errorMsg = 'Hubo un problema al desactivar el descuento. Intenta más tarde.';
                try {
                    errorMsg = ex.response.data.error_es;
                } catch (ex) {

                }
                Swal.fire({
                    icon:'error',
                    text:errorMsg,
                });
            })
    }
    
    renderValue(){
        const discount           = this.props.discount;
        if(discount.type === 'percentage') {
            return `${discount.value} %`;
        }else if(discount.type === 'fixed_amount') {
            return `${money(discount.value)}`;
        }else{
            return discount.value;
        }
    }

    render() {
        const discount = this.props.discount;
        return (
            <>
                <Row>
                    <Col className='py-3' md={12}>
                        <NPIf condition={!discount.isActive}>
                            <Alert variant={'danger'}>
                                Descuento desactivado
                            </Alert>
                        </NPIf>

                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>Resumen del descuento</Card.Title>
                                <Row>
                                    <Col sm={2}>Nombre del descuento</Col><Col sm={10}>{discount.name}</Col>
                                    <Col sm={2}>Tipo de descuento:</Col><Col sm={10}>{this.renderType(discount.type)}</Col>
                                    <Col sm={2} className={'mb-3'}>Valor:</Col>
                                    <Col sm={10} className={'mb-3'}>{this.renderValue()}</Col>
                                </Row>
                                <Row className={'mb-2'}>
                                    <hr/>
                                    {this.renderProducts(discount.products, discount.categories)}
                                    <hr/>
                                </Row>
                                <Row>
                                    <Col sm={2}>Fecha de inicio:</Col><Col sm={10}>{this.renderDate(discount.startDate)}</Col>
                                    <Col sm={2}>Fecha de finalización:</Col><Col sm={10}>{this.renderDate(discount.endDate)}</Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col md={12} className={'d-flex justify-content-end'}>
                        <NPIf condition={discount.isActive}>
                            <Button onClick={() => this.onClickDeactivate()} className={'mr-1'}
                                    variant={'danger'}
                                    disabled={!this.props.discount.isActive}>
                                Desactivar
                            </Button>
                        </NPIf>
                    </Col>
                </Row>
            </>
        )
    }
}
export default DiscountSummary;

DiscountSummary.propTypes    = {
    discount: PropTypes.object,
    onDeactivated: PropTypes.func
}
DiscountSummary.defaultProps = {
    discount: {},
    onDeactivated: () =>{}
}

