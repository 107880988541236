/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 */
import React from "react";
import PropTypes from 'prop-types';
import API from "../../../lib/api/NbioApi";
import DropdownButton from "react-bootstrap/DropdownButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup"
import Button from "react-bootstrap/cjs/Button";


class UsersPicker extends React.Component {
    constructor() {
        super();
        this.state      = {
            users: [],
            selectedUsers: []
        }
        this.loadUsers  = this.loadUsers.bind(this);
        this.removeUser = this.removeUser.bind(this);

    }

    componentDidMount() {
        this.setState({
            selectedUsers:this.props.selectedUsers
        })
        this.loadUsers();
    }
    loadUsers() {
        API.users.getUsers(this.props.roles).then((res) => {
            this.setState({users: res.data.users})
        }).then((res) => {

        })
    }
    onUserSelected(user) {
        if (this.state.selectedUsers.some((u) => u._id === user._id)) {
            // do nothing
        } else {
            // push
            const newSelected = [...this.state.selectedUsers];
            newSelected.push(user);
            this.setState({selectedUsers: newSelected}, () =>{
                this.props.onChange(this.state.selectedUsers);
            });
        }
    }

    removeUser(user) {
        let newSelected = [...this.state.selectedUsers];
        newSelected     = newSelected.filter((u) => u._id !== user._id)
        this.setState({selectedUsers: newSelected}, () =>{
            this.props.onChange(this.state.selectedUsers);
        });
    }

    render() {
        return (
            <div>
                {/*  SELECTED USERS AREA  */}
                <DropdownButton as={ButtonGroup}
                                variant="outline-primary"
                                className={'text-uppercase'}
                                title={this.props.label}>
                    {
                        this.state.users.map(operator => {
                            return (
                                <Dropdown.Item key={operator.uid}
                                               className={'text-capitalize'}
                                               onClick={() => this.onUserSelected(operator)}>
                                    {operator.name} {operator.last_name}
                                </Dropdown.Item>
                            )
                        })
                    }
                </DropdownButton>
                <ListGroup className={'mt-2'}>
                    {
                        this.state.selectedUsers.map((user) => {
                            return (
                                <ListGroup.Item key={`${user._id}_selected`} className={'d-flex justify-content-between'}>
                                    {printNameAndEmail(user)}
                                    <Button className={'link-danger'}
                                            size={"sm"}
                                            variant={'link'}
                                            onClick={() => this.removeUser(user)}>Remover</Button>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </div>

        )
    }

}

const printNameAndEmail  = (user) => {
    return `${[user.name, user.last_name].filter((n) => n).join(" ")} (${user.email})`
}
UsersPicker.defaultProps = {
    /** User object**/
    selectedUsers: [],
    roles: 'operator',
    /** Called when the user changes the selected users on the component**/
    onChange: (users) => {
    },
    label:'AGREGAR OPERADOR'
}
UsersPicker.propTypes    = {
    selectedUsers: PropTypes.array,
    onChange: PropTypes.func,
    roles: PropTypes.string,
    label: PropTypes.string
}

export default UsersPicker;
