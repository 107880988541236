import React from "react";
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import WSSClient from "../../lib/wss/wss-client";
const _ = require('underscore');

class WSSClientComponent extends React.Component {
    constructor() {
        super();
        this.state = {
        }
        this.wssOnMessage       = this.wssOnMessage.bind(this);
        this.connectWSS         = this.connectWSS.bind(this);
    }
    componentDidMount() {
        this.connectWSS();
    }
    connectWSS() {
        const _this = this;
        WSSClient.startListening().then((socket) => {
            socket.addEventListener('message', this.wssOnMessage);
            socket.addEventListener('close',() =>{
                socket.removeEventListener('message',this.wssOnMessage);
                setTimeout(() =>{
                    _this.connectWSS();
                },3000)
            })
        })
    }
    wssOnMessage(event){
        let jsonEvent = {}
        try{
            jsonEvent = JSON.parse(event.data);
        }catch (e) {

        }
        const wssEvent = new CustomEvent('nbioWssEvent', {
            detail:jsonEvent
        });
        window.dispatchEvent(wssEvent);
        switch (jsonEvent.nbioEvent) {
            case WSSClient.NBIO_EVENTS.ORDER_CREATED:
                break;
            case WSSClient.NBIO_EVENTS.ORDER_UPDATED:
                break;
            case WSSClient.NBIO_EVENTS.ORDER_CANCELLED:
                break;
            default:
                return -1;
        }
    }

    render() {
        return (
           null
        )
    }
}

WSSClientComponent.defaultProps =
    {}
WSSClientComponent.propTypes    =
    {}

export default withRouter(WSSClientComponent);
