/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2023
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import NbioApi from "../../lib/api/NbioApi";
import BootstrapTable from "react-bootstrap-table-next";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from 'react-bootstrap/Spinner'
import {money} from "../../lib/money/money";

//components
import NPPaginator from "../ui/NPPaginator";


class DiscountTable extends React.Component {
    constructor(p) {
        super(p);
        this.state       = {
            discounts: [],
            isLoading: true,
            pages: 1,
            currentPage: 1,
        }
        this.loadDiscounts = this.loadDiscounts.bind(this);
    }

    componentDidMount() {
        this.loadDiscounts();
    }

    loadDiscounts = () =>  {
        const pageSize = 25;
        this.setState({isLoading: false});
        NbioApi.discounts.getDiscounts(this.state.currentPage, pageSize).then(res => {
            this.setState({
                discounts: res.data.discounts,
                isLoading: false,
                pages: res.data.pages
            });
        }).catch((ex) => {
            this.setState({
                discounts: [],
                isLoading: false,
                pages: 1,
            });
        })
    }

    onPageChanged(page) {
        this.setState({currentPage: page}, () => {
            this.loadDiscounts();
        })
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: 'Nombre',
                formatter:(v,row) =>{
                    return(
                        <div>
                            <a href={`/discounts/${row._id}`}>{v}</a>
                        </div>
                    )
                }
            },
            {
                dataField: 'value',
                text: 'Valor',
                formatter:(v,row) =>{
                    let txt = ''
                    if(row.type === 'fixed_amount'){
                        txt = `${money(v)}`
                    }
                    if(row.type === 'percentage'){
                        txt = `${v} %`
                    }
                    return txt
                }
            },
            {
                dataField: 'isActive',
                text: '¿Activo?',
                formatter:(v,row) =>{
                    return v ? 'Si' : 'No'
                }
            },

        ];

        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3 d-flex justify-content-between'>
                        <h5 className={'text-capitalize '}>Descuentos</h5>
                        <a href={'/discounts/create'}>
                            <Button className={'ml-auto'}>Crear descuento</Button>
                        </a>
                    </Col>
                </Row>

                <Row>
                    <Col className='pb-3'>
                        <NPIf condition={this.state.isLoading}>
                            <div className={'d-flex align-items-center justify-content-center'}>
                                <Spinner animation="grow" variant={"secondary"}/>
                            </div>
                            <NPElse>
                                <BootstrapTable keyField='_id'
                                                data={this.state.discounts}
                                                columns={columns}
                                                noDataIndication={`No se encontraron descuentos`}
                                                bordered={false}
                                />
                                <NPPaginator
                                    numberOfPages={this.state.pages}
                                    onPageChanged={(p) => this.onPageChanged(p)} initialPage={this.state.currentPage}>
                                </NPPaginator>
                            </NPElse>
                        </NPIf>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(DiscountTable);
