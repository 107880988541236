/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import BootstrapTable from "react-bootstrap-table-next";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from 'react-bootstrap/Spinner'
import NbioApi from "../../lib/api/NbioApi";

//components
import NPPaginator from "../ui/NPPaginator";
import SortBtns from "../ui/SortBtns";
import Button from "react-bootstrap/Button";


class VehiclesTable extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
            reviews:[],
            isLoading:true,
            pages:1,
            currentPage:1,
        }

        this.loadVehicles = this.loadVehicles.bind(this);
    }

    componentDidMount() {
        this.loadVehicles();
    }

    loadVehicles(){
        const pageSize=25;
        this.setState({isLoading:true});
        NbioApi.vehicles.getVehicles(this.state.currentPage,pageSize).then(res => {
            this.setState({
                vehicles:res.data.vehicles,
                isLoading:false,
                pages:res.data.pages
            });
        }).catch((ex) =>{
            this.setState({
                vehicles:[],
                isLoading:false,
                pages:1,
            });
        })
    }

    onPageChanged(page){
        this.setState({currentPage:page},() =>{
            this.loadVehicles();
        })
    }

    render(){
        const columns = [
            {
                dataField: 'name',
                text: 'nombre',
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'200px',
                    }
                },
                formatter: (value,row) => {
                    return <a href={`/vehicles/${row._id}`}>{value}</a>
                }
            },
            {
                dataField: 'created_at',
                text: 'Fecha de creación',
                headerStyle:(row,rowIndex) =>{
                    return {
                        width:'200px',
                    }
                },
                sort: true,
                sortCaret: (order) => {
                    return <SortBtns order={order} />
                }
            },
        ];

        return(
            <Container fluid style={{overflowY:"scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3 d-flex justify-content-between'>
                        <h5 className={'text-capitalize '}>Vehículos</h5>
                        <a href={'/vehicles/create'}>
                            <Button className={'ml-auto'}>Crear vehículo</Button>
                        </a>
                    </Col>
                </Row>


                <Row>
                    <Col className='pb-3'>
                        <NPIf condition={this.state.isLoading}>
                            <div className={'d-flex align-items-center justify-content-center'}>
                                <Spinner animation="grow" variant={"secondary"}/>
                            </div>
                            <NPElse>
                                <BootstrapTable keyField='_id'
                                                data={this.state.vehicles}
                                                columns={columns}
                                                noDataIndication={`No se encontraron vehículos`}
                                                bordered={false}
                                                striped={true}
                                />
                                <NPPaginator
                                    numberOfPages={this.state.pages}
                                    onPageChanged={(p) => this.onPageChanged(p)} initialPage={this.state.currentPage}>
                                </NPPaginator>
                            </NPElse>
                        </NPIf>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(VehiclesTable);
