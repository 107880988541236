/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import NbioApi from "../../lib/api/NbioApi";
import WSSClient from "../../lib/wss/wss-client";
import Button from "react-bootstrap/cjs/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Dropdown from "react-bootstrap/Dropdown";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import {DateRangePicker} from 'react-date-range';
import {
    defaultStaticRanges,
    defaultInputRanges
} from "react-date-range/dist/defaultRanges";
import es from 'date-fns/locale/es'
import Spinner from "react-bootstrap/cjs/Spinner";

//style
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

//components
import NPIf from "np-if";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import API from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import OrdersTable from "./OrdersTable";
import OrderScheduledPlanner from "./OrdersScheduledPlanner";
import NPElse from "np-if/src/NPElse";
import OrdersMap from "./OrdersMap";


//const
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);
const AUTO_RELOAD_MS = 20 * 1000; //each 20 seconds


class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state                         = {
            status: "",
            title: "",
            orders: [],
            pages: 1,
            currentPage: 1,
            lastUpdateDate: null,
            selectedShippingMethod: 'all',
            activeSort: 'newest',
            selectedRanges: {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection',
            },
            dateMode: 'all',
            isDownloading: false,
            isDownloadingPDF: false,
            query: '',
            activeFilter: '',
            requiresInvoice: false,
            isWebSocketConnected: false,
            regions: [],
            selectedRegions: [],
            //programmed orders blocks
            programmedOrdersBlocks:[],
            dateField:'created_at',
            routes: [],
            showOrdersMap: false,
        }

        this.loadOrders                    = this.loadOrders.bind(this);
        this.wssOnMessage                  = this.wssOnMessage.bind(this);
        this.connectWSS                    = this.connectWSS.bind(this);
        this.onSortChanged                 = this.onSortChanged.bind(this);
        this.onShippingMethodFilterChanged = this.onShippingMethodFilterChanged.bind(this);
        this.handleDateSelect              = this.handleDateSelect.bind(this);
        this.onSearch                      = this.onSearch.bind(this);
        this.onClearSearch                 = this.onClearSearch.bind(this);
        this.onChangeQuery                 = this.onChangeQuery.bind(this);
        this.onFilterChange                = this.onFilterChange.bind(this);
        this.onFilterChange                = this.onFilterChange.bind(this);
        this.startAutoReload               = this.startAutoReload.bind(this);
        this.stopAutoReload                = this.stopAutoReload.bind(this);
        this.onSelectDay                   = this.onSelectDay.bind(this);
        this.onSelectBlock                 = this.onSelectBlock.bind(this);
        this.getRoutes                     = this.getRoutes.bind(this);
    }

    componentDidMount() {
        const orderType = this.props.match.params.id;
        let title       = ''
        const status = this.props.match.params.id;
        this.setState({status: status, title: title});
        this.connectWSS();
        this.loadRegions();
        this.getRoutes();
        if (orderType === 'asap') {
            title = 'inmediatas';
            this.setState({selectedShippingMethod:'asap'}, () => {this.loadOrders()});
        } else if(orderType === 'scheduled'){
            this.loadProgrammedSegments();
            this.setState({selectedShippingMethod:'programmed',dateField:'deliveryBlock.startTime'}, () => {this.loadOrders()});
            title = 'programadas';
        } else if (orderType === 'pending'){
            title = 'pendientes';
            this.loadOrders();
        } if (orderType === 'finished') {
            title = 'terminadas';
            this.loadOrders();
        } else if (orderType === 'paymentIntent') {
            title = 'con intento de pago';
            this.loadOrders();
        }

        this.setState({title:title});
    }

    getRoutes = () => {
        this.setState({isLoading:true});
        API.routes.getRoutes().then(res => {
            this.setState({
                routes:res.data.routes,
                isLoading:false
            }, () =>{
              this.loadOrders();
            });
        }).catch(ex => {

        })
    }

    onShippingMethodFilterChanged(value) {
        this.setState({selectedShippingMethod: value}, () => {
            this.loadOrders();
        })
    }

    startAutoReload() {
        if (!this.autoReloadInterval) {
            this.loadOrders(false, false);
            this.autoReloadInterval = setInterval(() => {
                this.loadOrders(false, false);
            }, AUTO_RELOAD_MS)
        } else {
        }

    }

    stopAutoReload() {
        clearInterval(this.autoReloadInterval)

    }

    componentWillUnmount() {
        this.stopAutoReload();
    }

    loadRegions() {
        this.setState({isLoading:true});
        API.regions.getRegions().then((res) => {
            this.setState({
                regions: res.data.regions.map((region) => {
                    return {
                        name: region.name,
                        _id: region._id,
                        color: region.color,
                        isLoading: false
                    }
                })
            })
        }).catch((ex) => {

        })
    }

    onRegionSelected(region){
        let _newRegions = [...this.state.selectedRegions];
        if(_newRegions.map((r) => r._id).includes(region._id)){
            _newRegions = _newRegions.filter((r) => r._id !== region._id);
        }else{
            _newRegions.push(region);
        }
        this.setState({
            selectedRegions: _newRegions
        },() =>{
            this.loadOrders();
        })
    }

    connectWSS() {
        const _this = this;
        WSSClient.startListening().then((socket) => {
            socket.addEventListener('open', () => {
            });
            socket.onopen = function () {
                // reload just in case some order got in-between
                _this.loadOrders(false, false);
                _this.stopAutoReload();
                _this.setState({
                    isWebSocketConnected: true
                })
            }
            socket.addEventListener('message', this.wssOnMessage);
            socket.addEventListener('close', () => {
                this.startAutoReload();
                this.setState({
                    isWebSocketConnected: false
                })
                socket.removeEventListener('message', this.wssOnMessage);
                setTimeout(() => {
                    this.setState({
                        isWebSocketConnected: false
                    })
                    _this.connectWSS();
                }, 3000)
            })
        }).catch((ex) => {
            this.setState({
                isWebSocketConnected: false
            })
        })
    }

    wssOnMessage(event) {
        this.loadOrders(false, false);
    }

    onPageChanged(page) {
        this.setState({currentPage: page}, () => {
            this.loadOrders();
        })
    }

    downloadOrders(format = 'xlsx') {
        if(format === 'xlsx'){
            if (!this.state.isDownloading) {
                this.setState({
                    isDownloading: true
                }, () => {
                    this.loadOrders(true,false,format);
                })
            }
        }else if(format === 'pdf'){
            if (!this.state.isDownloadingPDF) {
                this.setState({
                    isDownloadingPDF: true
                }, () => {
                    this.loadOrders(true,false,format);
                })
            }
        }

    }

    loadOrders = (download = false, showLoading = true, format = 'json') => {
        let orderType = this.props.match.params.id;
        if(orderType === 'scheduled' || orderType === 'asap'){
            orderType = 'pending';
        }
        let startDate = '';
        let endDate   = '';
        if(this.state.selectedShippingMethod === 'programmed'){
            console.log('here')
            startDate = this.state.selectedRanges.startDate;
            endDate   = this.state.selectedRanges.endDate;
            // debugger;
        }else{
            startDate = this.state.dateMode === 'range' ?
                dayjs(this.state.selectedRanges.startDate).format('MM-DD-YYYY') : '';
            endDate   = this.state.dateMode === 'range' ?
                dayjs(this.state.selectedRanges.endDate).format('MM-DD-YYYY') : '';
        }
        if (!download) {
            if (showLoading) {
                this.setState({isLoading: true})
            }

        }
        NbioApi.orders.getOrders(
            this.state.currentPage,
            50,
            orderType,
            this.state.selectedShippingMethod,
            this.state.activeSort,
            startDate,
            endDate,
            format,
            this.state.query,
            this.state.requiresInvoice,
            this.state.selectedRegions.map((region) => region._id),
            this.state.dateField
        ).then((res) => {
            if (!download) {
                this.setState(
                    {
                        orders: res.data.orders,
                        pages: res.data.pages,
                        lastUpdateDate: new Date(),
                        isLoading: false
                    }
                );
            } else {


                if(format === 'pdf'){
                    this.setState({
                        isDownloadingPDF: false
                    })
                    return Swal.fire({
                        title: 'Éxito',
                        icon: 'success',
                        html: res.data.message_es,
                        heightAuto:false
                    })
                }
                else if(format === 'xlsx'){
                    this.setState({
                        isDownloading: false
                    })
                }

                const url  = window.URL.createObjectURL(
                    new Blob([res.data],
                        {
                            type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        }))
                const link = document.createElement('a');
                link.href  = url;
                link.setAttribute('download', 'Reporte-Ordenes.xlsx');
                document.body.appendChild(link);
                link.click();
            }

        }).catch((ex) => {

        })
    }

    onSortChanged = (value) => {
        this.setState({activeSort: value}, () => {
            this.loadOrders();
        });
    }

    formatDate(date) {
        if (!date) {
            return '-';
        }
        return dayjs(date).format('DD MMM YYYY - HH:mm:ss');
    }

    onAllDatesClicked() {
        this.setState({dateMode: 'all'}, () => {
            this.loadOrders()
        });
    }

    handleDateSelect(ranges) {
        this.setState({
            selectedRanges: ranges.selection,
            dateMode: 'range'
        }, () => {
            this.loadOrders()
        })
    }

    onSearch = () => {
        this.setState({currentPage: 1}, () => {
            this.loadOrders();
        })
    }

    onClearSearch = () => {
        this.setState({query: '', page: 1}, () => {
            this.loadOrders();
        })
    }

    onChangeQuery = (e) => {
        this.setState({query: e.target.value});
    }

    onFilterChange = (value) => {
        this.setState({requiresInvoice: value}, () => {
            this.loadOrders();
        });
    }

    onSelectDay = (day) => {
        const blocks = day.blocks || [];
        if(blocks.length > 0){
            this.setState({
                selectedRanges:{
                    startDate: day.startOfDay,
                    endDate: day.endOfDay,
                    key: 'selection',
                }
            },() =>{
                this.loadOrders();
            })
        }
    }

    onSelectBlock = (block) => {
        this.setState({
            selectedRanges:{
                startDate: block.startTimeUTC,
                endDate: block.endTimeUTC,
                key: 'selection',
            }
        },() =>{
            this.loadOrders();
        })
    }
    /**
     * SchedulePlanner
     */
    loadProgrammedSegments(){
        NbioApi.orders.getDeliveryBlocks().then((res) =>{
            this.setState({
                programmedOrdersBlocks:res.data.blocks
            })
        }).catch((ex) =>{

        })
    }
    render() {
        const orderType = this.props.match.params.id;

        const selectedShippingMethod = this.state.selectedShippingMethod;
        const regions                = this.state.regions;
        const selectedRegionIds      = this.state.selectedRegions.map((r) => r._id);

        const selectionRange         = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }
        const staticRangesLabels     = {
            "Today": "Hoy",
            "Yesterday": "Ayer",
            "This Week": "Esta semana",
            "Last Week": "La última semana",
            "This Month": "Este mes",
            "Last Month": "El último mes"
        };

        const inputRangesLabels = {
            "days up to today": "Días hasta hoy",
            "days starting today": "Días a partir de hoy"
        };

        function translateRange(dictionary) {
            return (item) =>
                dictionary[item.label] ? {...item, label: dictionary[item.label]} : item;
        }

        const esStaticRanges = defaultStaticRanges.map(translateRange(staticRangesLabels));
        const esInputRanges  = defaultInputRanges.map(translateRange(inputRangesLabels));
        return (
            <Container fluid className={'h-100 overflow-auto'}>
                <Row>
                    <Col className='py-3 d-flex flex-row align-items-center justify-content-between'>
                        <h5 className={'text-capitalize'}>Órdenes {this.state.title}</h5>
                        <div className={'d-flex flex-row align-items-center'}>
                            <small>
                                Última actualización:<br/>
                                <b>{this.formatDate(this.state.lastUpdateDate)}</b>
                            </small>
                            <ButtonGroup className={'ml-2'}>
                                <Button onClick={() => this.loadOrders()}>
                                    <FontAwesomeIcon icon={'sync-alt'}/>
                                </Button>
                                <OverlayTrigger
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id="tooltip-disabled">
                                            {
                                                this.state.isWebSocketConnected ? 'Datos en tiempo real' : 'Los datos no están en tiempo real. Actualizando cada 20 segundos.'
                                            }
                                        </Tooltip>
                                    }>
                                    <Button variant={'outline-info'}>
                                        <FontAwesomeIcon icon={'circle'}
                                                         color={this.state.isWebSocketConnected ? 'green' : 'gray'}/>
                                    </Button>
                                </OverlayTrigger>
                            </ButtonGroup>

                        </div>
                    </Col>
                </Row>

                <Row className={'my-3'}>
                    <Col md={4} xl={5}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>
                                <FontAwesomeIcon icon={"search"}/>
                            </InputGroup.Text>
                            <FormControl
                                id={'query'}
                                type={'text'}
                                placeholder="Buscar orden por id, nombre o apellido"
                                value={this.state.query}
                                onKeyUp={event => {
                                    if (event.keyCode === 13) {
                                        this.onSearch();
                                    }
                                }}
                                onChange={event => this.onChangeQuery(event)}
                            />
                            <Button variant="outline-info"
                                    disabled={this.state.query.trim() === ''}
                                    onClick={this.onClearSearch}>
                                <FontAwesomeIcon icon={"times"}/>
                            </Button>
                            <Button variant="outline-secondary"
                                    disabled={this.state.query.trim() === ''}
                                    onClick={this.onSearch}>
                                Buscar
                            </Button>
                        </InputGroup>
                    </Col>

                    <Col md={6} xl={7} className={'d-flex justify-content-end align-items-center'}>
                        <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
                            {/*Display btns*/}
                            <ButtonGroup aria-label="Zero group" className={'me-2'}>
                                <ToggleButtonGroup type="radio"
                                                   name="showOrdersMap"
                                                   value={this.state.showOrdersMap}
                                                   onChange={(event) => this.setState({showOrdersMap:event})}>
                                    <ToggleButton style={{borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px'}}
                                                  className={'mb-0'}
                                                  variant={this.state.showOrdersMap ? 'medium' : 'outline-medium'}
                                                  id="displayMap"
                                                  value={true}>
                                        <FontAwesomeIcon icon={'map'}/>
                                    </ToggleButton>
                                    <ToggleButton variant={! this.state.showOrdersMap ? 'medium' : 'outline-medium'}
                                                  id="displayList"
                                                  className={'mb-0'}
                                                  value={false}>
                                        <FontAwesomeIcon icon={'list'}/>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </ButtonGroup>
                            <NPIf condition={! ["asap","scheduled"].includes(orderType) }>
                                <ButtonGroup aria-label="First group" className={'me-2'}>
                                    <ToggleButtonGroup type="radio"
                                                       name="selectedShippingMethod"
                                                       value={selectedShippingMethod}
                                                       onChange={(event) => this.onShippingMethodFilterChanged(event)}>
                                        <ToggleButton key={'selectedShippingMethod-1'}
                                                      style={{borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px'}}
                                                      className={'mb-0'}
                                                      variant={selectedShippingMethod === 'all' ? 'success' : 'outline-success'}
                                                      id="selectedShippingMethod-1"
                                                      value={"all"}>
                                            Todas
                                        </ToggleButton>
                                        <ToggleButton key={'selectedShippingMethod-2'}
                                                      variant={selectedShippingMethod === 'asap' ? 'success' : 'outline-success'}
                                                      id="selectedShippingMethod-2"
                                                      className={'mb-0'}
                                                      value={"asap"}>
                                            Inmediatas
                                        </ToggleButton>
                                        <ToggleButton key={'selectedShippingMethod-3'}
                                                      variant={selectedShippingMethod === 'programmed' ? 'success' : 'outline-success'}
                                                      id="selectedShippingMethod-3"
                                                      className={'mb-0'}
                                                      value={"programmed"}>
                                            Programadas
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonGroup>
                            </NPIf>
                            <ButtonGroup aria-label="Second group">
                                <NPIf condition={orderType !== 'scheduled'}>
                                    <Dropdown as={ButtonGroup}>
                                        <Dropdown.Toggle variant="info" id="dropdown-basic">
                                            <FontAwesomeIcon icon={'calendar'}/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div>
                                                <DateRangePicker
                                                    locale={es} onChange={(v) => this.handleDateSelect(v)}
                                                    ranges={[this.state.selectedRanges]}
                                                    staticRanges={esStaticRanges}
                                                    inputRanges={esInputRanges}>
                                                </DateRangePicker>
                                                <div className={'ml-3'}>
                                                    <Button onClick={() => this.onAllDatesClicked()}>Todas las
                                                        fechas</Button>
                                                </div>
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </NPIf>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle variant="info" id="dropdown-basic">
                                        <FontAwesomeIcon icon={'sort'}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.onSortChanged('newest')}
                                                       active={this.state.activeSort === 'newest'}>Más
                                            recientes </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.onSortChanged('oldest')}
                                                       active={this.state.activeSort === 'oldest'}>Menos
                                            recientes </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.onSortChanged('total_asc')}
                                                       active={this.state.activeSort === 'total_asc'}>Precio de mayor a
                                            menor </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.onSortChanged('total_desc')}
                                                       active={this.state.activeSort === 'total_desc'}>Precio de menor a
                                            mayor </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle variant="info" id="dropdown-filter">
                                        <FontAwesomeIcon icon={'filter'}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.onFilterChange(true)}
                                                       active={this.state.requiresInvoice}>
                                            Requieren Factura
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.onFilterChange(false)}
                                                       active={!this.state.requiresInvoice}>
                                            Todas
                                        </Dropdown.Item>
                                        <Dropdown.Divider>
                                        </Dropdown.Divider>
                                        <Dropdown.Item
                                            disabled={true}
                                            active={false}>
                                            Regiones
                                        </Dropdown.Item>
                                        {
                                            regions.map((region) => {

                                                return (
                                                    <Dropdown.Item onClick={(ev) => {
                                                        this.onRegionSelected(region);
                                                    }}
                                                                   active={
                                                                       selectedRegionIds.includes(region._id)
                                                                   }
                                                                   key={region._id}
                                                    >
                                                        <div className={'d-flex '} style={{justifyContent:'space-between'}}>
                                                            {region.name}
                                                            <div style={
                                                                {
                                                                    width:20,
                                                                    height:20,
                                                                    backgroundColor:region.color,
                                                                    borderRadius:5
                                                                }
                                                            }>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle variant="info" id="dropdown-filter">
                                        <FontAwesomeIcon icon={'download'}/>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.downloadOrders()}>
                                            {
                                                this.state.isDownloading ?
                                                    <Spinner animation={'border'} size={'sm'}/>
                                                    :
                                                    <span><FontAwesomeIcon icon={'file-excel'}/> Excel</span>
                                            }
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => this.downloadOrders('pdf')}>
                                            {
                                                this.state.isDownloadingPDF ?
                                                    <Spinner animation={'border'} size={'sm'}/>
                                                    :
                                                    <span><FontAwesomeIcon icon={'file-pdf'}/> PDF</span>
                                            }
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <NPIf condition={orderType === 'scheduled'}>
                            <OrderScheduledPlanner onSelectDay={(e) => this.onSelectDay(e)}
                                                   onSelectBlock={(e) => this.onSelectBlock(e)}
                                                   programmedOrdersBlocks={this.state.programmedOrdersBlocks}
                            >
                                <OrdersTable orders={this.state.orders}
                                             isLoading={this.state.isLoading}
                                             pages={this.state.pages}
                                             currentPage={this.state.currentPage}
                                             onPageChanged={(p) => this.onPageChanged(p)}
                                             routes={this.state.routes}
                                             onRouteCreated={() => this.getRoutes()}
                                >
                                </OrdersTable>
                            </OrderScheduledPlanner>
                            <NPElse>
                                <Card>
                                    <NPIf condition={this.state.showOrdersMap}>
                                        <OrdersMap orders={this.state.orders}
                                                   isLoading={this.state.isLoading}>
                                        </OrdersMap>
                                        <NPElse>
                                            <OrdersTable orders={this.state.orders}
                                                         isLoading={this.state.isLoading}
                                                         pages={this.state.pages}
                                                         currentPage={this.state.currentPage}
                                                         onPageChanged={(p) => this.onPageChanged(p)}
                                                         routes={this.state.routes}
                                                         onRouteCreated={() => this.getRoutes()}
                                            >
                                            </OrdersTable>
                                        </NPElse>
                                    </NPIf>

                                </Card>
                            </NPElse>
                        </NPIf>
                    </Col>
                </Row>
            </Container>
        )

    }


}

export default withRouter(Orders);
