/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2022
 */

import React from "react";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import PropTypes from "prop-types";
import NPIf from "np-if";
import Button from "react-bootstrap/Button";
import{money} from "../../lib/money/money";
import NbioApi from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import {Alert} from "react-bootstrap";
import {withRouter} from "react-router";
import Container from "react-bootstrap/cjs/Container";
import Navbar from "react-bootstrap/Navbar";
import Logo from "../../assets/logo-icon-small-dark-mode.png";
import Spinner from "react-bootstrap/Spinner";


//DATES
const dayjs = require('dayjs')
//CONST
const INFINITY = 9999999999;
const _  = require('underscore');

class InfluencerCouponView extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            coupon:{},
            isLoading:false,
            isErrored:false,
            errorMsg:''
        }
    }
    componentDidMount() {
        this.getInfluencerCoupon();
    }

    renderType = (type) => {
        switch (type){
            case 'total':
                return 'Total de la compra';
                break;
            case 'item':
                return 'Item de carrito';
                break;
            case 'free_shipping':
                return 'Envio gratis';
                break;
            default:
                return '---';
                break;
        }
    }

    renderMinMaxPurchase = (hasMinMax,amount) => {
        if(hasMinMax){
            return `Si / ${money(amount)}`;
        }else{
            return 'No'
        }
    }

    renderDate = (date) => {
        if(date){
            return date
        }else{
            return '---'
        }
    }
    renderPublishedBy = (user) =>{
        if(!user){
            return '-';
        }
        const name = [user.name, user.last_name].filter((n) => n).join(' ');
        return `${name} (${user.email})`;
    }

    renderDiscountType = (discount) => {
        switch (discount){
            case 'fixed_amount':
                return 'Cantidad';
                break;
            case 'percentage':
                return 'Porcentaje';
                break;
            default:
                return '---';
                break;
        }
    }

    renderDistribution = (dis) => {
        switch (dis){
            case 'internal':
                return 'Dentro de la aplicación';
                break;
            case 'external':
                return 'Fuera de las aplicación';
                break;
            default:
                return '---';
                break;
        }
    }
    createInfluencerLink = (coupon) =>{
        Swal.fire({
            title: '¿Estás seguro?',
            html: '¿Estás seguro de crear el enlace para influencers? Los datos del cupón serán visibles para los usuarios que visiten el enlace aún cuando el cupón haya caducado o desactivado.',
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#4dda85',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false,
            reverseButtons: true
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                this.doCreateInfluencerLink(coupon);
            } else if(result.dismiss === Swal.DismissReason.cancel){
            }
        })
    }
    renderAudience = (audience,segmentacionArray) => {
        const groups = _.groupBy(segmentacionArray,x => x.segmentLabel);
        const keys = Object.keys(groups);
        if(audience === 'segmentation'){
            // return 'segmentation'
            return keys.map((k) => {
                const segments = groups[k].map((s) => s.label).join(', ');
                return(
                    <>
                        <b>{k}:</b> {segments}
                        &nbsp;
                    </>
                );
            })
            // return segmentacionArray.map((s) => `${s.segmentLabel}: ${s.label}`).join(' ');
        }else if(audience === 'all'){
            return 'Todos los usuarios';
        }else{
            return '---'
        }
    }

    renderProducts = (type,products, categories) => {
        if(type === 'item'){
            const p = products.map(item => item.name);

            return(
                <>
                    <Col sm={2}>Productos: </Col><Col sm={10}>{p.length > 0 ? p.join(', ') : '---'}</Col>
                    <Col sm={2}>Categorías: </Col><Col sm={10}>{categories.length > 0 ? categories.join(', ') : '---'}</Col>
                    <hr/>
                </>
            )
        }else{
            return null
        }
    }
    onClickDeactivate = () =>{
        let text = `Al desactivar el cupón este será removido de los usuarios y no podrá ser canejado. Esta acción no puede ser deshecha.`;
        Swal.fire({
            title: 'Desactivar',
            html: text,
            confirmButtonText: 'Desactivar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#4dda85',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false,
            reverseButtons: true
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                this.deactivateCoupon();
            } else if(result.dismiss === Swal.DismissReason.cancel){
            }
        })
    }
    onClickPublish = () => {
        let text = `Una vez publicado y enviado, <u><b>el cupón no podrá ser editado</b></u>.
                    Solo se enviarán los cupones que su distribución sea por medio de la aplicación.`;

        Swal.fire({
            title: 'Publicar y enviar',
            html: text,
            confirmButtonText: 'Publicar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#4dda85',
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
            heightAuto:false,
            reverseButtons: true
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.isConfirmed) {
                this.publishCoupon();
            } else if(result.dismiss === Swal.DismissReason.cancel){
            }
        })
    }
    renderValue(){
        const coupon           = this.state.coupon;
        if(coupon.discountType === 'percentage') {
            return `${coupon.value} %`;
        }else if(coupon.discountType === 'fixed_amount') {
            return `${money(coupon.value)}`;
        }else if(coupon.discountType === 'free_shipping') {
            return 'Envío gratis'
        }else{
            return coupon.value;
        }
    }
    getInfluencerCoupon(){
        const couponId = this.props.match.params.id;
        const accessCode = this.props.match.params.code;
        this.setState({isLoading:true,isErrored:false});
        NbioApi.coupons.getAccessCode(couponId,accessCode).then((res) =>{
            this.setState({
                coupon:res.data.coupon
            })
        }).catch((ex) =>{
            const errorMsg = ex?.response?.data?.error_es || 'Hubo un error cargando los datos.';
            this.setState({errorMsg:errorMsg,isErrored:true})
        }).finally(() =>{
            this.setState({isLoading:false});
        })

    }
    render() {
        const coupon           = this.state.coupon;
        if(this.state.isLoading){
            return (
                <div className={'d-flex w-100 h-100 justify-content-center align-items-center'}>
                    <Spinner animation={'grow'} size={'xl'} variant={'info'}></Spinner>
                </div>
            )
        }
        if(this.state.isErrored){
            return (
                <div className={'d-flex w-100 h-100 justify-content-center align-items-center'}>
                    {this.state.errorMsg}
                </div>
            )
        }
        return (
            <div>
                <Navbar bg={'dark'} variant="dark">
                    <Container fluid>
                        <Navbar.Brand  className="d-inline-block align-top">
                            &nbsp;<img src={Logo} height={30}/> &nbsp;Nbio influencers
                        </Navbar.Brand>
                    </Container>
                </Navbar>
                <div style={{margin:'1rem'}}>

                    {/*<h2>Hola {coupon?.influencerName}. Estos son los resultados de tu cupón:</h2>*/}
                    <NPIf condition={!coupon.isActive}>
                        <Alert variant={'danger'}>
                            Cupón desactivado
                        </Alert>
                    </NPIf>

                    <Card >
                        <Card.Body>
                            <Card.Title className={'text-primary mb-3'}>Resumen del cupón {coupon.code}</Card.Title>

                            <Row>
                                <Col sm={2}><b>Código del cupón</b></Col><Col sm={10}>{coupon.code}</Col>
                                <Col sm={2} className={'mt-2'}><b>Tipo de cupón:</b></Col><Col sm={10}>{this.renderType(coupon.type)}</Col>
                                <Col sm={2} className={'mt-2'}><b>Tipo de descuento:</b></Col><Col sm={10}>{this.renderDiscountType(coupon.discountType)}</Col>
                                <Col sm={2} className={'mt-2'}><b>Valor:</b></Col><Col sm={10}>{this.renderValue()}</Col>
                                <div className={'nbio-hr'}/>
                                {this.renderProducts(coupon.type,coupon.products, coupon.categories)}

                                <Col sm={2}><b>Aplica con otros cupones?</b></Col><Col sm={10}>{coupon.canBeUsedWithOtherCoupons ? 'Si' : 'No'}</Col>
                                <Col sm={2} className={'mt-2'}><b>Aplica con otros descuentos?</b></Col><Col sm={10}>{coupon.canBeUsedWithDiscounts ? 'Si' : 'No'}</Col>
                                <Col sm={2} className={'mt-2'}><b>Tiene mínimo de compra?</b></Col>
                                <Col sm={10}>
                                    {this.renderMinMaxPurchase(coupon.hasMinimumPurchase, coupon.minimumPurchase)}
                                </Col>
                                <Col sm={2} className={'mt-2'}><b>Tiene máximo de compra?</b></Col>
                                <Col sm={10}>
                                    {this.renderMinMaxPurchase(coupon.hasMaximumPurchase, coupon.maximumPurchase)}
                                </Col>
                                <Col sm={2} className={'mt-2'}><b>Número de canjes del cupón:</b></Col><Col sm={10}>{coupon.maxRedeems === INFINITY ? 'No tiene máximo' : coupon.maxRedeems}</Col>
                                <Col sm={2} className={'mt-2'}><b>Número de canjes del cupón por usuario:</b></Col><Col sm={10}>{coupon.maxRedeemsPerUser}</Col>

                                <div className={'nbio-hr'}/>
                                <Col sm={2} className={'mt-2'}><b>Fecha de inicio:</b></Col><Col sm={10}>{this.renderDate(coupon.startDate)}</Col>
                                <Col sm={2} className={'mt-2'}><b>Fecha de finalización:</b></Col><Col sm={10}>{this.renderDate(coupon.endDate)}</Col>
                                <NPIf condition={coupon.isPublished}>
                                    <Col sm={2} className={'mt-2'}><b>Fecha de publicación:</b></Col><Col sm={10}>{this.renderDate(coupon.publishedAt)}</Col>
                                    <Col sm={2} className={'mt-2'}><b>Publicado por</b> </Col><Col sm={10}>{this.renderPublishedBy(coupon.publishedBy)}</Col>
                                </NPIf>

                                <div className={'nbio-hr'}/>
                                <Col sm={2}><b>Distribución:</b></Col><Col sm={10}>{this.renderDistribution(coupon.distributionMethod)}</Col>

                                <div className={'nbio-hr'}/>
                                <Col sm={2}><b>Audiencia:</b></Col><Col sm={10}>{this.renderAudience(coupon.audience,coupon.userSegments)}</Col>
                                <div className={'nbio-hr'}/>
                                <Col sm={2}><b>Veces usado:</b></Col><Col sm={10}>{coupon.redeemCount}</Col>
                                <div className={'nbio-hr'}/>
                                <Col sm={2}>
                                    <b>Premio por cupón referido:</b>
                                </Col>
                                <Col sm={10}>
                                    <b>{money(80)}</b>
                                </Col>
                                <Col sm={2}>
                                    <b>Total generado:</b>
                                </Col>
                                <Col sm={10}>
                                    <b>{money(coupon.redeemCount * 80)}</b>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </div>

        )
    }
}

InfluencerCouponView.propTypes    = {
    coupon: PropTypes.object,
    onClickCancel: PropTypes.func,
    onPublished: PropTypes.func,
    onDeactivated: PropTypes.func
}
InfluencerCouponView.defaultProps = {
    coupon: {},
    onClickCancel: () =>{},
    onPublished: () => {},
    onDeactivated: () =>{}

}
export default withRouter(InfluencerCouponView);
