/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import React from "react";
import PropTypes from 'prop-types';
import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";

//style
import '../../scss/components/ui/imageSelector.scss';

class ImageSelector extends React.Component{
    constructor() {
        super();
        this.state={
            showLightBox:false
        }
        this.renderLightBox = this.renderLightBox.bind(this);
    }
    componentDidMount() {

    }

    renderLightBox(){
        if(this.state.showLightBox){
            return (<Lightbox image={this.props.selectedImage} onClose={() => this.toggleLightBox()}></Lightbox>)
        }else{
            return null;
        }
    }
    onImageSelected(imageUrl){
        this.props.onImageSelected(imageUrl);
        this.toggleLightBox();
    }
    toggleLightBox(){
        this.setState({showLightBox:!this.state.showLightBox})
    }
    render(){
        const images = this.props.images;
        let style = {
            backgroundImage: `url(${this.props.selectedImage})`,
        }

        return(
            <div className={'image-selector'}>
                {this.renderLightBox()}
                <div className={'image-selected'} style={style} onClick={() => this.toggleLightBox()}>
                    <div className={'label'}>
                        Da click para abrir en pantalla completa
                    </div>
                </div>
                <div className={'image-carousel'}>
                    {
                        images.map(image => {
                            const isSelected = image.imageUrl === this.props.selectedImage ? 'is-selected' : '';
                            return(
                                <div key={image._id}
                                     className={`image-carousel-thumbnail ${isSelected}`}
                                     onClick={() => this.props.onImageSelected(image.imageUrl)}>
                                    <img src={image.imageThumbnailUrl} />
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

}

export default ImageSelector;


ImageSelector.propTypes = {
    images: PropTypes.array,
    selectedImage: PropTypes.string,
    onImageSelected: PropTypes.func
}

ImageSelector.defaultProps = {
    /** Image Array**/
    images: [],
    selectedImage:'',
    onImageSelected: () => {}
}
