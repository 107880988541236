import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import NbioApi from "../../lib/api/NbioApi";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../../scss/components/SegmentsPicker.scss";
import Button from "react-bootstrap/cjs/Button";
import NPIf from "np-if";
import Spinner from "react-bootstrap/cjs/Spinner";
import NPElse from "np-if/src/NPElse";
import PropTypes from "prop-types";


const SegmentsPicker = (
    {
        onSubSegmentsChanged = () =>{},
        preselectedSegments = []
    }
    ) => {
    const [segments, setSegments]                       = useState([]);
    const [currentSubSegments, setCurrentSubSegments]   = useState([]);
    const [selectedSubsegments, setSelectedSubsegments] = useState([]);
    const [usersInSegmentCount, setUsersInSegmentCount] = useState(0);
    const [isLoadingCount,setIsLoadingCount] = useState(false);

    // on init
    useEffect(() => {
        loadSegments();
        setSelectedSubsegments(preselectedSegments);
    }, []);
    // When selectedSubsegments changed
    useEffect(() =>{
        onSubSegmentsChanged(selectedSubsegments);
        countSegments();
    },[selectedSubsegments])
    const loadSegments           = async () => {
        NbioApi.segmentation.getAvailableSegments().then((res) => {
            setSegments(res.data.list);
            if (res.data.list.length > 0) {
                console.log('list', res.data.list[0].subSegments);
                setCurrentSubSegments(res.data.list[0].subSegments);
            }
        }).catch((ex) => {

        })
    }
    const onSegmentChange        = (v) => {
        setCurrentSubSegments(v.subSegments);
    }
    const countSegments          = () => {
        const subSegments = selectedSubsegments.map((f) => f.name);
        setIsLoadingCount(true);
        NbioApi.segmentation.count([], subSegments).then((res) => {
            setUsersInSegmentCount(res.data.count);
            setIsLoadingCount(false);
        }).catch((ex) => {
            setIsLoadingCount(false);
        })
    }
    const toggleSubSegment       = (subSegment) => {
        const newSelectedSubSegments   = [...selectedSubsegments];
        const selectedSubsegmentInList = newSelectedSubSegments.find((s) => s.name === subSegment.name);
        console.log('selecteda', selectedSubsegmentInList);
        if (selectedSubsegmentInList) {
            const newSelectedSubsegments = newSelectedSubSegments.filter((s) => s.name !== subSegment.name);
            setSelectedSubsegments(newSelectedSubsegments);
        } else {
            newSelectedSubSegments.push(subSegment);
            setSelectedSubsegments(newSelectedSubSegments);
        }
    }
    const isSubSegmentSelected   = (subSegment) => {
        const selectedSubsegmentInList = selectedSubsegments.find((s) => s.name === subSegment.name);
        if (selectedSubsegmentInList) {
            return {
                backgroundColor: '#2a7de1',
                color: 'white'
            }
        } else {
            return {};
        }
    }
    const selectAllSubSegments   = (segment) => {
        const subSegments            = segment.subSegments || [];
        const newSelectedSubSegments = [...selectedSubsegments];
        subSegments.forEach((subSegment) => {
            const selectedSubsegmentInList = selectedSubsegments.find((s) => s.name === subSegment.name);
            if (!selectedSubsegmentInList) {
                newSelectedSubSegments.push(subSegment);
            }
        });
        setSelectedSubsegments(newSelectedSubSegments);
    }
    const deselectAllSubSegments = (segment) => {
        const subSegments          = segment.subSegments || [];
        let newSelectedSubSegments = [...selectedSubsegments];
        const subSegmentsNames     = subSegments.map((s) => s.name);
        newSelectedSubSegments     = newSelectedSubSegments.filter((s) => !subSegmentsNames.includes(s.name));
        setSelectedSubsegments(newSelectedSubSegments);
    }
    const countSubSegments       = (segment) => {
        const subSegments = segment.subSegments || [];
        return subSegments.map((subSegment) => {
            const isInSelectedSubSegments = selectedSubsegments.find((s) => s.name === subSegment.name);
            return isInSelectedSubSegments ? 1 : 0;
        }).reduce((a, v) => a + v, 0);
    }
    return (
        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
            <Row>
                <Col sm={4}>
                    <ListGroup>
                        {
                            segments.map((segment) => {
                                const subsegments = segment.subSegments || [];
                                return (
                                    <ListGroup.Item action href={`#${segment.label}`}
                                                    onClick={() => onSegmentChange(segment)}>
                                        <div className={'d-flex justify-content-between'}>
                                            <div className={'sm-icon-holder'}>
                                                <FontAwesomeIcon icon={segment.icon}/>
                                            </div>
                                            <div className={'w-100'}>
                                                &nbsp;{segment.label}
                                            </div>
                                            <div>
                                                <NPIf condition={countSubSegments(segment) > 0}>
                                                    <span className="badge badge-danger badge-pill">
                                                        {
                                                            `${countSubSegments(segment)}/${segment.subSegments.length}`
                                                        }
                                                    </span>
                                                </NPIf>
                                            </div>
                                        </div>

                                    </ListGroup.Item>
                                );
                            })
                        }
                    </ListGroup>
                </Col>
                <Col sm={6}>
                    <Tab.Content>
                        {
                            segments.map((segment) => {
                                return (
                                    <Tab.Pane eventKey={`#${segment.label}`}>
                                        {/*{segment.label}*/}
                                        <Button size={'sm'} className={'mb-2'}
                                                onClick={() => selectAllSubSegments(segment)}>
                                            Seleccionar todos
                                        </Button>

                                        <Button size={'sm'} className={'ml-2 mb-2'}
                                                onClick={() => deselectAllSubSegments(segment)}>
                                            Remover todos
                                        </Button>

                                        <ListGroup>

                                            {
                                                currentSubSegments.map((subSegment) => {
                                                    return (
                                                        <ListGroup.Item className={'subsegment-item'}
                                                                        style={isSubSegmentSelected(subSegment)}
                                                                        onClick={() => toggleSubSegment(subSegment)}>
                                                            {subSegment.label}
                                                        </ListGroup.Item>
                                                    )
                                                })
                                            }

                                        </ListGroup>

                                    </Tab.Pane>
                                );
                            })
                        }
                    </Tab.Content>
                </Col>
                <Col sm={12} className={'mt-2 d-flex justify-content-end'}>
                    <NPIf condition={isLoadingCount}>
                        <Spinner animation={'grow'} variant={'primary'}>
                        </Spinner>
                        <NPElse>
                            <h5>Usuarios en estos segmentos: {usersInSegmentCount}</h5>
                        </NPElse>
                    </NPIf>

                </Col>
            </Row>
        </Tab.Container>
    );
}

export default SegmentsPicker;
// SegmentsPicker.propTypes    = {
//     preloadedSubSegments: PropTypes.array,
//     onSubSegmentsChanged: PropTypes.any
// }
// SegmentsPicker.defaultProps = {
//     preloadedSubSegments: [],
//     onSubSegmentsChanged: () => {}
// }
