/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import NbioApi from "../../lib/api/NbioApi";
import NPIf from "np-if";
import Nav from "react-bootstrap/Nav";
import PropTypes from "prop-types";


//const
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);

class OrderScheduledPlanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBlock : {},
            selectedTime : {},
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.programmedOrdersBlocks.length !== this.props.programmedOrdersBlocks.length) {
            if(this.props.programmedOrdersBlocks.length > 0){
                this.props.onSelectDay(this.props.programmedOrdersBlocks[0]);
                this.setState({
                    selectedBlock: this.props.programmedOrdersBlocks[0]
                })
            }
            this.updateAllCounters();
        }


    }
    updateAllCounters(){
        this.props.programmedOrdersBlocks.forEach((block) =>{
            console.log(block.startOfDay,block.endOfDay,block.shortDayLabel)

            // update day tabs;
            this.updateCounter(block.startOfDay,block.endOfDay,block.shortDayLabel)
        });
        // this.updateSelectedTimeBlock();

    }
    // updateSelectedTimeBlock(){
    //     const blocks = this.state.selectedBlock.blocks || [];
    //     blocks.forEach((block) =>{
    //         this.updateCounter(block.startTime,block.endTime,`${block.hoursLabel}-${block.id}`);
    //     })
    // }
    updateCounter(startDate,endDate,label){
        NbioApi.orders.getOrders(
            1,
            50,
            'pending',
            'programmed',
            'newest',
            startDate,
            endDate,
            'count',
            '',
            '',
            [],
            'deliveryBlock.startTime',
        ).then((res) => {
            this.setState({
                [label]:res.data.count
            })
            // console.log(label,res.data.count);

        }).catch((ex) => {

        });
    }
    onDaySelected(block){
        this.props.onSelectDay(block);
        this.updateAllCounters();
        this.setState({
            selectedBlock: block
        })

    }
    render() {
        const defaultActiveKey = this.props.programmedOrdersBlocks.length > 0? this.props.programmedOrdersBlocks[0].shortDayLabel : '';
        const selectedBlockBlocks = this.state.selectedBlock.blocks ? this.state.selectedBlock.blocks :[];
        const timeBlocks = selectedBlockBlocks.length > 0 ? selectedBlockBlocks : [];
        return (
            <>
                <Row>
                    <Col sm={1}>

                    </Col>
                    <Col>
                        <NPIf condition={defaultActiveKey}>
                            <Nav
                                 fill variant="tabs"
                                 defaultActiveKey={defaultActiveKey}
                                 onSelect={(e) => {
                                     const block = this.props.programmedOrdersBlocks.find((b) => b.shortDayLabel === e);
                                     this.onDaySelected(block);
                                 }}>
                                {
                                    this.props.programmedOrdersBlocks.map((block) => {
                                        const badgeCounter = this.state[block.shortDayLabel] || 0;
                                        return (
                                            <Nav.Item key={block.shortDayLabel}>
                                                <Nav.Link
                                                    className={'font-weight-bold'} eventKey={block.shortDayLabel}>
                                                    {block.shortDayLabel}
                                                    &nbsp;<Badge bg={badgeCounter === 0 ?'medium' : 'primary'}>{badgeCounter}</Badge>

                                                </Nav.Link>
                                            </Nav.Item>
                                        )
                                    })

                                }
                            </Nav>
                        </NPIf>

                    </Col>
                </Row>
                <Row>
                    <Col sm={1} >
                        <Nav variant="pills" defaultActiveKey="block-1" onSelect={(ev) =>{

                        }} >

                            {
                                timeBlocks.map((block) => {
                                    const key = `${block.hoursLabel}-${block.id}`;
                                    const badgeCounter = this.state[key] || 0;
                                    return (
                                        <Nav.Item key={key} onClick={() => this.onHourSelected(block)} >
                                            <Nav.Link className={'text-light font-weight-bold'}
                                                      eventKey={key}>
                                                {block.hoursLabel}
                                                {block.id}
                                            </Nav.Link>
                                        </Nav.Item>
                                    )
                                })

                            }
                        </Nav>
                    </Col>
                    <Col>
                        {this.props.children}
                    </Col>
                </Row>

            </>
        )

    }


    onHourSelected(block) {
        console.log('=>',block)
        this.updateAllCounters();
        this.props.onSelectBlock(block);
    }
}

export default withRouter(OrderScheduledPlanner);

OrderScheduledPlanner.propTypes    = {
    onSelectDay: PropTypes.func,
    onSelectBlock: PropTypes.func,
    programmedOrdersBlocks: PropTypes.array

}
OrderScheduledPlanner.defaultProps = {
    onSelectDay: () => {},
    onSelectBlock: () => {},
    programmedOrdersBlocks:[]

}
