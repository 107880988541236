/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import NbioApi from "../../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/cjs/Badge";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Nav from 'react-bootstrap/Nav';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProgressBar from "react-bootstrap/ProgressBar";
import WSSClient from "../../../lib/wss/wss-client";


//components


class UploadFile extends React.Component {
    constructor(p) {
        super(p);
        this.state            = {
            file: null,
            step: 'UPLOAD', //UPLOAD, UPLOADING, RESULT
            uploadProgress:0,
        }
        this.uploadFile       = this.uploadFile.bind(this);
        this.checkFile        = this.checkFile.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
    }
    componentDidMount() {
    }


    onUploadProgress = (ev) => {
        const progress = ev.loaded / ev.total * 100;
        this.setState({
            uploadProgress: progress.toFixed(2)
        })
    }
    uploadFile       = () => {
        this.setState({step: 'UPLOADING'}, () => {
            NbioApi.products.uploadBulk(this.state.file,this.onUploadProgress).then((res) => {
                this.props.nextStep(res.data);
            }).catch((ex) => {

            })
        });


    }

    checkFile = (file) => {
        console.log(file)
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            this.setState({file: file});
        }
    }

    renderStep = () => {
        switch (this.state.step) {
            case 'UPLOAD':
                return this.renderUploadFile();
            case 'UPLOADING':
                return this.renderUploading();
            case 'RESULT':
                return this.renderError();
            default:
                return null
        }
    }

    renderUploadFile = () => {
        return (
            <Form.Group controlId="file">
                <Form.Label></Form.Label>
                <Form.Control type="file"
                              id="file"
                              className={'border-0'}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              style={{height: 'auto'}}
                              onChange={(e) => this.checkFile(e.target.files[0])}
                />
            </Form.Group>
        )
    }

    renderUploading = () => {
        return <ProgressBar now={this.state.uploadProgress} label={`${this.state.uploadProgress}%`}/>;
    }

    renderError = () => {
        return (
            <>
                <p className={'text-danger'}>Hubo un error al subir tu archivo</p>
                <br/>
                <Button variant='link' className=''>Cerrar</Button>
            </>
        )
    }

    render() {
        const enabled = this.state.file && this.state.step === "UPLOAD";

        return (
            <Card>
                <Card.Body>
                    <Card.Title className='d-flex align-items-center justify-content-between'>
                        <span>Subir archivo</span>
                    </Card.Title>
                    <div className={'py-3'}>
                        {this.renderStep()}
                    </div>
                </Card.Body>
                <Card.Footer className={'d-flex '}>
                    <Button size="sm"
                            className={'text-uppercase ml-auto'}
                            disabled={!enabled}
                            onClick={() => this.uploadFile()}>
                        SUBIR ARCHIVO
                    </Button>
                </Card.Footer>
            </Card>
        )
    }
}

export default withRouter(UploadFile);


UploadFile.propTypes = {
    nextStep: PropTypes.func
}

UploadFile.defaultProps = {
    nextStep: () => {
    }
}
