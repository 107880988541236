import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import Card from "react-bootstrap/cjs/Card";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import Spinner from "react-bootstrap/Spinner";
import {money} from "../../lib/money/money";
import Col from "react-bootstrap/Col";

/**
 * Display the coupons applied to order
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */


class OrderCoupons extends React.Component {
    render() {
        const columns = [
            {
                dataField: 'code',
                text: 'Código',
                formatter:(v,row) =>{
                    return(
                        <div>
                            <a href={`/coupons/${row._id}`}>{v}</a>
                        </div>
                    )
                }
            },
            {
                dataField: 'value',
                text: 'Valor',
                formatter:(v,row) =>{
                    let txt = ''
                    if(row.discountType === 'fixed_amount'){
                        txt = `${money(v)}`
                    }
                    if(row.discountType === 'percentage'){
                        txt = `${v} %`
                    }
                    return txt
                }
            },
            {
                dataField: 'type',
                text: 'Tipo',
                formatter:(v,row) =>{
                    let txt = ''
                    if(v === 'free_shipping'){
                        txt = 'Envio gratis'
                    }
                    if(v === 'total'){
                        txt = 'Total de la compra'
                    }
                    if(v === 'item'){
                        txt = 'Por item'
                    }
                    return txt
                }
            },
        ];
        return (
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title className={'text-capitalize'}>
                            Cupones aplicados
                        </Card.Title>
                        <div className={'mb-2'}>
                            <NPIf condition={this.props.isLoading}>
                                <Spinner animation={'grow'} variant={'primary'}>
                                </Spinner>
                                <NPElse>
                                    <BootstrapTable keyField='_id'
                                                    data={this.props.coupons}
                                                    columns={columns}
                                                    noDataIndication={`No se aplicó ningún cupón`}
                                                    bordered={false}/>
                                </NPElse>
                            </NPIf>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        )
    }
}
export default withRouter(OrderCoupons);
OrderCoupons.propTypes    = {
    coupons: PropTypes.array,
    isLoading: PropTypes.bool
}
OrderCoupons.defaultProps = {
    coupons: [],
    isLoading: false
}

