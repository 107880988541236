/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2021
 */

import React from 'react';
import {withRouter} from "react-router";
import Container from "react-bootstrap/cjs/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/cjs/FloatingLabel";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/cjs/Button";
import NPIf from "np-if";

//images
import Logo from "../assets/nbio_logo.png";
import NbioApi from "../lib/api/NbioApi";
import NPElse from "np-if/src/NPElse";
import {Spinner} from "react-bootstrap";
import {AuthContext} from "../lib/context/AuthContext";


class LoginView extends React.Component{
    static contextType = AuthContext;
    constructor(p) {
        super(p);
        this.state = {
            email:'',
            password:'',
            errorMessage:'',
            isLoading:false
        }
        this.login = this.login.bind(this);
    }
    componentDidMount() {
        NbioApi.users.getMe().then((res) =>{
            this.props.history.push('/dashboard');
        }).catch((ex) =>{

        })
    }

    onChange(e){
        this.setState({
            [e.target.id]:e.target.value
        })
    }
    login(){
        this.setState({errorMessage:'',isLoading:true})
        NbioApi.users.login(this.state.email,this.state.password).then((res) =>{
            this.context.login(res.data.result.user);
            this.props.history.push('/dashboard');
        }).catch((ex) =>{
            console.log(ex)
            if(ex.response){
                if(ex.response.data){
                    if(ex.response.data.result){
                        if(ex.response.data.result.reason_es){
                            this.setState({errorMessage:ex.response.data.result.reason_es,isLoading:false});
                        }
                    }
                }
            }else{
                this.setState({errorMessage:'Hubo un error al iniciar sesión',isLoading:false});
            }
        })
    }

    render(){
        return(
            <Container className={"h-100"}>
                <Row className={"h-100 justify-content-center align-items-center"}>
                    <Col sm={12} md={8} lg={6}>
                        <Card className={'p-3 border-0'} bg="light">
                            <img className={"mr-auto ml-auto"} src={Logo} width={"50%"} />

                            <FloatingLabel className="mt-5" controlId="email" label="Email">
                                <Form.Control   type="email" placeholder="Email" id={'email'}
                                                onKeyUp={event => {
                                                    if (event.keyCode === 13) {
                                                        this.login();
                                                    }
                                                }}
                                                onChange={event => this.onChange(event)}
                                ></Form.Control>
                            </FloatingLabel>
                            <FloatingLabel  className="mt-3 mb-4" controlId="password" label="Contraseña">
                                <Form.Control
                                    id={'password'}
                                    type="password" placeholder="Contraseña"
                                    onKeyUp={event => {
                                        if (event.keyCode === 13) {
                                            this.login();
                                        }
                                    }}
                                    onChange={event => this.onChange(event)}
                                ></Form.Control>
                            </FloatingLabel>

                            <NPIf condition={!this.state.isLoading}>
                                <Button variant="primary" onClick={this.login}>Iniciar sesión</Button>
                                <NPElse>
                                    <div className={'text-center'} style={{height:38}}>
                                        <Spinner animation={'border'} color={'primary'}></Spinner>
                                    </div>
                                </NPElse>
                            </NPIf>

                            <div style={{color:'red',textAlign:'center',height:'20px',margin:'10px'}}>{this.state.errorMessage}</div>
                            <small className={"mt-2 text-center"}> v{process.env.REACT_APP_VERSION} - Powered by <a target="_blank" href={'https://www.nodeport.co/'}>Nodeport</a>⚡</small>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )

    }
}

export default withRouter(LoginView);
