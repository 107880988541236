/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 202
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form"
import NbioApi from "../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/cjs/Badge";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";

//components
import ProductsLogTable from "./ProductsLogTable";
import DragZone from "../ui/Dropzone";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import API from "../../lib/api/NbioApi";
import {Editor} from "@monaco-editor/react";
import Spinner from "react-bootstrap/cjs/Spinner";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import DOMPurify from 'dompurify';

let htmlEditor = null;
class Product extends React.Component {
    constructor(p) {
        super(p);
        this.state = {
            product: {
                type: 'parent',
                keywords: [],
                categories: [],
                subCategories: [],
                images:[],
                sku:'',
                name:'',
                isPromoted:false,
                isVisible:false,
                isTaxable:false,
                isControlled:false,
                medicationGroup:'S',
                mainIllness:'',
                secondaryIllness:'',
                upc:'',
                medicationType:'M',
                refrigeration:'A',
                richDescription:'',
                sellingPoints:[
                    '','','','','',''
                ]
            },
            isEditingProduct:false,
            categoryList: [],
            subCategoryList:[],
            medicationGroups: [
                {
                    value:'S',
                    label:'S'
                },
                {
                    value:'R1',
                    label:'R1'
                },
                {
                    value:'R2',
                    label:'R2'
                },
                {
                    value:'R3',
                    label:'R3'
                },
                {
                    value:'R4',
                    label:'R4'
                },
                {
                    value:'R5',
                    label:'R5'
                },
            ],
            medicationTypes:[
                {
                    value:'M',
                    label:'M - Medicamento'
                },
                {
                    value:'E',
                    label:'E - Especialidad'
                },
                {
                    value:'G',
                    label:'G - Genérico'
                }

            ],
            refrigerationValues:[
                {
                    value:'A',
                    label:'A - Ambiente (15-25°C)'
                },
                {
                    value:'F',
                    label:'F - Frío (2-8°C)'
                },
            ],
            isLoading:false,
            isPreviewingRichDescription:false,
            sanitizedRichDescription:''
        }
        this.loadProduct          = this.loadProduct.bind(this);
        this.onProductTypeChanged = this.onProductTypeChanged.bind(this);
        this.onImageDropped       = this.onImageDropped.bind(this);
        this.setCategory          = this.setCategory.bind(this);
        this.removeCategory       = this.removeCategory.bind(this);
        this.getCategories        = this.getCategories.bind(this);
        this.saveProduct          = this.saveProduct.bind(this);
        this.onClickSave          = this.onClickSave.bind(this);
        this.createProduct        = this.createProduct.bind(this);
        this.onEditorChange       = this.onEditorChange.bind(this);
    }
    componentDidMount() {
        const productId = this.props.match.params.id;

        if(productId !== 'create'){
            this.loadProduct(productId);
        }else{
        }
        this.getCategories();
        this.getSubCategories();
    }

    onChange(e) {
        const isCheckBox = ['isVisible', 'isControlled', 'isTaxable', 'isPromoted','isAffiliated'].includes(e.target.id);
        this.setState(prevState => {
            let product          = Object.assign({}, prevState.product);
            product[e.target.id] = isCheckBox ? e.target.checked : e.target.value;
            return {product};
        });
    }

    getCategories = () => {
        NbioApi.categories.getCategories().then(res => {
            const names = res.data.categories.map(c => c.name);
            this.setState({categoryList: names});
        }).catch((ex) =>{

        });
    }
    getSubCategories = () => {
        NbioApi.subCategories.getSubCategories().then(res => {
            const names = res.data.subCategories.map(c => c.name);
            this.setState({subCategoryList: names});
        }).catch((ex) =>{

        });
    }
    loadProduct(productId) {
        this.setState({isLoading:true})
        NbioApi.products.getProduct(productId).then(res => {
            this.setState({product: res.data.product,isEditingProduct:true});
        }).catch((ex) =>{

        }).finally(() =>{
            this.setState({isLoading:false})
        })
    }

    setCategory = (value) => {
        const {product} = {...this.state};
        if(! product.categories.includes(value)){
            product.categories.push(value);
            this.setState(product);
        }
    }
    setMedicationGroup = (value) => {
        const {product} = {...this.state};
        product.medicationGroup = value;
        this.setState(product);
    }
    setMedicationType = (value) => {
        console.log(value);
        const {product} = {...this.state};
        product.medicationType = value;
        this.setState(product);
    }
    removeCategory = (value) => {
        const {product} = {... this.state};
        product.categories = product.categories.filter((v) => v !== value);
        this.setState(product);
    }

    setRefrigeration(value) {
        const {product} = {...this.state};
        product.refrigeration = value;
        this.setState(product);
    }
    setSubCategory = (value) => {
        const {product} = {...this.state};
        if(! product.subCategories.includes(value)){
            product.subCategories.push(value);
            this.setState(product);
        }
    }
    removeSubCategory = (value) => {
        const {product} = {... this.state};
        product.subCategories = product.subCategories.filter((v) => v !== value);
        this.setState(product);
    }
    confirmDelete(){
        Swal.fire({
            icon: 'error',
            title: '¿Estás seguro de borrar el producto?',
            html: 'Una vez borrado el producto no se podrá recuperar',
            confirmButtonColor: '#f32735',
            confirmButtonText: 'Sí, borrar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            reverseButtons: true,
        }).then((res) => {
            if (res.isConfirmed) {
                const productId = this.props.match.params.id;
                API.products.deleteProduct(productId).then((res) => {
                    this.props.history.push('/products');
                }).catch((ex) => {
                    let errorMsg = 'Hubo un error al borrar el producto';
                    try {
                        errorMsg = ex.response.data.error_es;
                    } catch (ex) {
                        errorMsg = 'Servicio no disponible';
                    }
                    this.fireSwalError(errorMsg);
                })
            }
        });
    }
    getSellingPointValue(index){
        const sellingPoints = this.state.product?.sellingPoints || ['','','','','',''];
        return sellingPoints[index];
    }
    setSellingPointValue(index,value){
        const sellingPoints = this.state.product?.sellingPoints || ['','','','','',''];
        sellingPoints[index] = value;
        this.setState({sellingPoints:sellingPoints});
    }
    onEditorChange(value){
        // console.log(this.editorRef.getContent());
        this.setState(prevState => {
            let product          = Object.assign({}, prevState.product);
            product.richDescription = value;
            return {product};
        });
    }
    onMount(editor){
        htmlEditor = editor;
    }
    render() {
        const productId = this.props.match.params.id;
        const product   = this.state.product ? this.state.product : {};
        const keywords  = product.keywords || [];
        const userKeywords  = product.userKeywords || [];
        const images    = product.images ? product.images : [];
        const isEditing = this.state.isEditingProduct;
        const productSku = product.sku || null;
        const categories = product.categories || [];
        const subCategories = product.subCategories || [];
        const showLogs = product.sku && productId !== 'create';
        const categoryList = this.state.categoryList || [];
        const subCategoryList = this.state.subCategoryList || [];
        const medicationGroups = this.state.medicationGroups;
        const medicationTypes  = this.state.medicationTypes;
        const refrigerationValues = this.state.refrigerationValues;
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3'>
                        <div className={'d-flex align-items-center justify-content-between'}>
                            <h5 className={'text-capitalize mr-auto'}>{productId === 'create' ? 'Crear producto' : `Producto: ${product.name}`} </h5>
                            <NPIf condition={productId !== 'create'}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="danger" id="dropdown-basic">
                                        Configuración
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => this.confirmDelete()}>Borrar
                                            producto</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </NPIf>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='py-3' md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>Información general</Card.Title>
                                {/*Categories*/}

                                        <Form.Group as={Row} className="mb-3" controlId="categories">
                                            <Form.Label column sm={2}>Categorías</Form.Label>
                                            <Col sm={10} className={'d-flex flex-row align-items-center'}>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="categories" variant='outline-primary'>
                                                        Agregar categorías
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu >
                                                    {
                                                        categoryList.map((c,i) => {
                                                            return(
                                                                <Dropdown.Item key={i}
                                                                               onClick={() => this.setCategory(c)}>{c}
                                                                </Dropdown.Item>
                                                            )
                                                        })
                                                    }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className={'ml-3 d-flex flex-row flex-wrap'}>
                                                    {
                                                        categories.map((c, i) => {
                                                            return (
                                                                <Badge key={i} pill
                                                                       bg={'secondary'}
                                                                       style={{cursor:'pointer'}}
                                                                       onClick={() => this.removeCategory(c)}
                                                                       className={'mr-1 mb-1'}>
                                                                    {c}
                                                                    <FontAwesomeIcon
                                                                        className={'ml-1'}
                                                                        icon={"times-circle"}/>
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                        </Form.Group>
                                {/*Sub-categories*/}
                                        <Form.Group as={Row} className="mb-3" controlId="subCategories">
                                            <Form.Label column sm={2}>Sub-Categorías</Form.Label>
                                            <Col sm={10} className={'d-flex flex-row align-items-center'}>
                                                <Dropdown>
                                                    <Dropdown.Toggle id="subCategories" variant='outline-primary'>
                                                        Agregar sub-categorías
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu >
                                                        {
                                                            subCategoryList.map((c,i) => {
                                                                return(
                                                                    <Dropdown.Item key={i}
                                                                                   onClick={() => this.setSubCategory(c)}>{c}
                                                                    </Dropdown.Item>
                                                                )
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className={'ml-3 d-flex flex-row flex-wrap'}>
                                                    {
                                                        subCategories.map((c, i) => {
                                                            return (
                                                                <Badge key={i} pill
                                                                       bg={'secondary'}
                                                                       style={{cursor:'pointer'}}
                                                                       onClick={() => this.removeSubCategory(c)}
                                                                       className={'mr-1 mb-1'}>
                                                                    {c}
                                                                    <FontAwesomeIcon
                                                                        className={'ml-1'}
                                                                        icon={"times-circle"}/>
                                                                </Badge>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                        </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="sku">
                                    <Form.Label column sm={2}>SKU</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="SKU" value={product.sku}
                                                      readOnly={isEditing}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="name">
                                    <Form.Label column sm={2}>Nombre</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Nombre" value={product.name}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-4 mb-3" controlId="type">
                                    <Form.Label column sm={2}>Tipo de Producto: </Form.Label>
                                    <Col sm={6}>
                                        <Form.Check
                                            inline
                                            label="Padre"
                                            name="type"
                                            type={"radio"}
                                            id={"productType1"}
                                            value={"parent"}
                                            onChange={event => this.onProductTypeChanged(event)}
                                            checked={product.type === 'parent'}
                                        />
                                        <Form.Check
                                            inline
                                            label="Hijo"
                                            name="type"
                                            type={"radio"}
                                            id={"productType2"}
                                            value={"child"}
                                            onChange={event => this.onProductTypeChanged(event)}
                                            checked={product.type === 'child'}
                                        />

                                    </Col>
                                </Form.Group>
                                <NPIf condition={product.type === 'child'}>
                                    <Form.Group as={Row} className="mb-3" controlId="parentSku">
                                        <Form.Label column sm={2}></Form.Label>
                                        <Form.Label column sm={2}>SKU Padre</Form.Label>
                                        <Col sm={8}>
                                            <Form.Control type="text"
                                                          placeholder="SKU Padre"
                                                          value={product.parentSku}
                                                          onChange={event => this.onChange(event)}/>
                                        </Col>
                                    </Form.Group>
                                </NPIf>

                                <Form.Group as={Row} className="mb-3" controlId="price">
                                    <Form.Label column sm={2}>Precio</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Price" value={product.price}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="description">
                                    <Form.Label column sm={2}>Descripción</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Descripción" value={product.description}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="description2">
                                    <Form.Label column sm={2}>Indicación terapéutica</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" as="textarea" placeholder="Indicación terapéutica"
                                                      value={product.description2}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="label">
                                    <Form.Label column sm={2}>Etiqueta</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" as="textarea" placeholder="Etiqueta"
                                                      value={product.label}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="activeIngredientName">
                                    <Form.Label column sm={2}>Ingrediente activo</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Ingrediente activo"
                                                      value={product.activeIngredientName}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="brandName">
                                    <Form.Label column sm={2}>Marca</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Marca" value={product.brandName}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="dosageAmount">
                                    <Form.Label column sm={2}>Dosis</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Dosis" value={product.dosageAmount}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="dosageUnit">
                                    <Form.Label column sm={2}>Unidad de dosis</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Unidad de dosis"
                                                      value={product.dosageUnit}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="dosageForm">
                                    <Form.Label column sm={2}>Presentación</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Presentación" value={product.dosageForm}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="isVisible">
                                    <Form.Label column sm={2}></Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="switch"
                                            id="isVisible"
                                            label="Visible en tienda"
                                            checked={product.isVisible}
                                            onChange={event => this.onChange(event)}/>

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3" controlId="isControlled">
                                    <Form.Label column sm={2}></Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="switch"
                                            id="isControlled"
                                            label="Medicamento controlado"
                                            checked={product.isControlled}
                                            onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="isTaxable">
                                    <Form.Label column sm={2}></Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="switch"
                                            id="isTaxable"
                                            label="Libre de IVA"
                                            checked={product.isTaxable}
                                            onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="isPromoted">
                                    <Form.Label column sm={2}></Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="switch"
                                            id="isPromoted"
                                            label="Medicamento promocionado"
                                            checked={product.isPromoted}
                                            onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                            </Card.Body>
                        </Card>
                        <Card className={'mt-3'}>
                            <Card.Body>
                                <Card.Title className={'text-primary'}>Medidas</Card.Title>

                                <Form.Group as={Row} className="mb-3" controlId="width">
                                    <Form.Label column sm={2}>Ancho</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Ancho" value={product.width}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="depth">
                                    <Form.Label column sm={2}>Profundidad</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Profundidad" value={product.depth}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="weight">
                                    <Form.Label column sm={2}>Peso</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Peso" value={product.weight}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <NPIf condition={productId !== 'create'}>
                            <Card className={'mt-3'}>
                                <Card.Body>
                                    <Card.Title className={'text-primary'}>Otra información</Card.Title>

                                    <Form.Group as={Row} className="mb-3" controlId="keywords">
                                        <Form.Label column sm={2}>Palabras clave autogeneradas</Form.Label>
                                        <Col sm={10}>
                                            {
                                                keywords.map((k, i) => {
                                                    return (
                                                        <Badge pill bg={'info'} className={'mr-1'}>{k}</Badge>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="keywords">
                                        <Form.Label column sm={2}>Palabras clave del usuario</Form.Label>
                                        <Col sm={10}>
                                            {
                                                userKeywords.map((k, i) => {
                                                    return (
                                                        <Badge pill bg={'info'} className={'mr-1'}>{k}</Badge>
                                                    )
                                                })
                                            }
                                        </Col>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </NPIf>
                    </Col>
                    <Col className='py-3' md={4}>
                        <Card.Title className={'text-primary'}>Imagen</Card.Title>
                        La imagen debe ser cuadrada y de 512x512 pixeles. Al subir otra medida, la imagen será deformada.
                        <DragZone onImageDropped={this.onImageDropped}
                                  preSelectedImage={images[0]}></DragZone>
                    </Col>
                </Row>
                <Row>

                    <Col className={'py-3'} md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>
                                    Grupo de medicamento y enfermedades
                                </Card.Title>
                                {/*medicationGroup*/}
                                <Form.Group as={Row} className="mb-3" controlId="requiredPrescription">
                                    <Form.Label column sm={2}>Grupo de medicamento</Form.Label>
                                    <Col sm={10} className={'d-flex flex-row align-items-center'}>
                                        <Dropdown>
                                            <Dropdown.Toggle id="medicationGroup" variant='outline-primary'>
                                                {!this.state.product.medicationGroup ? 'Seleccionar' : this.state.product.medicationGroup}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                {
                                                    medicationGroups.map((v,i) => {
                                                        return(
                                                            <Dropdown.Item key={i}
                                                                           onClick={() => this.setMedicationGroup(v.value)}>{v.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Form.Group>
                                {/*Main illness*/}
                                <Form.Group as={Row} className="mb-3" controlId="mainIllness">
                                    <Form.Label column sm={2}>Padecimiento principal</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Padecimiento principal" value={product.mainIllness}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                {/*Main illness*/}
                                <Form.Group as={Row} className="mb-3" controlId="secondaryIllness">
                                    <Form.Label column sm={2}>Padecimiento secundario</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Padecimiento secundario" value={product.secondaryIllness}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                {/*UPC*/}
                                <Form.Group as={Row} className="mb-3" controlId="upc">
                                    <Form.Label column sm={2}>UPC</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="UPC" value={product.upc}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                                {/*medicationType*/}
                                <Form.Group as={Row} className="mb-3" controlId="medicationType">
                                    <Form.Label column sm={2}>Tipo de medicamento</Form.Label>
                                    <Col sm={10} className={'d-flex flex-row align-items-center'}>
                                        <Dropdown>
                                            <Dropdown.Toggle id="medicationType" variant='outline-primary'>
                                                {
                                                    !this.state.product.medicationType ?
                                                        'Seleccionar' :
                                                        this.renderMedicationType()


                                                }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                {
                                                    medicationTypes.map((v,i) => {
                                                        return(
                                                            <Dropdown.Item key={i}
                                                                           onClick={() => this.setMedicationType(v.value)}>{v.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Form.Group>
                                {/*refrigeration*/}
                                <Form.Group as={Row} className="mb-3" controlId="refrigeration">
                                    <Form.Label column sm={2}>Refrigeración</Form.Label>
                                    <Col sm={10} className={'d-flex flex-row align-items-center'}>
                                        <Dropdown>
                                            <Dropdown.Toggle id="refrigeration" variant='outline-primary'>
                                                {
                                                    !this.state.product.refrigeration ?
                                                        'Seleccionar' :
                                                        refrigerationValues
                                                            .find((v) => v.value === this.state.product.refrigeration)
                                                            .label
                                                }
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                {
                                                    refrigerationValues.map((v,i) => {
                                                        return(
                                                            <Dropdown.Item key={i}
                                                                           onClick={() => this.setRefrigeration(v.value)}>{v.label}
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>
                                </Form.Group>
                                {/* isAffiliated   */}
                                <Form.Group as={Row} className="mb-3" controlId="isAffiliated">
                                    <Form.Label column sm={2}></Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            type="switch"
                                            id="isAffiliated"
                                            label="Es afiliado"
                                            checked={product.isAffiliated}
                                            onChange={event => this.onChange(event)}/>

                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col className={'py-3'} md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>
                                    Logística
                                </Card.Title>
                                <Form.Group as={Row} className="mb-3" controlId="restockFrequency">
                                    <Form.Label column sm={2}>Frecuencia de restock (horas)</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="Frecuencia de restock (horas)" value={product.restockFrequency}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col className={'py-3'} md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title className={'text-primary mb-3'}>
                                    Marketing
                                </Card.Title>
                                <Form.Group as={Row} className="mb-3" controlId="restockFrequency">
                                    <Form.Label column sm={2}>Punto clave #1</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="#1" value={this.getSellingPointValue(0)}
                                                      onChange={event => this.setSellingPointValue(0,event.target.value)}/>
                                    </Col>
                                    <Form.Label column sm={2}>Punto clave #2</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="#2" value={this.getSellingPointValue(1)}
                                                      onChange={event => this.setSellingPointValue(1,event.target.value)}/>
                                    </Col>
                                    <Form.Label column sm={2}>Punto clave #3</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="#3" value={this.getSellingPointValue(2)}
                                                      onChange={event => this.setSellingPointValue(2,event.target.value)}/>
                                    </Col>
                                    <Form.Label column sm={2}>Punto clave #4</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="#4" value={this.getSellingPointValue(3)}
                                                      onChange={event => this.setSellingPointValue(3,event.target.value)}/>
                                    </Col>
                                    <Form.Label column sm={2}>Punto clave #5</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="#5" value={this.getSellingPointValue(4)}
                                                      onChange={event => this.setSellingPointValue(4,event.target.value)}/>
                                    </Col>
                                    <Form.Label column sm={2}>Punto clave #6</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text" placeholder="#6" value={this.getSellingPointValue(5)}
                                                      onChange={event => this.setSellingPointValue(5,event.target.value)}/>

                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="richDescription">
                                    <Form.Label column sm={2}>Descripción (HTML)</Form.Label>
                                    <Col sm={10}>
                                        {
                                            this.state.isLoading ?
                                                <Spinner animation={'grow'}></Spinner>
                                            :
                                                <div>
                                                    <BootstrapSwitchButton
                                                        checked={false}
                                                        onlabel='Previsualizar'
                                                        offlabel='Código'
                                                        width={150}
                                                        onChange={(checked) => {
                                                            this.sanitizeData();
                                                            this.setState({ isPreviewingRichDescription: checked })
                                                        }}
                                                    />
                                                    <div style={{height:20}}></div>
                                                    {
                                                        this.state.isPreviewingRichDescription ?
                                                            <div dangerouslySetInnerHTML={
                                                                {__html:this.state.sanitizedRichDescription}
                                                            }>
                                                            </div> :
                                                            <Editor
                                                                height="55vh"
                                                                defaultLanguage="html"
                                                                theme="vs-dark"
                                                                value={this.state.product.richDescription}
                                                                options={{
                                                                    minimap: {
                                                                        enabled: false
                                                                    }
                                                                }}
                                                                onChange={this.onEditorChange}
                                                                wrapperClassName={'class'}
                                                                onMount={this.onMount}
                                                            />
                                                    }

                                                </div>

                                        }
                                    </Col>
                                    {/*<Button onClick={() => console.log(this.editorRef.getContent())}></Button>*/}
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className={'mt-5 mb-5'}>
                    <Col md={8} className={'d-flex justify-content-end'}>
                        <Button onClick={() => this.onClickSave()}>Guardar</Button>
                    </Col>
                    <Col md={4}>
                    </Col>
                </Row>
                <NPIf condition={showLogs}>
                    <Row className={'mb-5'}>
                        <Col md={8}>
                            <ProductsLogTable ref={(ref) => this.logTable = ref} productId={productSku}></ProductsLogTable>
                        </Col>
                    </Row>
                </NPIf>
            </Container>
        )

    }

    sanitizeData() {
        this.setState({
            sanitizedRichDescription: DOMPurify.sanitize(this.state.product.richDescription)
        });
    }

    onImageDropped(image) {
        const product  = {...this.state.product};
        product.images = [image];
        this.setState({product: product})
    }

    isValidForm() {
        const productId = this.props.match.params.id;
        const {product} = this.state;
        // validate SKU
        if ( productId === 'create'){
            if(product.categories.length === 0){
                return {
                    message: 'Selecciona al menos una categoría',
                    isValid: false
                }
            }
        }
        if (isEmpty(product.sku)) {
            return {
                message: 'El SKU no puede estar vacío',
                isValid: false
            }
        }
        if (isEmpty(product.name)) {
            return {
                message: 'El nombre no puede estar vacío',
                isValid: false
            }
        }
        if (isEmpty(product.type)) {
            return {
                message: 'El tipo de producto no puede estar vacío',
                isValid: false
            }
        }
        const _price = product.price ? product.price.toString() : '';
        if (!isNumeric(_price)) {
            return {
                message: 'El precio es inválido',
                isValid: false
            }
        }
        if (parseFloat(product.price) <= 0) {
            return {
                message: 'El precio debe de ser mayor a $0.00',
                isValid: false
            }
        }

        if (product.images.length === 0) {
            return {
                message: 'Imagen faltante',
                isValid: false
            }
        }
        if (product.type === 'child') {
            if (isEmpty(product.parentSku)) {
                return {
                    message: 'El SKU Padre no puede estar vacío',
                    isValid: false
                }
            }
        }
        // Validate UPC
        if(isEmpty(product.upc)){
            return {
                message: 'El UPC no puede estar vacío',
                isValid: false
            }
        }
        // Validate medicationType
        if(!isValidMedicationType(product.medicationType)){
            return {
                message: 'El tipo de medicamento es inválido',
                isValid: false
            }
        }
        return {
            message: '',
            isValid: true
        }

    }

    async onClickSave() {
        const isValidForm = this.isValidForm();
        const productId = this.props.match.params.id;

        if (!isValidForm.isValid) {
            return Swal.fire({
                title: 'Datos inválidos',
                icon: 'warning',
                html: isValidForm.message,
                heightAuto:false
            })
        }
        if(productId === 'create'){
            return this.createProduct();
        }else{
            return this.saveProduct(productId);
        }
    }

    createProduct = () => {
        const product   = {...this.state.product};
        const sanitizedRichDescription = DOMPurify.sanitize(this.state.product.richDescription);
        product.richDescription = sanitizedRichDescription;
        this.setState({product:product});
        this.setState({isLoading:true});
        NbioApi.products.create(product).then((res) =>{
            Swal.fire({
                title: 'Producto creado',
                html: 'Producto creado',
                icon: 'sucess',
                confirmButtonColor: '#4dda85',
                heightAuto:false
            }).then((_res) => {
                this.props.history.push(`/products`);
            })
        }).catch((ex) =>{
            console.log(ex)
            let errorMsg = 'Hubo un error al crear el producto';
            try {
                errorMsg = ex.response.data.error_es;
            } catch (ex) {

            }
            Swal.fire({
                title: 'Error',
                html: errorMsg,
                icon: 'error',
                heightAuto:false
            }).then((res) => {
            })
        }).finally(() =>{
            this.setState({isLoading:true});
        });
    }

    saveProduct = (productId) => {
        const product   = {...this.state.product};
        const sanitizedRichDescription = DOMPurify.sanitize(this.state.product.richDescription);
        product.richDescription = sanitizedRichDescription;
        this.setState({product:product});
        const image     = product.images ? product.images[0] : '';
        if (!image.includes('data:image')) {
            delete product.images
        }
        this.setState({isLoading:true});
        NbioApi.products.update(productId, product).then((res) => {
            Swal.fire({
                title: 'Producto actualizado',
                html: 'Producto actualizado',
                icon: 'sucess',
                confirmButtonColor: '#4dda85',
                heightAuto:false
            });
            try{
                this.logTable.reload();
                this.loadProduct(productId);
            }catch(ex){

            }
        }).catch((ex) => {
            Swal.fire({
                title: 'Producto no actualizado',
                html: 'Hubo un problema al actualizar el producto. Intenta de nuevo.',
                icon: 'error',
                confirmButtonColor: '#4dda85',
                heightAuto:false
            });
        }).finally(() =>{
            this.setState({isLoading:false});
        })
    }

    onProductTypeChanged(event) {
        const _product = {...this.state.product};
        _product.type  = event.target.value;
        this.setState({product: _product})
    }

    renderMedicationType() {
        const medicationTypes = this.state.medicationTypes || [];
        const res = medicationTypes.find((v) => {
                return v.value === this.state?.product?.medicationType
        });
        return res ? res.label : '';
    }
}

export default withRouter(Product);

const isEmpty   = (v) => {
    if (v) {
        return v.trim() === '';
    } else {
        return true;
    }
}
const isValidMedicationType = ((v) => {
   const validMedicationTypes = ['M','E','G'];
   return validMedicationTypes.includes(v);
});

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}
