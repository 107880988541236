/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * November 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Badge from "react-bootstrap/Badge";
import {money} from "../../lib/money/money";
import BootstrapTable from "react-bootstrap-table-next";
import NbioApi from "../../lib/api/NbioApi";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import PropTypes from "prop-types";
import NPIf from "np-if";
import NPElse from "np-if/src/NPElse";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import ReactMapGl, {Layer, Marker, NavigationControl, Source} from "react-map-gl"

//style
import 'mapbox-gl/dist/mapbox-gl.css';

//components
import OrdersMarker from "./OrdersMarker";

//MAPBOX TOKEN
const MAPBOX_TOKEN = 'pk.eyJ1IjoiZWFwZXJlenYiLCJhIjoiY2w0ZzIzdWduMDJuMDNjczF5bTA2Y2M4MSJ9.Iyqpdv6RE0BeqkesEq7IXw';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

//const
const dayjs = require('dayjs')
const utc   = require('dayjs/plugin/utc');
dayjs.extend(utc);

/**
 * Order map
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */

class OrdersMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zoom: 12,
            viewport: {},
        }
    }

    componentDidMount() {
        this.setState({
            viewport: {
                latitude: this.props.latitude,
                longitude: this.props.longitude,
                zoom: this.state.zoom
            }
        })
    }

    formatDate(date) {
        if (!date) {
            return '-';
        }
        return (
            <div>
                <div>{dayjs(date).format('DD MMM YYYY')}</div>
                <div>{dayjs(date).format('HH:mm:ss')}</div>
            </div>
        )
    }
    render() {
        const orders = this.props.orders;
        return (
            // <div>
                <NPIf condition={this.props.isLoading}>
                    <Spinner animation={'grow'} variant={'primary'}></Spinner>
                    <NPElse>
                        <ReactMapGl
                            mapStyle="mapbox://styles/eaperezv/cl4hbzpj9001s15nywg1tdutg"
                            mapboxApiAccessToken={MAPBOX_TOKEN}
                            width={this.props.width}
                            height={this.props.height}
                            onViewportChange={(e) => this.setState({viewport: e})}
                            {...this.state.viewport}
                        >
                            <NavigationControl showCompass showZoom position='bottom-right'/>
                            {
                                orders.map(order => {
                                    return <OrdersMarker key={order._id} order={order}></OrdersMarker>
                                })
                            }
                        </ReactMapGl>
                    </NPElse>
                </NPIf>
            // </div>
        )
    }


}

export default withRouter(OrdersMap);

OrdersMap.propTypes    = {
    orders: PropTypes.array,
    isLoading: PropTypes.bool,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    width: PropTypes.string,
    height: PropTypes.string,
}
OrdersMap.defaultProps = {
    orders     : [],
    isLoading  : true,
    width: '100%',
    height: '500px',
    latitude: 19.4326018,
    longitude: -99.1332049,
}
