import Card from "react-bootstrap/cjs/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import PropTypes from 'prop-types';
import dayjs from "dayjs";
import NbioApi from "../../lib/api/NbioApi";
import mpLogo from '../../assets/mercado-pago.png';
import Col from "react-bootstrap/Col";
/**
 * Displays Order's Stripe status
 *
 * @author América Mendoza  <amendoza@nodeport.co>
 * @author Darién Miranda <dmiranda@nodeport.co>
 * @author Oscar Peña <opena@nodeport.co>
 */
class MPStatus extends React.Component{
    constructor(s) {
        super(s);
        this.state = {
            mpStatus:{
                status:''
            },
            isLoadingStripe:false
        }
        this.load = this.load.bind(this);
    }
    componentDidMount() {
        this.load();
    }
    load(){
        NbioApi.orders.getOrderStatusMP(this.props.orderId).then((res) =>{
            this.setState({mpStatus:res.data.paymentIntent});
        }).catch((ex) =>{

        })
    }
    render() {
        let last4 = '';
        let network = 'credit-card';
        let createdDate = null;
        let link = `https://www.mercadopago.com.mx/activities#from-section=menu`;
        try{
           createdDate = this.state.mpStatus.date_approved;
           last4 =  this.state.mpStatus.card.last_four_digits;
           network = this.state.mpStatus.payment_method_id;
        }catch (e) {

        }
        return (
            <Col>
                <Card>
                    <Card.Body>
                        <Card.Title>
                            <div className={'d-flex align-items-center'}>
                                <img src={mpLogo} height={50}/>
                                <span className={'ml-1'}>
                                    Mercado Pago
                                </span>
                            </div>

                        </Card.Title>
                        <div className={'stripe-data d-flex flex-row'}>
                            <div className={'element'}>
                                <div>
                                    <div className={'label'}>Fecha</div>
                                    <div>{this.parseDate(createdDate)}</div>
                                </div>
                                <div className={'separator'}>
                                </div>
                            </div>
                            <div className={'element'}>
                                <div>
                                    <div className={'label'}>Método de pago</div>
                                    <div className={'d-flex justify-content-around align-items-center'}>
                                        <FontAwesomeIcon icon={['fab',`cc-${network}`]}></FontAwesomeIcon>
                                        <div>
                                            ••••
                                        </div>
                                        <div>
                                            {last4}
                                        </div>
                                    </div>
                                </div>
                                <div className={'separator'}>
                                </div>
                            </div>
                            <div className={'element'}>
                                <div>
                                    <div className={'label'}>Estado</div>
                                    <div className={this.state.mpStatus.status !== 'approved' ? 'text-danger' : ''}>
                                        {this.parseStatus(this.state.mpStatus.status)}
                                    </div>
                                </div>
                                <div className={'separator'}>
                                </div>
                            </div>
                        </div>
                        <div  className={'mt-2'}>
                            <a href={link} target={'_blank'}>
                                Ver en Mercado Pago <FontAwesomeIcon icon={'arrow-right'}></FontAwesomeIcon>
                            </a>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        );
    }
    parseStatus(status){
        if(status === 'approved'){
            return 'Aprobado';
        }else if(status === 'rejected'){
            return 'Rechazado';
        }else if(status === 'in_process'){
            return 'En proceso';
        }else{
            return status;
        }
    }
    parseDate(createdDate) {
        if(!createdDate){
            return '-'
        }else{
            return dayjs(createdDate).format('DD-MM-YYYY HH:mm')
        }
    }
}
export default MPStatus;
MPStatus.propTypes = {
    /*Order ID*/
    OrderId: PropTypes.string
}
MPStatus.defaultProps = {
    orderId: ''
}
