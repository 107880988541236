/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * March 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import NbioApi from "../../lib/api/NbioApi";
import Button from "react-bootstrap/Button";
import NPIf from "np-if";
import Nav from 'react-bootstrap/Nav';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


//components
import UploadFile from "./bulk_importer/UploadFile";
import ValidateFile from "./bulk_importer/ValidateFile";
import ImportingFile from "./bulk_importer/ImportingFile";
import UploadImages from "./bulk_importer/UploadImages";


class ProductsBulkImporter extends React.Component {
    constructor(p) {
        super(p);
        this.state               = {
            step: 'UPLOAD_FILE', //UPLOAD_FILE, VALIDATE_FILE, IMPORTING, RESPONSE_IMPORTER, UPLOAD_IMAGES,
            showInstructions: true,
            bulkImport: null
        }
        this.onFileUploaded      = this.onFileUploaded.bind(this);
        this.onValidationError   = this.onValidationError.bind(this);
        this.onValidatedFile     = this.onValidatedFile.bind(this);
        this.onImportingError    = this.onImportingError.bind(this);
        this.onImportingFinished = this.onImportingFinished.bind(this);
        this.onImagesUploadError = this.onImagesUploadError.bind(this);
        this.onImagesNextStep    = this.onImagesNextStep.bind(this);
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        if(id === 'upload_images'){
            this.setState({step:'UPLOAD_IMAGES'});
        }

    }

    onFileUploaded(bl) {
        this.setState({
            step: 'VALIDATE_FILE',
            bulkImport: bl.bulkImport
        })
    }

    onValidationError() {
        this.setState({
            step: 'UPLOAD_FILE',
        })
    }

    onValidatedFile() {
        NbioApi.bulkImports.startImporting(this.state.bulkImport._id).then((res) => {
            this.setState({
                step: 'IMPORTING',

            })
        }).catch((ex) => {

        })


    }

    onImportingError() {

    }

    onImportingFinished() {
        this.setState({
            step: 'UPLOAD_IMAGES',
        })
    }

    onImagesUploadError() {

    }

    onImagesNextStep() {

    }

    renderComponent = () => {
        switch (this.state.step) {
            case 'UPLOAD_FILE':
                return <UploadFile nextStep={this.onFileUploaded}></UploadFile>;
            case 'VALIDATE_FILE':
                return <ValidateFile
                    bulkImport={this.state.bulkImport}
                    onError={this.onValidationError}
                    nextStep={this.onValidatedFile}
                ></ValidateFile>;
            case 'IMPORTING':
                return <ImportingFile
                    bulkImport={this.state.bulkImport}
                    onError={this.onImportingError}
                    nextStep={this.onImportingFinished}
                >
                </ImportingFile>;
            case 'UPLOAD_IMAGES':
                return <UploadImages
                    bulkImport={this.state.bulkImport}
                    onError={this.onImagesUploadError}
                    nextStep={this.onImagesNextStep}
                >
                </UploadImages>;
            default:
                return ''
        }
    }


    render() {
        return (
            <Container fluid style={{overflowY: "scroll"}} className={'h-100'}>
                <Row>
                    <Col className={'ml-auto mr-auto'} sm={12} md={9}>
                        <Row>
                            <Col className='mt-3 d-flex align-items-center justify-content-between'>
                                <h5 className={'text-capitalize '}>Importador</h5>
                                    <Button variant={'info'} href={'https://nbio.blob.core.windows.net/cdn/Muestra-nbio.xlsx'} target={'blank'}>
                                            <FontAwesomeIcon icon={'download'}/> Descargar archivo muestra</Button>
                            </Col>
                        </Row>

                        <NPIf condition={this.state.showInstructions && this.state.step === 'UPLOAD_FILE'}>
                            <Row className={'mt-5'}>
                                <Col>
                                    <Card bg={'medium'}>
                                        <Card.Body>
                                            <Card.Title className='d-flex align-items-center justify-content-between'>
                                                <span>Instrucciones</span>
                                                <Button size="sm"
                                                        variant={'light'}
                                                        onClick={() => this.setState({showInstructions: false})}>
                                                    <FontAwesomeIcon icon={'times-circle'}/>
                                                </Button>
                                            </Card.Title>
                                            <ol>
                                                <li>Descargar el archivo de muestra</li>
                                                <li>Agrega tus productos y da click en subir archivo. Debe de estar en formato .xlsx</li>
                                                <li>Espera el resultado de la validación</li>
                                                <li>Sube tus imágenes. Deben de ser de 512x512 pixeles y en formato .jpeg o .jpg</li>
                                            </ol>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </NPIf>

                        <Row className={'my-5'}>
                            <Col>
                                {this.renderComponent()}
                            </Col>
                        </Row>
                    </Col>
                    <Col className='py-3 d-sm-none d-md-block ml-auto' md={2}>
                        {/*//UPLOAD_FILE, VALIDATE_FILE, CONFIRM_SAVE, RESPONSE_IMPORTER, UPLOAD_IMAGES,*/}
                        <Nav activeKey={this.state.step} className={'flex-column border-left'}>
                            <Nav.Item>
                                <Nav.Link eventKey="UPLOAD_FILE">Subir archivo</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="VALIDATE_FILE">Validar archivo</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="IMPORTING">Importar productos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="UPLOAD_IMAGES">Subir imagenes</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default withRouter(ProductsBulkImporter);

