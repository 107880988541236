/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2022
 */

import React from 'react';
import {withRouter} from "react-router";
import Row from "react-bootstrap/cjs/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NPIf from "np-if";
import Form from "react-bootstrap/Form";
import API from "../../lib/api/NbioApi";
import Swal from "sweetalert2";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Alert from "react-bootstrap/Alert";

//components
import DragZone from "../ui/Dropzone";



class User extends React.Component{
    constructor(p) {
        super(p);
        this.state = {
            user:{
                uid:'',
                name:'',
                last_name:'',
                email:'',
                profileUrl:'',
                profileUrlThumbnail:'',
                source:'',
                roles:['operator'],
                is_disabled:false
            },
            password:'',
            password2:'',
            title:'',
            btnLabel: '',
            profileImage: null,
            isUserDisabled: false,
        }
        this.getUser                    = this.getUser.bind(this);
        this.setTitle                   = this.setTitle.bind(this);
        this.setLabel                   = this.setLabel.bind(this);
        this.onClickBtn                 = this.onClickBtn.bind(this);
        this.validateUser               = this.validateUser.bind(this);
        this.onClickChangePwd           = this.onClickChangePwd.bind(this);
        this.validatePwd                = this.validatePwd.bind(this);
        this.onClickUploadImage         = this.onClickUploadImage.bind(this);
        this.createUser                 = this.createUser.bind(this);
        this.updateUser                 = this.updateUser.bind(this);
        this.deleteUser                 = this.deleteUser.bind(this);
        this.disableUser                = this.disableUser.bind(this);
        this.confirmDeleteUser          = this.confirmDeleteUser.bind(this);
        this.confirmDisableUser         = this.confirmDisableUser.bind(this);
        this.enableUser                 = this.enableUser.bind(this);
    }

    componentDidMount() {
        const userId = this.props.match.params.id;
        if(userId !== 'create'){
            this.setTitle('Editar usuario');
            this.setLabel('Guardar cambios');
            this.getUser(userId);
        }else{
            this.setTitle('Crear usuario');
            this.setLabel('Crear usuario');
        }
    }

    setTitle = (title) => {
        this.setState({title:title});
    }

    setLabel = (label) => {
        this.setState({btnLabel:label});
    }

    getUser = (userId) => {
        API.users.getUser(userId).then(res => {
            const user = res.data.user;
            this.setState({user:user,isUserDisabled:user.is_disabled});
        }).catch(ex => {
            console.log(ex);
        })
    }

    onChangeRoles = (e) => {
        const user = {...this.state.user}
        user.roles = ['user',e.target.value];
        this.setState({user})
    }

    onChange = (e) => {
        this.setState(prevState => {
            let user = Object.assign({}, prevState.user);
            user[e.target.id] = e.target.value;
            return { user };
        });
    }

    onClickBtn = () => {
        const isUserValid = this.validateUser();
        const userId = this.props.match.params.id;

        if(isUserValid){
            if(userId === 'create'){
                //create user
                this.createUser();
            }else{
                //update user
                this.updateUser();
            }
        }
    }

    createUser = () => {
        const user = {...this.state.user};
        user.password = this.state.password;
        const role = user.roles.includes('operator') ? 'operador' : 'administrador';
        const txt = `El usuario <b>${user.name} ${user.last_name}</b> es ahora <b>${role}</b> de Nbio.`;

        API.users.create(user).then((res) =>{
            Swal.fire({
                icon:'success',
                title:'Usuario creado',
                html:txt,
                confirmButtonColor: '#4dda85',
                heightAuto:false
            }).then((res) =>{
                this.props.history.push('/users');
            })
        }).catch((ex) =>{
            let errorMsg = 'Hubo un error al crear el usuario';
            try{
                errorMsg = ex.response.data.error_es;
            }catch(ex){
                errorMsg = 'Servicio no disponible';
            }
            this.fireSwalError(errorMsg);
        })
    }

    updateUser = () => {
        const user = {...this.state.user};

        API.users.updateUser(user).then(res => {
            Swal.fire({
                icon:'success',
                title:'Usuario actualizado',
                confirmButtonColor: '#4dda85',
                heightAuto:false

            }).then((res) =>{
                this.getUser(user._id);
            })
        }).catch(ex => {
            let errorMsg = 'Hubo un error al actualizar el usuario';
            try{
                errorMsg = ex.response.data.error_es;
            }catch(ex){
                errorMsg = 'Servicio no disponible';
            }
            this.fireSwalError(errorMsg);
        })
    }

    validateUser = () => {
        const userId = this.props.match.params.id;
        const user = this.state.user;
        const name = user.name;
        const email = user.email;
        const last_name = user.last_name;
        const roles = user.roles
        const password = this.state.password;

        if(email.trim() === ''){
            return false
        };
        if(name.trim() === ''){
            return false
        };
        if(last_name.trim() === ''){
            return false
        };
        if(userId === 'create'){
            if(password.trim() === 0){
                return false
            };
        }
        if(roles.length === 0){
            return false
        };

        return true
    }

    onClickChangePwd = () => {
        const isPwdsValid = this.validatePwd();
        const userId = this.props.match.params.id;
        const pwd  = this.state.password;
        const pwd2 = this.state.password2;

        if(isPwdsValid){
            API.users.changePassword(userId,pwd,pwd2).then(res => {
                Swal.fire({
                    icon: 'success',
                    title: 'Contraseña actualizada',
                    confirmButtonColor: '#4dda85',
                    heightAuto:false

                }).then((res) => {
                    this.getUser(userId);
                })
            }).catch(ex => {
                this.fireSwalError('Hubo un error al cambiar la contraseña')
            })
        }
    }

    validatePwd = () => {
        const  pwd  = this.state.password.trim();
        const  pwd2 = this.state.password2.trim();

        if(pwd === '' || pwd2 === ''){
            this.fireSwalError('La contraseña no puede ser vacía');
            return false
        }

        if(pwd.length < 6 || pwd2 < 6){
            this.fireSwalError('La contraseña no pueder ser menor de 6 caracteres');
            return false
        }

        if(pwd !== pwd2){
            this.fireSwalError('La contraseñas no coinciden');
            return false
        }
        return true
    }

    onImageDropped = (image) => {
        this.setState({profileImage:image});
    }

    onClickUploadImage = () => {
        const userId = this.props.match.params.id;
        const image = this.state.profileImage;

        if(image){
            API.users.updateImage(userId,image).then(res => {
                Swal.fire({
                    icon:'success',
                    title:'Usuario actualizado',
                    confirmButtonColor: '#4dda85',
                    heightAuto:false

                }).then((res) =>{
                    this.getUser(userId);
                });
            }).catch((ex) => {
                let errorMsg = 'Hubo un error al actualizar la imagen';
                try{
                    errorMsg = ex.response.data.error_es;
                }catch(ex){
                    errorMsg = 'Servicio no disponible';
                }
                this.fireSwalError(errorMsg);
            })
        }else{
            this.fireSwalError('No has agregado la imagen de perfil');
        }
    }

    fireSwalError = (txt) => {
        Swal.fire({
            icon: 'error',
            title: txt,
            confirmButtonColor: '#2a7de1',
            heightAuto:false

        })
    }

    deleteUser = () => {
        const userId = this.props.match.params.id;
        API.users.deleteUser(userId).then(res => {
            Swal.fire({
                icon:'success',
                title:'Usuario borrado',
                confirmButtonColor: '#4dda85',
                heightAuto:false

            }).then((res) =>{
                this.props.history.push('/users');
            });
        }).catch(ex => {
            let errorMsg = 'Hubo un error al borrar el usuario'
            try{
                errorMsg = ex.response.data.error_es;
            }catch(ex){
                errorMsg = 'Servicio no disponible';
            }
            this.fireSwalError(errorMsg);
        })
    }

    enableUser = () => {
        const userId = this.props.match.params.id;
        API.users.enableUser(userId).then(res => {
            Swal.fire({
                icon:'success',
                title:'Usuario habilitado',
                confirmButtonColor: '#4dda85',
                heightAuto:false

            }).then((res) =>{
                this.getUser(userId);
            });
        }).catch(ex => {
            let errorMsg = 'Hubo un error al habilitar el usuario'
            try{
                errorMsg = ex.response.data.error_es;
            }catch(ex){
                errorMsg = 'Servicio no disponible';
            }
            this.fireSwalError(errorMsg);
        })
    }

    disableUser = () => {
        const userId = this.props.match.params.id;
        API.users.disableUser(userId).then(res => {
            Swal.fire({
                icon:'success',
                title:'Usuario deshabilitado',
                confirmButtonColor: '#4dda85',
                heightAuto:false

            }).then((res) =>{
                this.props.history.push('/users');
            });
        }).catch(ex => {
            let errorMsg = 'Hubo un error al deshabilitar el usuario'
            try{
                errorMsg = ex.response.data.error_es;
            }catch(ex){
                errorMsg = 'Servicio no disponible';
            }
            this.fireSwalError(errorMsg);
        })
    }

    confirmDeleteUser = () => {
        Swal.fire({
            icon:'warning',
            title:"¿Estás seguro de borrar el usuario?",
            html: "El usuario será borrado permanentemente del sistema. Esta acción no puede ser revertida",
            confirmButtonColor: '#f32735',
            cancelButtonText: 'No',
            confirmButtonText: 'Si, borrar',
            showCancelButton: true,
            reverseButtons: true,
            heightAuto:false

        }).then((res) =>{
            if (res.isConfirmed) {
                //Delete user
                this.deleteUser();
            }
        });
    }

    confirmDisableUser = () => {
        Swal.fire({
            icon:'warning',
            title:"¿Estás seguro de deshabilitar el usuario?",
            html: "Los usuarios deshabilitados no podrán acceder al sistema. Los usuarios pueden ser habilitados en cualquier momento.",
            confirmButtonColor: '#f32735',
            cancelButtonText: 'No',
            confirmButtonText: 'Si, deshabilitar',
            showCancelButton: true,
            reverseButtons: true,
            heightAuto:false
        }).then((res) =>{
            if (res.isConfirmed) {
                //Disable user
                this.disableUser();
            }
        });
    }

    render(){
        const user = this.state.user;
        const userId = this.props.match.params.id;
        const image = user.profileUrl ? user.profileUrl : null;
        const isUserDisabled = this.state.isUserDisabled;

        return(
            <Container fluid style={{overflowY:"scroll"}} className={'h-100'}>
                <Row>
                    <Col className='py-3'>
                        <div className={'d-flex align-items-center'}>
                            <h5 className={'text-capitalize mr-auto'}>{this.state.title}</h5>
                            <NPIf condition={userId !== "create"}>
                                <DropdownButton id="dropdown-basic-button" title="Configuración" variant={'danger'}>
                                    <Dropdown.Item disabled={isUserDisabled}
                                                   onClick={() => this.confirmDisableUser()}>Deshabilitar usuario
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => this.confirmDeleteUser()}>Borrar usuario
                                    </Dropdown.Item>
                                </DropdownButton>
                            </NPIf>
                        </div>
                    </Col>
                </Row>

                <NPIf condition={isUserDisabled}>
                    <Row>
                        <Col className='py-3'>
                            <Alert variant={'danger'} className={'d-flex align-items-center'}>
                                <span className={'text-uppercase mr-auto'}>Usuario deshabilitado</span>
                                <Button variant={'outline-danger'} onClick={() => this.enableUser()}>Habilitar usuario</Button>
                            </Alert>
                        </Col>
                    </Row>
                </NPIf>

                <Row>
                    <Col className='py-3' sm={userId === "create" ? 12 : 8}>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Datos del usuario
                                </Card.Title>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="email">
                                    <Form.Label column sm={2}>Email</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Email"
                                                      value={user.email}
                                                      disabled={isUserDisabled}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="name">
                                    <Form.Label column sm={2}>Nombre</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Nombre"
                                                      value={user.name}
                                                      disabled={isUserDisabled}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="last_name">
                                    <Form.Label column sm={2}>Apellido</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control type="text"
                                                      placeholder="Apellido"
                                                      value={user.last_name}
                                                      disabled={isUserDisabled}
                                                      onChange={event => this.onChange(event)}/>
                                    </Col>
                                </Form.Group>

                                <NPIf condition={userId === 'create'}>
                                    <Form.Group as={Row} className="mt-4 mb-3" controlId="password">
                                        <Form.Label column sm={2}>Contraseña</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="password" placeholder="Contraseña"
                                                          value={this.state.password}
                                                          disabled={isUserDisabled}
                                                          onChange={event => this.setState({password:event.target.value})}/>
                                        </Col>
                                    </Form.Group>
                                </NPIf>

                                <Form.Group as={Row} className="mt-4 mb-3" controlId="roles">
                                    <Form.Label column sm={2}>Rol</Form.Label>
                                    <Col sm={10}>
                                        <Form.Check
                                            inline
                                            label="Operador"
                                            name="roles"
                                            type={"radio"}
                                            id={"roles"}
                                            value={"operator"}
                                            disabled={isUserDisabled}
                                            onChange={event => this.onChangeRoles(event)}
                                            checked={user.roles.find((r) => r === 'operator')}
                                        />
                                        <Form.Check
                                            inline
                                            label="Administrador"
                                            name="roles"
                                            type={"radio"}
                                            id={"roles3"}
                                            value={"admin"}
                                            disabled={isUserDisabled}
                                            onChange={event => this.onChangeRoles(event)}
                                            checked={user.roles.find((r) => r === 'admin')}
                                        />
                                        <Form.Check
                                            inline
                                            label="Administrador de región"
                                            name="roles"
                                            type={"radio"}
                                            id={"roles4"}
                                            value={"region_admin"}
                                            disabled={isUserDisabled}
                                            onChange={event => this.onChangeRoles(event)}
                                            checked={user.roles.find((r) => r === 'region_admin')}
                                        />
                                        <Form.Check
                                            inline
                                            label="Super Administrador"
                                            name="roles"
                                            type={"radio"}
                                            id={"roles5"}
                                            disabled={isUserDisabled}
                                            value={"super_admin"}
                                            onChange={event => this.onChangeRoles(event)}
                                            checked={user.roles.find((r) => r === 'super_admin')}
                                        />
                                    </Col>
                                </Form.Group>
                                <div className={'d-flex justify-content-end'}>
                                    <Button onClick={() => this.onClickBtn()}
                                            disabled={isUserDisabled}>{this.state.btnLabel}</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <NPIf condition={userId !== 'create'}>
                        <Col className='py-3' sm={4}>
                            <Card.Body>
                                <Card.Title>
                                    Imagen
                                </Card.Title>
                                <DragZone disabled={isUserDisabled}
                                          onImageDropped={this.onImageDropped}
                                          preSelectedImage={image}>

                                </DragZone>

                                <div className={'d-flex justify-content-end mt-3'}>
                                    <Button onClick={() => this.onClickUploadImage()}
                                            disabled={isUserDisabled}>Subir imagen</Button>
                                </div>
                            </Card.Body>
                        </Col>
                    </NPIf>
                </Row>

                <NPIf condition={userId !== 'create'}>
                    <Row className={'mt-3'}>
                        <Col sm={8}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Cambiar contraseña</Card.Title>

                                    <Form.Group as={Row} className="mt-4 mb-3" controlId="password">
                                        <Form.Label column sm={2}>Nueva contraseña</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="password" placeholder="Nueva contraseña"
                                                          value={this.state.password}
                                                          disabled={isUserDisabled}
                                                          onChange={event => this.setState({password:event.target.value})}/>
                                            <small className={'text-muted'}>Mínimo 6 caracteres</small>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mt-4 mb-3" controlId="password">
                                        <Form.Label column sm={2}>Confirmar contraseña</Form.Label>
                                        <Col sm={10}>
                                            <Form.Control type="password" placeholder="Confirmar contraseña"
                                                          value={this.state.password2}
                                                          disabled={isUserDisabled}
                                                          onChange={event => this.setState({password2:event.target.value})}/>
                                            <small className={'text-muted'}>Mínimo 6 caracteres</small>
                                        </Col>
                                    </Form.Group>

                                    <div className={'d-flex justify-content-end'}>
                                        <Button disabled={isUserDisabled}
                                                onClick={() => this.onClickChangePwd()}>Cambiar contraseña</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </NPIf>
            </Container>
        )
    }
}

export default withRouter(User);
