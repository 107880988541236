// Create WebSocket connection.

import NbioApi from "../api/NbioApi";
const NBIO_EVENTS  = {
    ORDER_CREATED: 'ORDER_CREATED',
    ORDER_UPDATED: 'ORDER_UPDATED',
    ORDER_CANCELLED: 'ORDER_CANCELLED',
    USER_CONNECTED:'USER_CONNECTED',
    DATA_DEVICE_UPDATED:'DATA_DEVICE_UPDATED',
    BULK_IMPORT_VALIDATION_PROGRESS:'BULK_IMPORT_VALIDATION_PROGRESS',
    BULK_IMPORT_PROGRESS:'BULK_IMPORT_PROGRESS',
    BULK_IMPORT_VALIDATED:'BULK_IMPORT_VALIDATED',
    BULK_IMPORT_FINISHED:'BULK_IMPORT_FINISHED',
    BULK_IMAGE_UPLOAD_PROGRESS:'BULK_IMAGE_UPLOAD_PROGRESS',
    BULK_IMAGE_UPLOAD_FINISHED:'BULK_IMAGE_UPLOAD_FINISHED'

}
// let socket           = null
const startListening = async () =>{
    const url = process.env.REACT_APP_WS_URL;
    let token = NbioApi.users.getAuthToken() || '-'
    try{
        const socket = new WebSocket(url,[token]);
        // Connection opened
        socket.addEventListener('open', function (event) {
            socket.send('Hello Server!');
        });
        return Promise.resolve(socket);
    }catch(ex){
        return Promise.reject(ex);

    }


    // Listen for messages
    // socket.addEventListener('message', function (event) {
    //     console.log('Message from server ', event.data);
    // });
}
const WSSClient = {
    NBIO_EVENTS:NBIO_EVENTS,
    startListening:startListening,
}
export default WSSClient;
